import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import { Modal, ModalBody } from 'reactstrap';
import { Grid } from '@material-ui/core';
import url from '../../assets/url';
import functions from '../../assets/functions/common_functions';
import axios from "axios";
import cookie from 'react-cookies';
import LottieLoader from '../LottieLoader';

class VideoPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deviceInfo: {},
            browserInfo: {},
            progress: 0,
            videoSize: '',
            showCongrats: false,
            mturkdata: {},
            remainingTime: 0,
            loader: false
        };
    }
    componentDidMount = () => {
        console.log('this props are', this.props);
    }

    handleRecordAgain = () => {
        console.log("handleRecordAgain is");
        this.setState({ discardVideo: true })

    }

    remainingTimeCalculator = (timeStarted, uploadedBytes, totalBytes) => {
        let timeElapsed = ((new Date()) - timeStarted) / 1000;
        let uploadSpeed = uploadedBytes / (timeElapsed);

        return ((totalBytes - uploadedBytes) / uploadSpeed);
    }

    handleSave = (videoData) => {
        console.log("videoData is", videoData);
        this.setState({ modal4: true })
        let self = this
        const auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        const { program_id, partner_id } = this.props.program
        let videoType = videoData.type.split(";")[0]

        let timestamp = new Date().getTime();

        let videoName = 'Recording' + timestamp;

        let getSignedUrlReqest = {
            "action": 'GET_PRESIGNED_URL',
            "nameOfFile": videoName,
            "subId": auto_id,
            "programId": program_id,
            "content_type": videoType
        }

        functions.getSignedUrl(this, getSignedUrlReqest, function (json_response) {
            let data = JSON.parse(json_response).data;
            console.log("response data", data)

            console.log(" is", videoData)
            // post the data on the s3 url
            let videoSizeValue = self.formatBytes(videoData?.size);
            self.setState({ uploadProgress: true, videoSize: videoSizeValue });

            let startingTime = new Date();
            axios.put(data.url, videoData, {
                headers: {
                    'Content-Type': videoType
                },
                onUploadProgress: (progressEvent) => {
                    let remainingTime = self.remainingTimeCalculator(startingTime, progressEvent.loaded, progressEvent.total);
                    self.setState({ remainingTime: remainingTime.toFixed(2) });

                    const progress = (progressEvent.loaded / progressEvent.total) * 100;
                    console.log("progress 1", progress)
                    self.setProgress(progress);
                },
                onDownloadProgress: (progressEvent) => {
                    const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50;
                    console.log(progress);
                    self.setProgress(progress);
                }
            }).then(function (response) {
                console.log(response);


                if (response) {
                    self.setState({ uploadProgress: false, loader: true });

                    let getSignedUrlReqest = {
                        "action": 'GET_PRESIGNED_URL',
                        "nameOfFile": videoName,
                        "subId": auto_id,
                        "programId": program_id,
                        "content_type": videoType,
                        "operationName": 'getObject'
                    }

                    let videoUrlToBeSent = data.fileS3Url;
                    functions.getSignedUrl(self, getSignedUrlReqest, getObjectResponse => {
                        if (getObjectResponse) {
                            console.log("getObjectResponse is", getObjectResponse);
                            let getObjectUrl = JSON.parse(getObjectResponse).data;
                            console.log("getObjectUrl is", getObjectUrl);
                            videoUrlToBeSent = getObjectUrl.url

                            let mturkRequest = {
                                action: 'upload_video',
                                sub_id: auto_id,
                                video_url: videoUrlToBeSent,
                                partner_id: partner_id,
                                program_id: program_id
                            }
                            console.log('mturkRequest is', mturkRequest);
                            self.setState({ showCongrats: true })
                            functions.invokeMturkApi(self, mturkRequest, mturkResponse => {
                                if (mturkResponse) {
                                    console.log("mturkResponse is", mturkResponse);

                                    let mturkdata = JSON.parse(mturkResponse).data;
                                    console.log("mturkResponse is", mturkdata);
                                    if (mturkdata.success == 1) {
                                        self.setState({ loader: false, showCongrats: true, mturkdata: mturkdata.data.Item })

                                        setTimeout(() => {
                                            self.toggleOpen()
                                        }, 3000);

                                    }

                                }
                            })
                        }
                    })



                }
            })
                .catch(function (error) {
                    console.log(error);
                });
        })

    }

    setProgress = (percentage) => {
        console.log("percentage", percentage)
        this.setState({ progress: percentage.toFixed(2) })
    }

    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    discardPreview = () => {
        this.setState({ discardVideo: false })
        this.props.setBlobVideo(null);
        this.props.setvideoRecoder(true)
    }

    toggleOpen = (opend) => {
        console.log("opend", opend)
        let { mturkdata } = this.state
        this.setState({ showCongrats: false })
        this.props.onSubmit(mturkdata)
    }

    render() {
        const { progress, videoSize } = this.state;
        return (
            <div>
                <div className="common_main common_main_rmove videoPreVewUpr">
                    {this.props && this.props.videoBlob && (
                        <div className="videoPreVew 666">
                            <video src={window.URL.createObjectURL(this.props.videoBlob)} loop controls />
                            <div className="videoControl">
                                <Button onClick={() => this.handleRecordAgain()} className="recordAgain">Record Again</Button>
                                <Button onClick={() => this.handleSave(this.props.videoBlob)} className="recordSave">Save</Button>
                            </div>

                            {this.state.loader &&
                                <center>
                                    <LottieLoader className="loader_class" />
                                </center>
                            }
                        </div>
                    )}
                </div>
                <Modal isOpen={this.state.uploadProgress} className="congDetailUpr">
                    <ModalBody className="congDetail">
                        <a className="discardClose" onClick={() => this.setState({ uploadProgress: false })}>
                            <img src={url.family_one_image_url + 'Group5.png'} alt="" title="" style={{ height: 10 }} /></a>
                        <div className="videoUploadUpr">
                            <div className="videoTxt">
                                <label>Upload video</label>
                                <p>Upload your video you want to share.</p>
                            </div>
                            <div className="videoSizeCal">
                                <div className="videoSize">
                                    <strong>Video name</strong>
                                    <span>Size {videoSize}</span>
                                </div>
                                <div className="vdoProgresUpr">
                                    <div className="vdoProgres"><div className="vdoProgresInr" style={{ width: progress + "%" }}></div></div>
                                </div>
                                <div className="videoSizeTime">
                                    <p><strong>{parseInt(progress)}%</strong>
                                        <a>{this.state.remainingTime} seconds remaining</a>
                                    </p>
                                    <span><img src={url.family_one_image_url + 'vdoPause.svg'} alt="" title="" /></span>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.showCongrats} toggle={this.toggleOpen} className="congDetailUpr">
                    <ModalBody className="congDetail">
                        <a className="discardClose" onClick={() => this.toggleOpen()}>
                            <img src={url.family_one_image_url + 'Group5.png'} style={{ height: 10 }} alt="" title="" /></a>
                        <div className="congDetailTxt">
                            <label>Congratulations</label>
                            <Grid><img src={url.family_one_image_url + 'Congratulations.svg'} alt="Congratulations" title="Congratulations" /></Grid>
                            <p>Your video has submitted successfully, we’ll send you an email regarding the video soon.</p>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.discardVideo} className="discardVdoUpr">
                    <ModalBody className="discardVdo">
                        <a className="discardClose" onClick={() => this.setState({ discardVideo: false })}>
                            <img src={url.family_one_image_url + 'Group5.png'} alt="" style={{ height: 10 }} title="" /></a>
                        <label>Discard previous video?</label>
                        <div className="discardBtns">
                            <Button size="medium" color="primary" onClick={() => this.setState({ discardVideo: false })} className="discardBtnsActv">No</Button>
                            <Button size="medium" color="primary" onClick={() => this.discardPreview()}>Yes</Button>
                        </div>
                    </ModalBody>
                </Modal>

            </div>
        );
    }
}
export default VideoPreview;