import React, { Fragment, useState } from "react";
import '../../assets/css/style.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// import { InfoSharpIcon } from '@mui/icons-material';
import FormControl from '@material-ui/core/FormControl';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import functions from '../../assets/functions/common_functions';
import cookie from 'react-cookies';
import { isMobile } from "react-device-detect";
import DatePickerMobile from "react-mobile-datepicker";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { TimelineMax, Bounce, Linear, Power1, Elastic, Back, Sine } from "gsap/all";
import CSSPlugin from 'gsap/CSSPlugin';
import url from '../../assets/url';
import $ from "jquery";
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from "react-html-parser";

// import { Offline, Online } from "react-detect-offline";
// import OfflineComponent from '../boarding/index';
const C = CSSPlugin;
const { v1: uuidv1 } = require('uuid');
const monthMap = {
    '1': 'Jan',
    '2': 'Feb',
    '3': 'Mar',
    '4': 'Apr',
    '5': 'May',
    '6': 'Jun',
    '7': 'Jul',
    '8': 'Aug',
    '9': 'Sep',
    '10': 'Oct',
    '11': 'Nov',
    '12': 'Dec',
};

const Month = {
    "01": "January",
    "02": "February",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    "10": "October",
    "11": "November",
    "12": "December"
};

const dateConfig = {
    'year': {
        format: 'YYYY',
        caption: 'Year',
        step: 1,
    },
    'month': {
        format: value => monthMap[value.getMonth() + 1],
        caption: 'Mon',
        step: 1,
    },
    'date': {
        format: 'DD',
        caption: 'Day',
        step: 1,
    },
};

export default class addachild extends React.Component {
    constructor(props) {
        super(props);
        let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        auto_id = auto_id ? auto_id : ""
        if (auto_id && auto_id != "") {
        }
        else {
            this.props.history.push("/")
        }
        this.state = {
            open_edit: false,
            session_type: localStorage.getItem("session_type") ? localStorage.getItem("session_type") : "",
            open: false,
            modal_confirm: false,
            open_dailog: false,
            button_selected: 'Unknown',
            dynamic_content: "",
            child_state: 'Expectant',
            minimum_date: "",
            maximum_date: "",
            birthdate_entered: localStorage.getItem("form_type") == "edit_child" ? true : false,
            time: new Date(),
            previous_state: localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "",
            show_date: false,
            isOpen: false,
            child_validate: false,
            select_relation: false,
            addChildParent: false,
            multiple_birthdate: "",
            add_as_relative: localStorage.getItem("add_as_relative") == "true" ? true : false,
            relation_with_child: localStorage.getItem("add_as_relative") == "true" ? "parent" : "parent",
            index: localStorage.getItem("edit_index") ? localStorage.getItem("edit_index") : 0,
            child_date: new Date(),
            child_data: localStorage.getItem("child_data") ? JSON.parse(localStorage.getItem("child_data")) : [],
            add_child: localStorage.getItem("form_type") == "edit_child" ? false : true,
            creator_auto_id: localStorage.getItem("auto_id"),
        };
        this.toggle = this.toggle.bind(this);
    }

    dateFormat = (value) => {
        if (value) {

            let date_array = value.split("-");
            if (date_array && date_array.length > 1) {
                let year = date_array[0];
                let date = date_array[2];
                let month = date_array[1];
                let month_name = Month[month];
                let child_date = date + " " + month_name + " " + year;
                console.log("child_date", child_date)
                return child_date;
            }
            else {
                date_array = value.split("/");
                if (date_array && date_array.length > 1) {
                    let year = date_array[0];
                    let date = date_array[2];
                    let month = date_array[1];
                    let month_name = Month[month];
                    let child_date = date + " " + month_name + " " + year;
                    console.log("child_date", child_date)
                    return child_date;
                }
            }
        } else {
            return value;
        }
    }

    formateAddChildDate = (child_date) => {
        console.log("child_date", child_date)
        let value = new Date(child_date)
        console.log("month", value.getMonth())
        let month = value.getMonth() + 1
        console.log("month", month)
        let date_month = month < 10 ? "0" + month : month;
        let date_year = value.getFullYear();
        let date_date = value.getDate() < 10 ? "0" + value.getDate() : value.getDate();
        value = date_year + "-" + date_month + "-" + date_date;
        return value;
    }

    toggle() {
        console.log("here")
        this.setState({
            modal: !this.state.modal
        });
    }

    toggle_info() {
        console.log("here")
        this.setState({
            modal_info: !this.state.modal_info
        });
    }

    handleChildDateUpdate = () => {
        let action = "User clicked on Change button of Add child page"
        let image = 'user_clicked_change_button_add_child.png'
        functions.logUserActivity(action, image)
        this.toggle_confirm()
        if (isMobile) {
            this.handleClickDate()
        }
        else {
            console.log("here")
            $('#select_date').click()
        }
    }

    toggle_confirm() {
        this.setState({
            modal_confirm: !this.state.modal_confirm
        })
    }
    toggle_select_relation() {
        this.setState({
            select_relation: !this.state.select_relation,
            addChildParent: true
        })
    }

    handleTwinsClose = () => {
        let action = "User clicked on cancel of multiple child confirmation popup of Add child page"
        let image = 'user_clicked_cancel_of_mutiple_child_confirmation.png'
        functions.logUserActivity(action, image)
        this.toggle_twins()
    }

    toggle_twins() {
        this.setState({
            open_dailog: !this.state.open_dailog
        })
    }

    handleName = (name) => {
        let child_data = this.state.child_data;
        child_data[this.state.index].child_name = name;
        this.setState({ child_data: child_data, show_error_message: '' })
        let selectedRelative = this.props.location?.state?.stateData?.selectedRelation
        console.log("let selectedRelative = this.props.location?.state?.stateData?.selectedRelation", selectedRelative)
    }

    handleGender = (gender) => {
        let action = "User clicked on " + gender + " gender button on Add child page"
        let image = 'user_clicked_on_gender_' + gender + '.png';
        functions.logUserActivity(action, image)
        let child_data = this.state.child_data;
        child_data[this.state.index].child_gender = gender;
        this.setState({ child_data: child_data, button_selected: gender, show_error_message: '' })
        console.log("child_data", child_data);
    }

    handleType = (value) => {
        let action = "User clicked on " + value + " button on Add child page"
        let image = 'user_clicked_on_' + value + '_button_add_child.png'
        functions.logUserActivity(action, image)
        let child_data = this.state.child_data;
        child_data[this.state.index].type = value;
        if (value == "Existing") {
            child_data[this.state.index].child_gender = "Boy";
        }
        this.setState({ child_data: child_data, show_error_message: '' })
        console.log("child_data", child_data);
    }

    handleChildRelation = (value) => {
        let action = "User clicked on " + value + " button on Add child page"
        let image = ''
        functions.logUserActivity(action, image)
        let child_data = this.state.child_data;
        child_data[this.state.index].relation_with_child = value;
        this.setState({ child_data: child_data, show_error_message: '' })
        console.log("child_data", child_data);
        this.toggle_select_relation()
    }

    componentDidMount = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        let image = 'add_child_page_landing.png'
        functions.logUserActivity("User land on Add child page", image)
        this.handleCircleGrowEffect();
        let childs_data = this.state.child_data;

        if (childs_data && childs_data.length) {
            if (typeof childs_data == 'string') {
                childs_data = JSON.parse(childs_data)
            }
            childs_data.map((child_data) => {
                console.log("child_data.length23", child_data.child_birthdate.length)
                if (child_data.child_birthdate.length <= 9) {
                    let childs = child_data.child_birthdate.split('-');
                    if (childs[2].length == 1) childs[2] = '0' + childs[2]
                    if (childs[1].length == 1) childs[1] = '0' + childs[1]
                    child_data.child_birthdate = childs[0] + '-' + childs[1] + '-' + childs[2]
                    child_data.birthdate_timestamp = new Date(child_data.child_birthdate).getTime()
                }
                else {
                    child_data.birthdate_timestamp = new Date(child_data.child_birthdate).getTime()
                }
            })
            localStorage.setItem("child_data", JSON.stringify(childs_data))
            this.setState({ child_data: childs_data })
        }
        setTimeout(() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth' // You can use 'smooth' if you prefer smooth scrolling
            });
          }, 0);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
          setTimeout(() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth' // You can use 'smooth' if you prefer smooth scrolling
            });
          }, 0);
        }
      }

    componentWillMount = () => {
        let current_state = localStorage.getItem('current_state');
        if (current_state !== 'addchild') {
            functions.getLandingPage(this);
        }
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            console.log("dynamic_content", dynamic_content)
            this.setState({ dynamic_content: dynamic_content })
        }
        if (this.state.add_child) {
            this.toggle();
        }
        else {
            let min = new Date()
            let max = new Date();
            min.setFullYear(min.getFullYear() - 19);
            max.setMonth(max.getMonth() + 9);
            let minimum_date = this.formatMinMaxDate(min);
            let maximum_date = this.formatMinMaxDate(max);
            console.log("minimum_date", minimum_date)
            console.log("maximum_date", maximum_date)
            let child_date;
            // Check if child_data is an array and has the correct index
            if (Array.isArray(this.state.child_data) && this.state.child_data.length > this.state.index) {
                // Check if the object at the index has a child_birthdate property
                if (this.state.child_data[this.state.index].hasOwnProperty('child_birthdate')) {
                    child_date = this.state.child_data[this.state.index].child_birthdate;
                } else {
                    child_date = null;
                }
            } else {
                child_date = null;
            }
            let time = new Date(child_date)
            this.setState({ minimum_date: minimum_date, maximum_date: maximum_date, time: time, show_date: true })
        }
    }

    formatMinMaxDate = (date) => {
        console.log("date max", date)
        let date_month = date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
        let date_year = date.getFullYear();
        let date_date = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let final_date = date_year + "-" + date_month + "-" + date_date;
        console.log("final_date", final_date)
        return final_date;
    }

    handleAddChild = (value, toggle) => {
        let action = "User clicked on " + value + " on popup of Add child page"
        let image = 'user_clicked_' + value + '_button_add_child.png'
        functions.logUserActivity(action, image)
        let child_date;
        let min = new Date()
        let max = new Date();

        min.setFullYear(min.getFullYear() - 19);
        max.setMonth(max.getMonth() + 9);
        let minimum_date = this.formatMinMaxDate(min);
        let maximum_date = this.formatMinMaxDate(max);
        console.log("minimum_date", minimum_date)
        console.log("maximum_date", maximum_date)
        let child_gender = "Unknown"
        this.setState({ minimum_date: minimum_date, maximum_date: maximum_date })
        if (value == 'Expectant') {
            let child_min = new Date()
            let child_minimum_date = this.formatMinMaxDate(child_min);
            child_date = child_minimum_date

        }
        else if (value == 'Existing') {
            let child_max = new Date()
            let child_maximum_date = this.formatMinMaxDate(child_max);
            child_date = child_maximum_date
            child_gender = "Boy"
        }

        let child_data = localStorage.getItem("child_data") ? JSON.parse(localStorage.getItem("child_data")) : [];
        let child_name_number = child_data.length + 1;
        let index = child_data.length;

        let child_name = "Child " + child_name_number
        let type = value
        let child_birthdate = this.formateAddChildDate(child_date);
        let birthdate_timestamp = new Date(child_date).getTime();
        let time = new Date(child_date)
        let { relation_with_child, add_as_relative, creator_auto_id } = this.state
        let data = { child_name: child_name, child_gender: child_gender, child_birthdate: child_birthdate, birthdate_timestamp: birthdate_timestamp, type: type, child_id: uuidv1(), relation_with_child: relation_with_child, creator_auto_id: creator_auto_id }
        child_data.push(data)
        console.log("child_data", child_data);
        this.setState({ time: time, child_data: child_data, add_child: true, index: index, button_selected: "Unknown", show_error_message: '' })
        console.log("add_as_relative", add_as_relative)
        if (toggle == "true") {
            this.toggle()
            if (add_as_relative == true) {
                this.toggle_select_relation()
            }
        }
    }

    handleClose() {
        this.setState({ open: false, open_edit: false, show_error_message: '' })
    }

    handleChildDate = (child_date) => {
        // console.log("child_date", child_date)
        // console.log("month", child_date.getMonth())
        let month = child_date.getMonth() + 1
        // console.log("month", month)
        let date_month = month < 10 ? "0" + month : month;
        let date_year = child_date.getFullYear();
        let date_date = child_date.getDate() < 10 ? "0" + child_date.getDate() : child_date.getDate();
        child_date = date_year + "-" + date_month + "-" + date_date;
        let timestamp = new Date(child_date).getTime()
        let minimum_date = new Date(this.state.minimum_date)
        let maximum_date = new Date(this.state.maximum_date)
        if (timestamp >= minimum_date.getTime() && timestamp <= maximum_date.getTime() || child_date == '') {
            let child_data = this.state.child_data;
            child_data[this.state.index].child_birthdate = child_date;
            let time = new Date(child_date)
            child_data[this.state.index].birthdate_timestamp = new Date(child_date).getTime();
            this.setState({
                child_data: child_data,
                time: time,
                show_date: true,
                show_error_message: '',
                birthdate_entered: true
            });
            // console.log("child_data", child_data);
        }
    }

    handleContinue = (path) => {
        let user_action = "User got error on signup/addchild file handleContinue function";

        let action = "User clicked on " + path + " on Add child page"
        let image = 'user_clicked_' + path + '_child_page.png'
        functions.logUserActivity(action, image)
        // if (confirm == "") {
        //     console.log("confirm")
        //     this.toggle_confirm()
        // }
        // else if (confirm == "confirm") {
        //     console.log("confirm", confirm)
        // }
        let self = this;
        let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
        console.log("previous_state", previous_state)
        localStorage.setItem("revert_page", previous_state)
        if (path == "continue") {
            console.log('child', this.state.child_data[this.state.index]);
            console.log("birthdate_entered", this.state.birthdate_entered)
            console.log('this.state.child_data[this.state.index].child_birthdate', this.state.child_data[this.state.index].child_birthdate)
            if (this.state.child_data[this.state.index].child_birthdate && this.state.child_data[this.state.index].child_birthdate != '' && (this.state.child_data[this.state.index].child_birthdate).length > 3 && this.state.child_data[this.state.index].birthdate_timestamp != 0 && this.state.child_data[this.state.index].child_name != '' && this.state.birthdate_entered == true) {
                //Code to check child age
                let child_data = this.state.child_data;
                let child_verified = true;
                let same_date = 0;
                child_data = Array.isArray(this.state.child_data) ? this.state.child_data : JSON.parse(this.state.child_data)
                if (child_data.length > 0) {
                    child_data.forEach((element, index) => {
                        console.log("index", index)
                        if (element.relation_with_child == "parent") {
                            if (element.child_birthdate) {
                                if (element.child_birthdate != child_data[this.state.index].child_birthdate) {
                                    if (element.child_birthdate && element.child_birthdate.length <= 9) {
                                        let childs = element.child_birthdate.split('-');
                                        if (childs[2].length == 1) childs[2] = '0' + childs[2]
                                        if (childs[1].length == 1) childs[1] = '0' + childs[1]
                                        element.child_birthdate = childs[0] + '-' + childs[1] + '-' + childs[2]
                                        element.birthdate_timestamp = new Date(element.child_birthdate).getTime()
                                    }
                                    else {
                                        child_data.birthdate_timestamp = new Date(child_data.child_birthdate).getTime()
                                    }
                                    console.log("new child age", child_data[this.state.index].child_birthdate);
                                    console.log("check child age", element.child_birthdate);
                                    let diffTime = Math.abs(new Date(child_data[this.state.index].child_birthdate) - new Date(element.child_birthdate));
                                    console.log(diffTime);
                                    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                                    console.log(diffDays);
                                    if (diffDays < 2 || diffDays > 273) {
                                        console.log("add child");
                                        if (diffDays < 2) {
                                            same_date = same_date + 1;
                                        }
                                    }
                                    else {
                                        child_verified = false;
                                    }
                                }
                                else {
                                    same_date = same_date + 1;
                                }
                            }
                            else {
                                console.log("add child");
                            }
                        }
                        else {
                            console.log("add child");
                        }
                    });
                    console.log("same_date child", same_date, child_verified, this.state.child_data[this.state.index])
                    if (child_verified == true) {
                        if (same_date == 2 || same_date == 3) {
                            self.setState({ show_error_dailog: "You are adding twince.", open_dailog: true })
                        }
                        else if (same_date < 7) {
                            self.handleUpdateChild(child_verified)
                        } else
                            if (this.state.child_data[this.state.index].relation_with_child !== "parent") {
                                self.handleUpdateChild(true)
                            } else {
                                let body = url.dynamic_content.error_message.child_have_same_birthdate.en
                                functions.logUserError(user_action, body);
                                self.setState({ show_error_message: url.dynamic_content.error_message.child_have_same_birthdate.en })
                            }
                    } else
                        if (this.state.child_data[this.state.index].relation_with_child !== "parent") {
                            self.handleUpdateChild(true)
                        }
                        else {
                            let body = url.dynamic_content.error_message.incorrect_child_date.en
                            functions.logUserError(user_action, body);
                            this.setState({ show_error_message: url.dynamic_content.error_message.incorrect_child_date.en })
                        }
                }
                //End of Code to check child age
            }
            else {
                let body = url.dynamic_content.error_message.all_fields_required.en
                functions.logUserError(user_action, body);
                this.setState({ show_error_message: url.dynamic_content.error_message.all_fields_required.en })
            }
        }
        else if (path == "cancel") {
            // this.toggle()
            if (localStorage.getItem("child_data") && JSON.parse(localStorage.getItem("child_data")).length > 0) {
                let child_data = this.state.child_data;
                console.log("child_data", child_data)
                child_data = Array.isArray(this.state.child_data) ? this.state.child_data : JSON.parse(this.state.child_data)
                let length = child_data.length;
                console.log("length", length)
                let final_child_data = []
                if (child_data.length > 0) {
                    child_data.forEach((element, index) => {
                        if (index < (length - 1)) {
                            final_child_data.push(element)
                        }
                    })
                }
                this.setState({ add_child: false, child_data: final_child_data })
                if (previous_state == "family") {
                    localStorage.setItem("family_activeTab", '1')
                    setTimeout(function () {
                        functions.pushToRoute(self, "family", 'addchild')
                    }, 0)
                }
                else if (previous_state == "buildfamily") {
                    // setTimeout(function(){
                    functions.pushToRoute(self, "buildfamily", 'addchild')
                    // },0)
                }
                else if (previous_state == "program_detail") {
                    functions.pushToRoute(self, "main", 'addchild')
                }
                else if (previous_state == "main") {
                    functions.pushToRoute(self, "main", 'addchild')
                }
                else if (previous_state == "select-children") {
                    functions.pushToRoute(self, "select-children", 'addchild')
                }
                else if (previous_state == "addchildpopup") {
                    functions.pushToRoute(self, "addchildpopup", 'addchild')
                }
                else {
                    setTimeout(function () {
                        functions.pushToRoute(self, "buildyourfamily", 'addchild')
                    }, 0)
                }
            }
            else {
                if (previous_state == "family") {
                    localStorage.setItem("family_activeTab", '1')
                    functions.pushToRoute(self, "family", 'addchild')
                }
                else if (previous_state == "buildfamily") {
                    functions.pushToRoute(self, "buildfamily", 'addchild')
                }
                else if (previous_state == "program_detail") {
                    functions.pushToRoute(self, "main", 'addchild')
                }
                else if (previous_state == "main") {
                    functions.pushToRoute(self, "main", 'addchild')
                }
                else if (previous_state == "select-children") {
                    functions.pushToRoute(self, "select-children", 'addchild')
                }
                else {
                    functions.pushToRoute(self, "buildyourfamily", 'addchild')
                }
            }
        }
    }

    handleUpdateChild = () => {

        let child_data = this.state.child_data
        let index = this.state.index;
        console.log("child_data", child_data[index])
        let type = child_data[index].type
        let birthdate_timestamp = child_data[index].birthdate_timestamp
        console.log("type", type)
        if (type == "Expectant") {
            let min = new Date()
            let max = new Date();
            // min.setHours(min.getHours() + 24)
            let temp = max.getDate()
            max.setMonth(max.getMonth() + 9);
            let minimum_date = this.formatMinMaxDate(min);
            let maximum_date = this.formatMinMaxDate(max);
            let min_time = new Date(minimum_date).getTime()
            let max_time = new Date(maximum_date).getTime()
            console.log("min_time", min_time)
            console.log("max_time", max_time)
            if (birthdate_timestamp <= max_time && birthdate_timestamp >= min_time) {
                console.log("type ok", type)
                this.handleUpdateType(this.state.child_data)
            } else {
                console.log("type not ok", type)
                this.toggle_confirm()
            }
        } else if (type == "Existing") {
            let max = new Date();
            let min = new Date();
            let temp = max.getDate()
            console.log("temp", temp)
            min.setFullYear(min.getFullYear() - 19);
            let minimum_date = this.formatMinMaxDate(min);
            let maximum_date = this.formatMinMaxDate(max);
            let min_time = new Date(minimum_date).getTime()
            let max_time = new Date(maximum_date).getTime()
            console.log("min_time", min_time)
            console.log("max_time", max_time)
            if (birthdate_timestamp <= max_time && birthdate_timestamp >= min_time) {
                console.log("type ok", type)
                this.handleUpdateType(this.state.child_data)
            } else {
                console.log("type not ok", type)
                this.toggle_confirm()
            }
        }

    }

    hanleConfirm = () => {
        let action = "User clicked on Confirm Date of Add child page"
        let image = 'user_clicked_confirm_date_child_page.png'
        functions.logUserActivity(action, image)
        let child_data = this.state.child_data
        let index = this.state.index;
        console.log("child_data", child_data[index])
        let type = child_data[index].type
        if (type == "Expectant") {
            child_data[index].type = "Existing"
            child_data[index].child_gender = "Boy"
        } else if (type == "Existing") {
            child_data[index].type = "Expectant"
        }
        this.setState({ child_data: child_data, show_error_message: '' })
        console.log("child_data", child_data);
        this.handleUpdateType(child_data)
    }

    handleUpdateType = (child_data) => {
        let self = this;
        this.setState({ child_validate: true })
        let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        console.log("sub_id", sub_id)
        let old_child_data = localStorage.getItem('child_data') ? JSON.parse(localStorage.getItem('child_data')) : "";
        let index = this.state.index;
        let update_rel_child = false;
        console.log("child_data[index]", child_data[index])
        console.log("old_child_data[index]", old_child_data[index])

        if ((old_child_data && old_child_data[index])) {

            if (child_data[index].child_name !== old_child_data[index].child_name ||
                child_data[index].child_gender !== old_child_data[index].child_gender ||
                child_data[index].child_birthdate !== old_child_data[index].child_birthdate ||
                child_data[index].type !== old_child_data[index].type) {
                update_rel_child = child_data[index]
            }

        }

        console.log("update_rel_child", update_rel_child)
        this.handleUpdate(child_data, update_rel_child);
        localStorage.setItem("child_data", JSON.stringify(child_data))
        this.setState({ add_child: false, show_error_message: '' })
        let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
        console.log("handleUpdateType")
        if (previous_state == "addchildpopup") {
            functions.pushToRoute(self, "main", 'addchildpopup')
        } else
            if (previous_state == "select-children" || previous_state == "family" || previous_state == "main") {
                localStorage.setItem("family_activeTab", '1')
                setTimeout(function () {
                    functions.pushToRoute(self, "family", 'addchild')
                }, 1000)
            }
            else {
                functions.pushToRoute(self, "buildfamily", 'addchild');
            }
    }

    handleUpdate = (child_data, update_rel_child = false) => {
        let userdata = [];
        let today_timestamp = new Date()
        let add_child_data = child_data[this.state.index]
        console.log("add_child_data", add_child_data)
        let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        console.log("sub_id", sub_id)
        let previous_state = localStorage.getItem("previous_state")
        if (sub_id && sub_id != "") {
            let modify_child_json = {
                "action": 'migrate_child_data',
                child_id: add_child_data.child_id,
                sub_id: sub_id,
                child_data: add_child_data,
                last_add_child_ppoup_timestamp: today_timestamp.getTime()
            }
            let selectedRelative = this.props.location?.state?.stateData?.selectedRelation
            console.log("selectedRelative selectedRelative", selectedRelative)

            console.log("modify_child_json", modify_child_json);
            functions.SyncInMysql(this, modify_child_json);
            let dynamo_userdata = { 'child_data': child_data };
            console.log("selectedRelative selectedRelative2", selectedRelative)
            functions.updateUserDataChildName(this, userdata, dynamo_userdata, false, update_rel_child);
            if (selectedRelative && previous_state == "select-children" && selectedRelative.email && this.state.child_data[this.state.index].relation_with_child !== "parent") {
                let notifyrel = {
                    sub_id,
                    first_name: selectedRelative.given_name,
                    last_name: selectedRelative.family_name,
                    relative_email: selectedRelative.email,
                    relative_id: selectedRelative.relative_id,
                    child_id: [this.state.child_data[this.state.index].child_id]
                }
                console.log("notifyrel", notifyrel)
                functions.RequestChildAddToParent(this, notifyrel, notifyres => {

                    if (notifyres) {
                        console.log('notifyres is', notifyres);

                    }
                })
            }

        }
    }

    handleCircleGrowEffect = () => {
        let self = this;
        var body = document.getElementById("add_child_form");
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        var piTwo = Math.PI * 2;
        if (body) {
            // Viewport
            var vw = canvas.width = body.offsetWidth;
            var vh = canvas.height = body.offsetHeight;
            console.log('View Port width height', vw + ' ' + vh);

            if (document.getElementById('add_child_continue')) {
                document.getElementById('add_child_continue').addEventListener("click", createRipple)
            }
            window.addEventListener("resize", onResize);

            function createRipple(event) {
                if (!self.state.show_error_message && self.state.child_validate == true) {
                    console.log('event data', event);
                    // Exit if target is the canvas. That means it's still active.
                    if (event.target === canvas) return;
                    body.appendChild(canvas);

                    // Event coords
                    var x = event.clientX;
                    var y = event.clientY;

                    var dx = x < vw / 2 ? vw - x : x;
                    var dy = y < vh / 2 ? vh - y : y;
                    var radius = Math.sqrt(dx * dx + dy * dy);
                    var ripple = {
                        alpha: 0,
                        radius: 0,
                        x: vw / 2,
                        y: y
                    };
                    var tl = new TimelineMax({ onUpdate: drawRipple, onComplete: removeCanvas })
                        .to(ripple, 1.5, { alpha: 1, radius: radius })
                        .to(ripple, 0.1, { alpha: 0 }, 1.4);

                    function drawRipple() {
                        ctx.clearRect(0, 0, vw, vh);
                        ctx.beginPath();
                        ctx.arc(ripple.x, ripple.y, ripple.radius, 0, piTwo, false);
                        ctx.fillStyle = "rgba(252,241,239," + ripple.alpha + ")";
                        ctx.fill();
                    }
                }
            }

            function removeCanvas() {
                if (body.contains(canvas)) {
                    body.removeChild(canvas);
                }
            }
            function onResize() {
                vw = canvas.width = window.innerWidth;
                vh = canvas.height = window.innerHeight;
            }
        }
    }

    handleSelect = child_date => {
        this.setState({ isOpen: false });
        console.log("child_date", child_date)
        console.log("month", child_date.getMonth())
        let month = child_date.getMonth() + 1
        console.log("month", month)
        let date_month = month < 10 ? "0" + month : month;
        let date_year = child_date.getFullYear();
        let date_date = child_date.getDate() < 10 ? "0" + child_date.getDate() : child_date.getDate();
        child_date = date_year + "-" + date_month + "-" + date_date;
        let child_data = this.state.child_data;
        child_data[this.state.index].child_birthdate = child_date;
        child_data[this.state.index].birthdate_timestamp = new Date(child_date).getTime();
        let time = new Date(child_date)
        this.setState({
            child_data: child_data,
            time: time,
            show_date: true,
            show_error_message: '',
            birthdate_entered: true
        });
    };

    handleCancel = () => {
        let action = "User clicked on Cancel button of datepicker of Add child page"
        let image = 'user_clicked_cancel_button_of_datepicker_child_page.png'
        functions.logUserActivity(action, image)
        this.setState({ isOpen: false });
    };

    handleClickDate = () => {
        let action = "User clicked on date on Add child page"
        let image = 'user_clicked_date_child_page.png'
        functions.logUserActivity(action, image)
        this._showDateTimePicker();
    }

    _showDateTimePicker = () => this.setState({ isOpen: true });

    _hideDateTimePicker = () => this.setState({ isOpen: false });

    handleCloseDialog = () => {
        this.setState({ open_dailog: false })
    }

    handleAgree = () => {
        let action = "User clicked on Add Multiple of Add child page"
        let image = 'user_clicked_add_multiple_child_page.png'
        functions.logUserActivity(action, image)
        this.setState({ open_dailog: false })
        this.handleUpdateChild()
    }

    dateShowFormat = (value) => {
        let date_array = value.split("/");
        if (date_array.length > 1) {
            let year = date_array[0];
            let date = date_array[2];
            let month = date_array[1];
            let month_name = Month[month].substring(0, 3);
            let child_date = month_name + " " + date + ", " + year;
            return child_date;
        }
        else {
            date_array = value.split("-");
            if (date_array.length > 1) {
                let year = date_array[0];
                let date = date_array[2];
                let month = date_array[1];
                let month_name = Month[month].substring(0, 3);
                let child_date = month_name + " " + date + ", " + year;
                return child_date;
            }
        }
    }

    handleInfoClick = () => {
        let action = "User clicked on Info of Add child page"
        let image = ''
        functions.logUserActivity(action, image)
        this.toggle_info()
    }
    toggleDeleteConfirm = () => {
        this.setState({
            delete_confirm_modal: !this.state.delete_confirm_modal
        });
    }

    handleYes = () => {
        let action1 = "User clicked on yes button on family page"
        let image = 'yes_button_family_page.png'
        functions.logUserActivity(action1, image);

        this.handleDeleteChild()


    }
    
    handleDeleteChild = () => {
        let child_data = this.state.child_data
        let final_child_data = [];
        console.log("child_data", child_data)
        console.log("this.state.index", this.state.index)
        let delete_child = child_data[this.state.index]
        let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        if (sub_id && sub_id != "") {
            let delete_final_json = {
                "action": "delete-child",
                sub_id: sub_id,
                child_id: delete_child.child_id
            }
            child_data.forEach((element, index) => {
                if (index != this.state.index) {
                    final_child_data.push(element)
                }
            });
            this.setState({ child_data: final_child_data, open_edit: false })
            functions.SyncInMysql(this, delete_final_json);
            console.log("final_child_data", final_child_data)
            this.handleUpdateChildDelete(final_child_data);

            if (final_child_data.length < 1) {
                localStorage.removeItem("child_data")
                localStorage.setItem("child_deleted", "yes")
            } else {
                localStorage.setItem("child_data", JSON.stringify(final_child_data))
            }
        }

    }

    handleUpdateChildDelete = (child_data) => {
        let userdata = [];
        let send_child_data = []
        let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        child_data.map(childs => {
            if (childs.accept_status == "pending" && childs.creator_auto_id !== auto_id) {

            }
            else {
                send_child_data.push(childs)
            }
        })
        let dynamo_userdata = { 'child_data': send_child_data };
        functions.updateUserData(this, userdata, dynamo_userdata);
        setTimeout(() => {
            this.handleContinue('cancel')
        }, 100); 
    }

    render() {
        const { dynamic_content, add_child, previous_state, add_as_relative, relation_with_child} = this.state
        return (
            <div className="container" id="add_child_form">
                {/* <Online> */}
                {dynamic_content != "" &&
                    <div className="row">
                        <div className="common_mainAddOn">
                            <div className='usrBuildSetBg'>
                                <div className="common_main resSection_main">
                            <div onClick={() => { this.handleContinue('cancel') }} className="sign_backlink sign_backlinkNw">
                                <img src={require('../../assets/images/bkArorIcon.svg')} alt="" title="" />
                            </div>
                            {/* <div className="clearlink"><img src={url.family_one_image_url + 'Group5.png'} alt="" title="" /></div> */}
                            <div className="sign_up">
                                <h1>{!add_child || (previous_state != "buildfamily" && previous_state != "buildyourfamily") ? " " : "Sign Up"}</h1>
                            </div>
                            <div className="point_benefits">
                                <div className="info_head">
                                    {add_child ? <h2>{dynamic_content.static_text.addchild ? dynamic_content.static_text.addchild.add_child.en : "Add a Child"}</h2> : <h2>{"Edit Child's Info"}</h2>}
                                    {/* <span onClick={() => { this.handleInfoClick() }}>i</span> */}
                                </div>
                                {/* {add_child ? <p>{dynamic_content.static_text.addchild ? dynamic_content.static_text.addchild.points_and_benifits.en : "Text about points and benefits"}</p> : <p></p>} */}
                            </div>
                            {this.state.child_data[this.state.index] &&
                                <div className="unAdd_infoUpr">
                                    <div onClick={() => { this.handleType("Expectant") }} className={this.state.child_data[this.state.index].type == "Expectant" ? "unAdd_info unAdd_infoLft unAdd_infoActv" : "unAdd_info unAdd_infoLft"}>
                                            <div className="unchild_left">
                                                <img src={require('../../assets/images/ExpectantNw.svg')} alt="" title="" />
                                            </div>
                                            <div className="unAdd_rght ExpectantLbl">
                                                <label>{dynamic_content.static_text.addchild ? dynamic_content.static_text.addchild.expectant.en : "Expectant"}</label>
                                            </div>
                                            </div>
                                            <div onClick={() => { this.handleType("Existing") }} className={this.state.child_data[this.state.index].type == "Existing" ? "unAdd_info unAdd_infoRght unAdd_infoActv" : "unAdd_info unAdd_infoRght"}>
                                                <div className="unAdd_left">
                                                    <img src={require('../../assets/images/ChildNew2.png')} alt="" title="" />
                                                </div>
                                                <div className="unAdd_rght ExistingLbl">
                                                    <label>{dynamic_content.static_text.addchild ? dynamic_content.static_text.addchild.existing.en : "Existing"}</label>
                                                </div>
                                            </div>
                                </div>
                            }

                            <div className="addchild_form">
                                {this.state.child_data[this.state.index] &&
                                    <FormControl>
                                        <div className="expectant_name">
                                            {console.log("this.state.child_data[this.state.index].child_name", this.state.child_data[this.state.index].child_name)}

                                            <TextField
                                                autoComplete='off'
                                                autoFocus={false}
                                                hiddenLabel = "true"
                                                //label="Name"
                                                type="text"
                                                margin="normal"
                                                placeholder='Name'
                                                value={this.state.child_data[this.state.index].child_name}
                                                onChange={(event) => { this.handleName(event.target.value) }}
                                            />
                                            {/* {add_child && <p>{this.state.child_data[this.state.index].type}</p>} */}
                                        </div>
                                        <div className="addchild_gender">
                                            {/* <p>{dynamic_content.static_text.addchild ? dynamic_content.static_text.addchild.baby_gender.en : "Baby gender"}</p> */}
                                            {this.state.child_data[this.state.index].type != 'Existing' &&
                                                <Button size="medium" color="primary" onClick={() => { this.handleGender('Unknown') }} className={this.state.child_data[this.state.index].child_gender === 'Unknown' ? "gender_active" : ""} >
                                                    {dynamic_content.static_text.addchild ? dynamic_content.static_text.addchild.unknown.en : "Unknown"}
                                                </Button>
                                            }
                                            <Button size="medium" color="primary" onClick={() => { this.handleGender('Boy') }} className={this.state.child_data[this.state.index].child_gender === 'Boy' ? "genderboy_active" : ""} >
                                                {dynamic_content.static_text.addchild ? dynamic_content.static_text.addchild.boy.en : "Boy"}
                                            </Button>
                                            <Button size="medium" color="primary" onClick={() => { this.handleGender('Girl') }} className={this.state.child_data[this.state.index].child_gender === 'Girl' ? "gendergirl_active" : ""} >
                                                {dynamic_content.static_text.addchild ? dynamic_content.static_text.addchild.girl.en : "Girl"}
                                            </Button>
                                        </div>
                                        <div className="mt-16">
                                            {/* {isMobile && this.state.minimum_date && this.state.maximum_date &&
                                                <div>
                                                    <TextField
                                                        autoComplete='off'
                                                        onClick={() => { this.handleClickDate() }}
                                                        label={this.state.child_data[this.state.index].type == 'Existing' ? "Baby birth date?" : "When is the baby due to arrive?"}
                                                        type="text"
                                                        autoFocus={true}
                                                        inputRef={(input) => { this.child_birthdate = input; }}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        value={this.state.show_date == true ? this.dateFormat(this.state.child_data[this.state.index].child_birthdate) : " "}
                                                        // 
                                                        onChange={(e) => { console.log("e", e) }}
                                                        margin="normal"
                                                    />
                                                    <label>

                                                    </label>
                                                    <DatePickerMobile
                                                        value={this.state.time}
                                                        isOpen={this.state.isOpen}
                                                        onSelect={this.handleSelect}
                                                        onCancel={this.handleCancel}
                                                        theme="ios"
                                                        cancelText="Cancel"
                                                        confirmText="Ok"
                                                        showFormat="MM/DD/YYYY"
                                                        min={new Date(this.state.minimum_date)}
                                                        max={new Date(this.state.maximum_date)}
                                                        dateConfig={dateConfig}
                                                    />
                                                </div>
                                            } */}
                                            {this.state.minimum_date && this.state.maximum_date &&
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <Fragment className="addChildDate">
                                                      <div className="addChildDateIcon"> 
                                                        <DatePicker
                                                            openTo="year"
                                                            format="dd/MM/yyyy"
                                                            id="select_date"
                                                            views={["year", "month", "date"]}
                                                            // views={["year", "month"]}
                                                            //label={this.state.child_data[this.state.index].type == 'Existing' ? "Baby birth date?" : ""}
                                                            placeholder="When is the baby due to arrive?"
                                                            minDate={new Date(this.state.minimum_date)}
                                                            maxDate={new Date(this.state.maximum_date)}
                                                            value={this.state.show_date == true ? this.dateFormat(this.state.child_data[this.state.index].child_birthdate) : null}
                                                            onChange={(e) => { this.handleChildDate(e) }}
                                                            margin="normal"
                                                            //className="addChildDate"
                                                        />
                                                       </div>

                                                        {/* <label>
                                                            {this.state.child_data[this.state.index].type == 'Existing' ? "Baby birth date?" : "When is the baby due to arrive?"}
                                                        </label> */}
                                                        {/* <input
                                                            type="date"
                                                            min={this.state.minimum_date}
                                                            max={this.state.maximum_date}
                                                            value={this.state.show_date == true ? (this.state.child_data[this.state.index].child_birthdate) : null}
                                                            onChange={(e) => { this.handleChildDate(e.target.value) }}
                                                            className="background-transparent"
                                                        ></input> */}

                                                    </Fragment>
                                                </MuiPickersUtilsProvider>
                                            }
                                        </div>
                                        {this.state.show_error_message && this.state.show_error_message != "" &&
                                            <p className="error_message signErorMsg">
                                                {this.state.show_error_message}
                                            </p>
                                        }
                                        {/* {this.state.addChildParent &&
                                         <div className="addChildPrnt">
                                          <Button>Add Child’s Parent</Button>
                                          <img src={require('../../assets/images/addChildPlus.svg')} alt="" title="" />
                                         </div>
                                        } */}
                                        {!add_child &&
                                          <div className="rmvChildButton">
                                            <Button onClick={this.toggleDeleteConfirm}>Remove Child</Button>
                                          </div>
                                        }
                                        <div className="addchild_submit">
                                            <Button id="add_child_continue" size="medium" color="primary" onClick={() => { this.handleContinue("continue") }}>
                                                {dynamic_content.static_text.addchild ? dynamic_content.static_text.addchild.continue.en : "Continue"}
                                            </Button>
                                        </div>
                                        {/* <p></p> */}
                                        <div className="infoEntry">
                                            <p>We use this information to simplify your entry and to curate the engagements that you experience.</p>
                                        </div>

                                    </FormControl>
                                }
                            </div>
                        </div>

                        {/* Unknown child model */}
                        <div className="open_mail_model">

                            <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className + " custom_Addchildmodel"} backdrop={'static'} keyboard={false}>
                                <ModalBody className="custom_unknwnchildBody">
                                    <div className="unknwnchild_content unknwnchild_contentNw 13" style={{ postion: "relative" }}>
                                        
                                        {/* <img src={require('../../assets/images/closeChild.svg')} alt="" title="" onClick={() => { this.toggle_info("cancel") }} className="clearcontest_img popup_close_button" /> */}
                                        <img src={require('../../assets/images/closeChild.svg')} alt="" title="" onClick={() => { this.handleContinue("cancel") }} className="clearcontest_img popup_close_button" />
                                        
                                        <div className="unknwnchild_head">
                                            <h3>{dynamic_content.static_text.addchild ? dynamic_content.static_text.addchild.add_child.en : "Add a Child"}</h3>
                                            <div className="addOneChild">
                                                <p>{dynamic_content.static_text.addchild ? dynamic_content.static_text.addchild.add_children.en : "Add children one at a time."}</p>
                                                <p>{dynamic_content.static_text.addchild ? dynamic_content.static_text.addchild.add_as_many.en : "You can add as many as you need"}</p>
                                            </div>
                                        </div>
                                        <div className="unchild_info unchild_infoBg2 111" onClick={() => { this.handleAddChild("Expectant", "true") }}>
                                            <div className="unchild_left">
                                                <img src={require('../../assets/images/expecting.svg')} alt="" title="" />
                                            </div>
                                            <div className="unchild_rght expct_child" >
                                                <label>{dynamic_content.static_text.addchild ? dynamic_content.static_text.addchild.expecting_child.en : "Expectant Child"}</label>
                                                <p>{dynamic_content.static_text.addchild ? dynamic_content.static_text.addchild.due_date.en : "Due Date"}</p>
                                            </div>
                                        </div>
                                        <div className="unchild_info unchild_infoBg2 222" onClick={() => { this.handleAddChild("Existing", "true") }}>
                                            <div className="unchild_left">
                                               <img src={require('../../assets/images/ChildNew2.png')} alt="" title="" />
                                            </div>
                                            <div className="unchild_rght expctChildLast" >
                                                {ReactHtmlParser(dynamic_content.static_text.addchild.child_range.en)}
                                            </div>
                                        </div>
                                        <Button size="medium" color="primary" className="closeModel childdel_btn childdel_btnCncl" onClick={() => { this.handleContinue("cancel") }}>
                                            {dynamic_content.static_text.addchild ? dynamic_content.static_text.addchild.cancel.en : "Cancel"}
                                        </Button>
                                    </div>
                                </ModalBody>
                            </Modal>
                        </div>


                        <div className="open_mail_model">

                            <Modal isOpen={this.state.modal_info} toggle={this.toggle_info} className={this.props.className + " custom_Addchildmodel"} backdrop={'static'} keyboard={false}>
                                <ModalBody className="custom_unknwnchildBody">
                                    <div className="unknwnchild_content unknwnchild_contentNw" style={{ postion: "relative" }}>
                                        <img src={require('../../assets/images/closeChild.svg')} alt="" title="" onClick={() => { this.toggle_info("close") }} className="clearcontest_img popup_close_button" />
                                        <div className="unknwnchild_head_info">
                                            <h3>{dynamic_content.static_text.addchild ? dynamic_content.static_text.addchild.add_child.en : "Add a Child"}</h3>
                                            <div className="addOneChild">
                                                <p>{dynamic_content.static_text.addchild ? dynamic_content.static_text.addchild.points_and_benifits.en : "Text about points and benefits"}</p>
                                            </div>
                                        </div>
                                        <div className="ntcUpdateMultiple">
                                            <Button onClick={() => { this.toggle_info("done") }} size="medium" color="primary">Done</Button>
                                        </div>
                                    </div>
                                </ModalBody>
                            </Modal>
                        </div>


                        <div className="open_mail_model">
                            {this.state.child_data[this.state.index] && this.state.child_data[this.state.index].child_birthdate &&
                                <Modal isOpen={this.state.modal_confirm} toggle={this.toggle_confirm} className={this.props.className + " custom_Addchildmodel"} backdrop={'static'} keyboard={false}>
                                    <ModalBody className="ntcBody">
                                        <div className="ntvcontent">
                                            <img src={require('../../assets/images/closeChild.svg')} alt="" title="" onClick={() => { this.toggle_confirm() }} className="ntcimgClose" />
                                            <div className="ntc_head">
                                                <h3>{dynamic_content.static_text.addchild ? dynamic_content.static_text.addchild.notice.en : "Notice!"}</h3>
                                                <div className="ntc_headInr">
                                                    <p>{this.state.child_data[this.state.index].type == "Existing" ? dynamic_content.static_text.addchild.confirm_existing_child.en
                                                        : dynamic_content.static_text.addchild.confirm_expecting_child.en}</p>
                                                </div>
                                                <div className="ntcDate"><Button size="medium" color="primary">{this.dateShowFormat(this.state.child_data[this.state.index].child_birthdate)}</Button></div>
                                                <div className="ntcCnfrm"><Button onClick={() => { this.hanleConfirm() }} size="medium" color="primary">{dynamic_content.static_text.addchild ? dynamic_content.static_text.addchild.confirm_date.en : "Confirm Date"}</Button></div>
                                                <div className="ntcUpdate"><Button onClick={() => { this.handleChildDateUpdate() }} size="medium" color="primary">{dynamic_content.static_text.addchild ? dynamic_content.static_text.addchild.change.en : "Change"}</Button></div>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>
                            }
                        </div>
                        {/* Modal for select relation with child */}
                        <div className="open_mail_model">
                            {this.state.child_data[this.state.index] && this.state.child_data[this.state.index].child_birthdate &&
                                <Modal isOpen={this.state.select_relation} toggle={this.toggle_select_relation} className={this.props.className + " custom_Addchildmodel"} backdrop={'static'} keyboard={false}>
                                    <ModalBody className="ntcBody">
                                        <div className="ntvcontent 2121">
                                            <img src={require('../../assets/images/closeChild.svg')} alt="" title="" onClick={() => { this.toggle_select_relation() }} className="ntcimgClose" />
                                            <div className="ntc_head">
                                                <h3>{"This child is my"}</h3>
                                                <div className="ntc_headInr">
                                                    <p>Choose Description</p>
                                                </div>
                                                <div className="select_rel">
                                                    <div className="inside_option">
                                                        <Button className="insideSel1" onClick={() => this.handleChildRelation(this.state.child_data[this.state.index].child_gender == "Unknown" ? "grandchild" : this.state.child_data[this.state.index].child_gender == "Boy" ? "grandson" : "granddaughter")} size="medium" color="primary">{this.state.child_data[this.state.index].child_gender == "Unknown" ? "Grandchild" : this.state.child_data[this.state.index].child_gender == "Boy" ? "Grandson" : "Granddaughter"}</Button>
                                                        <Button className="insideSel2" onClick={() => this.handleChildRelation(this.state.child_data[this.state.index].child_gender == "Unknown" ? "niece/ nephew" : this.state.child_data[this.state.index].child_gender == "Boy" ? "nephew" : "niece")} size="medium" color="primary">{this.state.child_data[this.state.index].child_gender == "Unknown" ? "Niece/ Nephew" : this.state.child_data[this.state.index].child_gender == "Boy" ? "Nephew" : "Niece"}</Button>
                                                    </div>
                                                    <div className="inside_option">
                                                        <Button className="insideSel3" onClick={() => this.handleChildRelation(this.state.child_data[this.state.index].child_gender == "Unknown" ? "sibling" : this.state.child_data[this.state.index].child_gender == "Boy" ? "brother" : "sister")} size="medium" color="primary">{this.state.child_data[this.state.index].child_gender == "Unknown" ? "Sibling" : this.state.child_data[this.state.index].child_gender == "Boy" ? "Brother" : "Sister"}</Button>
                                                        <Button className="insideSel4" onClick={() => this.handleChildRelation("FamilyFriend")} size="medium" color="primary">{"Family Friend"}</Button>
                                                    </div>
                                                </div>
                                                <button className="own-child" onClick={() => this.handleChildRelation("parent")}>I'm adding my own child</button>
                                                <Button size="medium" color="primary" className="ntc_btnCncl" onClick={() => { this.toggle_select_relation() }} >
                                                  {dynamic_content.static_text.addchild ? dynamic_content.static_text.addchild.cancel.en : "Cancel"}
                                               </Button>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>
                            }
                        </div>
                        <div className="open_mail_model">
                                    <Modal isOpen={this.state.delete_confirm_modal} toggle={this.toggleDeleteConfirm} className={this.props.className + " skilltestmodel"}>
                                        <ModalHeader style={{ borderBottom: "none" }}>
                                            <img src={url.family_one_image_url + 'Group168.png'} onClick={() => { this.toggleDeleteConfirm() }} alt="" title="" className="clearcontest_img confirm_close_button" />
                                        </ModalHeader>
                                        <ModalBody className="skilltestBody">
                                            <div className="support_model" style={{ postion: "relative" }}>
                                                <div className="skill_query">
                                                    <p style={{ fontSize: "20px" }}>Do you want to delete this child?</p>
                                                </div>
                                                <div className="skilltest_content">
                                                    <Button size="medium" color="primary" onClick={() => { this.handleYes() }}>
                                                        Yes
                                                    </Button>
                                                    <Button size="medium" color="primary" onClick={() => { this.toggleDeleteConfirm() }}>
                                                        No
                                                    </Button>
                                                </div>
                                            </div>
                                        </ModalBody>
                                    </Modal>
                                </div>
                                {/* End of Modal for select relation with child */}
                        <div className="open_mail_model">
                            {this.state.child_data[this.state.index] && this.state.child_data[this.state.index].child_birthdate &&
                                <Modal isOpen={this.state.open_dailog} toggle={this.toggle_twins} className={this.props.className + " custom_Addchildmodel"} backdrop={'static'} keyboard={false}>
                                    <ModalBody className="ntcBody">
                                        <div className="ntvcontent">
                                            <img src={require('../../assets/images/closeChild.svg')} alt="" title="" onClick={() => { this.handleTwinsClose() }} className="ntcimgClose" />
                                            <div className="ntc_head">
                                                <h3>{dynamic_content.static_text.addchild ? dynamic_content.static_text.addchild.multiple.en : "Multiples!!!"}</h3>
                                                <div className="ntc_headInr">
                                                    <p>{dynamic_content.static_text.addchild ? dynamic_content.static_text.addchild.are_you_adding_twin.en : ""}
                                                    </p>
                                                </div>
                                                <div className="ntcDate"><Button size="medium" color="primary">{this.dateShowFormat(this.state.child_data[this.state.index].child_birthdate)}</Button></div>
                                                <div className="ntcUpdateMultiple"><Button onClick={() => { this.handleAgree() }} size="medium" color="primary">{dynamic_content.static_text.addchild ? dynamic_content.static_text.addchild.add_multiples.en : "Add multiples"}</Button></div>
                                                <div className="ntcUpdate"><Button onClick={() => { this.handleTwinsClose() }} size="medium" color="primary">Cancel</Button></div>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>
                            }
                        </div>
                        {/* Unknown child model */}
                        </div>
                        </div>
                    </div>
                }
                {/* </Online>
                <Offline>
                    <OfflineComponent props={this} />
                </Offline> */}
            </div >
        );
    }
}