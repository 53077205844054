import React, { Component } from 'react';

// import LottieLoader from '../LottieLoader';
// import Countdown from "react-countdown-now";
// import Dialog from "material-ui/Dialog";
// import Drawer from "./drawer";
import url from "../../assets/url";
import cookie from "react-cookies";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import functions from "../../assets/functions/common_functions";
import LottieLoader from '../LottieLoader';
import { TimelineMax, Bounce, Linear, Power1, Elastic, Back, Sine } from "gsap/all";
import CSSPlugin from 'gsap/CSSPlugin';
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from "react-html-parser";
const C = CSSPlugin;
// {ReactHtmlParser(program.program_title)}
// import RaisedButton from "material-ui/RaisedButton";
// import session from "./function/session"
let nextid;
class terms_page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stepIndex: 0,
      finished: false,
      dynamic_content: "",
      progressPercent: 0,
      currentStep: 0
    };
  }

  componentDidMount = () => {
    let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
    if (dynamic_content != "") {
      this.setState({ dynamic_content: dynamic_content })

    }
    let success_action = "User landed on simialc terms page"
    let image = 'similac_terms_page_landing.png'
    functions.logUserActivity(success_action, image);
    let TL22 = new TimelineMax();
    TL22.fromTo('.fade_it_up', 2, { opacity: 0, y: -10 }, { opacity: 1, y: 0 });
    console.log("this.props", this.props.props)
  }

  handleAnimation = () => {
    let TL = new TimelineMax();
    TL.fromTo('.phone_verification_block', 0.5, { opacity: 1 }, { opacity: 0, height: '200px' })
      .to('.program_entry_loader', 0.5, { opacity: 1 })
  }

  removeAnimationEffect = () => {
    let TL = new TimelineMax();
    TL.fromTo('.phone_verification_block', 0.5, { opacity: 0 }, { opacity: 1, height: 'auto' })
      .to('.program_entry_loader', 0.5, { opacity: 0 })
  }

  handleNext = () => {
    let success_action = "User clicked on yes button on similac terms page"
    let image = 'yes_button_similac_terms_page.png'
    functions.logUserActivity(success_action, image);
    let self = this;
    if (this.state.stepIndex == 2) {
      this.handleAnimation();
      this.setState({
        finished: true
      });
      setTimeout(() => {
        localStorage.setItem("show_terms", "false");
        // localStorage.setItem("terms_finished", "true");
        let userdata = [];

        let dynamo_userdata = { 'similac_terms': "yes" };
        if (this.props.props.program_detail.program_id !== "6260") {
          localStorage.setItem("similac_terms", "yes");
        }
        else{
          dynamo_userdata =  { 'pediasure_terms': "yes" }
          localStorage.setItem("pediasure_terms", "yes");
        }


        functions.updateUserData(self, userdata, dynamo_userdata);
        self.props.onSubmit()
      }, 500);
    }

    const { stepIndex } = this.state;
    this.setState({
      stepIndex: stepIndex + 1
    });

    const { currentStep } = this.state;
    if (currentStep < 3) { // Assuming there are 3 steps (0-indexed)
      this.setState(prevState => ({
        progressPercent: (prevState.currentStep + 1) * 25,
        currentStep: prevState.currentStep + 1,
      }));
    }

  };

  handlePrev = () => {
    let success_action = "User clicked on back button on similac terms page"
    let image = 'back_button_similac_terms_page.png'
    functions.logUserActivity(success_action, image);
    const { stepIndex } = this.state;
    if (stepIndex > 0) {
      this.setState({ stepIndex: stepIndex - 1 });
    }
    else {
      this.props.Back()
    }
  };

  render() {
    const { finished, stepIndex, dynamic_content } = this.state;
    const contentStyle = { margin: "0 16px" };
    const { progressPercent, currentStep } = this.state;
    return (
      <div >
        {dynamic_content != "" && this.props && this.props.props &&
          <div className="row rel_block fade_it_up">
           <div className='entrFreeBeiBg'>
            
            <div className="phone_verification_block" style={{ position: "relative" }}>
              <div className="phone_verify_backlink termScreenArow">
                <img onClick={() => { this.handlePrev() }} src={require('../../assets/images/bkArorIcon.svg')} alt="" title="" />
              </div>
              <div className="contest_up termScreenHead">
                <h1>{dynamic_content.static_text.t_and_c ? dynamic_content.static_text.t_and_c.enter_freebie.en : "Enter freebie"}</h1>
              </div>
              <div className="contestProcsFreeBei">
                <p>{dynamic_content.static_text.t_and_c && this.props.props.program_detail.program_id !== "6260" ? dynamic_content.static_text.t_and_c.requirement_subtitle.en : "In order to redeem, PediaSure® (Abbott Nutrition) requires your consent to the following"}</p>
                <div className="row">

                  {/* <div className="nextnum_div">
                    <Stepper linear="false" activeStep={stepIndex}>
                      <Step className="steplabel_fstUpr">
                        <StepLabel className="steplabel_fst" />
                      </Step>
                      <Step className="steplabel_secondUpr">
                        <StepLabel
                          className={
                            this.state.stepIndex > 0 ? "steplabel_second" : ""
                          }
                        />
                      </Step>
                      <Step className="steplabel_thirdUpr">
                        <StepLabel
                          className={
                            this.state.stepIndex > 1 ? "steplabel_third" : ""
                          }
                        />
                      </Step>
                    </Stepper>
                  </div> */}

                  <div className='progressSurvey progressFreebie'>
                    <ul>
                      {Array.from({ length: 3 }, (_, i) => (
                        <li key={i}>
                          <img
                            src={
                              i < currentStep
                                ? require('../../assets/images/freeBieProgress.svg')
                                : require('../../assets/images/freeBieDisable.svg')
                            }
                            alt=""
                            title=""
                            style={{
                              opacity: i < currentStep ? (i + 1) / 3 : 1,
                              transition: 'opacity 0.5s ease', // Smooth transition for opacity change
                            }}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="artBoard_allparadiv">
                    {this.state.stepIndex === 0 && (
                      <div>
                        <p>
                          {dynamic_content.static_text.t_and_c && this.props.props.program_detail.program_id !== "6260" ? dynamic_content.static_text.t_and_c.consent_description.en : "Yes, I AGREE to receive emails and other forms of commercial electronic messages about Abbott Nutrition products and services from Abbott Nutrition or Abbott Nutrition’s third-party service providers on its behalf."}
                        </p>
                        {/* <div className="editChildInfo_stper 3434">
                          <Button size="medium" color="primary" onClick={() => { this.handleNext(); }} >
                            {dynamic_content.static_text.t_and_c ? dynamic_content.static_text.t_and_c.yes_button.en : "Yes"}
                          </Button>
                        </div> */}
                      </div>
                    )}
                    {this.state.stepIndex === 1 && (
                      <div>
                        {this.props.props.program_detail.program_id !== "6260" && <p>
                          I have
                          read and agree to the terms of the linked
                          <a
                            style={{ textDecoration: "none" }}
                            target="_blank"
                            href="https://similac.ca/en/privacy-policy"
                          >
                            <span> Privacy Policy </span>
                          </a>{" "}
                          and to the{" "}
                          <a
                            style={{ textDecoration: "none" }}
                            target="_blank"
                            href="https://similac.ca/en/terms-of-use"
                          >
                            <span> Terms of Use </span>
                          </a>
                          and accordingly consent to the collection, use, and
                          disclosure of my personal information.
                        </p>}
                        {this.props.props.program_detail.program_id !== "6260" && <p>
                          I understand that I can withdraw my consent or update my
                          preferences at any time directly with Similac<sup>®</sup> ONLY.
                          Contact Similac<sup>®</sup> directly for more details.
                        </p>}
                        {this.props.props.program_detail.program_id == "6260" && <p>
                        I have read and agree to the terms of the linked
                          <a
                            style={{ textDecoration: "none" }}
                            target="_blank"
                            href="https://www.ca.abbott/en-ca/privacy-policy.html"
                          >
                            <span> Privacy Policy </span>
                          </a>{" "}
                          and to the{" "}
                          <a
                            style={{ textDecoration: "none" }}
                            target="_blank"
                            href="https://pediasure.ca/en/terms-of-use"
                          >
                            <span> Terms of Use </span>
                          </a>
                          and accordingly consent to the collection, use, and disclosure of my personal information.
                          I understand that I can withdraw my consent or update my preferences at any time directly with PediaSure® ONLY. Contact PediaSure® directly for more details.
                        </p>}


                        {/* <div className="editChildInfo_stper2">
                          <Button size="medium" color="primary" onClick={() => { this.handleNext(); }} disabled={currentStep === 3}>
                            Yes
                          </Button>
                        </div> */}

                      </div>
                    )}
                    {this.state.stepIndex === 2 && (
                      <div>
                        {<p>
                         {this.props.props.program_detail.program_id !== "6260"? `I CONSENT to be contacted for surveys and research
                          initiatives conducted by Abbott Nutrition and/or Abbott
                          Nutrition’s service providers on its behalf. I understand
                          that these surveys and research initiatives will be
                          subject to the Abbott Nutrition`:
                          `I AGREE to be contacted for surveys and research initiatives conducted by Abbott Nutrition and/or Abbott Nutrition’s third-party service providers on its behalf. I understand that these surveys and research initiatives will be subject to Abbott Nutrition’s `}
                          <a
                            style={{ textDecoration: "none" }}
                            target="_blank"
                            href={this.props.props.program_detail.program_id !== "6260" ? "https://similac.ca/en/privacy-policy" : "https://www.ca.abbott/en-ca/privacy-policy.html"}
                          >
                            <span> Privacy Policy </span>
                          </a>
                        </p>}

                        {/* <div className="editChildInfo_stper3">
                          <Button size="medium" color="primary" onClick={() => { this.handleNext(); }}>
                            Yes
                          </Button>
                        </div> */}

                      </div>
                    )}
                  </div>

                  {/* <div
                  className="artBoardbtn_div"
                  style={{ position: "relative", display: "flex" }}
                >
                  <div className="artBoard_btnbackdiv">
                    <Button
                      className="flatbtn"
                      onClick={() => {
                        this.handlePrev();
                      }}
                    >
                      <img
                        src={url.image_path + "prev_arrow.jpg"}
                        width="40"
                        className="btnback_arrow"
                      />
                      Back
                    </Button>
                  </div>
                  <button
                    className="artBoard_btnyes"
                    onClick={() => {
                      this.handleNext();
                    }}
                  >
                    Yes
                  </button>
                </div>  */}

                </div>
              </div>             
            </div>

            <div className="program_entry_loader">
              <LottieLoader className="loading" />
            </div>

            </div>

            <div className="artBoard_allparadiv">
              {this.state.stepIndex === 0 && (
                <div className="editChildInfo_stper 3434">
                  <Button size="medium" color="primary" onClick={() => { this.handleNext(); }} >
                    {dynamic_content.static_text.t_and_c ? dynamic_content.static_text.t_and_c.yes_button.en : "Yes"}
                  </Button>
                </div>
              )}
              {this.state.stepIndex === 1 && (
                <div className="editChildInfo_stper2">
                  <Button size="medium" color="primary" onClick={() => { this.handleNext(); }} disabled={currentStep === 3}>
                    Yes
                  </Button>
                </div>
              )}
              {this.state.stepIndex === 2 && (
                <div className="editChildInfo_stper3">
                <Button size="medium" color="primary" onClick={() => { this.handleNext(); }}>
                  Yes
                </Button>
                </div>
              )}
          </div>

          </div>

          
        }
      </div>
    );
  }
}

export default terms_page;
