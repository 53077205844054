import React, { Fragment, useState } from "react";
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import functions from '../../assets/functions/common_functions';
import cookie from 'react-cookies';
import url from '../../assets/url';


export default class AlternativeEmailPopup extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            // auto_id: cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id"),
            // relative_id: cookie.load("relative_id") ? cookie.load("relative_id") : localStorage.getItem("relative_id")
            emailToDisplay: 'relative@family.one'
        }
    }

    componentDidMount() {
        // console.log("ABCDSFERSF", this.props)
        this.getRelationInvitationData();
    }

    getRelationInvitationData = () => {
        let self = this;
        let invite_id = localStorage.getItem("parent_invite_id") ? localStorage.getItem("parent_invite_id") : "";
        if (invite_id.trim()!=="") {
            functions.getDetailByRelativeID(invite_id, function (response_json) {
                let response = JSON.parse(response_json);
                if (response.catch_error) {
                    self.setState({ loader: false })
                    let error_body = response.message
                    self.setState({ show_error_message: response.message });
                }
                else if (!response.catch_error) {
                    self.setState({ loader: false })
                    if (response.data.error === 1) {
                        let error_body = response.data.message
                        self.setState({ show_error_message: response.data.message });
                    } else if (response.data.statusCode === 400) {
                        let error_body = response.data.message
                        self.setState({ show_error_message: response.data.message });
                    }
                    else if (response.data && response.data.length > 0) {
                        self.setState({ loader: false })
                        self.setState({
                            emailToDisplay: response.data[0].email,
                        })
                    }
                }
            })
        }
        // else {
        //     this.props.toggle()
        // }
    }

    handleChoice = (accept) => {
        let self = this
        let auto_id = localStorage.getItem("auto_id") ? localStorage.getItem("auto_id") : "";
        let relative_id = localStorage.getItem("rlid") ? localStorage.getItem("rlid") : ""
        let invite_id = localStorage.getItem("parent_invite_id") ? localStorage.getItem("parent_invite_id") : "";
        console.log("accept", accept, auto_id)
        console.log("relative_id , invite_id", relative_id)
        console.log(", invite_id", invite_id)
        if (accept == "yes") {
            if (auto_id && relative_id && invite_id) {
                functions.changeAlternativeEmail(self, auto_id, relative_id, invite_id, function (response_json) {
                    if (response_json) {
                        localStorage.removeItem("parent_invite_id")
                        localStorage.removeItem("rlid")
                        let response = JSON.parse(response_json)
                        console.log("RESPONSE123", response);
                        let alternative_email = response.data.alternative_email;
                        localStorage.setItem("alternative_email", alternative_email)
                        self.props.toggle()
                    }
                })
            }
        }
        else if (accept == "no") {
            self.props.toggle()
        }
    }

    handleFirstname = (event) => {

        if (!event.target.value.match(/^[a-zA-Z-'. àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
        }
        else {
            this.setState({
                given_name: event.target.value.trim(),
                show_error_message: ''
            });
            if (event.target.value.trim().length > 1) {
                this.setState({
                    given_name_complete: true
                });
            } else {
                this.setState({
                    given_name_complete: false
                });
            }
        }
    }

    render() {
        return (
            <div className="open_mail_model">
                <Modal isOpen={this.props.isOpenChangeEmailParent} toggle={this.props.toggle} className={this.props.className + " custom_Addchildmodel"} backdrop={'static'} keyboard={false}>
                    <ModalBody className="custom_unknwnchildBody">
                        <div className="parentinvite_content unknwnchild_contentNw alterEmailUpr" style={{ postion: "relative" }}>
                            <div className="unknwnchild_head alterEmail">
                                {/* <img src={url.family_one_image_url + 'Group168.png'} onClick={() => { this.props.toggle() }} alt="" title="" className="clearcontest_img popup_close_button" /> */}
                                <img src={require('../../assets/images/Group5.png')} onClick={() => { this.props.toggle() }} alt="" title="" className="clearcontest_img popup_close_button" />
                                <h1>Alternate email</h1>
                                <p>Enter your alternate email address,<br />
                                    if you want to save another email</p>
                            </div>
                            <div className="alterEmailForm">
                                <div className="alterEmailFormInr">
                                    <img src={require('../../assets/images/Inviterelative2.png')} alt="" title="" />
                                    <label>{this.state.emailToDisplay}</label>
                                </div>
                            </div>
                            <div className="prent_submit">
                                <Button size="medium" color="primary" onClick={() => { this.handleChoice('yes') }}>Save</Button>
                                <Button className="parent-skip" size="medium" color="primary" onClick={() => { this.handleChoice('no') }}>Skip</Button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>

        )
    }
}