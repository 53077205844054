import React from 'react';
import '../../assets/css/style.css';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { TimelineLite, TweenLite, TweenMax, TimelineMax, Bounce, Linear, Power1, Power2, Power3, Back, Power4, SlowMo, Circ, Elastic } from "gsap/all";
import functions from '../../assets/functions/common_functions';
import Footer from '../innerpages/footer';
import CSSPlugin from 'gsap/CSSPlugin';
import cookie from 'react-cookies';
import LottieLoader from '../LottieLoader';
import url from '../../assets/url';
import { Grid } from '@material-ui/core';
import axios from 'axios';

const C = CSSPlugin;
var initials = require("initials");
const Month = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec"
};

class family extends React.Component {
    constructor(props) {
        super(props);
        localStorage.setItem("session_type", "login")
        // functions.handleBackOne(this, 'family')
        // this.release = this.release.bind(this);
        this.state = {
            action: "",
            activeTab: localStorage.getItem("family_activeTab") ? localStorage.getItem("family_activeTab") : '1',
            modal: false,
            relation_status: '',
            child_data: [],
            relations: [],
            dynamic_content: "",
            isMergeChildPopup: true,
            invitedChildData: [],

            selectedChildrenIds: []
        };
        this.dateFormat = this.dateFormat.bind(this);
    }

    componentDidMount() {
        console.log("this props are", this.props);

        localStorage.setItem("add_as_relative", "false")
        let action = "User landed on family page"
        let image = 'family_page_landing.png'
        functions.logUserActivity(action, image);
        localStorage.setItem("active_button", "2")
        if (this.state.relations.length == 0) {
            setTimeout(function () {
                var timeline = new TimelineMax();
                timeline.fromTo(".two_person_img", 0.4, { width: "18px", opacity: 0, y: 20 }, { width: "36px", opacity: 1, ease: Circ.easeOut, y: 0 })
                    .fromTo(".person_cup", 0.4, { width: "16px", opacity: 0, y: 20 }, { width: "32px", opacity: 1, ease: Circ.easeOut, y: 0 }, "-=0.4")
                    .fromTo(".star_plusOne", 0.4, { left: "115px" }, { left: "0px" }, "-=0.4")
                    .fromTo(".star_plusTwo", 0.4, { left: "115px" }, { left: "13px" }, "-=0.4")
                    .fromTo(".star_plusThree", 0.4, { left: "115px" }, { left: "55px" }, "-=0.4")
                    .fromTo(".star_plusFour", 0.4, { right: "115px" }, { right: "0px" }, "-=0.4")
                    .fromTo(".star_plusFive", 0.4, { right: "115px" }, { right: "13px" }, "-=0.4")
                    .fromTo(".star_plusseven", 0.4, { right: "115px" }, { right: "48px" }, "-=0.4")
            }, 1000);
        }

    }

    componentWillMount = () => {
        // let redirect_main = localStorage.getItem("redirect_main") && localStorage.getItem("redirect_main") == "true" ? localStorage.getItem("redirect_main") : "false"
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        // if (redirect_main == "true") {
        //     functions.pushToRoute(this, "main", 'family');
        //     localStorage.setItem("redirect_main", 'false')
        // }
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
            setTimeout(() => {
                var myDiv1 = document.getElementsByClassName('all_children_section');
                console.log("myDiv1", myDiv1)
                if (myDiv1) {
                    myDiv1.scrollTop = 0;
                }
                if (this.state.relations.length > 0) {
                    var myDiv2 = document.getElementsByClassName('all_relative_section2');
                    console.log("myDiv2", myDiv2)
                    if (myDiv2) {
                        myDiv2.scrollTop = 0;
                    }
                }

            }, 1000);
        }
        this.setState({ loader: true })
        let previous_state = localStorage.getItem("previous_state")
        console.log("previous_state", previous_state)
        if (previous_state == "" || previous_state == "select-children") {
            this.GetInfo()
        } else {
            // settimeout for loading updated data
            this.setTimeOut = setTimeout(() => { this.GetInfo() }, 2000);
        }

    }

    daysBetweenTwoTimestamps = (timestamp1, timestamp2) => {
        var difference = timestamp1 - timestamp2;
        var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);

        return Math.abs(daysDifference);
    }

    GetInfo = () => {
        let self = this
        let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        sub_id = sub_id ? sub_id : ""
        let email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email")
        email = email ? email : ""
        if (email && email != "" && email != "null" && email != null && typeof email != undefined && sub_id && sub_id != "" && sub_id != "null" && sub_id != null && typeof sub_id != undefined) {
            let finaljson = {
                email: email,
                sub_id: sub_id
            }
            functions.checkUserExistence(self, finaljson, function (response1_json) {
                let response1 = JSON.parse(response1_json)
                console.log("res", response1)
                if (response1.data && response1.data.dynamo && response1.data.dynamo.Items && response1.data.dynamo.Items.length == 0) {
                    functions.pushToRoute(self, "", "family")
                } else {
                    functions.getuserChildAndrelativeAndinvitedChild(self, email, sub_id, function (response_json) {
                        let response = JSON.parse(response_json);
                        console.log("response", response)
                        if (response.catch_error) {
                            let action = "User got error on family/index file GetInfo function";
                            let body = response.message
                            functions.logUserError(action, body);
                            self.setState({ loader: false, show_error_message: response.message })
                        }
                        else if (!response.catch_error) {
                            if (response.data.error === 1) {
                                let action = "User got error on family/index file GetInfo function";
                                let body = response.data.message
                                functions.logUserError(action, body);
                                self.setState({ loader: false, show_error_message: response.data.message })
                            } else if (response.data.statusCode === 400) {
                                let action = "User got error on family/index file GetInfo function";
                                let body = response.data.message
                                functions.logUserError(action, body);
                                self.setState({ loader: false, show_error_message: response.data.message })
                            }

                            else if (response.status === 200) {
                                let child_data = (response.data.child_data.Items[0] && response.data.child_data.Items[0].child_data) ? response.data.child_data.Items[0].child_data : [];
                                let relation_data = response.data.relation_data ? response.data.relation_data : {}

                                console.log("invited_child", response.data.invited_child)
                                if (response.data.invited_child && response.data.invited_child.length > 0) {
                                    let invitedChildData = [...response.data.invited_child]
                                    self.setState({ invitedChildData: invitedChildData });
                                }
                                console.log("child_data", child_data)
                                let success_action = "User get success in response of getuserChildAndrelative function in family/index page"
                                functions.logUserActivity(success_action, null);

                                if (child_data && child_data.length > 0) {
                                    localStorage.setItem("child_data", JSON.stringify(child_data));
                                }
                                else {
                                    localStorage.removeItem("child_data");
                                }
                                let child_to_invite = child_data.filter(
                                    childD => {
                                        if (childD.relation_with_child !== "parent" && !childD.parent_id)
                                            return childD
                                    }
                                )

                                let relation_data_final = [];
                                if (Object.keys(relation_data).length > 0) {
                                    for (var key in relation_data) {
                                        relation_data[key].forEach(element => {
                                            if (key == 'approvedRelations') {
                                                element.relation_status = 'approved_relations'
                                            }
                                            if (key == 'pendingRelation') {
                                                element.relation_status = 'pending_relation'
                                            }
                                            if (key == 'pendingInvitation') {
                                                element.relation_status = 'pending_invitation'
                                            }
                                            relation_data_final.push(element)
                                        });
                                    }
                                    self.setState({ child_data: child_to_invite, relations: relation_data_final, loader: false });
                                    localStorage.setItem("relation_data", JSON.stringify(relation_data_final));
                                } else {
                                    self.setState({ child_data: child_to_invite, relations: relation_data_final, loader: false })
                                    localStorage.removeItem("relation_data");
                                }
                                self.handleCircleGrowEffect();
                            }
                        }
                    })
                }
            });

        } else {
            this.props.history.push("/")
        }
    }

    dateFormat = (value) => {
        let date_array = value.split("-");
        if (date_array.length > 1) {
            let year = date_array[0].substring(2, 4);
            let date = date_array[2];
            let month = date_array[1];
            let month_name = Month[month];
            let child_date = date + " " + month_name + " " + year;
            return child_date;
        }
        else {
            date_array = value.split("/");
            if (date_array.length > 1) {
                let year = date_array[0].substring(2, 4);
                let date = date_array[2];
                let month = date_array[1];
                let month_name = Month[month];
                let child_date = date + " " + month_name + " " + year;
                return child_date;
            }
        }
    }

    // release = () => {
    //     localStorage.setItem("release_index", this.state.child_index)
    //     localStorage.removeItem("family_activeTab")
    //     functions.pushToRoute(this, 'release_continue', 'family');
    // }

    handleCircleGrowEffect = () => {
        var body = document.getElementById("family_listing");
        // var body = document.body;
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        var piTwo = Math.PI * 2;
        // Viewport
        if (body) {
            var vw = canvas.width = body.offsetWidth;
            var vh = canvas.height = body.offsetHeight;

            if (document.getElementById('allchild_grow')) {
                document.getElementById('allchild_grow').addEventListener("click", createRipple1);
            }

            if (document.getElementById('add_child_grow')) {
                document.getElementById('add_child_grow').addEventListener("click", createRipple1);
            }

            if (document.getElementById('add_relative_grow')) {
                document.getElementById('add_relative_grow').addEventListener("click", createRipple1);
            }

            if (document.getElementById('add_relative_grow2')) {
                document.getElementById('add_relative_grow2').addEventListener("click", createRipple1);
            }

            window.addEventListener("resize", onResize);

            //createRipple(this);
            function createRipple1(event) {
                // Exit if target is the canvas. That means it's still active.
                if (event.target === canvas) return;

                body.appendChild(canvas);
                // Event coords
                var x = event.clientX;
                var y = event.clientY;
                // Delta - difference between event and farthest corner
                var dx = x < vw / 2 ? vw - x : x;
                var dy = y < vh / 2 ? vh - y : y;
                var radius = Math.sqrt(dx * dx + dy * dy);

                var ripple = {
                    alpha: 0,
                    radius: 0,
                    x: vw / 2,
                    y: y
                };

                var tl = new TimelineMax({ onUpdate: drawRipple, onComplete: removeCanvas })
                    .to(ripple, 1.5, { alpha: 1, radius: radius })
                    .to(ripple, 0.1, { alpha: 0 }, 1.4);

                function drawRipple() {
                    ctx.clearRect(0, 0, vw, vh);
                    ctx.beginPath();
                    ctx.arc(ripple.x, ripple.y, ripple.radius, 0, piTwo, false);
                    ctx.fillStyle = "rgba(252,241,239," + ripple.alpha + ")";
                    ctx.fill();
                }
            }
            function removeCanvas() {
                if (body.contains(canvas)) {
                    body.removeChild(canvas);
                }
            }
            function onResize() {
                vw = canvas.width = window.innerWidth;
                vh = canvas.height = window.innerHeight;
            }
        }

    }

    onClickCheckBox = (event, childId) => {
        console.log('event is', event, childId);

        let selectedChildrenList = [];
        if (childId) {
            selectedChildrenList.push(childId);
        }
        this.setState({
            selectedChildrenIds: selectedChildrenList
        });
    }

    handleAddChild = async () => {
        let action = "User clicked on add child button on family page"
        let image = 'add_child_button_family_page.png'
        functions.logUserActivity(action, image);
        let self = this;
        localStorage.setItem("form_type", 'add_child')
        localStorage.removeItem("family_activeTab")

        localStorage.setItem("add_as_relative", "true")
        let selectedRelative = this.props.location?.state?.stateData?.selectedRelation

        setTimeout(async function () {
            await self.setStateAsync({ selectedRelation: selectedRelative });
            functions.pushToRoute(self, "addchild", 'select-children');
        }, 1000)
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve)
        });
    }

    handleAddRelation = (selectedRelative, childIds) => {
        let self = this;
        self.setState({ loader: true })
        if (Object.keys(selectedRelative).length) {
            let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
            sub_id = sub_id ? sub_id : ""
            let notifyrel = {
                sub_id,
                first_name: selectedRelative.given_name,
                last_name: selectedRelative.family_name,
                relative_email: selectedRelative.email,
                relative_id: selectedRelative.relative_id,
                child_id: childIds
            }
            functions.RequestChildAddToParent(self, notifyrel, notifyres => {
                self.setState({ loader: false })
                if (notifyres) {
                    console.log('notifyres is', notifyres);
                    functions.pushToRoute(self, 'family', '', true)
                }
            })
        }
        else {
            self.setState({ loader: false })
        }
    }

    pendingRelativeHandleAddRelation = (selectedRelative, childIds) => {
        let self = this;
        console.log("error childadd", self.state.selectedChild)
        let data = {
            sub_id: cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id"),
            first_name: selectedRelative?.given_name,
            sender_name: localStorage.getItem("given_name") ? localStorage.getItem("given_name") : '',
            last_name: selectedRelative?.family_name,
            relation: selectedRelative?.relation,
            optional_relation: '',
            email: selectedRelative?.email,
            user_email: cookie.load("username") ? cookie.load("username") : localStorage.getItem("email"),
            relative_id: cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id"),
            relation_status: selectedRelative?.relation_status,
            email_verified: localStorage.getItem("email_verified") ? localStorage.getItem("email_verified") : "false",
            old_email: '',
            is_relation_update: 0,
            child_id: childIds
        }

        console.log("data", data);
        return;
        // axios
        //     .request({
        //         method: "POST",
        //         url: url.add_user_relation,
        //         data: JSON.stringify(data),
        //         headers: {
        //             "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : functions.getAuthKey()
        //         }
        //     })
        //     .then(function (response) {
        //         console.log("response", response)

        //         if (response.data.failed && response.data.failed == 1) {
        //             self.setState({ loader: false })
        //         }

        //         if (response.data.error == 1) {
        //             console.log(response.data.message)
        //             self.setState({ loader: false })
        //         } else {

        //             console.log("successfully updated");
        //             // let child_data = localStorage.getItem("child_data") ? JSON.parse(localStorage.getItem("child_data")) : [];
        //             // child_data && child_data.length > 0 && child_data.map(item => {
        //             //     if (item.child_id === self.state.selectedChild.child_id) {
        //             //         item['parent_id'] = response?.data?.relativeId;
        //             //         item['accepted_child'] = 'pending'
        //             //     }
        //             // })
        //             // localStorage.setItem("child_data", JSON.stringify(child_data));

        //             self.setState({ loader: false })
        //             // }
        //         }
        //     })
        //     .catch(function (error) {
        //         self.setState({ loader: false })
        //         console.log("error", error)
        //     });
    }

    onClickSaveChildren = () => {
        console.log('clicked on onClickSaveChildren');
        let selectedRelative = this.props.location?.state?.stateData?.selectedRelation
        console.log('selectedChildrenIds is', this.state.selectedChildrenIds);

        if (selectedRelative?.relation_status === "pending") {
            this.pendingRelativeHandleAddRelation(selectedRelative, this.state.selectedChildrenIds)
        } else {
            this.handleAddRelation(selectedRelative, this.state.selectedChildrenIds)
        }
    }

    formatMinMaxDate = (date) => {
        console.log("date max", date)
        let date_month = date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
        let date_year = date.getFullYear();
        let date_date = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let final_date = date_year + "-" + date_month + "-" + date_date;
        console.log("final_date", final_date)
        return final_date;
    }

    GetChildType = (birthdate_timestamp) => {
        let min = new Date()
        let max = new Date();
        let temp = max.getDate()
        max.setMonth(max.getMonth() + 9);
        let minimum_date = this.formatMinMaxDate(min);
        let maximum_date = this.formatMinMaxDate(max);
        let min_time = new Date(minimum_date).getTime()
        let max_time = new Date(maximum_date).getTime()
        console.log("min_time", min_time)
        console.log("max_time", max_time)
        console.log("birthdate_timestamp", birthdate_timestamp)
        if (birthdate_timestamp <= max_time && birthdate_timestamp >= min_time) {
            return "Expecting"
        }
        return "Existing"
    }

    render() {
        const { dynamic_content } = this.state
        return (
            <div className="container" id="family_listing">
                {dynamic_content != "" &&
                    <div className="row">
                        <div className="common_mainAddOn">
                            <div className="common_main shrScrenTabsRel customShrScrenTabsRel">

                                <div className="shrScrenTabs shrScrenRmvBg">
                                    <div className="sign_backlink sign_backlinkNw" onClick={() => { functions.pushToRoute(this, "family", 'select-children') }}>
                                        <img src={require('../../assets/images/bkArorIcon.svg')} alt="" title="" />
                                    </div>

                                    <div className="chldrnSelect">
                                        <p>Select Children</p>
                                    </div>

                                </div>

                                <div activeTab={this.state.activeTab}>
                                    <div tabId="1">
                                        {this.state.loader &&
                                            <center>
                                                <LottieLoader />
                                            </center>
                                        }
                                        {!this.state.loader && this.state.child_data && this.state.child_data.length == 0 &&
                                            <div className="your_odds_upr 5252">
                                                <div className="star_group">

                                                    {/* <img style={{ width: "10px" }} src={url.family_one_image_url + 'Group245.4.png'} alt="" title="" className="star_plusOne starComon" />
                                                    <img style={{ width: "10px" }} src={url.family_one_image_url + 'Group245.png'} alt="" title="" className="star_plusTwo starComon" />
                                                    <img style={{ width: "10px" }} src={url.family_one_image_url + 'Group245.3.png'} alt="" title="" className="star_plusThree starComon" />

                                                    <img style={{ width: "10px" }} src={url.family_one_image_url + 'Group245.4.png'} alt="" title="" className="star_plusFour starComon" />
                                                    <img style={{ width: "10px" }} src={url.family_one_image_url + 'Group245.png'} alt="" title="" className="star_plusFive starComon" />
                                                    <img style={{ width: "10px" }} src={url.family_one_image_url + 'Group245.3.png'} alt="" title="" className="star_plusseven starComon" /> */}


                                                    {/* <span className="two_person">
                                                        <img style={{ width: "36px" }} src={url.family_one_image_url + 'Groupt234.png'} alt="" title="" className="two_person_img" />
                                                        <img src={url.family_one_image_url + 'Groupt214.png'} alt="" title="" className="person_cup" />
                                                    </span> */}

                                                    <span className='inviteparentIcon'>
                                                        <img src={require('../../assets/images/inviteparent.svg')} alt="" title="" />
                                                    </span>

                                                </div>
                                                <div className="your_odds">
                                                    <label></label>
                                                    <p> </p>
                                                    <Button id="add_child_grow" size="medium" color="primary" onClick={() => { this.handleAddChild() }} >
                                                        Add Child
                                                    </Button>
                                                </div>
                                            </div>
                                        }
                                        {!this.state.loader && this.state.child_data && this.state.child_data.length > 0 &&
                                            <div className="all_children_section ">
                                                {this.state.child_data && this.state.child_data.length > 0 && this.state.child_data.map((child, index) => (
                                                    <div className="all_children">
                                                        <div className="allChildrenRdo">
                                                            <input id={"checkbox-" + (index)} className="checkboxCustom" name={"checkbox_child"} type="radio" onChange={(event) => { this.onClickCheckBox(event, child.child_id) }} />
                                                            <label for={"checkbox-" + (index)} className="checkboxCustomLabel"></label>
                                                        </div>
                                                        <div className="all_children_left"><label>{initials(child.child_name.toUpperCase()).substring(0, 2)}</label></div>
                                                        <div className="all_children_rght all_children_rghtCstm">
                                                            <label>{child.child_name} {child.parent_name ? "(" + child.parent_name + ")" : ""}</label>
                                                            <p>{this.GetChildType(child.birthdate_timestamp)}, {this.dateFormat(child.child_birthdate)}</p>
                                                        </div>
                                                        {/* <div className="all_children_close">
                                                        <a>:</a>
                                                    </div> */}
                                                    </div>
                                                ))}

                                                <div className="plusChildNW" onClick={() => { this.handleAddChild() }}><a>+</a></div>
                                            </div>
                                        }
                                        {!this.state.loader && this.state.child_data && this.state.child_data.length > 0 &&
                                            <div className="familyadd_upr relative_add_section2 familyaddNwOne">
                                                <Button id="add_relative_grow2" size="medium" color="primary" onClick={() => { this.onClickSaveChildren() }} >
                                                    Save
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                    {/* End of Relative Tab Content */}


                                </div>

                                <Footer props={this.props} />
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}
export default family;