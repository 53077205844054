import React from "react";
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import functions from '../../assets/functions/common_functions';
import url from "../../assets/url";

export default class ChoiceBetweenRelative extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            dynamic_content: "",
            session_type: localStorage.getItem("session_type") ? localStorage.getItem("session_type") : ""
        }
    }

    componentDidMount() {
    }

    handleContinue = (path) => {
        let action = "User clicked on " + path + " on parentInvitationNewRelative page"
        let image = path + '_button_buildyourfamily_page.png'
        functions.logUserActivity(action, image);

        if (path == "newrelative") {
            // localStorage.setItem("add_as_relative", "true")
            functions.pushToRoute(this, "addChildParentelative", 'choicebetweenrelative')
        }
        else if (path == "registeredrelative") {
            // localStorage.setItem("already_a_relative", "true")
            functions.pushToRoute(this, "existingRelativeList", 'choicebetweenrelative')
        }

    }

    handleBack = () => {
        let action = "User clicked on back button of add relative page"
        let image = 'user_clicked_back_button_relative_page.png'
        functions.logUserActivity(action, image)
        let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
        console.log("previous_state", previous_state)
        let relation_data = localStorage.getItem("relation_data") ? JSON.parse(localStorage.getItem("relation_data")) : [];
        let enter_relative_program = localStorage.getItem("enter_relative_program") && localStorage.getItem("enter_relative_program") == "true" ? true : false;
        console.log("enter_relative_program", enter_relative_program)
        // this.props.history.push("/main")
        functions.pushToRoute(this, "main", '',true);
    }

    render() {
        const { dynamic_content } = this.state
        return (
            <div className="container">
                <div className="row">
                    {/* {dynamic_content != "" && */}
                    <div className="common_mainAddOn">
                    <div className="common_main">
                        <div className="sign_backlink white_circle_div">
                            <img onClick={() => { this.handleBack() }} src={url.family_one_image_url + 'Group147.png'} alt="" title="" />
                        </div>
                        {/* <div className="sign_up">
                              <h1>{this.state.session_type == "signup" ? dynamic_content.static_text.buildyourfamily ? dynamic_content.static_text.buildyourfamily.signup.en : "Sign Up" : ""}</h1>
                            </div> */}
                        <div className="inviteType">
                            <div className="add_parent inviteAdd_parent">
                                    <div className="add_parent_inner">
                                        <img src={url.family_one_image_url + 'Group235.png'} alt="" title="" />
                                        {/* <a onClick={() => { this.handleContinue("addchild") }}>+</a> */}
                                    </div>
                                </div>
                            <div className="build_content">
                                <h1>Choose your relative type</h1>
                                <p>Select whether you want to invite a New relative or 
                                already registered relative for parent invitaion..</p>
                            </div>
                            <div className="prent_submit prent_submit2">
                                <Button size="medium" color="primary" onClick={() => { this.handleContinue("newrelative") }}>
                                    <div><img src={require('../../assets/images/Inviterelative.png')} alt="" title="" /></div>
                                    Invite a New relative
                                </Button >
                                <Button onClick={() => { this.handleContinue("registeredrelative") }}>
                                    <div><img src={require('../../assets/images/Inviterelative2.png')} alt="" title="" /></div>
                                    Invite a relative list
                                </Button>
                            </div>
                        </div>

                    </div>
                    </div>
                    {/* } */}
                </div>
            </div>

        )
    }
}