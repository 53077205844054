import React, { Fragment, useState } from 'react';
import '../../../assets/css/style.css';
import TextField from '@material-ui/core/TextField';
import functions from '../../../assets/functions/common_functions';
import { isMobile } from "react-device-detect";
import DatePickerMobile from "react-mobile-datepicker";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from "react-html-parser";
const monthMap = {
    '1': 'Jan',
    '2': 'Feb',
    '3': 'Mar',
    '4': 'Apr',
    '5': 'May',
    '6': 'Jun',
    '7': 'Jul',
    '8': 'Aug',
    '9': 'Sep',
    '10': 'Oct',
    '11': 'Nov',
    '12': 'Dec',
};

const Month = {
    "01": "January",
    "02": "February",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    "10": "October",
    "11": "November",
    "12": "December"
};

const dateConfig = {
    'year': {
        format: 'YYYY',
        caption: 'Year',
        step: 1,
    },
    'month': {
        format: value => monthMap[value.getMonth() + 1],
        caption: 'Mon',
        step: 1,
    },
    'date': {
        format: 'DD',
        caption: 'Day',
        step: 1,
    },
};

export default class Entercontestdob extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            birthdate: new Date(),
            birthdate_selected: false,
            time: new Date(1990),
            show_date: false,
            isOpen: false,
            dynamic_content: ""
        }
    }

    formatMinMaxDate = (date) => {
        let date_month = date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
        let date_year = date.getFullYear();
        let date_date = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let final_date = date_year + "-" + date_month + "-" + date_date;
        return final_date;
    }

    componentWillMount = () => {
        console.log("this", this.props)
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
        }
        let time = new Date()
        time.setFullYear(time.getFullYear() - 30)
        this.setState({ time: time })
    }

    handleClickDate = () => {
        this._showDateTimePicker();
    }

    _showDateTimePicker = () => this.setState({ isOpen: true });

    _hideDateTimePicker = () => this.setState({ isOpen: false });

    handleSelect = date => {
        let success_action = "User select date in all entercontestdob page"
        let image = 'date_select_entercontestdob_page.png'
        functions.logUserActivity(success_action, image);
        this.setState({ isOpen: false });
        let month = date.getMonth() + 1
        let date_month = month < 10 ? "0" + month : month;
        let date_year = date.getFullYear();
        let date_date = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        date = date_year + "-" + date_month + "-" + date_date;
        this.setState({
            birthdate: date,
            time: new Date(date),
            show_date: true,
            show_error_message: '',
            birthdate_selected: true
        });
        // let userdata = [{ Name: 'birthdate', Value: date }];
        // let dynamo_userdata = { 'birthdate': date };
        // localStorage.setItem("birthdate", date);
        // functions.updateUserData(this, userdata, dynamo_userdata);
        this.props.onDOBSubmit(date)
    };

    handleCancel = () => {
        console.log("handleCancel")
        this.setState({ isOpen: false });
    };

    dateFormat = (value) => {
        if (value) {

            let date_array = value.split("-");
            if (date_array.length > 1) {
                let year = date_array[0];
                let date = date_array[2];
                let month = date_array[1];
                let month_name = Month[month];
                let final_date = date + " " + month_name + " " + year;
                return final_date;
            }
            else {
                date_array = value.split("/");
                if (date_array.length > 1) {
                    let year = date_array[0];
                    let date = date_array[2];
                    let month = date_array[1];
                    let month_name = Month[month];
                    let final_date = date + " " + month_name + " " + year;
                    return final_date;
                }
            }
        } else {
            return value;
        }
    }

    handleFinalDate = (value) => {
        let success_action = "User select date in all entercontestdob page"
        let image = 'date_select_entercontestdob_page.png'
        functions.logUserActivity(success_action, image);
        let month = value.getMonth() + 1
        let date_month = month < 10 ? "0" + month : month;
        let date_year = value.getFullYear();
        let date_date = value.getDate() < 10 ? "0" + value.getDate() : value.getDate();
        let date = date_year + "-" + date_month + "-" + date_date;
        let timestamp = new Date(date).getTime()
        let minimum_date = new Date(this.props.minimum_date)
        let maximum_date = new Date(this.props.maximum_date)
        if (timestamp >= minimum_date.getTime() && timestamp <= maximum_date.getTime() || date == '') {
            this.setState({
                birthdate: date,
                time: new Date(date),
                show_date: true,
                show_error_message: '',
                birthdate_selected: true
            });
            // let userdata = [{ Name: 'birthdate', Value: date }];
            // let dynamo_userdata = { 'birthdate': date };
            // localStorage.setItem("birthdate", date);
            // functions.updateUserData(this, userdata, dynamo_userdata);
            this.props.onDOBSubmit(date)
        }
    }

    render() {
        const { dynamic_content } = this.state
        const props = this.props
        return (
            <div className="">
                {dynamic_content != "" && props &&
                    <div>
                        <div className={props.className}>
                            {isMobile &&
                                <div>
                                    <TextField
                                        autoComplete='off'
                                        onClick={() => { this.handleClickDate() }}
                                        // label={props.dateofbirth_label}
                                        type="text"
                                        autoFocus={true}
                                        inputRef={(input) => { this.child_birthdate = input; }}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        value={this.state.show_date == true ? this.dateFormat(this.state.birthdate) : " "}
                                        margin="normal"
                                    />
                                    <label>

                                    </label>
                                    <div className="addChildDateIcon">
                                      <DatePickerMobile
                                        value={this.state.time}
                                        isOpen={this.state.isOpen}
                                        onSelect={this.handleSelect}
                                        onCancel={this.handleCancel}
                                        theme="ios"
                                        cancelText="Cancel"
                                        confirmText="Ok"
                                        showFormat="MM/DD/YYYY"
                                        min={new Date(props.minimum_date)}
                                        max={new Date(props.maximum_date)}
                                        dateConfig={dateConfig}
                                      />
                                    </div>
                                </div>
                            }
                            {!isMobile && props.minimum_date && props.maximum_date &&
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Fragment>
                                      <div className="addChildDateIcon">
                                        <DatePicker
                                            openTo="year"
                                            format="dd/MM/yyyy"
                                            id="select_date"
                                            views={["year", "month", "date"]}
                                            // label={props.dateofbirth_label}
                                            // placeholder="dd/mm/yyyy"
                                            minDate={new Date(props.minimum_date)}
                                            maxDate={new Date(props.maximum_date)}
                                            value={this.state.show_date == true ? this.state.birthdate : null}
                                            onChange={(e) => { this.handleFinalDate(e) }}
                                            margin="normal"
                                        />
                                      </div> 
                                    </Fragment>
                                </MuiPickersUtilsProvider>
                            }

                        </div>

                    </div>
                }
            </div>
        );
    }
}