import React, { Suspense } from 'react';
import '../../assets/css/style.css';
import '../../assets/css/animation_style.css';
import '../../assets/css/program_main_animation.css';
import Swiper from 'react-id-swiper';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import functions from '../../assets/functions/common_functions';
import url from '../../assets/url';
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from "react-html-parser";
import Footer from './footer';
import Button from '@material-ui/core/Button';
import { lighten, withStyles } from '@material-ui/core/styles';
import LottieLoader from '../LottieLoader';
import cookie from 'react-cookies';
import { Modal, ModalBody } from 'reactstrap';
import $ from "jquery";
import Programtile from '../home/program_tiles';
import ProgramDetail from './program_detail';
import LinearProgress from '@material-ui/core/LinearProgress';
import TermsCondition from '../TermsCondition/index'
import { TimelineLite, TweenLite, TweenMax, Expo, TimelineMax, Bounce, Circ, Linear, Power1, Power2, Power3, Back, Power4, SlowMo } from "gsap/all";
import CSSPlugin from 'gsap/CSSPlugin';
import Urgent from './urgent'
import StoryDetail from './story_detail';
import PerksDetailAll from '../perks/perkdetailall';
import Geocode from "react-geocode";
import { isMobileSafari } from 'react-device-detect';
import MyLoader from '../Component/ContentLoader';
import AlternativeEmailPopup from "../commonPopup/alteranativeEmail";
import ParentInvitaionPopup from './parentInvitation';
import { connect } from 'react-redux';

const { v1: uuidv1 } = require('uuid');
const C = CSSPlugin;
var initials = require("initials");
const Month = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "08": "Aug",
    "07": "Jul",
    "09": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec"
};

const BorderLinearProgress = withStyles({
    root: {
        height: 6,
        backgroundColor: lighten('#66BD99', 0.5),
    },
    bar: {
        borderRadius: 20,
        backgroundColor: '#66BD99',
    },
})(LinearProgress);

class Sharegain extends React.Component {
    constructor(props) {
        //console.log("super")
        super(props);
        // functions.handleBackOne(this, 'main')
        this.toggleTab = this.toggleTab.bind(this);
        // this.notification = this.notification.bind(this);
        this.state = {
            active: false,
            activeTab: 'all',
            activeIndex: 0,
            modal_prize: false,
            main_loader: true,
            notification: false,
            loader: false,
            all_program_array: [],
            all_program: [],
            featured_program_array: [],
            main_program_array: [],
            is_still_locked: false,
            show_t_and_c: false,
            urgent_data: [],
            program: [],
            show_freebie: false,
            show_giveaway: false,
            show_survey: false,
            show_review: false,
            show_story: false,
            edit_name_child_data: [],
            notify_number: 0,
            modal_child_name: false,
            modal_add_child: false,
            modal_add_child_popup: false,
            child_data: localStorage.getItem("child_data") ? JSON.parse(localStorage.getItem("child_data")) : [],
            dynamic_content: "",
            auto_id: cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id"),
            urgent: false,
            user_signup_process: localStorage.getItem("user_signup_process") && localStorage.getItem("user_signup_process") == "true" ? true : false,
            show_urgent_notifications: false,
            relation: localStorage.getItem("relation") ? JSON.parse(localStorage.getItem("relation")) : [],
            isOpenChangeEmailParent: false,
            isOpen: false,
            isOpenNotification: false,
            isVideoRecordOpen: false
            // show_popup_email: false
            // isOpen: false

        };
        this.goNext = this.goNext.bind(this)
        this.goPrev = this.goPrev.bind(this)
        this.openprize = this.openprize.bind(this)
        this.child = React.createRef();
        this.togglechild_name = this.togglechild_name.bind(this)
        this.toggleaddchild = this.toggleaddchild.bind(this)
        this.swiper = null
    }

    monthDiff(d1, d2) {
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    }

    getAge = birthDate => Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10)

    componentDidMount = () => {

        const phoneTransfer = localStorage.getItem('phone_transfer');
        const emailTransfer = localStorage.getItem('email_transfer');
        const email = localStorage.getItem('email');



        // Step 2: Check if the value exists
        if (phoneTransfer && emailTransfer && emailTransfer === email) {
            console.log(`Phone number found: ${phoneTransfer}`);
            localStorage.setItem("current_state", "editprofile")
            // Step 3: Redirect to the /edit_address route
            window.location.href = `${window.location.origin}/editprofile`;
        }

        const checkMainIndex = localStorage.getItem("CheckMainIndex");
        let self = this
        if (localStorage.getItem("programSONSuccess") == "true") {
            functions.pushToRoute(self, 'program_detail', 'main_index')
            return;
        }
        if (checkMainIndex && checkMainIndex == "main_index" && (localStorage.getItem("previous_state") == 'program_detail' || localStorage.getItem("previous_state") == 'perks_detail')) {
            localStorage.removeItem("CheckMainIndex");
            let program_list = JSON.parse(localStorage.getItem("program_list"));
            let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
            self.setState({ dynamic_content })
            self.getAllPrograms(program_list, 1);
            self.GetProgramInfo();
            const tab = localStorage.getItem("activeTab") ? localStorage.getItem("activeTab") : "all"
            setTimeout(() => {
                this.toggleTab(tab);
                functions.scrollToDiv(localStorage.getItem('program_div'))
            }, 10);
            return false;
        }

        self.setState({ isVideoRecordOpen: false });
        let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        let rlid = localStorage.getItem("rlid") ? localStorage.getItem("rlid") : ""
        let parent_invite_id = localStorage.getItem("parent_invite_id") ? localStorage.getItem("parent_invite_id") : "";
        if (rlid && parent_invite_id) {
            let isOpenChangeEmailParent = localStorage.getItem("show_popup_email") == "true" ? localStorage.getItem("show_popup_email") : false;
            this.setState({ isOpenChangeEmailParent })
        } else {
            localStorage.removeItem('show_popup_email');
        }

        auto_id = auto_id ? auto_id : "";
        if (auto_id && auto_id != "" && auto_id != "null" && auto_id != null && typeof auto_id != undefined) {
            let email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email")
            if (email && email != "" && email != "null" && email != null && typeof email != undefined && auto_id && auto_id != "" && auto_id != "null" && auto_id != null && typeof auto_id != undefined) {
                let finaljson = {
                    email: email,
                    sub_id: auto_id,
                }

                functions.checkUserExistence(self, finaljson, function (response_json) {
                    let response = JSON.parse(response_json)
                    if (response.data && response.data.dynamo && response.data.dynamo.Items && (response.data.dynamo.Items.length == 0 || !response.data.dynamo.Items)) {
                        console.log("landking page 13")
                        functions.pushToRoute(self, "", "main")
                    } else {
                        let edit_name_child_data = []
                        let named_child_data = []
                        let dynamo_data = response?.data?.dynamo?.Items[0]
                        let child_data = dynamo_data && dynamo_data.child_data ? dynamo_data.child_data : []
                        let last_name_popup_timestamp = dynamo_data && dynamo_data.last_name_popup_timestamp ? new Date(dynamo_data.last_name_popup_timestamp) : ""
                        let last_add_child_ppoup_timestamp = dynamo_data && dynamo_data.last_add_child_ppoup_timestamp ? new Date(dynamo_data.last_add_child_ppoup_timestamp) : ""
                        // last_name_popup_timestamp = new Date(1627288026000)
                        // last_add_child_ppoup_timestamp = new Date(1619342492000)
                        if (dynamo_data && dynamo_data.phone_verified) localStorage.setItem("phone_verified", dynamo_data.phone_verified)
                        let child_timestap_ftm = cookie.load("ftm_child_time_stamp", { path: "/" });
                        child_timestap_ftm = child_timestap_ftm ? parseInt(child_timestap_ftm) : ''
                        if (child_timestap_ftm && new Date(child_timestap_ftm)) {
                            //console.log("child_timestap_ftm", child_timestap_ftm)
                            let child_date = self.formatDate(child_timestap_ftm);
                            let child_data = self.state.child_data ? self.state.child_data : []
                            let temp_child_Data = { child_name: "child " + (child_data.length + 1), child_gender: 'Unknown', child_birthdate: child_date, birthdate_timestamp: child_timestap_ftm, type: 'Expectant', child_id: uuidv1(), relation_with_child: 'parent', creator_auto_id: auto_id }
                            child_data.push(temp_child_Data);
                            let userdata = []
                            let dynamo_userdata = { 'child_data': child_data, 'delete_ftm_ts': true };
                            localStorage.setItem("child_data", JSON.stringify(child_data))
                            functions.updateUserData(self, userdata, dynamo_userdata);

                        }
                        // let props = this.props.props;
                        let { isOpen } = self.state;

                        if (!isOpen && self.props.history && self.props.history.location && self.props.history.location.state && self.props.history.location.state.show_popup) {
                            //console.log("called from herse ", self.props.history.location.state)
                            self.props.history.replace(
                                self.props.history.location.pathname
                                , {})
                            self.childInvitation(100);
                        }
                        let today_timestamp = new Date()
                        let show_child_name_modal = false
                        let show_add_child_modal = true
                        let show_add_child_popup_modal = false
                        let child_popup = cookie.load("child_popup") ? cookie.load("child_popup") : localStorage.getItem("child_popup");
                        if (child_popup == "true") {
                            show_add_child_popup_modal = true
                        }
                        let user_signup_process = self.state.user_signup_process
                        if (last_name_popup_timestamp != "") {
                            let month_diff = self.monthDiff(last_name_popup_timestamp, today_timestamp)
                            if (month_diff == 3) {
                                show_child_name_modal = true
                            }
                        } else if (user_signup_process == false) {
                            show_child_name_modal = true
                        }
                        if (last_add_child_ppoup_timestamp != "") {
                            let month_diff = self.monthDiff(last_add_child_ppoup_timestamp, today_timestamp)
                            if (month_diff != 6) {
                                show_add_child_modal = false
                            }
                        }

                        if (child_data.length) {
                            edit_name_child_data = child_data.filter(x => x.child_name.toLowerCase().substring(0, 5) == "child");
                            named_child_data = child_data.filter(x => x.child_name.toLowerCase().substring(0, 5) != "child");
                        }
                        self.setState({ edit_name_child_data: edit_name_child_data, child_data: child_data })
                        if (show_child_name_modal && edit_name_child_data.length) {
                            let userdata = []
                            let dynamo_userdata = { 'last_name_popup_timestamp': today_timestamp.getTime() };
                            functions.updateUserData(this, userdata, dynamo_userdata);
                            self.togglechild_name()
                        }

                        let age;
                        if (child_data && child_data.length > 0) {
                            age = self.getAge(child_data[child_data.length - 1]?.birthdate_timestamp);
                        }
                        if (!show_child_name_modal && show_add_child_modal && named_child_data.length > 0 && age >= 1) {
                            let userdata = []
                            let dynamo_userdata = { 'last_add_child_ppoup_timestamp': today_timestamp.getTime() };
                            functions.updateUserData(this, userdata, dynamo_userdata);
                            self.toggleaddchild()
                        }
                        if (!show_child_name_modal && show_add_child_popup_modal) {
                            let userdata = []
                            let dynamo_userdata = { 'last_add_child_ppoup_timestamp': today_timestamp.getTime() };
                            functions.updateUserData(this, userdata, dynamo_userdata);
                            cookie.remove("child_popup", { path: '/' })
                            self.toggleaddchildPopup()
                        }

                        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
                        if (dynamic_content != "") {
                            self.setState({ dynamic_content: dynamic_content })
                            self.getAllPrograms(response.data.program_data)
                            self.GetProgramInfo()
                        }
                        else {
                            setTimeout(function () {
                                window.location.reload()
                            }, 1000);
                        }
                    }
                });
            } else {
                this.props.history.push("/")
            }
        }
        else {
            this.props.history.push("/")
        }

        let success_action = "User landed on main page after login"
        let image = 'main_page_landing_after_login.png'
        functions.logUserActivity(success_action, image);
        localStorage.setItem("active_button", "0")
        localStorage.setItem("perk_source", "main")
        this.handleSlideDownEffect()
    }

    formatDate(date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    GetProgramInfo = () => {
        let self = this;
        // check if the user is winner in any program
        let winner_data = localStorage.getItem("winner_data") ? JSON.parse(localStorage.getItem("winner_data")) : ""
        if (Array.isArray(winner_data) && winner_data.length > 0) {
            cookie.save("winner_program_id", winner_data[0].program_id, { path: "/" })
            cookie.save("winner_id", cookie.load("auto_id"), { path: "/" })
            let date1 = new Date();
            let date2 = new Date(winner_data[0].claim_expiry_date);
            var Difference_In_Time = date2.getTime() - date1.getTime();
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            cookie.save("winner_claim_days", parseInt(Difference_In_Days), { path: "/" })
            cookie.save("claim_status", 'pending', { path: "/" })
            // cookie.save("winner_later", 'false', { path: '/' })
            let later_winer = cookie.load('winner_later') && cookie.load('winner_later') == 'true' ? true : false
            if (!later_winer) {
                this.openprize()
            }

        }

        //check user is having unread notification or not
        let new_notifications = [];
        let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        sub_id = sub_id ? sub_id : ""
        if (sub_id != "" && sub_id != "undefined" && typeof sub_id != undefined) {
            functions.getNotifications(sub_id, 'false', function (response_json) {
                let response = JSON.parse(response_json);
                if (response.data) {
                    if (response.data.error == 1) {
                        let success_action = "User get error response on getNotifications function on main page after login"
                        functions.logUserActivity(success_action, null);
                    }
                    else {
                        let success_action = "User get success response on getNotifications function on main page after login"
                        functions.logUserActivity(success_action, null);
                        new_notifications = response.data.new_notification;
                        let notify_number = new_notifications.length;
                        self.setState({ notify_number: notify_number })
                        // if (notify_number > 0) {
                        //     self.setState({ isOpenNotification: true })
                        // }
                        // self.setState({ show_urgent_notifications: true })
                        // if (notify_number > 0) {
                        //     TweenMax.to('.notification_pop', 1, { scale: 1.1, ease: Bounce.easeOut, repeat: -1 });
                        // }
                    }
                }
            })

            //check user is having any urgent notification or not
            functions.getUrgentNotifications(sub_id, function (response_json) {
                let response = JSON.parse(response_json);
                if (response.data) {
                    if (response.data.error == 1) {
                        let success_action = "User get error response on getUrgentNotifications function on main page after login"
                        functions.logUserActivity(success_action, null);
                    }
                    else {
                        let success_action = "User get success response on getUrgentNotifications function on main page after login"
                        functions.logUserActivity(success_action, null);
                        if (response.data && response.data.length > 0) {
                            let urgent_data = response.data;
                            localStorage.setItem('urgent_data', JSON.stringify(urgent_data))
                            self.setState({ urgent: true });
                        }
                        else {
                            self.setState({ urgent: false });
                        }
                    }
                }
            })
        }

    }

    toggleAnimation = () => {
        let self = this;
        // console.clear();

        var root = document.documentElement;
        var body = document.body;
        var pages = document.querySelectorAll(".program-page");
        var tiles = document.querySelectorAll(".program-tile");
        for (var i = 0; i < tiles.length; i++) {
            let tiles_id = tiles[i].getAttribute("id");
            let ids = '#' + tiles_id + '.program-page.hero-1';
            let pageselected = []
            pages.forEach(page => {
                if (page.getAttribute("id") == tiles_id) {
                    pageselected.push(page)
                }
            });
            addListeners(tiles[i], pageselected[0]);
        }

        function addListeners(program_tile, page) {

            program_tile.addEventListener("click", function () {
                animateHero('', program_tile, page);
            });

            let page_id = page.getAttribute("id");
            if (page_id) {
                $(document).on('click', '#' + page_id + ' .close_btn', function () {
                    localStorage.removeItem("session_entered_id");
                    animateHero(page_id, page, program_tile);
                })
            } else {
                page.querySelectorAll(".close_btn")[0].addEventListener("click", function () {
                    localStorage.removeItem("session_entered_id");
                    animateHero('', page, program_tile);
                });
            }
        }

        function animateHero(page_id, fromHero, toHero) {
            var clone = fromHero.cloneNode(true);
            var from = calculatePosition(fromHero);
            var to = calculatePosition(toHero);
            $('.program-page.active').find('.terms_all').removeClass("terms_active");
            TweenLite.set([fromHero, toHero], { visibility: "hidden", className: "-=active" });
            TweenLite.set(clone, { position: "absolute", margin: 0, background: "#F9EAE2" });
            body.appendChild(clone);
            var style = {
                x: to.left - from.left,
                y: to.top - from.top,
                width: to.width,
                height: to.height,
                autoRound: false,
                ease: Power1.easeOut,
                onComplete: onComplete
            };
            TweenLite.set(clone, from);
            TweenLite.to(clone, 0.3, style)
            if (clone.classList.contains('program-tile', 'program_detail_logo', 'program_title01')) {
                $('.survey_section').css('background-image', 'none');
                TweenMax.fromTo(clone.querySelectorAll(".program_detail_logo")[0], 0.3, { bottom: "16px" }, { bottom: "-31px" }, 0)
                TweenMax.fromTo(clone.querySelectorAll(".program_title01 label")[0], 0.3, { 'font-size': "20px" }, { 'font-size': "25px" }, 0)
                $('html, body').css('overflow', 'hidden');
                $('.common_main_space1, .sharegain_swiper, .program-tile-container, .extragain_tabs').css('opacity', 0);
                $('.program-page-container').find('.program-page').css('min-height', window.innerHeight);
                localStorage.setItem('detail_page', true);

            } else if (clone.classList.contains('program-page', 'program_detail_logo', 'freebies_iner')) {
                $('.survey_section').css('background-image', 'url(https://app2.family.one/images/survey-background.png)');
                TweenMax.fromTo(clone.querySelectorAll(".program_detail_logo")[0], 0.3, { bottom: "-31px" }, { bottom: "16px" }, 0)
                TweenMax.fromTo(clone.querySelectorAll(".program_title01 label")[0], 0.3, { 'font-size': "25px" }, { 'font-size': "20px" }, 0)
                $('html, body').css('overflow', 'initial');
                $('.common_main_space1, .sharegain_swiper, .program-tile-container, .extragain_tabs').css('opacity', 1);
                $('.program-page-container').find('.program-page').find('.terms_all').removeClass("active");
                $('.program-page-container').find('.program-page').css('min-height', window.innerHeight);
                localStorage.setItem('detail_page', false);
            }

            function onComplete() {
                // ,zIndex:"999999",minHeight:"100vh",height:"100vh",
                TweenLite.set(toHero, { visibility: "visible", className: "+=active" });
                $('.program-page.active').find('.terms_all').addClass("terms_active");
                body.removeChild(clone);
            }
        }

        function calculatePosition(element) {
            var rect = element.getBoundingClientRect();
            var scrollTop = window.pageYOffset || root.scrollTop || body.scrollTop || 0;
            var scrollLeft = window.pageXOffset || root.scrollLeft || body.scrollLeft || 0;
            var clientTop = root.clientTop || body.clientTop || 0;
            var clientLeft = root.clientLeft || body.clientLeft || 0;
            return {
                top: Math.round(rect.top + scrollTop - clientTop),
                left: Math.round(rect.left + scrollLeft - clientLeft),
                height: rect.height,
                width: rect.width,
            };
        }
        /*End of Code of animation */
    }

    openprize() {
        if (this.state.modal_prize == true) {
            let self = this;
            cookie.save("winner_later", 'true', { path: '/' })
            let program_data = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : []
            let session_entered_id = localStorage.getItem("session_entered_id") ? localStorage.getItem("session_entered_id") : ""
            if (session_entered_id != "") {

                var index = program_data.findIndex(p => p.program_id == session_entered_id)
                let temp_program = program_data[index]
                localStorage.setItem("program", JSON.stringify(temp_program))
                localStorage.setItem("program_index", index + 1)
                localStorage.setItem("program_id", session_entered_id)
                let main_id = temp_program.program_type + temp_program.program_id
                this.handleShowDetail(temp_program, main_id)
            }
        }
        this.setState({
            modal_prize: !this.state.modal_prize
        });
    }

    toggleaddchild = (click_button) => {
        if (click_button == "later") {
            let success_action = "User clicked on later button on add child popup"
            let image = ''
            functions.logUserActivity(success_action, image);
        }
        this.setState({
            modal_add_child: !this.state.modal_add_child
        });
    }

    toggleaddchildPopup = (click_button) => {
        if (click_button == "later") {
            let success_action = "User clicked on later button on add child popup"
            let image = ''
            functions.logUserActivity(success_action, image);
        }
        this.setState({
            modal_add_child_popup: !this.state.modal_add_child_popup
        });
    }

    togglechild_name = (click_button) => {
        if (click_button == "later") {
            let success_action = "User clicked on later button on edit child name popup"
            let image = ''
            functions.logUserActivity(success_action, image);
        }

        this.setState({
            modal_child_name: !this.state.modal_child_name
        });
    }

    getAllPrograms = (data, back) => {
        localStorage.setItem("active_button", "0")
        let self = this;
        let main_program_array = [];
        let all_program = []
        let cognito_data = {};
        let dynamo_data = {};
        let program_data = data ? data : []
        let relation_data = {}
        program_data.sort(function (a, b) {
            return a.order_number - b.order_number
        })
        program_data = self.sortInOrder(program_data)
        let store_all = 'false';
        functions.storeCookie(cognito_data, dynamo_data, relation_data, program_data, store_all);
        // let already_enetered_program = program_data.filter((e) => e.has_enter == 1);
        let cst_visibility = self.checkCSTVisibilty(program_data)
        let cst_eligible = false
        program_data.forEach((element, index) => {
            if (element.program_status === "running") {

                //console.log("cst_eligible", cst_eligible)
                if (element.after_entry_of && element.after_entry_of != "" && element.after_entry_of != "[]") {
                    let element_visibility = self.CheckProgramVisibility(element, program_data)

                    //  if (element_visibility == true) {
                    element.program_index = index + 1;
                    if (cst_visibility || (element.partner_id !== '1' && element.partner_id !== '15')) {
                        if (element.hasOwnProperty('visible_to_user_signup') && element.visible_to_user_signup === false) {
                            element.is_visible = element.visible_to_user_signup
                        } else {
                            if (element.hasOwnProperty("visible_to_user") && !element.notentry_after_of) {
                                element.is_visible = element.visible_to_user
                            } else {
                                element.is_visible = element_visibility ? element_visibility : false
                            }
                        }
                    }
                    else {
                        element.is_visible = false
                    }
                    if (element.has_enter == 0 && element.is_eligible == 0 && element.show_to_eligible_user == 1) {
                        element.show_program = false
                    } else {
                        element.show_program = true
                    }
                    main_program_array.push(element)
                    if (element.featured == 1 && element.show_program) {
                        element.program_index = index + 1
                    }

                    if (element.show_program && element.is_visible) {
                        switch (element.program_type) {
                            case "freebie":
                            case "contest":
                            case "survey":
                            case "story":
                            case "review":
                                element.program_index = index + 1
                                all_program.push(element)
                                break;
                        }
                    }

                } else {
                    let element_visibility = true
                    if (cst_visibility || (element.partner_id !== '1' && element.partner_id !== '15')) {
                        if (element.notentry_entry_type) {
                            element_visibility = self.check_notentry_of_after(element, program_data)
                        }
                        element.program_index = index + 1
                        if (element.hasOwnProperty('visible_to_user_signup') && element.visible_to_user_signup === false) {
                            element.is_visible = element.visible_to_user_signup
                        } else {
                            if (element.hasOwnProperty("visible_to_user") && !element.notentry_after_of) {
                                element.is_visible = element.visible_to_user
                            } else {
                                element.is_visible = element_visibility ? element_visibility : false
                            }
                        }
                    }
                    else {
                        element.is_visible = false
                    }
                    if (element.has_enter == 0 && element.is_eligible == 0 && element.show_to_eligible_user == 1) {
                        element.show_program = false
                    } else {
                        element.show_program = true
                    }
                    main_program_array.push(element)
                    if (element.show_program && element.is_visible) {
                        switch (element.program_type) {
                            case "freebie":
                            case "contest":
                            case "survey":
                            case "story":
                            case "review":
                                element.program_index = index + 1
                                all_program.push(element)
                                break;
                        }
                    }
                }
                if (element.partner_id === "14")
                    //console.log("element 14", element)
                    if (element.partner_id === "14" && element.is_eligible !== 0 && element.cst_consent !== "no" && element.show_program && element.is_visible) {
                        //console.log(" element.cst_consent", element, element.partner_id, element.is_eligible, element.program_id)
                        cst_eligible = true
                    }
            }
        })
        let program_array_enter = [];
        let program_array_pending = [];
        let program_array_is_eligble = []
        let program_array_notenter = []
        all_program.map((ele) => {

            if (ele.has_enter) {
                if (ele.pending_fields.length) program_array_pending.push(ele)
                else program_array_enter.push(ele)
            }
            else {
                if (ele.is_eligible || ele.pending_fields.length)
                    program_array_is_eligble.push(ele);
                else
                    program_array_notenter.push(ele);
            }
        })
        program_array_pending.sort(function (a, b) {
            return a.all_order_number - b.all_order_number
        })
        program_array_enter.sort(function (a, b) {
            return a.all_order_number - b.all_order_number
        })
        program_array_notenter.sort(function (a, b) {
            return a.all_order_number - b.all_order_number
        })
        program_array_is_eligble.sort(function (a, b) {
            return a.all_order_number - b.all_order_number
        })

        all_program = [...program_array_pending, ...program_array_is_eligble, ...program_array_notenter, ...program_array_enter]
        // if(!cst_eligible)
        // all_program = [...all_program, ...program_array_enter]
        //  else
        // {
        //     let kff_entry = [];
        //     let new_program = []
        //     all_program.map(prog=>{
        //         if(prog.partner_id == "1")
        //         kff_entry.push(prog)
        //         else 
        //         new_program.push(prog)
        //     })
        //     new_program = [...new_program,...kff_entry, ...program_array_enter]
        //     all_program = new_program
        // }


        let featured_program_array = all_program.filter((e) => e.featured == 1 == true && e.show_program && e.is_visible);
        featured_program_array.sort(function (a, b) {
            let aOrder = a.feature_order_number ? a.feature_order_number : 0
            let bOrder = b.feature_order_number ? b.feature_order_number : 0
            return aOrder - bOrder
        })
        let contest_array = all_program.filter((e) => e.program_type == "contest");
        let freebie_array = all_program.filter((e) => e.program_type == "freebie");
        let survey_array = all_program.filter((e) => e.program_type == "survey");
        let stories_array = all_program.filter((e) => e.program_type == "story");
        let review_array = all_program.filter((e) => e.program_type == "review");

        // self.setState({ loader: false })
        let show_freebie = false
        let show_giveaway = false
        let show_survey = false
        let show_review = false
        let show_story = false
        if (freebie_array.length) {
            show_freebie = true
        }
        if (contest_array.length) {
            show_giveaway = true
        }
        if (survey_array.length) {
            show_survey = true
        }
        if (review_array.length) {
            show_review = true
        }
        if (stories_array.length) {
            show_story = true
        }
        self.props.handleAllprogramList(all_program)
        self.setState({ show_survey, show_freebie, show_giveaway, show_story, show_review, all_program: all_program, featured_program_array: featured_program_array, main_program_array: main_program_array })
        if (!localStorage.getItem('detail_page') || localStorage.getItem('detail_page') == 'false') {
            self.setState({
                main_loader: false
            })
        }
        // self.toggleAnimation();
        self.handleProgramDetailAnimation();
        // self.handleProgramDetailAnimation();
        if (back && back == 1) {
            let perk_array = JSON.parse(localStorage.getItem('perk_response'));
            all_program = all_program && all_program.concat(perk_array)
            self.setState({ perk_array: perk_array, all_program: all_program, main_loader: false })
            self.handleFixedHeader()

            // self.checkEnteredPerk(perk_array)
            self.props.handleAllprogramList(all_program)
            functions.scrollToDiv(localStorage.getItem('program_div'))
        } else {
            self.getAllPerks(program_data, back)
        }
        self.toggleTab('all')
        $('html, body').css('overflow', 'initial');

    }


    toggleTab(tab) {
        let self = this;
        if (this.state.activeTab !== tab) {
            self.handleProgramDetailAnimation();
            // self.handleOpenAnimation()
            // self.toggleAnimation();
            // $('html, body').css('overflow', 'initial');
            self.handleFixedHeader()
            setTimeout(function () {
                self.setState({
                    activeTab: tab
                });
            }, 50);
        }
    }

    sortInOrder(program_data) {
        let not_eligible_program = []
        let pending_program = [];
        let entered_program = [];
        let not_entered_program = [];

        program_data.forEach((element, index) => {
            // //console.log("element", element)
            if (element.is_eligible == 0) {
                not_eligible_program.push(element)
            }
            else {
                if (element.pending_fields && element.pending_fields.length > 0) {
                    pending_program.push(element)
                }
                else if (element.has_enter == 1) {
                    entered_program.push(element)
                }
                else {
                    not_entered_program.push(element)
                }
            }
        });
        let final_program = [];
        final_program = Array.prototype.concat.apply([], [pending_program, not_entered_program, entered_program, not_eligible_program])
        //console.log("final_program", final_program)
        return final_program;
    }

    getAllPerks = (program_data, back) => {
        let self = this;
        let is_winner = false;
        let session_entered_id = localStorage.getItem("session_entered_id") ? localStorage.getItem("session_entered_id") : ""
        let winner_data = localStorage.getItem("winner_data") ? JSON.parse(localStorage.getItem("winner_data")) : ""
        if (Array.isArray(winner_data) && winner_data.length > 0) {
            is_winner = true
        }
        let address = localStorage.getItem("address") ? JSON.parse(localStorage.getItem("address")) : {}
        let province = address.province ? address.province : ""
        functions.getPerks(this, province, function (response_json) {
            let response = JSON.parse(response_json);
            if (response.catch_error) {
                let success_action = "User get error response on getAllPerks function on main page after login"
                functions.logUserActivity(success_action, null);
            }
            else if (!response.catch_error) {
                let success_action = "User get success response on getAllPerks function on main page after login"
                functions.logUserActivity(success_action, null);
                if (response && response.length > 0) {
                    let { all_program_array, all_program } = self.state;
                    let perk_array = response.sort(function (a, b) {
                        return a.order_number - b.order_number
                    })
                    all_program = all_program && all_program.concat(perk_array)
                    if (perk_array && perk_array.length > 0) {
                        perk_array.forEach((perk_detail, perk_index) => {
                            if (perk_detail.show_in_all == 1) {
                                all_program_array.push(perk_detail)

                            }
                            let places_lat_long = []
                            Geocode.setApiKey("AIzaSyA10e_yHodj3O1MbN_bOBCPDnnP6GPCeN8")
                            if (perk_detail.perk_location && perk_detail.perk_location.length > 0) {
                                perk_detail.perk_location.forEach((element, index) => {
                                    if (element && element.trim() !== "") {
                                        Geocode.fromAddress(element).then(
                                            response => {
                                                // const { lat, lng } = response.results[0].geometry.perk_location;
                                                // places_lat_long.push({ lat: lat, lng: lng })

                                                // if (index == (perk_detail.perk_location.length - 1)) {
                                                //     perk_array[perk_index].places_lat_long = places_lat_long;
                                                // }
                                            },
                                            error => {
                                                console.error(error);
                                            }
                                        );
                                    }
                                })
                            }
                        })
                    }
                    let program_array_enter = []
                    let program_array_is_eligble = []
                    let program_array_notenter = []
                    all_program_array.map((ele) => {
                        if (ele.has_enter && (!ele.pending_fields || ele.pending_fields.length == 0)) {
                            program_array_enter.push(ele)
                        }
                        else {
                            if (ele?.is_eligible || (ele.pending_fields && ele.pending_fields.length))
                                program_array_is_eligble.push(ele);
                            else
                                program_array_notenter.push(ele);
                        }
                    })
                    program_array_enter.sort(function (a, b) {
                        return a.all_order_number - b.all_order_number
                    })
                    program_array_notenter.sort(function (a, b) {
                        return a.all_order_number - b.all_order_number
                    })
                    program_array_is_eligble.sort(function (a, b) {
                        return a.all_order_number - b.all_order_number
                    })
                    all_program_array = [...program_array_is_eligble, ...program_array_notenter, ...program_array_enter]
                    let final_all_program = all_program_array
                    self.props.handleAllprogramList(all_program)
                    self.setState({
                        main_loader: false,
                        perk_array: perk_array,
                        all_program_array: final_all_program,
                        all_program: all_program
                    })
                    // self.toggleAnimation();
                    localStorage.setItem("perk_response", JSON.stringify(perk_array))
                    self.handleProgramDetailAnimation();
                    // self.handleOpenAnimation()
                    self.handleFixedHeader()
                    self.checkEnteredPerk(perk_array)
                    let entered_program_id = localStorage.getItem("entered_id") ? localStorage.getItem("entered_id") : ""
                    if (session_entered_id != "" && is_winner == false) {
                        var index = program_data.findIndex(p => p.program_id == session_entered_id)
                        if (index != -1) {
                            let temp_program = program_data[index]
                            localStorage.setItem("program", JSON.stringify(temp_program))
                            localStorage.setItem("program_index", index + 1)
                            localStorage.setItem("program_id", session_entered_id)

                            let main_id = temp_program.program_type + temp_program.program_id
                            self.handleShowDetail(temp_program, main_id)
                        }
                    }
                    else if (entered_program_id != '' && localStorage.getItem("detail_page") == 'true') {
                        var index = program_data.findIndex(p => p.program_id == entered_program_id)
                        if (index != -1) {
                            let temp_program = program_data[index]
                            localStorage.setItem("program", JSON.stringify(temp_program))
                            localStorage.setItem("program_index", index + 1)
                            localStorage.setItem("program_id", entered_program_id)

                            let main_id = temp_program.program_type + temp_program.program_id
                            self.setState({ show_urgent_notifications: false })
                            self.handleShowDetail(temp_program, main_id)
                        }
                    }
                } else {
                    let perk_array = [];
                    self.setState({
                        main_loader: false
                    })
                    self.checkEnteredPerk(perk_array)
                }
            }
        })

    }

    checkEnteredPerk = (perk_array) => {
        let self = this
        let entered_program_id = localStorage.getItem("entered_id") ? localStorage.getItem("entered_id") : ""
        let is_winner = false;
        let session_entered_id = localStorage.getItem("session_entered_id") ? localStorage.getItem("session_entered_id") : ""
        let winner_data = localStorage.getItem("winner_data") ? JSON.parse(localStorage.getItem("winner_data")) : ""
        if (Array.isArray(winner_data) && winner_data.length > 0) {
            is_winner = true
        }
        console.log("session_entered_id", session_entered_id)
        console.log("entered_program_id", entered_program_id)
        console.log("localStorage.getItem('detail_page') ", localStorage.getItem("detail_page"))
        if (session_entered_id != "" && is_winner == false) {
            var perk_index = perk_array.findIndex(p => p.auto_id == session_entered_id)
            if (perk_index != -1) {
                let temp_program = perk_array[perk_index]
                let main_id = "perks_" + temp_program.auto_id
                self.setState({ show_urgent_notifications: false })
                self.handleShowDetail(temp_program, main_id)
            }
        }
        else if (entered_program_id != '' && localStorage.getItem("detail_page") == 'true') {
            var perk_index = perk_array.findIndex(p => p.auto_id == entered_program_id)
            if (perk_index != -1) {
                let temp_program = perk_array[perk_index]
                let main_id = "perks_" + temp_program.auto_id
                self.setState({ show_urgent_notifications: false })
                self.handleShowDetail(temp_program, main_id)
            }
        }
    }

    CheckProgramVisibility = (element, program_data) => {
        let after_entry_of = JSON.parse(element.after_entry_of)
        let program_visible = localStorage.getItem("program_visible") ? localStorage.getItem("program_visible") : ""
        if (program_visible && program_visible != "" && program_visible == element.program_id) {
            return true
        } else if (element.has_enter == 1) {
            return true
        } else if (element.hasOwnProperty("visible_to_user_signup") && element.visible_to_user_signup === false) {
            return false
        }
        else if (element.after_entry_type == "any") {
            let element_visible = false
            after_entry_of.forEach((program_element, program_index) => {
                var temp_index = program_data.findIndex(p => p.program_id == program_element)
                if (temp_index != -1) {
                    let detail = program_data[temp_index]
                    if (detail.has_enter == 1 && detail.is_eligible == 1) {
                        element_visible = true
                    }
                }
            })
            if (element_visible && element.notentry_entry_type) {
                element_visible = this.check_notentry_of_after(element, program_data)
                return element_visible
            }
            else return element_visible
        } else if (element.after_entry_type == "all") {
            let element_visible = true
            after_entry_of.forEach((program_element, programa_index) => {
                var temp_index = program_data.findIndex(p => p.program_id == program_element)
                if (temp_index != -1) {
                    let detail = program_data[temp_index]
                    if (detail.has_enter == 0 || detail.is_eligible == 0) {
                        element_visible = false
                    }
                }
            })
            if (element_visible && element.notentry_entry_type) {
                element_visible = this.check_notentry_of_after(element, program_data)
                return element_visible
            }
            else return element_visible
        } else {
            return false
        }
    }

    check_notentry_of_after = (element, program_data) => {
        let not_etnery_type = element.notentry_entry_type;
        let type = element.notentry_after_of_type ? element.notentry_after_of_type : 'program';
        let notentry_after_of = JSON.parse(element.notentry_after_of)
        let notentry_after_of_partner = JSON.parse(element.notentry_after_of_partner);
        // if(element.program_id=='1')
        if (not_etnery_type == "any") {
            let element_visible = true
            if (notentry_after_of) {
                notentry_after_of.forEach((program_element, program_index) => {
                    var temp_index = program_data.findIndex(p => (p.program_id == program_element))
                    if (temp_index != -1) {
                        let detail = program_data[temp_index]
                        if (detail.has_enter == 1) {
                            element_visible = false
                        }
                    }
                })
            }
            else if (notentry_after_of_partner) {
                //console.log("notentry_after_of_partner", notentry_after_of_partner)
                notentry_after_of_partner.forEach((program_element, program_index) => {
                    program_data.map(p => {
                        if (p.partner_id == program_element) {
                            if (p.has_enter == 1) {
                                element_visible = false
                            }
                        }
                    })
                })
            }
            //console.log("element_visible", element_visible)
            return element_visible
        } else if (not_etnery_type == "all") {
            let element_visible = false
            if (notentry_after_of) {
                notentry_after_of.forEach((program_element, program_index) => {
                    var temp_index = program_data.findIndex(p => (type == "program" && p.program_id == program_element) || (type == "partner" && p.partner_id == program_element))
                    if (temp_index != -1) {
                        let detail = program_data[temp_index]
                        if (detail.has_enter == 0 || detail.is_eligible == 0) {
                            element_visible = true
                        }
                    }
                })
            }
            else if (notentry_after_of_partner) {
                notentry_after_of_partner.forEach((program_element, program_index) => {
                    program_data.map(p => {
                        if (p.partner_id == program_element) {
                            if (p.has_enter == 0 || p.is_eligible == 0) {
                                element_visible = true
                            }
                        }
                    })
                })
            }
            return element_visible
        } else {
            return true
        }
    }

    checkCSTVisibilty = (program_data) => {

        let cst_programm = program_data.filter(ele => ele.partner_id == "14" && ele.cst_consent == "yes")
        console.log("program_data cst_programm", cst_programm)
        let cst = program_data.filter(ele => ele.partner_id == "14" && ele.cst_consent == "yes" && (ele.pending_fields.length == 0 || !ele.pending_fields))
        if (cst && cst.length) {
            return false
        } else {
            return true
        }
    }

    UpdateProgramOnEnter = () => {
        let self = this
        let program_data = this.state.all_program;
        // let featured_program_array = [];
        let main_program_array = []
        let all_program = []
        // program_data = self.sortInOrder(program_data)
        let perk_array = this.state.perk_array
        let cst_visibility = self.checkCSTVisibilty(program_data)
        program_data && program_data.length > 0 && program_data.forEach((element, index) => {
            if (element.program_status === "running" || element.perk_location) {
                if (element.after_entry_of && element.after_entry_of != "" && element.after_entry_of != "[]") {
                    let element_visibility = self.CheckProgramVisibility(element, program_data)
                    element.program_index = index + 1;
                    if (cst_visibility || (element.partner_id !== '1' && element.partner_id !== '15')) {
                        if (element.hasOwnProperty('visible_to_user_signup') && element.visible_to_user_signup === false) {
                            element.is_visible = element.visible_to_user_signup
                        } else {
                            if (element.hasOwnProperty("visible_to_user") && !element.notentry_after_of) {
                                element.is_visible = element.visible_to_user
                            } else {
                                element.is_visible = element_visibility ? element_visibility : false
                            }
                        }
                    }
                    else {
                        element.is_visible = false
                    }
                    if (element.has_enter == 0 && element.is_eligible == 0 && element.show_to_eligible_user == 1) {
                        element.show_program = false
                    } else {
                        element.show_program = true
                    }
                    main_program_array.push(element)
                    // if (element.featured == 1 && element.show_program) {
                    //     element.program_index = index + 1
                    //     featured_program_array.push(element)
                    // }
                    if (element.show_program && element.is_visible) {
                        switch (element.program_type) {
                            case "freebie":
                            case "contest":
                            case "survey":
                            case "story":
                            case "review":
                                element.program_index = index + 1
                                all_program.push(element)
                                break;
                        }
                        // all_program.push(element)
                    }
                } else {
                    let element_visibility = true
                    if (cst_visibility || (element.partner_id !== '1' && element.partner_id !== '15')) {
                        if (element.notentry_entry_type) {
                            element_visibility = self.check_notentry_of_after(element, program_data);
                        }
                        element.program_index = index + 1;
                        if (element.hasOwnProperty('visible_to_user_signup') && element.visible_to_user_signup === false) {
                            element.is_visible = element.visible_to_user_signup
                        } else {
                            if (element.hasOwnProperty("visible_to_user") && !element.notentry_after_of) {
                                element.is_visible = element.visible_to_user
                            } else {
                                element.is_visible = element_visibility ? element_visibility : false
                            }
                        }
                    }
                    else {
                        element.is_visible = false
                    }
                    if (element.has_enter == 0 && element.is_eligible == 0 && element.show_to_eligible_user == 1) {
                        element.show_program = false
                    } else {
                        element.show_program = true
                    }
                    main_program_array.push(element)
                    switch (element.program_type) {
                        case "freebie":
                        case "contest":
                        case "survey":
                        case "story":
                        case "review":
                            element.program_index = index + 1
                            all_program.push(element)
                            break;
                    }
                    // all_program.push(element)
                }
                // all_program = all_program.concat(perk_array)
            }
        })
        all_program = all_program && all_program.concat(perk_array)
        let program_array_enter = []
        let program_array_is_eligble = []
        let program_array_notenter = []
        all_program.map((ele) => {
            if (ele.has_enter && ele.pending_fields.length == 0) {
                program_array_enter.push(ele)
            }
            else {
                if (ele.is_eligible || (ele.pending_fields && ele.pending_fields.length))
                    program_array_is_eligble.push(ele);
                else
                    program_array_notenter.push(ele);
            }
        })
        // program_array_enter = program_array_enter.sort(function (a, b) {
        //     return a.all_order_number - b.all_order_number
        // })
        // program_array_notenter = program_array_notenter.sort(function (a, b) {
        //     return a.all_order_number - b.all_order_number
        // })
        // program_array_is_eligble = program_array_is_eligble.sort(function (a, b) {
        //     return a.all_order_number - b.all_order_number
        // })
        // let final_all_program = [...program_array_is_eligble, ...program_array_notenter, ...program_array_enter]
        // let final_all_program = program_array_is_eligble.concat(program_array_notenter, program_array_enter)
        // // //console.log("final_all_program", final_all_program);
        let featured_program_array = all_program.filter((e) => e.featured == 1 == true && e.show_program && e.is_visible);
        featured_program_array.sort(function (a, b) {
            let aOrder = a.feature_order_number ? a.feature_order_number : 0
            let bOrder = b.feature_order_number ? b.feature_order_number : 0
            return aOrder - bOrder
        })
        // self.superheroElement.current.ChangeProgramInfo();
        // self.setState({ loader: false })
        this.props.handleAllprogramList(all_program)
        self.setState({ all_program, main_program_array, featured_program_array })
        // self.toggleTab('all')

    }



    updateProgram = (program) => {
        let self = this;
        let program_data = this.state.all_program;
        let locked_id = localStorage.getItem('locked_id') ? JSON.parse(localStorage.getItem('locked_id')) : '';
        let all_program = []
        if (program_data) {
            let cst_consent = ""

            if (program.partner_id == '14') {
                cst_consent = program.cst_consent
            }
            console.log("cst_consent", cst_consent)
            let cst_visibility = this.checkCSTVisibilty(program_data)
            //console.log("program.pending_fields", program.pending_fields)
            program_data.map((pro) => {
                if (pro.program_id === program.program_id && program.partner_id == '14') {
                    pro.cst_consent = program.cst_consent
                }
                if (program.partner_id == "10" && pro.program_id == "1262") {
                    pro.has_enter = 1
                    pro.pending_fields = []
                }
                if ((pro.partner_id == "14" && (program.partner_id == "15" || program.partner_id == "1"))) {
                    //console.log("pro.partner_id", pro.partner_id)
                    pro.is_visible = false;
                }
                if (pro.program_status == "running" || pro.perk_location) {
                    if (pro.program_id == program.program_id) {
                        pro.has_enter = 1
                    }
                    if (((cst_consent && cst_consent == 'yes') || !cst_visibility) && (pro.partner_id == '1' || pro.partner_id == '15') && (program.pending_fields.length == 0 || !program.pending_fields)) {
                        //console.log("program.pending_fields", pro.is_visible)
                        pro.is_visible = false
                    }
                    else {
                        if (pro.after_entry_of && pro.after_entry_of != "" && pro.after_entry_of != "[]") {
                            let element_visibility = self.CheckProgramVisibility(pro, program_data)

                            if (pro.hasOwnProperty('visible_to_user_signup') && pro.visible_to_user_signup === false) {
                                pro.is_visible = pro.visible_to_user_signup
                            } else {
                                if (pro.hasOwnProperty("visible_to_user") && !pro.notentry_after_of) {
                                    pro.is_visible = pro.visible_to_user
                                } else {
                                    pro.is_visible = element_visibility ? element_visibility : false
                                }
                            }
                            if (pro.has_enter == 0 && pro.is_eligible == 0 && pro.show_to_eligible_user == 1) {
                                pro.show_program = false
                            } else {
                                pro.show_program = true
                            }

                        } else {
                            let element_visibility = true
                            if (pro.notentry_entry_type) {
                                element_visibility = self.check_notentry_of_after(pro, program_data);
                            }
                            if (pro.hasOwnProperty('visible_to_user_signup') && pro.visible_to_user_signup === false) {
                                pro.is_visible = pro.visible_to_user_signup
                            } else {
                                if (pro.hasOwnProperty("visible_to_user") && !pro.notentry_after_of) {
                                    pro.is_visible = pro.visible_to_user
                                } else {
                                    pro.is_visible = element_visibility ? element_visibility : false
                                }
                            }
                            if (pro.partner_id == "14") {
                                //console.log("element_visibility partner cst", element_visibility)
                            }
                            if (pro.has_enter == 0 && pro.is_eligible == 0 && pro.show_to_eligible_user == 1) {
                                pro.show_program = false
                            } else {
                                pro.show_program = true
                            }

                        }

                    }
                    if (locked_id) {
                        let required_program_to_unlock = this.child.current.GetFreebieAndContestNotLocked(pro);
                        let required_program_to_unlock_ids = []
                        if (pro.partner_id != "6" && pro.program_id !== "1989") {
                            if (pro.program_id !== "2629") {
                                required_program_to_unlock_ids = required_program_to_unlock.map(element => {
                                    return element.program_id;
                                })
                            } else {
                                let show_partner = ["1", "3", "10"];
                                let show_partner_id_array = required_program_to_unlock.filter((e) => show_partner.includes(e.partner_id));
                                required_program_to_unlock_ids = show_partner_id_array.map(element => {
                                    return element.program_id
                                });
                            }

                        } else {
                            let show_partner = ["1", "2", "3", "10", "14"]
                            let show_partner_id_array = required_program_to_unlock.filter((e) => show_partner.includes(e.partner_id));
                            required_program_to_unlock_ids = show_partner_id_array.map(element => {
                                return element.program_id
                            });
                        }
                        if (required_program_to_unlock_ids && required_program_to_unlock_ids.length > 0) {
                            let program_to_unlock = [];
                            let locked_number = 0;
                            //console.log("required_program_to_unlock_ids manin", required_program_to_unlock_ids)
                            program_data.forEach(element => {
                                if (required_program_to_unlock_ids.indexOf(element.program_id) > -1) {
                                    if (element.partner_id == "14" && (program.partner_id == "15" && program.has_enter == 1)) {
                                    }
                                    else {
                                        if (element.has_enter == 0 && element.is_eligible == 1 && element.program_type !== "story" && !element?.is_lock_program && (typeof element.is_visible == 'undefined' || element.is_visible != false)) {
                                            program_to_unlock.push(element);
                                            locked_number = locked_number + 1;
                                            pro.is_still_locked = true;
                                            pro.locked_number = locked_number;
                                            pro.program_to_unlock = program_to_unlock;
                                        }
                                    }
                                }

                            });
                            //console.log("locked_number1 main", locked_number)
                        } else {
                            let program_to_unlock = [];
                            let locked_number = 0;
                            locked_number = locked_number;
                            pro.is_still_locked = false;
                            pro.locked_number = locked_number;
                            pro.program_to_unlock = program_to_unlock;
                            //console.log("locked_number2 main", locked_number)
                        }

                    }


                    all_program.push(pro)
                }
            })

            let featured_program_array = all_program.filter((e) => e.featured == 1 == true && e.show_program && e.is_visible);
            // //console.log("featured_program_array 11", featured_program_array)
            // //console.log("all_program 11", all_program)
            featured_program_array.sort(function (a, b) {
                let aOrder = a.feature_order_number ? a.feature_order_number : 0
                let bOrder = b.feature_order_number ? b.feature_order_number : 0
                return aOrder - bOrder
            })
            this.setState({ all_program, featured_program_array })

        }

    }

    handleUpdateUgrade = (perk_id, state) => {
        if (state == 'is_locked') {
            let perk_data = this.state.perk_array
            var index = perk_data.findIndex(p => p.auto_id == perk_id)
            perk_data[index].locked = 0;
            this.setState({ perk_data: perk_data })
        } else if (state == 'is_upgraded') {
            let perk_data = this.state.perk_array
            var index = perk_data.findIndex(p => p.auto_id == perk_id)
            perk_data[index].is_upgraded = 1;
            this.setState({ perk_data: perk_data })
        }

    }

    notification() {
        let success_action = "User clicked on notification icon on main page after login"
        let image = 'user_clicked_notification_icon.png'
        functions.logUserActivity(success_action, image);
        // this.toggleNotification(true)
        functions.pushToRoute(this, 'notification', 'main');
    }

    goNext() {

        if (this.swiper) {
            this.swiper.slideNext()
            this.setState({ activeIndex: this.swiper.activeIndex })
        }
    }

    goPrev() {
        if (this.swiper) {
            this.swiper.slidePrev()
            this.setState({ activeIndex: this.swiper.activeIndex })
        }
    }

    handleShowPrize = () => {
        let success_action = "User clicked on show prize on main page after login"
        let image = 'user_clicked_show_prize_after_login.png'
        functions.logUserActivity(success_action, image);
        functions.pushToRoute(this, 'prize_detail', 'main')
    }

    handleShowDetail = (program, main_id) => {
        let self = this
        console.log("handleShowDetail", program)
        let success_action = "User clicked on program on main page after login"
        let image = 'user_clicked_program_main_page_after_login.png'
        functions.logUserActivity(success_action, image);
        switch (program.program_type) {
            case "freebie":
            case "contest":
            case "review":
            case "story":
            case "survey":
                self.setState({ activeTab: program.program_type })
                break;
            default:
                this.setState({ activeTab: 'perks' })
                break;
        }
        let click_id = document.getElementById(main_id)
        localStorage.setItem("detail_page", 'true')
        localStorage.setItem("program_div", main_id)
        if(document.getElementById(main_id)) {
            document.getElementById(main_id).click();
        }
        if (program.program_type == "freebie" || program.program_type == "contest" || program.program_type == "review" || program.program_type == "story") {
            // localStorage.setItem("detail_page", 'true')
            // document.getElementById(main_id).click();
            // functions.pushToRoute(self, "program_detail", "main")
        }
        else if (program.auto_id && program.auto_id != "") {

            console.log('haerere')
            // functions.pushToRoute(self, "perks_detail", "main")
            // document.getElementById(main_id).click();
        }
        else {
            // localStorage.setItem("detail_page", 'true')
            // document.getElementById(main_id).click();
        }


    }

    handleAnimation = () => {
        function random(min, max) {
            return Math.floor(Math.random() * (1 + max - min) + min);
        }
        let tlexplosion = new TimelineMax({ repeat: 0 }),
            container11 = $(".container11"),
            html = "",
            isMobile = !!("ontouchstart" in window),
            dotsCount = isMobile ? 50 : 100;

        let partical_list = ['partical1', 'partical2', 'partical3', 'partical4', 'partical5'];
        let color_list = ['#DD3649'];
        let rotate_list = ['rotate10', 'rotate20', 'rotate30', 'rotate40', 'rotate50', 'rotate60', 'rotate70', 'rotate80', 'rotate90'];
        let rotate_functions = ['rotateZ', 'rotateX', 'rotateY'];
        for (var i = 0; i < dotsCount; i++) {
            let partical_class = partical_list[Math.floor(Math.random() * partical_list.length)];
            let rotate_class = rotate_list[Math.floor(Math.random() * rotate_list.length)];
            html += "<div class = '" + partical_class + "'></div>";
        }
        var dots = $(html).appendTo(container11);

        let x_value = 0;
        let execute_time = 2;
        var tl = new TimelineMax()
        tl.fromTo(".layer1", 2, { x: -150 }, { x: x_value, ease: Power3.easeOut })
            .fromTo(".layer2", 2, { x: -150, left: 0 }, { x: x_value, left: 20, ease: Power2.easeOut }, 0)
            .fromTo(".layer3", 2, { x: -150, left: 0 }, { x: x_value, left: 40, ease: Power1.easeOut }, 0)
            .fromTo("#awardcup11", 2, { x: -150, rotation: 45, opacity: 0 }, { x: x_value, rotation: 0, opacity: 1, ease: Back.easeOut.config(1.7) }, 0)
            .to('.wrt_dot,.wrb_dot', 0.1, { display: 'block' }, 1.5)
            .fromTo(".wrt_dot", 0.5, { width: "15px" }, { width: "0px" }, 1.5)
            .fromTo(".wrb_dot", 0.5, { width: "10px" }, { width: "0px" }, 1.5)
            .to('.container11', 0.1, { display: 'block' }, 1.5);
        dots.each(function () {
            tl.fromTo(this, 1, {
                left: random(200, 210),
                top: random(130, 140),
                //z: random(-500, 500),
                backgroundColor: color_list[Math.floor(Math.random() * color_list.length)],
                opacity: Math.random()
            }, {
                left: "+=" + random(-50, 30) + "%",
                top: "+=" + random(-30, 30) + "%",
                //z: "+=" + random(-400, 300),
                backgroundColor: "#DD3649",
                yoyo: true,
                ease: Circ.easeInOut
            }, 1.6)
            // .to(this, 5, {
            //     left: "+=" + random(-20, 20) + "%",
            //     top: "+=" + random(0, 50) + "%",
            //     z: "+=" + random(-700, 700),
            //     repeat: -1,
            //     transform: "+= " + rotate_functions[Math.floor(Math.random() * rotate_functions.length)] + "(" + random(0, 360) + "deg)",
            //     "-webkit-transform": "+= " + rotate_functions[Math.floor(Math.random() * rotate_functions.length)] + "(" + random(0, 360) + "deg)"
            // }, 2.2)

            // .fromTo(this, 5, {
            //     transform: "rotateZ(10deg)",
            //     "-webkit-transform": "rotateZ(10deg)"
            // },
            //     {
            //         transform: "rotateZ(120deg)",
            //         "-webkit-transform": "rotateZ(120deg)",
            //         repeat: -1
            //     }, 2.2)
        });
    }

    handleUrgent = () => {
        this.setState({ show_urgent_notifications: false, urgent: false })
    }

    handleSwiperProgramClick = (program) => {
        let self = this
        let { program_id, program_type } = program;
        let action = "User clicked on arrow of " + program_id + " on slider on main index page"
        let image = 'user_clicked_slider_arror_of_program_' + program_id
        functions.logUserActivity(action, image);
        let main_id = program_type + program_id;
        self.handleShowDetail(program, main_id)
    }

    handleProgramDetailAnimation() {
        let self = this;
        $(document).on('click', '.program_slidess', function (e) {
            let classes = $(this).attr('class');
            let temp_classes = classes.split(" ")
            let program_array = self.state.all_program;
            var index = program_array.findIndex(p => p.program_id == temp_classes[2])
            if (index != -1) {
                let program = program_array[index];
                let program_id = program.program_id;
                let program_type = program.program_type;
                let action = "User clicked on arrow of " + program_id + " on slider on main index page"
                let image = 'user_clicked_slider_arror_of_program_' + program_id
                functions.logUserActivity(action, image);
                // let main_id = 'session_' + program_type + program_id;
                let main_id = program_type + program_id;
                self.handleShowDetail(program, main_id)
            }
        });

        $(document).on('click', '.extragain_tabs', function () {
            var navbar = document.getElementById("navbar");
            var tab_content_start = document.getElementById("tab_content_start");
            if (navbar && tab_content_start) {
                var sticky = navbar.offsetTop;
                var sticky2 = navbar.offsetHeight;
                let sticky_tabcontent = tab_content_start.offsetTop
                let scrolled = $(window).scrollTop() == 0 ? $(window).scrollTop() + 2 : $(window).scrollTop() + 2
                if ($(window).scrollTop() >= (sticky)) {
                    window.scrollTo({
                        top: sticky_tabcontent,
                        behavior: 'smooth'
                    })
                } else {
                    window.scrollTo({
                        top: $(window).scrollTop() == 0 ? sticky : sticky + scrolled,
                        behavior: 'smooth'
                    })
                    setTimeout(() => {
                        window.scrollTo({
                            top: sticky_tabcontent - 10,
                            behavior: 'smooth'
                        })
                    }, 700);
                }
            }
        })
        $(document).on('click', '.ar_middle', function () {
            if (self.swiper) {
                self.setState({ activeIndex: self.swiper.realIndex })
            }
        });
        $(document).on('click', '.al_middle', function () {
            if (self.swiper) {
                self.setState({ activeIndex: self.swiper.realIndex })
            }
        });
    }

    handleOpenAnimation = () => {
        // $(document).on('click', id, function () {
        // $('.survey_section').css('background-image', 'transparent');
        let section = document.getElementsByClassName("survey_section")
        if (section.length > 0) {
            section[0].setAttribute("background-image", "transparent");
            section[1].setAttribute("background-image", "transparent");
        }
    }

    handleProgramdetailClick = (program) => {

        let program_array = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : [];
        console.log('program_array', program_array)
        console.log('program_array program', program)
        this.props.handleprogramList(program_array)
        this.props.handleProgramDetail(program);
    }

    handleSlideDownEffect = () => {

        let self = this;
        document.addEventListener("scroll", function myFunction() {
            let session_entered_id = localStorage.getItem("session_entered_id") ? localStorage.getItem("session_entered_id") : "";

            let entered_program_id = localStorage.getItem("entered_id") ? localStorage.getItem("entered_id") : ""

            if (document.documentElement.scrollTop == 0) {

                if ((localStorage.getItem("detail_page") == 'false' || localStorage.getItem("detail_page") == false) && self.state.urgent) {
                    self.setState({ show_urgent_notifications: true });
                }
            }
        });
    }

    handleAddChild = () => {
        let success_action = "User clicked on add child on main page popup"
        let image = ''
        functions.logUserActivity(success_action, image);
        localStorage.setItem("form_type", 'add_child')
        localStorage.removeItem("family_activeTab")
        functions.pushToRoute(this, "addchild", 'main');
    }

    handleFixedHeader = () => {
        let self = this;
        document.addEventListener("scroll", function () {
            var navbar = document.getElementById("navbar");
            if (navbar) {
                var sticky = navbar.offsetTop;
                if ($(window).scrollTop() > (sticky - 39)) {
                    $('.fix_main').addClass('fixed-header');
                }
                else {
                    $('.fix_main').removeClass('fixed-header');
                }
            }
        });
        $(document).on('click', '.terms_active', function () {

            let program_list = self.state.all_program
            var index = program_list.findIndex(p => p.program_id == this.id)
            if (index != -1) {
                let program = program_list[index];
                if (this.id == program.program_id) {
                    self.handleTerms(program)
                }
            }

        })
    }

    handleUrgentProgramClick = () => {
        let success_action = "User clicked on urgent notification list on main page after login"
        let image = 'user_clicked_urgent_notification_after_login.png';
        functions.logUserActivity(success_action, image);
        localStorage.setItem('urgent_detail', true);
        let program_list = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : [];
        let entered_program_id = localStorage.getItem("entered_id") ? localStorage.getItem("entered_id") : ""

        var index = program_list.findIndex(p => p.program_id == entered_program_id)
        let temp_program = program_list[index]
        let main_id = temp_program.program_type + temp_program.program_id
        this.setState({ loader: false });
        this.handleShowDetail(temp_program, main_id)
    }

    handleProgramClick = () => {
        let success_action = "User clicked on urgent notification list on main page after login"
        let image = 'user_clicked_urgent_notification_after_login.png';
        functions.logUserActivity(success_action, image);
        localStorage.setItem('urgent_detail', true);
        let program_list = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : [];
        let entered_program_id = localStorage.getItem("entered_id") ? localStorage.getItem("entered_id") : ""

        var index = program_list.findIndex(p => p.program_id == entered_program_id)
        let temp_program = program_list[index]
        let main_id = temp_program.program_type + temp_program.program_id
        this.setState({ loader: false });
        this.handleShowDetail(temp_program, main_id)
    }
    handleMenu = () => {
        console.log("handleMenu called")
        localStorage.setItem("detail_page", 'false')
        localStorage.removeItem("session_entered_id")
        $('.program-page.active').find('.close_btn').trigger('click');
    }

    handleCloseTerms = () => {
        let success_action = "User clicked on close button on terms and condition"
        let image = 'user_clicked_on_close_button_terms_and_condition.png'
        functions.logUserActivity(success_action, image);
        let self = this;
        this.setState({ show_t_and_c: false })
    }

    handleTerms = (program) => {
        this.setState({ current_program: program, show_t_and_c: true });
    }

    toggle = () => {
        this.setState({ isOpenChangeEmailParent: !this.state.isOpenChangeEmailParent });
        localStorage.removeItem("show_popup_email")
    }

    handleEditChild = (child_index) => {
        let action = "User clicked on Edit Child button on main page"
        let image = ''
        functions.logUserActivity(action, image);
        localStorage.setItem("edit_index", child_index)
        localStorage.setItem("form_type", 'edit_child')
        functions.pushToRoute(this, "addchild", 'main');
        this.togglechild_name()
    }

    dateFormat = (value) => {
        let date_array = value.split("/");
        if (date_array.length > 1) {
            let year = date_array[0];
            let date = date_array[2];
            let month = date_array[1];
            let month_name = Month[month];
            let child_date = date + " " + month_name + " " + year;
            return child_date;
        }
        else {
            date_array = value.split("-");
            if (date_array.length > 1) {
                let year = date_array[0];
                let date = date_array[2];
                let month = date_array[1];
                let month_name = Month[month];
                let child_date = date + " " + month_name + " " + year;
                return child_date;
            }
        }
    }

    childInvitation = (timeSet) => {
        let self = this;
        let child_data = localStorage.getItem("child_data") ? JSON.parse(localStorage.getItem("child_data")) : []
        if (child_data && child_data.length) {
            let isOpen = false
            child_data.map(checkChild => {
                if (!checkChild.parent_id && (checkChild.relation_with_child !== 'parent' || !checkChild.relation_with_child)) {
                    isOpen = true
                }
            })
            setTimeout(() => {
                self.setState({ isOpen })
                if (this.invitaitonref) this.invitaitonref.calltoupdateIndex()
            }, timeSet);
        }
    }

    toggleParentInvitation = (open) => {
        let self = this;
        self.setState({ isOpen: open })
    }


    toggleNotification = (notify) => {
        let { isOpenNotification } = this.state;
        if (notify) {
            isOpenNotification = false
        }
        this.setState({ isOpenNotification: !isOpenNotification })
    }

    toggleVideoRecord = (states = '') => {
        this.setState(prevState => ({
            isVideoRecordOpen: states ? states : !prevState.isVideoRecordOpen
        }));
    }

    render() {
        const { dynamic_content } = this.state
        let { all_program } = this.props
        let temp_all_program = all_program && all_program.length ? all_program : []
        const params = {
            loop: true,
            activeSlideKey: 1,
            effect: 'coverflow',
            grabCursor: true,
            centeredSlides: true,
            slidesPerView: 2,
            coverflowEffect: {
                rotate: 40,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: false,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            rebuildOnUpdate: true,
            renderPrevButton: () => <button className="swiper-button-prev al_middle">Prev</button>,
            renderNextButton: () => <button className="swiper-button-next ar_middle">Next</button>
        }
        return (
            <div className="container" id="some-program">
                <div className="row">
                   <div className="common_mainAddOn">
                    <div className="common_main common_container_width common_main_rmove">
                        {this.state.main_loader &&
                            <>
                                <div className="discover_headLeft discover_headLeft2">
                                    {/* <img className="discover_image" src={url.cloudflare_image_path + "familyOneLogo_500px.png" + url.varient} alt="" title="" /> */}
                                    <img className="discover_image 2222" src={require('../../assets/images/familyOneNewLogo.svg')} alt="" title="" />
                                </div>
                                <center>
                                    {localStorage.getItem('detail_page') == 'true' ?
                                        <LottieLoader /> :
                                        <MyLoader />
                                    }
                                </center>
                            </>
                        }
                        <AlternativeEmailPopup isOpenChangeEmailParent={this.state.isOpenChangeEmailParent} toggle={this.toggle} />
                        <ParentInvitaionPopup ref={refrence => this.invitaitonref = refrence} history={this.props.history} isOpen={this.state.isOpen} toggle={(open) => this.toggleParentInvitation(open)} />
                        
                        {/* <NotificationPopup history={this.props.history} notify_number={this.state.notify_number} isOpenNotification={this.state.isOpenNotification} toggleNotification={this.toggleNotification} /> */}
                        {dynamic_content != "" && !this.state.main_loader &&
                            <div>
                                <div>
                                  <div className='addSliderBg'>
                                    {!this.state.show_urgent_notifications &&
                                        <div className="common_main_space1 addPurpalLineBgInr">
                                            {/* Head bar */}
                                            {this.state.notify_number > 0 &&
                                                <div className="notify_head discover_head">

                                                    {/* <div className="discover_headLeft"><label>{dynamic_content.static_text.main && dynamic_content.static_text.main.discover ? dynamic_content.static_text.main.discover.en : "Discover"}</label></div> */}
                                                    <div className="discover_headLeft">
                                                        {/* <img className="discover_image" src={url.cloudflare_image_path + "familyOneLogo_500px.png" + url.varient} alt="" title="" /> */}
                                                        <img className="discover_image 3333" src={require('../../assets/images/familyOneNewLogo.svg')} alt="" title="" />
                                                    </div>
                                                    <div className="notify_headRght">
                                                        <p onClick={() => { functions.pushToRoute(this, 'notification', 'main') }} className="notification_count notification_pop">
                                                            <span>{this.state.notify_number}</span>
                                                            <div className="circle delay1"></div>
                                                            <div className="circle delay2"></div>
                                                        </p>

                                                    </div>

                                                </div>
                                            }
                                            {this.state.notify_number < 1 &&

                                                <div className="discover_head">

                                                    {/* <div className="discover_headLeft"><label>{dynamic_content.static_text.main && dynamic_content.static_text.main.discover ? dynamic_content.static_text.main.discover.en : "Discover"}</label></div> */}
                                                    <div className="discover_headLeft">
                                                        {/* <img className="discover_image" src={url.cloudflare_image_path + "familyOneLogo_500px.png" + url.varient} alt="" title="" /> */}
                                                        <img className="discover_image 4444" src={require('../../assets/images/familyOneNewLogo.svg')} alt="" title="" />
                                                    </div>
                                                    <div className="discover_headRght">
                                                        {/* <img onClick={() => { functions.pushToRoute(this, 'notification', 'main') }} src={url.family_one_image_url + 'Group159.png'} alt="" title="" /> */}
                                                        <img onClick={() => { functions.pushToRoute(this, 'notification', 'main') }} src={require('../../assets/images/NotificationNew.svg')} alt="" title="" />
                                                    </div>
                                                </div>

                                            }
                                            {/* Head bar */}
                                        </div>
                                    }

                                    {this.state.show_urgent_notifications &&
                                        <div>
                                            <div className="urgent_main1" style={this.state.urgent ? { display: "block" } : { display: "none" }}>
                                                <Urgent setUrgent={this.handleUrgent} handleUrgentProgramClick={this.handleUrgentProgramClick} props={this.props} notify_number={this.state.notify_number} />

                                            </div>
                                            <div className="discover_head">

                                                {/* <div className="discover_headLeft"><label>{dynamic_content.static_text.main && dynamic_content.static_text.main.discover ? dynamic_content.static_text.main.discover.en : "Discover"}</label></div> */}
                                                <div className="discover_headLeft">
                                                    {/* <img className="discover_image" src={url.cloudflare_image_path + "familyOneLogo_500px.png" + url.varient} alt="" title="" /> */}
                                                    <img className="discover_image 5555" src={require('../../assets/images/familyOneNewLogo.svg')} alt="" title="" />
                                                </div>
                                                <div className="discover_headRght">
                                                </div>

                                            </div>
                                        </div>
                                    }

                                    {this.state.loader &&
                                        <center className="main_loader">
                                            <LottieLoader />
                                        </center>
                                    }
                                    {!this.state.loader && this.state.featured_program_array && this.state.featured_program_array.length > 0 &&
                                        <div>
                                            <div className="sharegain_swiper 1313">

                                                <Swiper {...params} ref={node => { if (node) this.swiper = node.swiper }}>
                                                    {this.state.featured_program_array && this.state.featured_program_array.length > 0 && this.state.featured_program_array.map((program, index) => (
                                                        <div key={"program" + index}>

                                                            <div key={index} className={"program_slidess slides " + program.program_id} style={{ position: "relative" }}>

                                                                <img style={{ paddingTop: "0px" }} src={(program.featured_image ? url.cloudflare_image_path + program.featured_image : url.cloudflare_image_path + program.program_image) + url.varient} alt="" title="" />

                                                                {program.program_type == "contest" &&
                                                                 <div className="nextslides"> 
                                                                   <div className="discover_icon_div discover_icon_divAdd">
                                                                     <img src={require('../../assets/images/bannerIcon.svg')} alt="" title="" />
                                                                    </div> 
                                                                 </div>
                                                                }
                                                                {program.program_type == "survey" &&
                                                                  <div className="nextslides"> 
                                                                    <div className="discover_icon_div discover_icon_divAdd">
                                                                      <img src={require('../../assets/images/Surveys-active-Icon.svg')} alt="" title="" />
                                                                     </div> 
                                                                  </div>
                                                                }
                                                                {program.program_type == "freebie" &&
                                                                  <div className="nextslides"> 
                                                                    <div className="discover_icon_div discover_icon_divAdd">
                                                                      <img src={require('../../assets/images/Freebies-active-Icon.svg')} alt="" title="" />
                                                                     </div> 
                                                                  </div>
                                                                }
                                                                {program.program_type == "story" &&
                                                                 <div className="nextslides"> 
                                                                  <div className="discover_icon_div discover_icon_divAdd">
                                                                    <img src={require('../../assets/images/Stories-active-Icon.svg')} alt="" title="" />
                                                                   </div> 
                                                                </div>
                                                                }
                                                                {program.program_type == "review" &&
                                                                    <div className="nextslides"> <div className="discover_icon_div" ><img src={url.family_one_image_url + 'white-icon-REVIEW_128.png'} alt="" title="" /> </div></div>
                                                                }
                                                            </div>

                                                            {/* {this.swiper && this.swiper.realIndex && this.swiper.realIndex == index && */}
                                                            <div className="gain_extra">
                                                                <h1>{ReactHtmlParser((program.feature_title && program.feature_title.trim() !== "") ? program.feature_title : program.program_title)} </h1>
                                                            </div>
                                                            {/* } */}
                                                        </div>
                                                    ))}
                                                </Swiper>
                                            </div>
                                            {/* ))} */}
                                            <img src={require('../../assets/images/outerDotSliderLogout.svg')} alt="" title="" className='outerDotSliderLogin' />
                                        </div>
                                    }
                                    </div>
                                    {!this.state.loader &&
                                        <div className="common_main_space cmnMainSpcAddon" id="tab_content_start">

                                            {/*Tabs content */}
                                            <div id="navbar" className="extragain_tabs fix_main">
                                                                                                
                                            <Nav tabs>
                                            {this.state.all_program && this.state.all_program.length > 0 &&
                                                <NavItem>
                                                    <NavLink
                                                        onClick={() => { this.toggleTab('all'); }}
                                                    >
                                                        <div className='cmnTabPrgrm allTabClr'>
                                                          {this.state.activeTab === 'all' &&
                                                           <img src={require('../../assets/images/all-active-Icon.svg')} alt="" title="" />
                                                          }
                                                          {this.state.activeTab != 'all' &&
                                                           <img src={require('../../assets/images/all-Icon.svg')} alt="" title="" />
                                                          }
                                                          <label>All</label>
                                                        </div>
                                                        <span className="nav_dot" ></span>
                                                    </NavLink>
                                                </NavItem>
                                            }
                                            {this.state.show_freebie &&
                                                <NavItem>
                                                    <NavLink
                                                        onClick={() => { this.toggleTab('freebie'); }}
                                                    >
                                                        <div className='cmnTabPrgrm freebiesTabClr'>
                                                          {this.state.activeTab === 'freebie' &&
                                                           <img src={require('../../assets/images/Freebies-active-Icon.svg')} alt="" title="" />
                                                          }
                                                          {this.state.activeTab != 'freebie' &&
                                                            <img src={require('../../assets/images/Freebies-Icon.svg')} alt="" title="" />
                                                          }
                                                          <label>Freebies</label>
                                                        </div>
                                                        <span className="nav_dot" ></span>
                                                    </NavLink>
                                                </NavItem>
                                            }

                                            {this.state.show_giveaway &&
                                                <NavItem>
                                                    <NavLink
                                                        onClick={() => { this.toggleTab('contest'); }}
                                                    >
                                                        <div className='cmnTabPrgrm contestTabClr'>
                                                          {this.state.activeTab === 'contest' &&
                                                           <img src={require('../../assets/images/Giveaways-active-Icon.svg')} alt="" title="" />
                                                          }
                                                          {this.state.activeTab != 'contest' &&
                                                           <img src={require('../../assets/images/Giveaways-Icon.svg')} alt="" title="" />
                                                          }
                                                          <label>Giveaways</label>
                                                        </div>
                                                        <span className="nav_dot" ></span>
                                                    </NavLink>
                                                </NavItem>
                                            }

                                            {this.state.show_survey &&
                                                <NavItem>
                                                    <NavLink
                                                        onClick={() => { this.toggleTab('survey'); }}
                                                    >
                                                        <div className='cmnTabPrgrm surveyTabClr'>
                                                          {this.state.activeTab === 'survey' &&
                                                           <img src={require('../../assets/images/Surveys-active-Icon.svg')} alt="" title="" />
                                                          }
                                                          {this.state.activeTab != 'survey' &&
                                                           <img src={require('../../assets/images/Surveys-Icon.svg')} alt="" title="" />
                                                          }
                                                          <label>Surveys</label>
                                                        </div>

                                                        <span className="nav_dot" ></span>
                                                    </NavLink>
                                                </NavItem>
                                            }


                                            {this.state.perk_array && this.state.perk_array.length > 0 &&
                                                <NavItem>
                                                    <NavLink
                                                        onClick={() => { this.toggleTab('perks'); }}
                                                    >
                                                        <div className='cmnTabPrgrm perksTabClr'>
                                                          {this.state.activeTab === 'perks' &&
                                                           <img src={require('../../assets/images/Perks-active-Icon.svg')} alt="" title="" />
                                                          }
                                                          {this.state.activeTab != 'perks' &&
                                                           <img src={require('../../assets/images/Perks-Icon.svg')} alt="" title="" />
                                                          }
                                                          <label>Perks</label>
                                                        </div>
                                                        <span className="nav_dot"></span>
                                                    </NavLink>
                                                </NavItem>
                                            }
                                            
                                            {this.state.show_review &&
                                                <NavItem>
                                                    <NavLink
                                                        onClick={() => { this.toggleTab('review'); }}
                                                    >
                                                        {dynamic_content ? dynamic_content.static_text.landing_page.reviews.en : "Reviews"}
                                                        <span className="nav_dot"></span>
                                                    </NavLink>
                                                </NavItem>
                                            }

                                            {this.state.show_story &&
                                                <NavItem>
                                                    <NavLink
                                                        onClick={() => { this.toggleTab('story'); }}
                                                    >
                                                        <div className='cmnTabPrgrm storyTabClr'>
                                                          {this.state.activeTab === 'story' &&
                                                           <img src={require('../../assets/images/Stories-active-Icon.svg')} alt="" title="" />
                                                          }
                                                          {this.state.activeTab != 'story' &&
                                                           <img src={require('../../assets/images/Stories-Icon.svg')} alt="" title="" />
                                                          }
                                                          <label>Stories</label>
                                                        </div>
                                                        <span className="nav_dot" ></span>
                                                    </NavLink>
                                                </NavItem>
                                            }
                                        </Nav>
                                            </div>

                                            <TabContent activeTab="1">
                                                {/* All Tab Content */}
                                                <TabPane tabId="1">

                                                    <div className="program-tile-container">
                                                        {temp_all_program && temp_all_program.length > 0 && temp_all_program.map((program, index) => (
                                                            program.program_id !== "9127" &&
                                                            <Programtile activeTab={this.state.activeTab} usedIn="innerpage" onClick={() => { this.handleOpenAnimation() }} handleProgramdetailClick={() => this.handleProgramdetailClick(program)} key={"main_program" + index} props={this.props} program={program} />
                                                        ))}
                                                    </div>
                                                    {/* <div className="program-page-container">
                                                        {temp_all_program && temp_all_program.length > 0 && temp_all_program.map((program, index) => (
                                                            <div key={program.program_id ? "all_main_detail_" + program.program_id : "all_main_detail_" + program.auto_id}>
                                                                {program.program_id && program.program_type !== 'story' ?
                                                                    <ProgramDetail ref={this.child} key={"all_main" + index} updateMainState={this.UpdateProgramOnEnter} updateProgram={(program) => this.updateProgram(program)} props={this.props} program={program} program_id={program.program_id} childInvitation={this.childInvitation}
                                                                        toggleVideoRecord={this.toggleVideoRecord}
                                                                    />
                                                                    :
                                                                    program.program_type === 'story' ?
                                                                        <StoryDetail key={"stories" + index} props={this.props} program={program} />
                                                                        :
                                                                        <PerksDetailAll usedIn='innerpage' key={"all_main_perk" + index} props={this.props} detail={program} auto_id={this.state.auto_id} active={true} updateIsUpgrade={this.handleUpdateUgrade} />

                                                                }
                                                            </div>
                                                        ))}
                                                    </div> */}

                                                </TabPane>
                                            </TabContent>
                                            {/* End of Tabs content */}
                                        </div>
                                    }

                                    {!this.state.isVideoRecordOpen &&
                                        <Footer onClick={() => { this.handleMenu() }} props={this.props} />
                                    }
                                    {this.state.show_t_and_c &&
                                        <TermsCondition Back={this.handleCloseTerms.bind(this)} program={this.state.current_program} />
                                    }
                                </div>

                                <Modal isOpen={this.state.modal_child_name} toggle={this.togglechild_name} className="custom_prizemodel">

                                    <ModalBody className="custom_unknwnchildBody">
                                        <div className="unknwnchild_content unknwnchild_contentNw" style={{ postion: "relative" }}>

                                            {/* <img src={url.family_one_image_url + 'Group168.png'} onClick={() => { this.togglechild_name("later") }} alt="" title="" className="clearcontest_img popup_close_button" /> */}
                                            <img src={require('../../assets/images/closeChild.svg')} onClick={() => { this.togglechild_name("later") }} alt="" title="" className="clearcontest_img popup_close_button" />
                                            
                                            <div className="unknwnchild_head">
                                                <h3>Name your children</h3>
                                            </div>
                                            <div className="fixed_editNm">
                                                {!this.state.loader && this.state.edit_name_child_data && this.state.edit_name_child_data.length > 0 && this.state.edit_name_child_data.map((child, child_index) => (
                                                    <div key={child.child_name + "_" + child_index} className="editNm">
                                                        <div className="editNmLft">
                                                            <div className="editNmLftOne">
                                                                <span>{initials(child.child_name.toUpperCase()).substring(0, 2)}</span>
                                                            </div>
                                                            <div className="editNmLftTwo">
                                                                <label>{child.child_name}</label>
                                                                <span>{this.dateFormat(child.child_birthdate)}</span>
                                                            </div>
                                                        </div>
                                                        <div className="editNmRght">
                                                            <Button onClick={() => { this.handleEditChild(child_index) }}>Edit</Button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="name_later"><Button onClick={() => { this.togglechild_name("later") }} size="medium" color="primary">{"Later"}</Button></div>
                                        </div>
                                    </ModalBody>
                                </Modal>
                                <Modal isOpen={this.state.modal_add_child} toggle={this.toggleaddchild} className="custom_prizemodel">
                                    <ModalBody className="custom_unknwnchildBody main_child_popup">
                                        <div className="unknwnchild_content unknwnchild_contentNw" style={{ postion: "relative" }}>

                                            <img src={require('../../assets/images/closeChild.svg')} onClick={() => { this.toggleaddchild("later") }} alt="" title="" className="clearcontest_img popup_close_button" />

                                            <div className="unknwnchild_head">
                                                <h3>Add More Children</h3>
                                                <p>Qualify for more offers <br />When you add all of your children</p>
                                            </div>
                                            <div className="fixed_editNm">
                                                {!this.state.loader && this.state.child_data && this.state.child_data.length > 0 && this.state.child_data.map((child, child_index) => (
                                                    <div key={child.child_name + "_" + child_index} className="editNm">
                                                        <div className="editNmLft">
                                                            <div className="editNmLftOne">
                                                                <span>{initials(child.child_name.toUpperCase()).substring(0, 2)}</span>
                                                            </div>
                                                            <div className="editNmLftTwo">
                                                                <label>{child.child_name}</label>
                                                                <span>{this.dateFormat(child.child_birthdate)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="name_later"><Button onClick={() => { this.handleAddChild() }} size="medium" color="primary">{"Add More"}</Button></div>
                                        </div>
                                    </ModalBody>
                                </Modal>
                                <Modal isOpen={this.state.modal_add_child_popup} toggle={this.toggleaddchildPopup} className="custom_prizemodel">
                                    <ModalBody className="custom_unknwnchildBody main_child_popup">
                                        <div className="unknwnchild_content unknwnchild_contentNw" style={{ postion: "relative" }}>
                                            <img src={require('../../assets/images/closeChild.svg')} onClick={() => { this.toggleaddchildPopup("later") }} alt="" title="" className="clearcontest_img popup_close_button" />
                                            <div className="unknwnchild_head">
                                                <h3>Add More Children</h3>
                                                <p>Qualify for more offers <br />When you add all of your children</p>
                                            </div>
                                            <div className="fixed_editNm 1414">
                                                {!this.state.loader && this.state.child_data && this.state.child_data.length > 0 && this.state.child_data.map((child, child_index) => (
                                                    
                                                    <div key={child.child_name + "_" + child_index} 
                                                    className={`editNm ${child.type === "Expectant" ? "ExpectantAddons" : child.type === "Existing" ? "ExistingAddons" : ""}`}
                                                    >
                                                        <div className="editNmLft">
                                                            <div className="editNmLftOne">
                                                                <span>{initials(child.child_name.toUpperCase()).substring(0, 2)}</span>
                                                            </div>
                                                            <div className="editNmLftTwo">
                                                                <label>{child.child_name}</label>
                                                                <span>{this.dateFormat(child.child_birthdate)}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                ))}
                                            </div>
                                            <div className="name_later"><Button onClick={() => { this.handleAddChild() }} size="medium" color="primary">{"Add More"}</Button></div>
                                        </div>
                                    </ModalBody>
                                </Modal>
                                <Modal isOpen={this.state.modal_prize} onOpened={() => { this.handleAnimation() }} className="custom_prizemodel">
                                    <ModalBody className="custom_prizeBody">
                                        <div className="open_prize" >
                                            <div className="awardcup_main open_winner_img">
                                                <div className="layers">
                                                    <div className="layer1"></div>
                                                    <div className="layer2"></div>

                                                    <div className="layer3">
                                                        <img src={url.family_one_image_url + 'Groupaward214.png'} alt="" title="" className="awardcup" id="awardcup11" />
                                                        <span className="wrt_dot"></span>
                                                        <span className="wrb_dot"></span>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="open_prize_content">
                                                <h4>{dynamic_content.static_text.main && dynamic_content.static_text.main.you_are_winner ? dynamic_content.static_text.main.you_are_winner.en : "You are the Winner!"}</h4>
                                                <p>{dynamic_content.static_text.main && dynamic_content.static_text.main.tap_to_continue ? dynamic_content.static_text.main.tap_to_continue.en : "Tap to continue to show contest info and claim your prize."}</p>
                                            </div>
                                            <Button size="medium" color="primary" className="closeModel" onClick={() => { this.handleShowPrize() }}>
                                                {dynamic_content.static_text.main && dynamic_content.static_text.main.show ? dynamic_content.static_text.main.show.en : "Show"}
                                            </Button>
                                            <div className="prizelater"><label onClick={this.openprize} >{dynamic_content.static_text.main && dynamic_content.static_text.main.later ? dynamic_content.static_text.main.later.en : "Later"}</label></div>
                                            <div className="container11"></div>
                                        </div>
                                    </ModalBody>
                                </Modal>
                            </div>
                        }
                    </div>
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = ({ program, child_invitation }) => {

    const { program_detail, program_list, all_program } = program;
    const { child_invitation_switch } = child_invitation
    return { program_detail, program_list, all_program, child_invitation_switch }
}

const mapDispatchToProps = dispatch => {
    return {
        handleProgramDetail: (program_detail) => dispatch({ type: 'ADD_PROGRAM_DETAIL', payload: program_detail }),
        handleprogramList: (program) => dispatch({ type: 'ADD_PROGRAM_LIST', payload: program }),
        handleAllprogramList: (all_program) => dispatch({ type: 'ADD_ALL_PROGRAM_LIST', payload: all_program }),
        handleChildInvitationSwitch: (child_invitation_switch) => dispatch({ type: 'SWITCH_CHILD_INVITATION', payload: child_invitation_switch })
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(Sharegain);


