import React from 'react';
import '../../assets/css/style.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MaskedInput from 'react-text-mask';
import url from '../../assets/url';
import axios from 'axios';
import functions from '../../assets/functions/common_functions';
import session from '../../assets/functions/session';
import LottieLoader from '../LottieLoader';
import cookie from 'react-cookies';

export default class Phone extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phone_number: '',
            loader: false,
            dynamic_content: ""
        }
        this.verification = this.verification.bind(this);
    }
    verification() {
        this.props.history.push('/verification');
    }

    handleContinue = () => {
        let self = this;

        let action = "User landed on phone page"
        let image = 'phone_page_landing.png'
        functions.logUserActivity(action, image);

        localStorage.setItem("phone_number", this.state.phone_number);
        let phone_number = this.formatPhoneNumber(this.state.phone_number);
        if (phone_number == null || phone_number === "" || phone_number.length < 13) {
            let user_action = "User got error on phone/index file handleContinue function";
            let body = url.dynamic_content.error_message.invalid_phone.en
            functions.logUserError(user_action, body);
            this.setState({ show_error_message: url.dynamic_content.error_message.invalid_phone.en })
        } else {
            this.setState({ loader: true })
            let finalJson = {
                "phone_number": phone_number,
                "action": "forget_email",
                "country_code": url.phone_code,
                "mode": "sms"
            }
            axios
                .request({
                    method: "POST",
                    url: url.forget_email,
                    data: JSON.stringify(finalJson),
                    headers: {
                        "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : functions.getAuthKey()
                    }
                })
                .then(function (response) {
                    if (response.data.failed && response.data.failed == 1) {
                        functions.handleErrorPopup()
                    }
                    if (response.data.result === "user_not_exist") {
                        let user_action = "User got error on phone/index file handleContinue function";
                        let body = url.dynamic_content.error_message.user_not_exist_with_phone.en
                        functions.logUserError(user_action, body);
                        self.setState({ loader: false, show_error_message: url.dynamic_content.error_message.user_not_exist_with_phone.en })
                    } else if (response.data.result === "successfull") {
                        let success_action = "User got success response on forget_email function in phone page"
                        functions.logUserActivity(success_action , null);
                        self.setState({ loader: false })
                        functions.pushToRoute(self, "verification", 'phone')

                    }
                })
                .catch(function (error) {
                    let user_action = "User got error on phone/index file handleContinue function";
                    let body = error.message
                    functions.logUserError(user_action, body);
                });
        }
    }

    formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            return '(' + match[1] + ')' + match[2] + '-' + match[3]
        }
        return null
    }

    componentWillMount = () => {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
        }
    }

    componentDidMount = () => {
        let action = "User landed on phone page"
        let image = 'phone_page_landing.png'
        functions.logUserActivity(action, image);
        let session_id = cookie.load("session_id") ? cookie.load("session_id") : "null"
        if (session_id != "null") {
            session.updatePageSession(session_id, "phone")
        }
    }

    handleBack = () => {
        let action = "User clicked on back button on phone page"
        let image = 'back_button_phone_page.png'
        functions.logUserActivity(action, image);
        this.props.history.push("/login")
    }

    render() {
        const { dynamic_content } = this.state
        return (
            <div className="container">
                {dynamic_content != "" &&
                    <div className="row">
                         <div className="common_mainAddOn">
                          <div className='usrPhoneSetBg'>  
                        <div className="common_main">
                            <div className="sign_backlink sign_backlinkNw" onClick={() => { this.handleBack() }}>
                                <img src={require('../../assets/images/bkArorIcon.svg')} alt="" title="" />
                            </div>
                            {/* <div className="clearlink"><img src={require('../../assets/images/Group5.png'} alt="" title="" /></div> */}
                            <div className="sign_up">
                                <h1>{dynamic_content.static_text.phone ? dynamic_content.static_text.phone.restore_email.en : "Restore Email"}</h1>
                                <h2>{dynamic_content.static_text.phone ? dynamic_content.static_text.phone.enter_mobile.en : "Please enter your mobile number to login"}</h2>
                            </div>
                            {this.state.loader &&
                                <center>
                                    <LottieLoader className="loader_class" />
                                </center>
                            }
                            {!this.state.loader &&
                                <div className="verification_process1Cstm">
                                    <div className="verification_process1">
                                        <TextField
                                            autoComplete='off'
                                            type="text"
                                            value={url.phone_code}
                                            className="maskBefore"
                                        />
                                        <MaskedInput
                                            mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                            className=""
                                            placeholder="555-5342-8400"
                                            value={this.state.phone_number}
                                            onChange={(e) => { this.setState({ phone_number: e.target.value, show_error_message: "" }) }}
                                            onKeyPress={event => {
                                                if (event.key === "Enter") {
                                                    this.handleContinue()
                                                }
                                            }}
                                            autoFocus={true}
                                        //guide={false}
                                        //id="my-input-id"
                                        //onBlur={() => {}}
                                        //onChange={() => {}}
                                        />
                                    </div>

                                    {this.state.show_error_message && this.state.show_error_message != "" &&
                                        <p className="error_message">
                                            {this.state.show_error_message}
                                        </p>
                                    }
                                    <div className="receive_msg">
                                        <p>{dynamic_content.static_text.phone ? dynamic_content.static_text.phone.you_will_receive.en : "You’ll receive a text message with a verification code"}</p>
                                        <div className="phone_opt">
                                            <Button size="medium" color="primary" onClick={() => { this.handleContinue() }}>
                                                {dynamic_content.static_text.phone ? dynamic_content.static_text.phone.next.en : "Next"}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            }
                            {/* <div className="or_contact">
                                <p>{dynamic_content.static_text.phone ? dynamic_content.static_text.phone.conctact_us.en : "or contact us"}</p>
                                <Button size="medium" color="primary">
                                    {dynamic_content.static_text.phone ? dynamic_content.static_text.phone.email.en : "Email"}
                                </Button>
                                <Button size="medium" color="primary">
                                    {dynamic_content.static_text.phone ? dynamic_content.static_text.phone.chat.en : "Chat"}
                                </Button>
                            </div> */}
                        </div>
                        </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}