import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import url from '../../assets/url';
import functions from '../../assets/functions/common_functions';
import session from '../../assets/functions/session';
import cookie from 'react-cookies';
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from "react-html-parser";

export default class Sent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: localStorage.getItem("email") ? localStorage.getItem("email") : "",
            dynamic_content: "",
            // migrated: true
            migrated: localStorage.getItem("migrated") && localStorage.getItem("migrated") == "true" ? true : false,
        };
        this.toggleAddchild = this.toggleAddchild.bind(this);
        this.openAccounts = this.openAccounts.bind(this);
    }
    toggleAddchild() {
        this.setState({
            modal: !this.state.modal
        });
    }
    openAccounts() {
        this.setState({
            modal_account: !this.state.modal_account
        });
    }

    componentDidMount = () => {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
        }
        dynamic_content.static_text.sent.email_message.en = dynamic_content.static_text.sent.email_message.en.replace("%EMAIL%", this.state.email)
        let action = "User landed on sent page";
        let image = 'sent_page_landing.png'
        functions.logUserActivity(action, image);
        let session_id = cookie.load("session_id") ? cookie.load("session_id") : "null"
        if (session_id != "null") {
            session.updatePageSession(session_id, "sent")
        }
    }

    render() {
        const { dynamic_content } = this.state
        return (
            <div className="container">
                {this.state.dynamic_content != "" &&
                    <div className="row">
                      <div className="common_mainAddOn">
                        <div className="common_main common_mainIner commonMainInerBg">
                            <div className="sign_backlink sign_backlinkNw"
                                onClick={() => {
                                    let action = "User clicked on back button on sent page"
                                    let image = 'back_button_sent_page.png'
                                    functions.logUserActivity(action, image);
                                    this.props.history.push("/login")
                                }} >
                                <img src={require('../../assets/images/bkArorIcon.svg')} alt="" title="" />
                            </div>

                            <div className="log_up">
                                <h1>{dynamic_content.static_text.sent.log_in.en}</h1>
                            </div>
                            <div className="sentLinkIcon">
                                <img src={require('../../assets/images/senMail.svg')} alt="" title="" />
                            </div>
                            {!this.state.migrated &&
                                <div className="sent_content 11">
                                    <h1>{dynamic_content.static_text.sent.sent.en}</h1>
                                    <p>
                                        {ReactHtmlParser(dynamic_content.static_text.sent.email_message.en)}
                                    </p>
                                </div>
                            }
                            {this.state.migrated &&
                                <div className="sent_content 22">
                                    <h1>Migration Complete!</h1>
                                    <p>
                                        We sent a login link by email to <b>{this.state.email}</b>.
                                        {/* We sent a login link by email to <b>24july1@webnexus.in</b>. */}
                                    </p>
                                    <p>Please check your inbox.</p>
                                </div>
                            }
                        </div>

                        {/* Add another child model */}
                        <div className="open_mail_model">
                            <Modal isOpen={this.state.modal} toggleAddchild={this.toggleAddchild} className={this.props.className + " custom_Addchildmodel"}>
                                {/* <ModalHeader toggleAddchild={this.toggleAddchild} close={closeAddchild}></ModalHeader> */}
                                <ModalBody className="custom_AddchildBody">
                                    <div className="Addchild_content">
                                        <Button size="medium" color="primary" onClick={this.openAccounts} >
                                            {dynamic_content.static_text.sent.open_mail_app.en}
                                        </Button>
                                        <Button size="medium" color="primary" className="closeModel" onClick={this.toggleAddchild} >
                                            {dynamic_content.static_text.sent.cancel.en}
                                        </Button>
                                    </div>
                                </ModalBody>
                            </Modal>
                        </div>
                        {/* Add another child model */}


                        {/* open accounts model */}
                        <div className="open_mail_model">
                            <Modal isOpen={this.state.modal_account} openAccounts={this.openAccounts} className={this.props.className + " custom_Addchildmodel"}>
                                {/* <ModalHeader toggleAddchild={this.toggleAddchild} close={closeAddchild}></ModalHeader> */}
                                <ModalBody className="custom_AddchildBody">
                                    <div className="open_account">
                                        <div className="open_account_img"><img src={url.family_one_image_url + 'Group216.png'} alt="" title="" /></div>
                                        <div className="open_account_content">
                                            <h4>{dynamic_content.static_text.sent.accounts.en}</h4>
                                            <p>{dynamic_content.static_text.sent.facebook_and_familyone_account_connented.en} </p>
                                        </div>
                                        <Button size="medium" color="primary" className="closeModel" onClick={this.openAccounts} >
                                            {dynamic_content.static_text.sent.ok.en}
                                        </Button>
                                    </div>

                                </ModalBody>
                            </Modal>
                        </div>
                        {/* End of open accounts model*/}
                        {/* Add Animation Here */}
                        <div className="signupConectCircleInr">
                            <div className="signupConectInr"></div>
                        </div>
                        {/* End of Animation Here */}
                    </div>
                  </div>
                }
            </div>
        );
    }
}