import React from 'react';
import '../../assets/css/style.css';
import { browserName, isMobile, CustomView, isAndroid, isIOS, OsTypes } from 'react-device-detect';
import VideoRecorderCommon from './VideoRecorderCommon';
import VideoRecorderIOS from './VideoRecorderIOS';
import { Modal, ModalBody } from 'reactstrap';
import url from '../../assets/url';
import { Grid } from '@material-ui/core';
import cookie from 'react-cookies';
import functions from '../../assets/functions/common_functions';
import LottieLoader from '../LottieLoader';

class VideoRecorderPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deviceInfo: {},
            browserInfo: {},
            sendMail: true,
            loaderShow: true
        };
    }
    componentDidMount = () => {
        let browser_info = {};
        let browser = this.get_browser_info();
        browser_info.Browser_CodeName = window.navigator.appCodeName == "" ? " " : window.navigator.appCodeName;
        browser_info.Browser_Name = browser.name == "" ? " " : browser.name;
        browser_info.Browser_Version = browser.version == "" ? " " : browser.version;
        browser_info.Cookies_Enabled = window.navigator.cookieEnabled == "" ? " " : window.navigator.cookieEnabled;
        browser_info.Platform = window.navigator.platform == "" ? " " : window.navigator.platform;
        browser_info.User_agent_header = window.navigator.userAgent == "" ? " " : window.navigator.userAgent;
        browser_info.Vendor = window.navigator.vendor == "" ? " " : window.navigator.vendor

        this.setState({ browserInfo: browser });

        console.log("browser", browser);
        console.log("react device detect is", browserName, isMobile, isAndroid, isIOS, OsTypes);
        console.log('navigator', window.navigator)
        console.log('navigator', window.navigator.platform)
        this.setState({ loaderShow: false })
        if (browserName == 'Safari' && window.navigator.platform == 'MacIntel') {
            this.props.history.push("/link-send-email", { program_id: this.props.program.program_id})
            
        }

        if (isIOS) {
            let recordButton = document.getElementsByClassName("button__Button-bmihlc-0 dexpoW")
        }

    }

    openModalSendMail = () => {
        let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        auto_id = auto_id ? auto_id : null
        let email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email")
        email = email ? email : null;
        let self = this
        functions.sendMailForMacUser(this, auto_id, email, this.props.program.program_id, (sendData) => {
            let response = JSON.parse(sendData).data
            console.log("response", response)
            if (response) {
                self.setState({ loaderShow: false })
                self.props.history.push("/link-send-email", { login_link: response.login_link })

            }
        })
        // this.props.Back('sendMail');
    }



    get_browser_info = () => {
        var ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return { name: 'IE ', version: (tem[1] || '') };
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\bOPR\/(\d+)/)
            if (tem != null) { return { name: 'Opera', version: tem[1] }; }
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
        return {
            name: M[0],
            version: M[1]
        };
    }

    render() {
        const { sendMail, loaderShow } = this.state;
        return (
            <div>
                {!loaderShow && <div className="videoSection">
                    {isIOS ?
                        <VideoRecorderIOS history={this.props.history} program={this.props.program} onSubmit={this.props.onSubmit}
                            toggleVideoRecord={this.props.toggleVideoRecord} Back={this.props.Back} />
                        :
                        <VideoRecorderCommon history={this.props.history} program={this.props.program} onSubmit={this.props.onSubmit}
                            toggleVideoRecord={this.props.toggleVideoRecord} Back={this.props.Back} />
                    }
                </div>}
                {/* <Modal isOpen={sendMail} toggle={this.openModalSendMail} className="congDetailUpr">
                    <ModalBody className="congDetail">
                        <a className="discardClose" onClick={() => this.openModalSendMail()}>
                            <img src={url.family_one_image_url + 'Group5.png'} style={{ height: 10 }} alt="" title="" /></a>
                        <div className="congDetailTxt">
                            <label></label>
                            
                            <p>We send link .</p>
                        </div>
                    </ModalBody>
                </Modal> */}
                {loaderShow && <div className="row rel_block fade_it_up">
                    <div className="program_entry_loader">
                        <LottieLoader className="loading" />
                    </div>
                </div>}
            </div>
        );
    }
}

export default VideoRecorderPage;