import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import url from '../../assets/url';
import functions from '../../assets/functions/common_functions';
import session from '../../assets/functions/session';
import cookie from 'react-cookies';
// import { isMobile } from 'react-device-detect';
import { browserName, isMobile, CustomView, isAndroid, isIOS } from 'react-device-detect';
// import { Offline, Online } from "react-detect-offline";
// import OfflineComponent from '../boarding/index';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import LottieLoader from '../LottieLoader';
import $ from "jquery";
import axios from 'axios';
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from "react-html-parser";

var base64 = require('base-64');


class Home extends React.Component {

    constructor(props) {
        let search = window.location.search;
        super(props);
        this.scrollToTopRef = React.createRef();
        
        this.state = {
            loader: false,
            dynamic_content: "",
            migrate_note: false,
            search: search && search.substring(0, 6) == "?code=" ? true : false,
            isUIReady: false
        }
        console.log("search", search.substring(0, 6))
    }

    responseFailFacebook = (error) => {
        alert(error)
        let self = this;
        self.setState({ loader: false })
        let action = "User got error response from Facebook on home page"
        let image = 'facebook_error_response.png';
        functions.logUserActivity(action, image);
        functions.logUserErrors(error)
    }

    handleClickLoginSingup = (value) => {
        let action = "User clicked on " + value + " on home page"
        let image = 'user_clicked_on_' + value + '.png'
        functions.logUserActivity(action, image);

        if (value == "signup") {
            let element = document.getElementById("signup_button")
            console.log("element", element)
            element.click();
        } else if (value == "login") {
            let element = document.getElementById("login_button")
            console.log("element", element)
            element.click();
        }
        $('html, body').css('overflow', 'initial');
        functions.pushToRoute(this, value, 'home');
    }

    componentWillMount = () => {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        let irclickid = cookie.load("irclickid") ? cookie.load("irclickid") : ""
        if (irclickid) {
            cookie.save("provider", "impact", { path: "/" });
        }
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
        }
        else {
            // functions.pushToRoute(this, '', 'home');
        }
    }

    scrollToTop = () => {
        if (this.scrollToTopRef.current) {
            this.scrollToTopRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.state.isUIReady && !prevState.isUIReady) || (this.state.dynamic_content !== "" && prevState.dynamic_content === "")) {
            // Perform scroll to top action
            this.scrollToTop();
        }
    }

    componentDidMount = () => {
        let self = this;
        let action = "User landed on home page"
        let image = 'homepage_landing.png';
        functions.logUserActivity(action, image);
        let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        auto_id = auto_id ? auto_id : ""
        console.log("auto_id", auto_id)
        let back_on = localStorage.getItem("back_on") && localStorage.getItem("back_on") == "create_password" ? "create_password" : ""
        if (auto_id && auto_id != "" && back_on == "") {
            functions.getLandingPage(this);
        } else {
            localStorage.removeItem("back_on")
        }
        let session_id = cookie.load("session_id") ? cookie.load("session_id") : "null"
        console.log("session_id", session_id)
        if (session_id != "null") {
            session.updatePageSession(session_id, "home")
        }
        let element = document.getElementById("home_view")
        console.log("element", element)
        element.click();
        this.setState({ isUIReady: true });

    }

    checkUserExistence = (email, provider) => {
        let self = this;
        let finaljson = {
            email: email
        }
        functions.CheckUserExistenceInOldNewSytsem(this, finaljson, function (json_response) {
            let response = JSON.parse(json_response)
            console.log("check_user_existence response", response)
            if (response.data.user_exist_in_old_system == 1) {
                console.log("call migrate function")
                self.migrateUser(email, provider)
            } else if (response.data.success == 0) {
                functions.pushToRoute(self, 'signup', 'home')
            } else if (response.data.success == 1 && response.data.message == "User Already Exist") {
                functions.getUserData(self, email, provider);
            }
        })
    }

    migrateUser = (email, provider) => {
        let self = this
        let migration_uri = 'https://request.family.one/request/cron_makeSync.php';
        let dataset = {
            "email": email,
            sync: 0
        }
        self.setState({
            migrate_note: true
        })
        axios
            .request({
                url: migration_uri,
                data: JSON.stringify(dataset),
                method: "post"
            })
            .then(function (response) {
                console.log("getUserData response", response);
                functions.getUserData(self, email, provider);
            });
    }

    responseFacebook = (response) => {
        // functions.sendEmail(JSON.stringify(response))
        // alert(response)
        let self = this;
        self.setState({ loader: true })
        if (response.email) {
            let action = "User got success response from Facebook on home page"
            let image = 'facebook_success_response.png';
            functions.logUserActivity(action, image);

            let api_auth = localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : "";
            let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
            let current_state = localStorage.getItem("current_state") ? localStorage.getItem("current_state") : "";
            let session_entered_id = localStorage.getItem("session_entered_id") ? localStorage.getItem("session_entered_id") : "";
            let perks_or_main = localStorage.getItem("perks_or_main") ? localStorage.getItem("perks_or_main") : "";
            let program = localStorage.getItem("program") ? JSON.parse(localStorage.getItem("program")) : "";
            let program_list = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : "";
            let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
            let user_signup_process = localStorage.getItem("user_signup_process") ? localStorage.getItem("user_signup_process") : "";
            localStorage.clear();
            localStorage.setItem("user_signup_process", user_signup_process)
            localStorage.setItem("session_entered_id", session_entered_id)
            localStorage.setItem("perks_or_main", perks_or_main)
            localStorage.setItem("previous_state", previous_state)
            localStorage.setItem("current_state", current_state)
            localStorage.setItem("program", JSON.stringify(program))
            localStorage.setItem("program_list", JSON.stringify(program_list))
            localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))
            localStorage.setItem("api_auth", api_auth)
            let email = response.email;
            cookie.save("provider", "facebook", { path: "/" });
            cookie.save("facebook_id", response.id, { path: "/" });
            localStorage.setItem("given_name", response.name.split(" ")[0]);
            localStorage.setItem("picture", response.picture.data.url)
            localStorage.setItem("family_name", response.name.split(" ")[1])
            localStorage.setItem("email", response.email)


            self.checkUserExistence(email, "facebook")
        }
        else {
            self.setState({ loader: false })
            let action = "User got error response from Facebook on home page"
            let image = 'facebook_error_response.png';
            functions.logUserActivity(action, image);
        }
    }

   

    responseGoogle = (response) => {
        console.log("response", response)
        let self = this;
        let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
        let current_state = localStorage.getItem("current_state") ? localStorage.getItem("current_state") : "";
        let session_entered_id = localStorage.getItem("session_entered_id") ? localStorage.getItem("session_entered_id") : "";
        let perks_or_main = localStorage.getItem("perks_or_main") ? localStorage.getItem("perks_or_main") : "";
        let program = localStorage.getItem("program") ? JSON.parse(localStorage.getItem("program")) : "";
        let program_list = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : "";
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        let api_auth = localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : "";
        let user_signup_process = localStorage.getItem("user_signup_process") ? localStorage.getItem("user_signup_process") : "";
        localStorage.clear();
        localStorage.setItem("user_signup_process", user_signup_process)
        localStorage.setItem("perks_or_main", perks_or_main)
        localStorage.setItem("session_entered_id", session_entered_id)
        localStorage.setItem("previous_state", previous_state)
        localStorage.setItem("current_state", current_state)
        localStorage.setItem("program", JSON.stringify(program))
        localStorage.setItem("api_auth", api_auth)
        localStorage.setItem("program_list", JSON.stringify(program_list))
        localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))


        if (response.profileObj) {
            console.log("response.profileObj", response.profileObj)
            let action = "User got success response from Google on home page"
            // functions.logUserActivity(action);
            let email = response.profileObj.email;
            cookie.save("provider", "google", { path: "/" });
            cookie.save("google_id", response.profileObj.googleId, { path: "/" });
            localStorage.setItem("given_name", response.profileObj.givenName)
            localStorage.setItem("picture", response.profileObj.imageUrl)
            localStorage.setItem("family_name", response.profileObj.familyName)
            localStorage.setItem("email", response.profileObj.email)
            self.setState({ loader: true })
            self.checkUserExistence(email, "google")
        }
        else {
            let action = "User got error response from Google on home page"
            // functions.logUserActivity(action);
        }
    }

    handleClick = (value) => {
        let action = "User clicked on " + value + " on home page"
        let image = 'user_clicked_on_' + value + '.png'
        functions.logUserActivity(action, image);
        if (value == "facebook") {
            let element = document.getElementById("facebook_button")
            console.log("element", element)
            element.click();
        }
    }

    render() {
        const { dynamic_content, show_ftm_home } = this.state
        return (

            <div ref={this.scrollToTopRef} className="container">
                {/* <Online> */}
                <div id="home_view"></div>
                <div id="facebook_button"></div>
                <div id="login_button"></div>
                <div id="signup_button"></div>
                <div className="row">
                <div className="common_mainAddOn">
                    <div className="common_main common_mainCstm">
                        {this.state.loader &&
                            <div className="loader_div">
                                <center>
                                    <LottieLoader />
                                </center>
                                {this.state.migrate_note &&
                                    <div style={{ marginTop: "20px" }}>
                                        <p className="migrate_note">Welcome to the new family.one</p>
                                        <p className="migrate_note">It may take up to 10 seconds to migrate your profile.</p>
                                        <p className="migrate_note">We'll email you a login link when migration is complete.</p>
                                    </div>
                                }
                            </div>
                        }
                        {!this.state.loader &&
                            <div className='loginSocialArea'>

                                 <div className="welcmStup">
                                    <div className="welcmStupBg">
                                      <img src={require('../../assets/images/purpalLine.svg')} alt="" title="" className='signUpPurpalBg' />
                                        <div className="logo_upr">
                                            <div className="welLogin">
                                                <label>
                                                    {/* {dynamic_content ? dynamic_content.static_text.home.welcome_to.en : "welcome to"} */}
                                                    Welcome to
                                                </label>
                                            </div>
                                            <div><img src={require('../../assets/images/familyOneNewLogo.svg')} alt="" title="" /></div>
                                        </div>
                                        <div className="membrsProv">
                                            <h2>The Largest Online Network <br />for Families in Canada!</h2>
                                            <p>Dedicated to providing families with freebies. giveaways, special offers, packages, and more..</p>
                                        </div>
                                        <div className="signupHere">
                                            <img src={require('../../assets/images/ARROWBOX.svg')} alt="" title="" />
                                        </div>
                                    </div>
                                    <img src={require('../../assets/images/yellowDot2.svg')} alt="" title="" className='outerDotBg' />
                                </div>

                                <div className="signupConectCircle">
                                    <div className="signupConect">
                                        <div className={browserName == "Mobile Safari" ? "signupConectIner safari-signupConectIner" : "signupConectIner"}>
                                            <div className="loginFB">
                                                {browserName == "Mobile Safari"&& isIOS ?
                                                    <FacebookLogin
                                                        cssClass="facebook"
                                                        appId="1099732394769901"
                                                        isMobile={isMobile ? true : false}
                                                        autoLoad={browserName !== "Facebook" ? false : true}
                                                        onClick={() => { this.handleClick('facebook') }}
                                                        redirectUri={url.family_one_url}
                                                        onFailure={this.responseFailFacebook}
                                                        disableMobileRedirect={true}
                                                        fields="name,email,picture"
                                                        callback={this.responseFacebook}
                                                        icon="fa-facebook"
                                                        textButton={dynamic_content ? dynamic_content.static_text.home.login_with_facebook.en : "Login with Facebook"}
                                                    /> :
                                                    <FacebookLogin
                                                        cssClass="facebook"
                                                        appId="1099732394769901"
                                                        isMobile={isMobile ? true : false}
                                                        autoLoad={false}
                                                        onClick={() => { this.handleClick('facebook') }}
                                                        redirectUri={url.family_one_url}
                                                        fields="name,email,picture"
                                                        callback={this.responseFacebook}
                                                        icon="fa-facebook"
                                                        textButton={dynamic_content ? dynamic_content.static_text.home.login_with_facebook.en : "Login with Facebook"}
                                                    />
                                                }
                                            </div>

                                            
                                            <div className="signup_opt">
                                                <Button size="medium" color="primary" onClick={() => { this.handleClickLoginSingup('signup') }}>
                                                    {dynamic_content ? dynamic_content.static_text.home.sign_up.en : "Sign Up"}
                                                </Button>
                                            </div>
                                            <div className="login_opt">
                                                <Button size="medium" color="primary" onClick={() => { this.handleClickLoginSingup('login') }}>
                                                    {dynamic_content ? dynamic_content.static_text.home.log_in.en : "Log In"}
                                                </Button>
                                            </div>


                                            <div className="social_icon">
                                                <p>{dynamic_content ? dynamic_content.static_text.home.enter_via.en : "or enter using"}</p>
                                                <div>
                                                    <div className="socialLoginBtns">
                                                        {/* <a href="#"><img src={url.family_one_image_url + 'Group166.png'} alt="" title="" className="twiterLgin" /></a> */}

                                                        <div className="loginGogle">
                                                            <a onClick={() => { this.handleClick('google') }} className="custom_googleMain">
                                                                <GoogleLogin
                                                                    clientId="376413996313-invib4050j4vdd0picfh71shq40hdvep.apps.googleusercontent.com"
                                                                    // clientId="630950640067-2joesrmd9s9gdvibnslieu9p5831gqlj.apps.googleusercontent.com"
                                                                    buttonText={dynamic_content ? dynamic_content.static_text.home.login_with_google.en : "Login with Google"}
                                                                    className="google"
                                                                    icon={false}
                                                                    onSuccess={this.responseGoogle}
                                                                    onFailure={this.responseGoogle}
                                                                    cookiePolicy={'single_host_origin'}
                                                                />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        </div>
                    </div>
                </div>
                {/* </Online>
                <Offline>
                    <OfflineComponent />
                </Offline> */}
            </div>
        );
    }
}
export default Home;

