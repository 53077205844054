import React, { Fragment, useState } from 'react';
import '../../assets/css/style.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import functions from '../../assets/functions/common_functions';
import MaskedInput from 'react-text-mask';
import url from '../../assets/url';
import cookie from 'react-cookies';
import LottieLoader from '../LottieLoader';
import { TimelineMax, Bounce, Linear, Power1, Elastic, Back, Sine } from "gsap/all";
import DateOfBirth from './components/dateofbirth'
import $ from "jquery";


export default class Entercontestdob extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            active_state: 0,
            dynamic_content: "",
            survey_answers: {},
            button_percent: 0,
            disable_enter_button: true,
            answer_completed: false,
            policyme_link: "",
            status: 0,
            user_eligible: "true",
            submit_text: "Next",
            progress_percent: 0
        }
        this.myRef = React.createRef();
    }

    capitalize = (value) => {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    formatBirthdate = (date) => {
        let new_date = date.split("-")
        console.log("new_date", new_date)
        let [year, month, day] = new_date
        return day + "" + month + "" + year
    }

    getPolicyLink = (smoking_value, birthdate_value) => {
        const { birthdate, parent_smoking_status } = this.state;
        let gender = localStorage.getItem("gender") && localStorage.getItem("gender") == "male" ? "M" : "F"
        let given_name = localStorage.getItem("given_name") ? localStorage.getItem("given_name") : ""
        let family_name = localStorage.getItem("family_name") ? localStorage.getItem("family_name") : ""
        let email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email")
        email = email ? email : ""
        let smoking = smoking_value == "yes" ? "True" : "False"
        let url = "https://www.policyme.com/life/familyone-quotes?email_ref_v13=1&birthdate=" + this.formatBirthdate(birthdate_value) + "&gender=" + gender + "&smoker=" + smoking + "&first_name=" + given_name + "&last_name=" + family_name + "&email=" + email + "&utm_source=familyone"
        console.log("url", url)
        this.setState({ policyme_link: url })
        return url
    }

    componentWillMount = () => {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content, show_error_message: "" })
        }
        console.log("this", this)
    }

    componentDidMount = () => {
        let program = this.props.props.program_detail
        let success_action = "User landed on survey questions on program detail page " + program.program_id
        let image = ''
        functions.logUserActivity(success_action, image);
        this.handleLoaderAnimation()
    }

    handleSubmit = () => {
        let self = this
        let program = this.props.props.program_detail
        this.handleAnimation(program.partner_id);
        let email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email")
        email = email ? email : ""
        let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        auto_id = auto_id ? auto_id : ""
        let { survey_answers, user_eligible } = this.state
        let final_json = {
            "action": "create_survey_entry",
            "email": email,
            "partner_id": program.partner_id,
            "sub_id": auto_id,
            "program_id": program.program_id
        }
        let merged = { ...final_json, ...survey_answers };
        let birthdate = survey_answers["what_is_your_date_of_birth?"] ? survey_answers["what_is_your_date_of_birth?"] : ""
        let phone_number = survey_answers["what_is_your_date_of_birth?"] ? survey_answers["what_is_your_date_of_birth?"] : ""
        if (birthdate != "") {
            let userdata = [{ Name: 'birthdate', Value: birthdate }];
            let dynamo_userdata = { 'birthdate': birthdate };
            localStorage.setItem("birthdate", birthdate);
            functions.updateUserData(self, userdata, dynamo_userdata);
        }
        console.log("merged", merged)
        functions.LogInSurveyEntry(this, merged, function (json_response) {
            let response = JSON.parse(json_response)
            self.removeAnimationEffect()
            self.props.onSubmit(user_eligible, survey_answers)
        })

    }

    handleAnimation = (partner_id) => {
        let TL = new TimelineMax();
        if(partner_id=='15'){
            TL.fromTo('.phone_verification_block', 0.5, { opacity: 1 }, { opacity: 0, height: '200px' })
                .to('.program_entry_loader', 0.5, { opacity: 1, top : "50%", position : "absolute" })
        }else{
            TL.fromTo('.phone_verification_block', 0.5, { opacity: 1 }, { opacity: 0, height: '200px' })
                .to('.program_entry_loader', 0.5, { opacity: 1 })
        }
    }

    removeAnimationEffect = () => {
        let TL = new TimelineMax();
        TL.fromTo('.phone_verification_block', 0.5, { opacity: 0 }, { opacity: 1, height: 'auto' })
            .to('.program_entry_loader', 0.5, { opacity: 0 })
    }

    handleBack = (element, index, questions) => {
        let program = this.props.props.program_detail
        let success_action = "User clicked on BACK button on survey questions on program detail page " + program.program_id
        let image = ''
        functions.logUserActivity(success_action, image);
        let self = this
        let question_length = questions.length
        let { active_state, survey_answers, answer_completed } = this.state
        console.log("active_state", active_state)
        console.log("index", index)
        let question_name = element.questionText.split(/[ ,]+/).join('_').toLowerCase()

        let button_percent = answer_completed ? 100 / (question_length) * (active_state) : 100 / (question_length) * (active_state - 1)
        console.log("button_percent", button_percent)

        if (index == 0) {
            this.props.Back()
        } else {
            console.log("this.state", this.state)
            this.modifyProgressVal(-1, index)
            setTimeout(() => {
                self.setState({ show_error_message: "", active_state: index - 1, submit_text: "Next" })
            }, 1000);
        }
    }

    handleDone = (element, index, questions) => {
        let { active_state, survey_answers, submit_text } = this.state
        let program = this.props.props.program_detail
        console.log("active_state", active_state)
        console.log("index", index)
        if (submit_text != "Next") {
            let success_action = "User clicked on Done button on survey questions on program detail page " + program.program_id
            let image = ''
            functions.logUserActivity(success_action, image);
            console.log("complete done")
            this.handleSubmit()
        } else {
            let success_action = "User clicked on Next button on survey questions on program detail page " + program.program_id
            let image = ''
            functions.logUserActivity(success_action, image);
            let question_name = element.questionText.split(/[ ,]+/).join('_').toLowerCase()
            let value = survey_answers[question_name]
            if (value && value.trim() != "") {
                this.handleAnswer(element, value, index, questions, "")
            } else {
                this.setState({ show_error_message: "Answer Required" })
                console.log("field required")
            }
        }
    }
    
    handleAnswer = (element, value, index, questions, eligible_answer) => {
        let self = this
        let { survey_answers } = this.state
        let new_date = new Date()
        console.log("eligible_answer", eligible_answer)

        let program = this.props.props.program_detail
        let question_name = element.questionText.split(/[ ,]+/).join('_').toLowerCase()
        survey_answers[question_name] = value
        survey_answers[question_name + "_timestamp"] = new_date.getTime()
        console.log("survey_answers", survey_answers)
        this.setState({ survey_answers, show_error_message: "" })
        let question_length = questions.length
        let new_button_percent = 100 / (questions.length) * (index + 1)
        // console.log("new_button_percent", new_button_percent)
        this.modifyProgressVal(1, index)
        let success_action = "User answered on " + index + " question on survey questions on program detail page " + program.program_id
        let image = ''
        // let final_user_qualify = self.checkUserQualify(survey_answers, questions)


        functions.logUserActivity(success_action, image);
        setTimeout(() => {
            if (index < question_length - 1) {
                self.setState({ active_state: index + 1 })
            } else {
                let set_disable_enter_button = "false"
                self.checkUserQualify(survey_answers, questions, function (response_json) {
                    console.log("final_user_qualify",)
                    let user_eligible = response_json
                    let smoking_value = survey_answers["do_you_smoke?"]
                    let birthdate_value = survey_answers["what_is_your_date_of_birth?"]
                    if (program.program_id == "1544" || program.program_id == "6854") {
                        set_disable_enter_button = "true"
                        self.getPolicyLink(smoking_value, birthdate_value)
                    }
                    console.log("complete")
                    console.log("user_eligible", user_eligible)
                    if (user_eligible == "false") {
                        self.setState({ answer_completed: true, submit_text: "Done" })
                    } else {
                        if (set_disable_enter_button == "true") {
                            self.setState({ disable_enter_button: false, submit_text: "Done & View" })
                        }
                        self.setState({ answer_completed: true, submit_text: "Done" })
                    }
                })
            }
        }, 1000);

    }

    checkUserQualify = (survey_answers, questions, callback) => {
        let self = this
        console.log("survey_answers", survey_answers)
        let user_qualify = "true"
        questions.forEach((element, index) => {
            if (element.question_option_type != "date") {
                let question_name = element.questionText.split(/[ ,]+/).join('_').toLowerCase()
                console.log("element", element)
                if (element.question_option_type == "button") {
                    let user_answer = survey_answers[question_name] ? survey_answers[question_name] : ""
                    console.log("user_answer", user_answer)
                    let index = element.answer_data.buttonData.findIndex(x => x.text.split(/[ ,]+/).join('_').toLowerCase() === user_answer)
                    console.log("index", index)
                    if (index > -1) {
                        console.log("element.answer_data.buttonData[index].eligible_to_qualify", element.answer_data.buttonData[index].eligible_to_qualify)
                        if (element.answer_data.buttonData[index].eligible_to_qualify == "false") {
                            user_qualify = "false"
                        }
                    }
                }
                if (element.question_option_type == "dropdown") {
                    let user_answer = survey_answers[question_name] ? survey_answers[question_name] : ""
                    console.log("user_answer", user_answer)
                    let index = element.answer_data && element.answer_data.dropDownData && element.answer_data.dropDownData.findIndex(x => x.text.split(/[ ,]+/).join('_').toLowerCase() === user_answer)
                    console.log("index", index)
                    if (index > -1) {
                        console.log("element.answer_data.buttonData[index].eligible_to_qualify", element.answer_data.dropDownData[index].eligible_to_qualify)
                        if (element.answer_data.dropDownData[index].eligible_to_qualify == "false") {
                            user_qualify = "false"
                        }
                    }

                }
            }
            if (index == questions.length - 1) {
                console.log("user_qualify", user_qualify)
                self.setState({ user_eligible: user_qualify })
                callback(user_qualify)
            }
        });
    }

    handleDateSubmit = (value) => {
        console.log("value", value)
        let { active_state } = this.state
        let { program_detail } = this.props.props
        console.log("program", program_detail.survey_questions)
        console.log("active_state", active_state)
        // console.log("active_state",active_state)
        let element = program_detail.survey_questions[active_state]
        let eligible_answer = element.answer_data.eligible_to_qualify
        this.handleAnswer(element, value, active_state, program_detail.survey_questions, eligible_answer)
    }

    handleDropDownSubmit = (e) => {
        console.log("e", e.target.value)
        console.log("e name", e.target.name)
        if (e.target.value != "") {
            let { active_state } = this.state
            let { program_detail } = this.props.props
            console.log("program_detail", program_detail.survey_questions)
            console.log("active_state", active_state)
            let element = program_detail.survey_questions[active_state]
            console.log("element", element)
            let eligible_answer = "false"
            let value = (e.target.value).split(/[ ,]+/).join('_').toLowerCase()
            console.log("value", value)
            let dropDownData = element && element.answer_data && element.answer_data.dropDownData
            dropDownData && dropDownData.length > 0 && dropDownData.forEach((element, index) => {
                let answer_text = (element.text).split(/[ ,]+/).join('_').toLowerCase()
                if (value == answer_text && element.eligible_to_qualify == "true") {
                    eligible_answer = "true"
                }
            });
            this.setState({ [e.target.name]: value, show_error_message: "" })
            this.handleAnswer(element, value, active_state, program_detail.survey_questions, eligible_answer)
        }
    }

    handleTextField = (e) => {
        let name = e.target.name
        this.setState({ [name]: e.target.value, show_error_message: "" })
        let { active_state } = this.state
        let { program_detail } = this.props.props
        console.log("program", program_detail.survey_questions)
        console.log("active_state", active_state)
        let element = program_detail.survey_questions[active_state]
        let value = (e.target.value).split(/[ ,]+/).join('_').toLowerCase()
        let eligible_answer = "false"
        this.handleAnswer(element, value, active_state, program_detail.survey_questions, eligible_answer)
    }

    handleLoaderAnimation = () => {
        let self = this
        var dataval = parseInt($('.progress_test').attr("data-amount"));
        if (dataval < 100) {
            $('.progress_test .amount').css("width", 100 - dataval + "%");
        }
    }

    modifyProgressVal = (type, active_state) => {
        console.log("type clicked", type)
        let { answer_completed } = this.state
        let { program_detail } = this.props.props
        let question_length = program_detail.survey_questions?.length ?? null;
        
        var dataval = parseInt($('.progress_test').attr("data-amount"));
        
        console.log("dataval prev", dataval)
        console.log("question_length prev", question_length)
        console.log("active_state prev", active_state + 1)
        
        if (type == 1) dataval = 100 / (question_length) * (active_state + 1)
        else if (type == -1) dataval = answer_completed ? 100 / (question_length) * (active_state) : 100 / (question_length) * (active_state - 1);
        console.log("dataval new", dataval)
        this.setState({ progress_percent: dataval });
        console.log("progress_percent", this.state.progress_percent)
        
        let new_dataval = 100 - dataval
        console.log("pink_dataval", new_dataval)
        $('.progress_test .amount').css("width", new_dataval + "%");
        $('.progress_test').attr("data-amount", dataval); 
    }

    changeText() {
        this.myRef.current.style.width = '250px';
    }

    render() {
        const { dynamic_content, active_state, button_percent, survey_answers, policyme_link, answer_completed, submit_text } = this.state
        const { program } = this.props
        const btnClass = this.state.status ? 'primary' : 'secondary';
        const { progress_percent } = this.state;
        return (
            <div className="">
                {dynamic_content != "" &&
                    <div className="row">

                        <div className="phone_verification_block" style={{ position: "relative" }}>

                            {/* <div className="survey_up completeEntry">
                                <a><img src={url.family_one_image_url + 'survey-icon-pink.png'} alt="" title="" /></a>
                                <h1>{dynamic_content.static_text.enterpolicyadvisorconsent ? dynamic_content.static_text.enterpolicyadvisorconsent.enter_contest.en : "Complete Your Entry"}</h1>
                            </div> */}

                            <div>
                                {program.survey_questions?.length > 0 && program.survey_questions.map((element, index) => (
                                    <div style={index == active_state ? {} : { display: "none" }}>
                                      <div className='survayInrForm'>

                                        <div className='progressSurvey'>
                                            <div><img onClick={() => { this.handleBack(element, index, program.survey_questions) }} src={require('../../assets/images/bkArorIcon.svg')}  alt="" title="" /></div>
                                            
                                            {/* <ul>
                                              {Array.from({ length: program.survey_questions?.length }, (_, i) => (
                                                <li key={i}>
                                                <img
                                                    src={
                                                    i * 25 <= progress_percent
                                                        ? require('../../assets/images/progress1.svg')
                                                        : require('../../assets/images/progress1Disable.svg')
                                                    }
                                                    alt=""
                                                    title=""
                                                    style={{
                                                    opacity: i === program.survey_questions?.length - 1 ? 1 : (i + 1) / program.survey_questions?.length,
                                                    transition: 'opacity 0.5s ease', // Smooth transition for opacity change
                                                    }}
                                                /> 
                                                </li>
                                              ))}
                                            </ul> */}

                                            <ul>
                                             {Array.from({ length: program.survey_questions?.length }, (_, i) => (
                                                <li key={i}>
                                                <img
                                                    src={
                                                    i * 25 < progress_percent
                                                        ? require('../../assets/images/progress1.svg')
                                                        : require('../../assets/images/progress1Disable.svg')
                                                    }
                                                      alt=""
                                                      title=""
                                                      style={{
                                                      opacity: i * 25 < progress_percent ? ((i + 1) / program.survey_questions?.length) : 1,
                                                      transition: 'opacity 0.5s ease', // Smooth transition for opacity change
                                                    }}
                                                />
                                                </li>
                                               ))}
                                            </ul>
                                        </div>

                                        <div className="survey_up setposition">
                                            <h1>{element.questionText}</h1>
                                        </div>

                                        {element.question_option_type == "button" &&
                                            <div className="survey_sec">
                                                {element.answer_data.buttonData && element.answer_data.buttonData.map((data, data_index) => (
                                                    <Button className={survey_answers[element.questionText.split(/[ ,]+/).join('_').toLowerCase()] == data.text.toLowerCase() ? "survey_active_button" : ""} style={data_index != 0 && data_index % 2 == 0 ? { marginTop: "10px" } : {}} onClick={() => { this.handleAnswer(element, data.text.toLowerCase(), index, program.survey_questions, data.eligible_to_qualify) }} size="medium" color="primary">{this.capitalize(data.text)}</Button>
                                                ))}
                                            </div>
                                        }
                                        {element.question_option_type == "date" &&
                                            <div>
                                                <DateOfBirth className="entrDobUsr entrDobUsrSurvy" dateofbirth_label="Enter Date of Birth:" onDOBSubmit={this.handleDateSubmit} minimum_date={element.answer_data.minDate} maximum_date={element.answer_data.maxDate} />
                                            </div>
                                        }
                                        {element.question_option_type == "dropdown" &&
                                            <div className="survey_dropdown">
                                                <select onChange={(e) => { this.handleDropDownSubmit(e) }} name={element.questionText.split(/[ ,]+/).join('_').toLowerCase()} value={this.state[element.questionText.split(/[ ,]+/).join('_').toLowerCase()]}>
                                                    <option value="">Select</option>
                                                    {element.answer_data && element.answer_data.dropDownData && element.answer_data.dropDownData.map((data, data_index) => (
                                                        <option key={data.text.split(/[ ,]+/).join('_').toLowerCase()} value={data.text.split(/[ ,]+/).join('_').toLowerCase()}>{data.text}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        }
                                        {element.question_option_type == "textfield" &&
                                            <div className="survey_form survey_formCstm">
                                                {element.personal_data_map == "phone_number" ?
                                                    <MaskedInput
                                                        mask={['(', /[1-9]/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                        className=""
                                                        type="tel"
                                                        // placeholder="55-5342-8400"
                                                        name={element.questionText.split(/[ ,]+/).join('_').toLowerCase()}
                                                        value={survey_answers[element.questionText.split(/[ ,]+/).join('_').toLowerCase()] ? survey_answers[element.questionText.split(/[ ,]+/).join('_').toLowerCase()] : ""}
                                                        onChange={(event) => { this.handleTextField(event); }}
                                                    />
                                                    :
                                                    <TextField
                                                        autoComplete='off'
                                                        autoFocus={true}
                                                        hiddenlabel={element.answer_data.textfieldLabel}
                                                        type="text"
                                                        margin="normal"
                                                        className="survey_textfield"
                                                        name={element.questionText.split(/[ ,]+/).join('_').toLowerCase()}
                                                        value={survey_answers[element.questionText.split(/[ ,]+/).join('_').toLowerCase()] ? survey_answers[element.questionText.split(/[ ,]+/).join('_').toLowerCase()] : ""}
                                                        onChange={event => {
                                                            this.handleTextField(event);
                                                        }}
                                                    />
                                                }

                                            </div>
                                        }
                                        {this.state.show_error_message && this.state.show_error_message != "" &&
                                            <p className="error_message">
                                                {this.state.show_error_message}
                                            </p>
                                        }

                                        </div>
                                        <div className="contest_msg">
                                            <div className="contest_opt_policy_advisor">
                                                {submit_text == "Done & View" ?
                                                    <div>
                                                        {submit_text == "Done" ?
                                                            <div onClick={() => { this.handleDone(element, index, program.survey_questions) }} className={submit_text == "Next" ? "progress_test" : "progress_test done"} data-amount={button_percent}>
                                                                {/* <span>{submit_text}</span> */}
                                                                <span>Next</span>
                                                                <div className="amount"></div>
                                                            </div>
                                                            :
                                                            <a onClick={() => { this.handleDone(element, index, program.survey_questions) }} className={this.state.disable_enter_button && this.state.user_eligible == "false" ? "disable_link" : ""} href={policyme_link} target="_blank">
                                                                <div className={submit_text == "Next" ? "progress_test" : "progress_test done"} data-amount={button_percent}>
                                                                    {/* <span>{submit_text}</span> */}
                                                                    <span>Next</span>
                                                                    <div className="amount"></div>
                                                                </div>
                                                            </a>}
                                                    </div> :
                                                    <div onClick={() => { this.handleDone(element, index, program.survey_questions) }} className={submit_text == "Next" ? "progress_test" : "progress_test done"} data-amount={button_percent}>
                                                        {/* <span>{submit_text}</span> */}
                                                        <span>Next</span>
                                                        <div className="amount"></div>
                                                    </div>
                                                }
                                                <button style={{ display: "none" }} id="increase"></button>
                                                <button style={{ display: "none" }} id="decrease"></button>
                                            </div>
                                        </div>

                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className= "program_entry_loader">
                            <LottieLoader />
                        </div>
                    </div>
                }
            </div>
        );
    }
}