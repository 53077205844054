export const initFacebookSdk = () => {
    return new Promise((resolve, reject) => {
      console.log("login here", window)
      // Load the Facebook SDK asynchronously
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: '1099732394769901',
          cookie: true,
          xfbml: true,
          version: 'v19.0'
        });
        // Resolve the promise when the SDK is loaded
        resolve("APP");
      };
    })
}

export const getFacebookLoginStatus = () => {
  return new Promise((resolve, reject) => {
    if (window && window.FB) {
      window.FB.getLoginStatus((response) => {
        console.log("response", response);
        resolve(response);
      });
    }
    else {
      resolve(null)
    }
  });
};


export const fbLogin = () => {
  return new Promise((resolve, reject) => {
    window.FB.login((response) => {
      resolve(response)
    })
  })
}