import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import url from '../../assets/url';
import axios from 'axios';
import functions from '../../assets/functions/common_functions';
import LottieLoader from '../LottieLoader';

export default class Enteremail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: localStorage.getItem("email") ? localStorage.getItem("email") : "",
            show_error_message: "",
            loader: false,
            dynamic_content: ""
        };
    }

    componentWillMount = () => {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
        }
    }

    handleContinue = () => {
        let success_action = "User clicked on continue button on restore password page"
        let image = 'continue_button_restore_password_page.png'
        functions.logUserActivity(success_action, image);
        if (this.state.email === "") {
            let user_action = "User got error on phone/restorepassword file handleContinue function";
            let body = url.dynamic_content.error_message.enter_email.en
            functions.logUserError(user_action, body);
            this.setState({ show_error_message: url.dynamic_content.error_message.enter_email.en });
        }
        if (this.state.email !== "") {
            if (
                !this.state.email.match(/^([a-zA-Z0-9_.-]+@([\w-]+\.)+[\w-]{2,10})?$/)
            ) {
                let user_action = "User got error on phone/restorepassword file handleContinue function";
                let body = url.dynamic_content.error_message.invalid_email.en
                functions.logUserError(user_action, body);
                this.setState({ show_error_message: url.dynamic_content.error_message.invalid_email.en });
            } else if (this.state.email.length > 0) {
                this.handleSend();
            }
        }
    }

    handleSend = () => {
        let success_action = "User clicked on send button on restore password page"
        let image = 'send_button_restore_password_page.png'
        functions.logUserActivity(success_action, image);
        let self = this;
        this.setState({ loader: true })
        let finalJson = {
            "action": "forget_password",
            "email": this.state.email
        }
        axios
            .request({
                method: "POST",
                url: url.forget_email,
                data: JSON.stringify(finalJson),
                headers: {
                    "Authorization": localStorage.getItem("api_auth")
                }
            })
            .then(function (response) {
                self.setState({ loader: false })
                if(response.data.failed && response.data.failed == 1){
                    functions.handleErrorPopup()
                  }
                if (response.data.success == 1) {
                    let success_action = "User got success on forget_password function  on restore password page"
                    let image = 'success_reponse_for_forget_password_restore_password_page.png'
                    functions.logUserActivity(success_action, image);
                    functions.pushToRoute(self, 'restorepasswordsent', 'restorepassword');
                }
                if (response.data.result == "failed") {
                    let user_action = "User got error on phone/restorepassword file handleSend function";
                    let body = response.data.message
                    functions.logUserError(user_action, body);
                    self.setState({ show_error_message: response.data.message })
                }
            })
            .catch(function (error) {
                let user_action = "User got error on phone/restorepassword file handleSend function";
                let body = error.message
                functions.logUserError(user_action, body);
            });
    }

    render() {
        const { dynamic_content } = this.state
        return (
            <div className="container">
                {dynamic_content != "" &&
                    <div className="row">
                       <div className="common_mainAddOn">  
                        <div className="common_main">
                            <div className="sharegain_backlink profileBkImg">
                                <img onClick={() => { this.props.history.push("/enterpassword") }} src={require('../../assets/images/bkArorIcon.svg')} alt="" title="" />
                            </div>
                            {/* <div className="clearlink"><img src={url.family_one_image_url + 'Group5.png'} alt="" title="" /></div> */}
                            <div className="sign_up">
                                <h1>{dynamic_content.static_text.restorepassword ? dynamic_content.static_text.restorepassword.restore_password.en : "Restore Password"}</h1>
                                <h2>{dynamic_content.static_text.restorepassword ? dynamic_content.static_text.restorepassword.enter_email.en : "Please enter your email to restore"}</h2>
                            </div>
                            {this.state.loader &&
                                <center>
                                    <LottieLoader className="loader_class" />
                                </center>
                            }
                            {!this.state.loader &&
                                <div className="restorepass_form">
                                    {/* <TextField
                                    autoComplete='off'
                                    label="Enter your email"
                                    type="email"
                                    margin="normal"
                                /> */}
                                    <div className="clear_input">
                                        <Input
                                            value={this.state.email}
                                            onChange={(e) => { this.setState({ email: e.target.value, show_error_message: "" }) }}
                                            placeholder="Enter your email"
                                            inputProps={{
                                                'aria-label': 'Description',
                                            }}
                                            onKeyPress={event => {
                                                if (event.key === "Enter") {
                                                    this.handleContinue()
                                                }
                                            }}
                                        />
                                        <img onClick={() => { this.setState({ email: "" }) }} src={url.family_one_image_url + 'Group168.png'} className="clear_img" alt="" title="" />

                                    </div>
                                    {this.state.show_error_message && this.state.show_error_message != "" &&
                                        <p className="error_message">
                                            {this.state.show_error_message}
                                        </p>
                                    }
                                    <div className="restorepass_submit">
                                        <Button size="medium" color="primary" onClick={() => { this.handleContinue() }}>
                                            {dynamic_content.static_text.restorepassword ? dynamic_content.static_text.restorepassword.continue.en : "Continue"}
                                        </Button>
                                    </div>
                                </div>
                            }
                            {/* <div className="restorepass_email">
                            <p>Forgot Email?</p>
                        </div> */}

                        </div>
                       </div> 
                    </div>
                }
            </div>
        );
    }
}