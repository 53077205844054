import React from 'react';
import '../../assets/css/style.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import functions from '../../assets/functions/common_functions';
import MaskedInput from 'react-text-mask';
import cookie from 'react-cookies';
import url from '../../assets/url';

export default class Extrasupport extends React.Component {
    constructor(props) {
        super(props);
        // functions.handleBackOne(this.props, 'support')
        this.extrahelp = this.extrahelp.bind(this);
        this.handlePreviousPage = this.handlePreviousPage.bind(this);
        this.state = {
            email: localStorage.getItem("support_email") ? localStorage.getItem("support_email") : localStorage.getItem("email"),
            message: "",
            phone_number: localStorage.getItem("support_number") ? localStorage.getItem("support_number") : localStorage.getItem("phone_number"),
            u_name: localStorage.getItem("u_name") ? localStorage.getItem("u_name") : localStorage.getItem("given_name"),
            show_error_message: "",
            response_message: "",
            response_status: "",
            dynamic_content: ""
        }
    }

    componentDidMount = () => {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
        }

    }

    extrahelp() {
        let action = "User clicked on send button on support page"
        let image = 'send_button_support_page.png'
        functions.logUserActivity(action, image);
        let self = this;
        if (this.state.show_error_message == "") {
            let cookies = JSON.stringify(cookie.loadAll());


            let local_storage = this.allLocalStorage();
            let phone_number = this.state.phone_number;
            local_storage.forEach((element, index) => {
                console.log("element", element)
                for (var key in element) {
                    console.log("key", key)
                    if (key == "dynamic_content" || key == "program_list" || key == "program") {
                        console.log("delete", local_storage[index])
                        local_storage.splice(index, 1);
                    }
                }
            });

            console.log("local_storage", local_storage)
            let final_local_storage = JSON.stringify(local_storage, null, 2);

            let email = this.state.email
            let admin_email = 'support@family.one'

            let object_to_replace = {
                '%USER_MESSAGE%': this.state.message,
                '%PHONE_NUMBER%': phone_number,
                '%COOKIES%': cookies,
                '%LOCAL_STORAGE%': final_local_storage
            }
            let subject = this.state.dynamic_content.email_template && this.state.dynamic_content.email_template.need_support_subject ? this.mutliStringReplace(object_to_replace, this.state.dynamic_content.email_template.need_support_subject.en) : 'Need Support';
            let text_message = this.state.message
            let html_message = this.state.dynamic_content.email_template && this.state.dynamic_content.email_template.need_support_message ? this.mutliStringReplace(object_to_replace, this.state.dynamic_content.email_template.need_support_message.en) : `<div>` + this.state.message + `</div>
                                <h3>Phone Number</h3>
                                <p>`+ phone_number + `</p>
                                <h3>User Email</h3>
                                <p>`+ email + `</p>
                                <br/><br/><br/><br/>
                                <h3>Cookies Data</h3>
                                <div>`+ cookies + `</div>
                                <h3>Local Stroage Data</h3>
                                <div>`+ final_local_storage + `</div>`,
                category = 'Support Mail';
            functions.sendSMTPMail(this, admin_email, subject, text_message, html_message, category, function (response_json) {
                response_json = JSON.parse(response_json);
                if (response_json.data) {
                    if (response_json.data.success == 1) {
                        let success_action = "User success while sending support email to admin"
                        let image = 'support_email_success.png'
                        functions.logUserActivity(success_action, image);
                        self.setState(
                            {
                                message: "",
                                response_status: "success",
                                response_message: response_json.data.message
                            }
                        )
                    } else {
                        let success_action = "User failed while sending support email to admin"
                        let image = 'support_email_failed.png'
                        functions.logUserActivity(success_action, image);
                        self.setState(
                            {
                                response_message: response_json.data.message,
                                response_status: "failed"
                            }
                        )
                    }
                }
            });
        }
    }

    mutliStringReplace = (object, string) => {
        var val = string
        var entries = Object.entries(object);
        entries.forEach((para) => {
            var find = para[0]
            var regExp = new RegExp(find, 'g')
            val = val.replace(regExp, para[1])
        })
        return val;
    }


    allLocalStorage() {
        var values = [],
            keys = Object.keys(localStorage),
            i = keys.length;

        while (i--) {
            let keyname = keys[i];
            values.push({ [keyname]: localStorage.getItem(keys[i]) });
        }
        return values;
    }

    allCookieData() {
        var values = [],
            keys = Object.keys(localStorage),
            i = keys.length;

        while (i--) {
            let keyname = keys[i];
            values.push({ [keyname]: cookie.load(keys[i]) });
        }
        return values;
    }

    handleName = (event) => {
        if (!event.target.value.match(/^[a-zA-Z-'. àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
        }
        else {
            this.setState({
                u_name: event.target.value,
                show_error_message: ''
            });
            localStorage.setItem('u_name', event.target.value)
        }
    }

    handleContinue = () => {
        let phone_number = this.formatPhoneNumber(this.state.phone_number);
        if (phone_number == null || phone_number === "" || phone_number.length < 13) {
            let action = "User got error on extrasupport file handleContinue function";
            let body = url.dynamic_content.error_message.invalid_phone.en
            functions.logUserError(action, body);
            this.setState({ show_error_message: url.dynamic_content.error_message.invalid_phone.en })
        }
    }

    formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            return '(' + match[1] + ')' + match[2] + '-' + match[3]
        }
        return null
    }

    handlePreviousPage = () => {
        let action = "User clicked on back button on support page"
        let image = 'back_button_support_page.png'
        functions.logUserActivity(action, image);
        this.props.onBack()
    }

    render() {
        const { dynamic_content } = this.state
        return (
            <div className="container">
                {dynamic_content != "" &&
                    <div className="row">
                        <div className="common_mainAddOn">
                            <div className="common_main gainextra_formFull">

                                <div className="sharegain_backlink profileBkImg" onClick={() => { this.handlePreviousPage() }}>
                                    <img src={require('../../assets/images/bkArorIcon.svg')} alt="" title="" />
                                </div>
                                <div className="profileTp">
                                    <h1>Profile</h1>
                                    <h2>{dynamic_content.static_text.extra ? dynamic_content.static_text.extra.support.en : "Support"}</h2>
                                </div>

                                <div className="gainextra_form">
                                    <FormControl>
                                        <div>
                                            {/* <TextField
                                            autoComplete='off'
                                            label={dynamic_content.static_text.extra ? dynamic_content.static_text.extra.name.en : "Name"}
                                            type="text"
                                            margin="normal"
                                            value={this.state.u_name}
                                            onChange={event => {
                                                this.handleName(event);
                                            }}
                                        /> */}

                                            <div className='editUsrInfo'>
                                                <label>Name</label>
                                                <input
                                                    autoComplete='off'
                                                    label={dynamic_content.static_text.extra ? dynamic_content.static_text.extra.name.en : "Name"}
                                                    type="text"
                                                    margin="normal"
                                                    value={this.state.u_name}
                                                    onChange={event => {
                                                        this.handleName(event);
                                                    }}
                                                />
                                            </div>

                                            {/* <TextField
                                            autoComplete='off'
                                            label={dynamic_content.static_text.extra ? dynamic_content.static_text.extra.email.en : "Email"}
                                            type="email"
                                            margin="normal"
                                            value={this.state.email}
                                            onChange={(e) => {
                                                this.setState({ email: e.target.value, show_error_message: '' })
                                                localStorage.setItem('support_email', e.target.value)
                                            }}
                                        /> */}

                                            <div className='editUsrInfo'>
                                                <label>Email</label>
                                                <input
                                                    autoComplete='off'
                                                    type="email"
                                                    margin="normal"
                                                    value={this.state.email}
                                                    onChange={(e) => {
                                                        this.setState({ email: e.target.value, show_error_message: '' })
                                                        localStorage.setItem('support_email', e.target.value)
                                                    }}
                                                />
                                            </div>

                                            <div className='editUsrInfo'>
                                                <label>Phone</label>
                                                <MaskedInput
                                                    mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                    className=""
                                                    placeholder="Phone Number"
                                                    value={this.state.phone_number}
                                                    onChange={(e) => {
                                                        this.setState({ phone_number: e.target.value, show_error_message: "" })
                                                        localStorage.setItem('phone_number', e.target.value)
                                                    }}
                                                    onKeyPress={event => {
                                                        if (event.key === "Enter") {
                                                            this.handleContinue()
                                                        }
                                                    }}
                                                />
                                            </div>

                                            {/* <TextField
                                            autoComplete='off'
                                            label={dynamic_content.static_text.extra ? dynamic_content.static_text.extra.your_message.en : "Your Message"}
                                            type="text"
                                            margin="normal"
                                            multiline
                                            rowsMax="4"
                                            value={this.state.message}
                                            onChange={(e) => { this.setState({ message: e.target.value }) }}
                                        /> */}

                                            <div className='editUsrInfo editUsrInfoSuprt'>
                                                <label>Your message</label>
                                                <textarea
                                                    autoComplete='off'
                                                    margin="normal"
                                                    multiline
                                                    rowsMax="6"
                                                    value={this.state.message}
                                                    onChange={(e) => { this.setState({ message: e.target.value }) }}
                                                />
                                            </div>

                                        </div>
                                        {this.state.show_error_message && this.state.show_error_message != "" &&
                                            <p className="error_message">
                                                {this.state.show_error_message}
                                            </p>
                                        }

                                        {this.state.response_message && this.state.response_message != "" && this.state.response_status == "success" &&
                                            <div className="successResponse">
                                                {this.state.response_message}
                                            </div>
                                        }

                                        {this.state.response_message && this.state.response_message != "" && this.state.response_status == "failed" &&
                                            <div className="failedResponse">
                                                {this.state.response_message}
                                            </div>
                                        }
                                        <div className="gainextra_submit">
                                            <Button size="medium" color="primary" onClick={this.extrahelp}>
                                                {dynamic_content.static_text.extra ? dynamic_content.static_text.extra.send.en : "Send"}
                                            </Button>
                                        </div>
                                    </FormControl>
                                </div>

                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}