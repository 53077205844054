import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import { Modal, ModalBody } from 'reactstrap';
import url from '../../assets/url';
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from "react-html-parser";
import functions from '../../assets/functions/common_functions';

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: true,
            dynamic_content: ""
        };
        this.TermCon = this.TermCon.bind(this);
    }
    TermCon() {
        let action = "User clicked on back button on terms and condition model"
        let image = 'back_button_terms_and_condition_model.png'
        functions.logUserActivity(action, image);
        this.setState({
            modal: !this.state.modal
        });
        this.props.Back()
    }

    componentDidMount = () => {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
        }
        if (this.props && this.props.program && this.props.program.term_and_condition) {

        } else {
            let user_action = "User got error on terms and condition model";
            let error_body = "Something missing"
            functions.logUserError(user_action, error_body);
        }
    }
    render() {
        const { dynamic_content } = this.state
        return (
            <div className="container">
                <div className="row">
                  <div className="common_mainAddOn">
                    <div className="common_main">
                        <div className="tc_baby">
                            {/* Term and condition model */}
                            <div>
                                <Modal isOpen={this.state.modal} toggle={this.TermCon} className="TermCon_model">
                                    <ModalBody className="TermCon_Body">
                                        <div className="support_model" >
                                        <img src={require('../../assets/images/closeChild.svg')} alt="" title="" onClick={() => { this.TermCon() }} className="terms_close" />
                                            <div className="sharegain_up">
                                                <h1>{dynamic_content && dynamic_content.static_text.t_and_c.terms_and_conditions ? dynamic_content.static_text.t_and_c.terms_and_conditions.en : "Terms and Conditions"}</h1>
                                            </div>

                                            <div className="terms_content">
                                                {ReactHtmlParser(this.props.program.term_and_condition)}
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>
                            </div>
                            {/* Term and condition model */}
                        </div>
                    </div>
                  </div>  
                </div>
            </div>
        );
    }
}
export default Index;

