import React from 'react';
import '../../assets/css/style.css';
import Collapsible from 'react-collapsible';
import Button from '@material-ui/core/Button';
import { Modal, ModalBody } from 'reactstrap';
import TermsConditions from './terms';
import Support from './extrasupport';
import Footer from './../innerpages/footer';
import functions from '../../assets/functions/common_functions';
import url from '../../assets/url';
import cookie from 'react-cookies';
class Help extends React.Component {
  constructor(props) {
    super(props);
    // functions.handleBackOne(this, 'help')
    this.state = {
      show_terms: false,
      show_support: false,
      show_messenger: false,
      dynamic_content: ""
    };
    this.editprofile = this.editprofile.bind(this);
    this.toggleAddchild = this.toggleAddchild.bind(this);
    this.handlePreviousPage = this.handlePreviousPage.bind(this);
    this.handleState = this.handleState.bind(this);
    this.terms = this.terms.bind(this);
  }

  componentWillMount() {
    let self = this
    let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
    sub_id = sub_id ? sub_id : "null"
    let email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email")
    if (sub_id == "null") {
      this.props.history.push('/home');
    } else {
      if (email && email != "" && email != "null" && email != null && typeof email != undefined && sub_id && sub_id != "" && sub_id != "null" && sub_id != null && typeof sub_id != undefined) {
        let finaljson = {
          email: email,
          sub_id: sub_id
        }
        functions.checkUserExistence(self, finaljson, function (response1_json) {
          let response1 = JSON.parse(response1_json)
          console.log("res", response1)
          if (response1.data && response1.data.dynamo && response1.data.dynamo.Items && response1.data.dynamo.Items.length == 0) {
            functions.pushToRoute(self, "", "help")
          } else {
            let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
            if (dynamic_content != "") {
              self.setState({ dynamic_content: dynamic_content })
            }

            let action = "User landed on help page"
            let image  = 'help_page_landing.png' 
            functions.logUserActivity(action, image);
            localStorage.setItem("active_button", "3")
            self.setState(
              {
                show_terms: localStorage.getItem('show_terms') == 'true' ? localStorage.getItem('show_terms') : false,
                show_support: localStorage.getItem('show_support') == 'true' ? localStorage.getItem('show_support') : false
              }
            )
          }
        })
      }else{
        this.props.history.push("/")
      }
    }
  }

  editprofile() {
    let action = "User clicked on edit button on help page"
    let image  = 'edit_button_help_page.png' 
    functions.logUserActivity(action, image);
    this.props.history.push('/editprofile');
  }

  terms() {
    this.props.history.push('/terms');
  }

  toggleAddchild() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleState = (state_name) => {
    let action = "User clicked on " + state_name + " button on support page"
    let image  = state_name +"button_support_page.png" 
    functions.logUserActivity(action, image);
    this.setState({ [state_name]: true })
    if (state_name == 'show_support') {
      let action = "User landed on support page"
      let image = 'support_page_landing.png'
      functions.logUserActivity(action, image);
    } else if (state_name == 'show_terms') {
      let action = "User landed on support terms page"
      let image  = 'support_terms_page_landing.png' 
      functions.logUserActivity(action, image);
    }
    localStorage.setItem([state_name], 'true');
  }

  handlePreviousPage = () => {
    let action = "User clicked on back button on help page"
    let image  = 'back_button_help_page.png'
    functions.logUserActivity(action, image);
    this.setState({ show_terms: false })
    this.setState({ show_support: false })
    localStorage.removeItem('show_terms');
    localStorage.removeItem('show_support');
  }

  handleTermsClick = () => {
    let mydiv = document.getElementById("open_term")
    if (mydiv) {
      mydiv.click()
    }
  }

  render() {
    const { dynamic_content } = this.state
    return (
      <div>
        {dynamic_content != "" &&
          <div className="container">
            {this.state.show_terms &&
              <TermsConditions props={this.props} onBack={this.handlePreviousPage} />
            }

            {this.state.show_support &&
              <Support props={this.props} onBack={this.handlePreviousPage} />
            }
            {!(this.state.show_terms || this.state.show_support) &&
              <div className="row">
                <div className="common_mainAddOn">
                <div className="common_main">
                
                  <div className="sign_backlink sign_backlinkNw" onClick={() => { functions.pushToRoute(this, "profile", 'setting') }}>
                    <img src={require('../../assets/images/bkArorIcon.svg')} alt="" title="" />
                  </div>

                  <div className="profileTp">
                    {/* <h1 >{dynamic_content.static_text.help ? dynamic_content.static_text.help.help.en : "Help"}</h1> */}
                    <h1>Profile</h1>
                    <h2>Help/Support</h2>
                  </div>

                  <div className="help_content" onClick={() => { this.handleState('show_support') }}>
                    <div className="help_contentLeft" >
                      <img src={require('../../assets/images/supportNew.svg')} alt="" title="" /><span>{dynamic_content.static_text.help ? dynamic_content.static_text.help.support.en : "Support"}</span>
                    </div>
                    <div className="help_contentRght">
                      <a><img src={require('../../assets/images/arowRght.svg')} alt="" title="" /></a>
                    </div>
                  </div>
                  <a target="_blank" href="https://www.iubenda.com/privacy-policy/7780464" id="open_term" /> 
                  <div className="help_content" onClick={() => { this.handleTermsClick() }}>
                    <div className="help_contentLeft" >
                      <img src={require('../../assets/images/tdNew.svg')} alt="" title="" /><span>{dynamic_content.static_text.help ? dynamic_content.static_text.help.terms_and_conditions.en : "Terms and Conditions"}</span>
                    </div>
                    <div className="help_contentRght">
                      <a><img src={require('../../assets/images/arowRght.svg')}alt="" title="" /></a>
                    </div>
                  </div>

                  {/* faqs */}
                  <div className="faq_label"><h2>{dynamic_content.static_text.help ? dynamic_content.static_text.help.faq.en : "FAQ"}</h2></div>

                  <div className="custom_faqs_main">

                    <div className="custom_faqs">
                      <Collapsible trigger="What's the difference between 32-bit and 64-bit versions of Windows?">
                        <div className="faqs_ans">
                          <p>{dynamic_content.static_text.help ? dynamic_content.static_text.help.faq_ans1.en : ""}</p>
                        </div>
                      </Collapsible>
                    </div>

                    <div className="custom_faqs">
                      <Collapsible trigger="What's the difference between 32-bit and 64-bit versions of Windows?">
                        <div className="faqs_ans">
                          <p>{dynamic_content.static_text.help ? dynamic_content.static_text.help.faq_ans2.en : ""}</p>
                        </div>
                      </Collapsible>
                    </div>
                  </div>
                  {/* faqs */}

                  {/* Site menu */}
                  <Footer props={this.props} />
                  {/*End of Site menu */}

                  {/* Add another child model */}
                  <div className="open_mail_model">
                    <Modal isOpen={this.state.modal} className="msg_dtlmodel">
                      <ModalBody className="msg_dltBody">
                        <div className="msg_model">
                          <div className="msg_dlt_content">
                            <div className="msg_mail">
                              <a>help@familyone.com</a>
                            </div>
                            <div>
                              <Button size="medium" color="primary" onClick={this.editprofile}>
                                {dynamic_content.static_text.help ? dynamic_content.static_text.help.send_email.en : "Send Email"}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </ModalBody>
                    </Modal>
                  </div>

                  {/* Add another child model */}

                </div>
              </div>
              </div>
            }
          </div>
        }</div>
    );
  }
}

export default Help;