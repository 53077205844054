import React, { useState } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder';
import Switch from '@material-ui/core/Switch';

const NewVideoRecorder = () => {
    const [video, setVideo] = useState(true);
    const [audio, setAudio] = useState(true);

    const {
        status,
        startRecording,
        stopRecording,
        mediaBlobUrl
    } = useReactMediaRecorder({ video: video, audio: audio });

    return (
        <div>
            <div>
                <p>{status}</p>
                Video <Switch
                    checked={video}
                    onChange={() => setVideo(prevVideo => !prevVideo)}
                    value="Video"
                />
                Audio <Switch
                    checked={audio}
                    onChange={() => setAudio(prevAudio => !prevAudio)}
                    value="Audio"

                />
            </div>
            <div>
                <button onClick={startRecording}>Start Recording</button>
                <button onClick={stopRecording}>Stop Recording</button>
            </div>

            <div>
                <video src={mediaBlobUrl} controls autoPlay loop />
            </div>

        </div>
    );
};

export default NewVideoRecorder;