import React from 'react';
import '../../assets/css/style.css';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { TimelineLite, TweenLite, TweenMax, TimelineMax, Bounce, Linear, Power1, Power2, Power3, Back, Power4, SlowMo, Circ, Elastic } from "gsap/all";
import functions from '../../assets/functions/common_functions';
import Footer from '../innerpages/footer';
import CSSPlugin from 'gsap/CSSPlugin';
import cookie from 'react-cookies';
import url from '../../assets/url';
import MergeChildPopup from './mergeChildPopup';
import { Grid } from '@material-ui/core';
import LottieLoader from '../LottieLoader';

const C = CSSPlugin;
var initials = require("initials");
const Month = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec"
};

class family extends React.Component {
    constructor(props) {
        super(props);
        localStorage.setItem("session_type", "login")
        functions.handleBackOne(this, 'family')
        this.toggleTab = this.toggleTab.bind(this);
        this.toggleEditDelete = this.toggleEditDelete.bind(this);
        // this.release = this.release.bind(this);
        this.state = {
            action: "",
            activeTab: localStorage.getItem("family_activeTab") ? localStorage.getItem("family_activeTab") : '1',
            relation_modal: false,
            relation_pending_modal: false,
            edit_delete_modal: false,
            modal: false,
            relation_status: '',
            child_data: [],
            relations: [],
            delete_confirm_modal: false,
            modal_message: "Do you want to delete this relation?",
            is_child: false,
            dynamic_content: "",
            child_invitation_data: [],
            child_status: '',
            isRelativeChild: false,
            isMergeChildPopup: true,
            invitedChildData: [],
            childObjectToBeUsed: {},
            mergeChildPopUpLoader: false,
            invitation_status: false,

            showColonOptions: '',
            showOptions: false,
            selectedRelation: {}
        };
        this.dateFormat = this.dateFormat.bind(this);
    }

    componentDidMount() {
        localStorage.setItem("add_as_relative", "false")
        let action = "User landed on family page"
        let image = 'family_page_landing.png'
        functions.logUserActivity(action, image);
        localStorage.setItem("active_button", "2")
        if (this.state.relations.length == 0) {
            setTimeout(function () {
                var timeline = new TimelineMax();
                timeline.fromTo(".two_person_img", 0.4, { width: "18px", opacity: 0, y: 20 }, { width: "36px", opacity: 1, ease: Circ.easeOut, y: 0 })
                    .fromTo(".person_cup", 0.4, { width: "16px", opacity: 0, y: 20 }, { width: "32px", opacity: 1, ease: Circ.easeOut, y: 0 }, "-=0.4")
                    .fromTo(".star_plusOne", 0.4, { left: "115px" }, { left: "0px" }, "-=0.4")
                    .fromTo(".star_plusTwo", 0.4, { left: "115px" }, { left: "13px" }, "-=0.4")
                    .fromTo(".star_plusThree", 0.4, { left: "115px" }, { left: "55px" }, "-=0.4")
                    .fromTo(".star_plusFour", 0.4, { right: "115px" }, { right: "0px" }, "-=0.4")
                    .fromTo(".star_plusFive", 0.4, { right: "115px" }, { right: "13px" }, "-=0.4")
                    .fromTo(".star_plusseven", 0.4, { right: "115px" }, { right: "48px" }, "-=0.4")
            }, 1000);
        }

    }

    componentWillMount = () => {
        let redirect_main = localStorage.getItem("redirect_main") && localStorage.getItem("redirect_main") == "true" ? localStorage.getItem("redirect_main") : "false"
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (redirect_main == "true") {
            functions.pushToRoute(this, "main", 'family');
            localStorage.setItem("redirect_main", 'false')
        }
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
            setTimeout(() => {
                var myDiv1 = document.getElementsByClassName('all_children_section');
                console.log("myDiv1", myDiv1)
                if (myDiv1) {
                    myDiv1.scrollTop = 0;
                }
                if (this.state.relations.length > 0) {
                    var myDiv2 = document.getElementsByClassName('all_relative_section2');
                    console.log("myDiv2", myDiv2)
                    if (myDiv2) {
                        myDiv2.scrollTop = 0;
                    }
                }

            }, 1000);
        }
        this.setState({ loader: true })
        let previous_state = localStorage.getItem("previous_state")
        console.log("previous_state", previous_state)
        if (previous_state == "") {
            this.GetInfo()
        } else {
            // settimeout for loading updated data
            this.setTimeOut = setTimeout(() => { this.GetInfo() }, 2000);
        }

    }

    daysBetweenTwoTimestamps = (timestamp1, timestamp2) => {
        var difference = timestamp1 - timestamp2;
        var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);

        return Math.abs(daysDifference);
    }

    GetInfo = () => {
        let self = this
        let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        sub_id = sub_id ? sub_id : ""
        let email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email")
        email = email ? email : ""
        if (email && email != "" && email != "null" && email != null && typeof email != undefined && sub_id && sub_id != "" && sub_id != "null" && sub_id != null && typeof sub_id != undefined) {
            let finaljson = {
                email: email,
                sub_id: sub_id
            }
            functions.checkUserExistence(self, finaljson, function (response1_json) {
                let response1 = JSON.parse(response1_json)
                console.log("res", response1)
                if (response1.data && response1.data.dynamo && response1.data.dynamo.Items && response1.data.dynamo.Items.length == 0) {
                    functions.pushToRoute(self, "", "family")
                } else {
                    functions.getuserChildAndrelativeAndinvitedChild(self, email, sub_id, function (response_json) {
                        let response = JSON.parse(response_json);
                        console.log("response", response)
                        if (response.catch_error) {
                            let action = "User got error on family/index file GetInfo function";
                            let body = response.message
                            functions.logUserError(action, body);
                            self.setState({ loader: false, show_error_message: response.message })
                        }
                        else if (!response.catch_error) {
                            if (response.data.error === 1) {
                                let action = "User got error on family/index file GetInfo function";
                                let body = response.data.message
                                functions.logUserError(action, body);
                                self.setState({ loader: false, show_error_message: response.data.message })
                            } else if (response.data.statusCode === 400) {
                                let action = "User got error on family/index file GetInfo function";
                                let body = response.data.message
                                functions.logUserError(action, body);
                                self.setState({ loader: false, show_error_message: response.data.message })
                            }

                            else if (response.status === 200) {
                                let child_data = (response.data.child_data.Items[0] && response.data.child_data.Items[0].child_data) ? response.data.child_data.Items[0].child_data : [];
                                let relation_data = response.data.relation_data ? response.data.relation_data : {}
                                let relativeParentChildData = [];
                                let mergingChildrenData = [];
                                if (response.data.relative_parent_child && response.data.relative_parent_child.length > 0) {
                                    relativeParentChildData = [...response.data.relative_parent_child];
                                }
                                if (response.data.mergingChildren && response.data.mergingChildren.length > 0) {
                                    mergingChildrenData = [...response.data.mergingChildren];
                                    console.log("mergingChildrenData before is", mergingChildrenData)

                                    mergingChildrenData.sort(function (a, b) {
                                        let nameA = self.daysBetweenTwoTimestamps(a.own_child.birthdate_timestamp, a.merging_child.birthdate_timestamp); // ignore upper and lowercase
                                        let nameB = self.daysBetweenTwoTimestamps(b.own_child.birthdate_timestamp, b.merging_child.birthdate_timestamp); // ignore upper and lowercase
                                        console.log("names are", nameA, nameB)
                                        if (nameA < nameB) {
                                            return -1;
                                        }
                                        if (nameA > nameB) {
                                            return 1;
                                        }
                                        return 0;
                                    });

                                    console.log("mergingChildrenData is", mergingChildrenData)
                                    let mergeChildIndex = mergingChildrenData.length > 0 && mergingChildrenData.findIndex(item => item.own_child.merge_status === "pending");
                                    self.setState({ isMergeChildPopup: true, selected_merge_child_index: mergeChildIndex });
                                }
                                console.log("invited_child", response.data.invited_child)

                                let invitedChildData = [];
                                if (response.data.invited_child && response.data.invited_child.length > 0) {
                                    invitedChildData = [...response.data.invited_child]
                                }
                                self.setState({ invitedChildData: invitedChildData });

                                console.log("child_data", child_data)
                                let success_action = "User get success in response of getuserChildAndrelative function in family/index page"
                                functions.logUserActivity(success_action, null);

                                if (child_data && child_data.length > 0) {
                                    localStorage.setItem("child_data", JSON.stringify(child_data));
                                }
                                else {
                                    localStorage.removeItem("child_data");
                                }

                                let relation_data_final = [];
                                if (Object.keys(relation_data).length > 0) {
                                    for (var key in relation_data) {
                                        relation_data[key].forEach(element => {
                                            if (key == 'approvedRelations') {
                                                element.relation_status = 'approved_relations'
                                            }
                                            if (key == 'pendingRelation') {
                                                element.relation_status = 'pending_relation'
                                            }
                                            if (key == 'pendingInvitation') {
                                                element.relation_status = 'pending_invitation'
                                            }
                                            relation_data_final.push(element)
                                        });
                                    }
                                    self.setState({ mergingChildrenData: mergingChildrenData, relativeParentChildData: relativeParentChildData, child_data: child_data, relations: relation_data_final, loader: false });
                                    localStorage.setItem("relation_data", JSON.stringify(relation_data_final));
                                } else {
                                    self.setState({ child_data: child_data, relations: relation_data_final, loader: false })
                                    localStorage.removeItem("relation_data");
                                }
                                self.handleCircleGrowEffect();
                            }
                        }
                    })
                }
            });

        } else {
            this.props.history.push("/")
        }
    }

    dateFormat = (value) => {
        let date_array = value.split("-");
        if (date_array.length > 1) {
            let year = date_array[0].substring(2, 4);
            let date = date_array[2];
            let month = date_array[1];
            let month_name = Month[month];
            let child_date = date + " " + month_name + " " + year;
            return child_date;
        }
        else {
            date_array = value.split("/");
            if (date_array.length > 1) {
                let year = date_array[0].substring(2, 4);
                let date = date_array[2];
                let month = date_array[1];
                let month_name = Month[month];
                let child_date = date + " " + month_name + " " + year;
                return child_date;
            }
        }
    }

    toggleTab(tab) {
        if (tab == "2") {
            let action = "User clicked on relation tab on family page"
            let image = 'relation_tab_family_page.png'
            functions.logUserActivity(action, image);
        }
        else if (tab == '1') {
            let action = "User clicked on children tab on family page"
            let image = 'children_tab_family_page.png'
            functions.logUserActivity(action, image);
        }
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
            localStorage.setItem("family_activeTab", tab)
        }
    }

    toggleEditDelete() {
        this.setState(prevState => ({
            edit_delete_modal: !prevState.edit_delete_modal
        }));
        // this.setState({
        //     edit_delete_modal: !this.state.edit_delete_modal
        // });
    }

    toggleShowColonOptions = (id) => {
        this.setState({
            showColonOptions: id
        })

        this.setState(prevState => ({
            showOptions: !prevState.showOptions
        }));
    }

    // release = () => {
    //     localStorage.setItem("release_index", this.state.child_index)
    //     localStorage.removeItem("family_activeTab")
    //     functions.pushToRoute(this, 'release_continue', 'family');
    // }

    toggleRelation() {
        this.setState({
            relation_modal: !this.state.relation_modal
        });
    }

    togglePendingRelation() {
        this.setState({
            relation_pending_modal: !this.state.relation_pending_modal
        });
    }

    toggleDeleteConfirm() {
        this.setState({
            delete_confirm_modal: !this.state.delete_confirm_modal
        });
    }

    closeRelationCancel = () => {
        this.GetInfo();
    }

    toggleMergeChildPopup = () => {
        this.setState({
            isMergeChildPopup: !this.state.isMergeChildPopup
        });
    }

    toggleInvitation = () => {
        this.setState({
            invitation_status: false
        });
    }

    skip_child = () => {
        let { selected_merge_child_index, mergingChildrenData } = this.state;
        console.log("inside skipchild is", selected_merge_child_index, mergingChildrenData)
        if (mergingChildrenData && mergingChildrenData.length > selected_merge_child_index)
            this.setState({ selected_merge_child_index: selected_merge_child_index + 1 });
        else
            this.toggleMergeChildPopup();
    }

    togglePoints() {
        this.setState({
            modal: !this.state.modal
        });
    }

    toggleEditRelationConfirm = () => {
        this.setState({
            relation_edit_cancel: !this.state.relation_edit_cancel
        });
    }

    closeToggle(value, state) {
        let action = "User clicked on " + state + " on family page"
        let image = state + '_button_family_page.png'
        functions.logUserActivity(action, image);
        value.setState({
            [state]: !value.state[state]
        });
    }

    handleDeclineRelation = (is_child = false) => {
        if (is_child) {
            let child_data = this.state.child_data;
            let child_index = this.state.child_index
            let final_relations = [];
            let removedData = child_data.splice(1, child_index)
            let sub_id = cookie.load('auto_id');
            let user_email = localStorage.getItem('email');
            functions.DeclineRelationChild(this, sub_id, removedData.creator_auto_id, user_email, removedData['child_id'], function (response_json) {
            });
        }
        else {
            let relations = this.state.relations
            let final_relations = []
            relations.forEach((element, index) => {
                if (index !== this.state.relation_index) {
                    final_relations.push(element)
                }
                else {
                    let self = this;
                    let sub_id = cookie.load('auto_id');
                    let user_email = localStorage.getItem('email');
                    functions.DeclineRelation(this, sub_id, element.relative_id, user_email, function (response_json) {
                    });
                }
            });

            this.setState({ relations: final_relations, open_edit: false })
            // this.togglePendingRelation()
            //api to delete relation 
            if (final_relations.length < 1) {
                localStorage.removeItem("relation_data")
            } else {
                localStorage.setItem("relation_data", JSON.stringify(final_relations))
            }
        }
        setTimeout(() => {
            this.setState({ invitation_status: 'decline' })
        }, 100);
        setTimeout(() => {
            this.setState({ invitation_status: false })
        }, 15000);
    }

    handleAcceptRelation = (is_child = false) => {
        let relations = this.state.relations;
        let child_data = this.state.child_data;
        let final_relations = [];
        if (is_child) {
            child_data.forEach((element, index) => {
                if (index == this.state.child_index) {
                    let self = this;
                    let sub_id = cookie.load('auto_id') ? cookie.load('auto_id') : localStorage.getItem("auto_id");
                    let user_email = cookie.load('username') ? cookie.load('username') : localStorage.getItem('email');
                    functions.AcceptRelationChild(this, sub_id, element.creator_auto_id, user_email, element.child_id, function (response_json) {
                        element['accept_status'] = 'approve'
                    });
                }
                final_relations.push(element)

            })
            this.setState({ child_data: final_relations, open_edit: false })

            localStorage.setItem("relation_data", JSON.stringify(final_relations))
        }
        else {
            relations.forEach((element, index) => {
                if (index == this.state.relation_index) {
                    element.relation_status = 'approved_relations';
                    let self = this;
                    let sub_id = cookie.load('auto_id') ? cookie.load('auto_id') : localStorage.getItem("auto_id");
                    let user_email = cookie.load('username') ? cookie.load('username') : localStorage.getItem('email');
                    functions.AcceptRelation(self, sub_id, element.relative_id, user_email, function (response_json) {
                    });
                }
                final_relations.push(element)

            });
            this.togglePendingRelation()
            this.setState({ relations: final_relations, open_edit: false })

            localStorage.setItem("relation_data", JSON.stringify(final_relations))
        }
        setTimeout(() => {
            this.setState({ invitation_status: 'accept' })
        }, 100);
        setTimeout(() => {
            this.setState({ invitation_status: false })
        }, 15000);
    }


    handleDeleteRelation = () => {
        let relations = this.state.relations
        let final_relations = []
        relations.forEach((element, index) => {
            if (index !== this.state.relation_index) {
                final_relations.push(element)
            }
            else {
                let user_email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email");
                let relative_id = element.relative_id ? element.relative_id : element.id;
                functions.deleteRelation(this, element.email, user_email, relative_id);
            }
        });
        this.setState({ relations: final_relations, open_edit: false })

        // api to delete relation 
        if (final_relations.length < 1) {
            localStorage.removeItem("relation_data")
        } else {
            localStorage.setItem("relation_data", JSON.stringify(final_relations))
        }

    }

    handleEditRelation = () => {
        localStorage.setItem("edit_index", this.state.relation_index)
        localStorage.setItem("form_type", 'edit_relation')
        localStorage.removeItem("family_activeTab")
        functions.pushToRoute(this, "addrelative", 'family');
        this.toggleRelation()
    }

    handleChildIndex = (index) => {
        this.setState({ child_index: index });
        this.toggleEditDelete()
    }

    isChildInvitationPending = (invitedChildObject, sub_id) => {
        let index = -1;
        if (invitedChildObject && invitedChildObject.parent_data && invitedChildObject.parent_data.length > 0) {
            index = invitedChildObject.parent_data.findIndex(item => (item.accepted_child === "pending") && (item.parent_id === sub_id));
        }

        return index === -1 ? false : true;
    }

    handleColonClick = (element, index, is_child) => {
        console.log("element, index, is_child is", element, index, is_child);
        this.setState({ showColonOptions: '', showOptions: false });
        if (is_child == "no") {
            let action = "User open edit relation popup on family page"
            let image = 'edit_relation_popup_family_page.png'
            functions.logUserActivity(action, image);
        } else {
            let action = "User copen edit child popup on family page"
            let image = 'edit_child_popup_family_page.png'
            functions.logUserActivity(action, image);
        }
        this.setState({ child_status: "", relation_status: "", isRelativeChild: false });
        if (element && element.merge_child_id && element.merge_status) {
            this.setState({ isRelativeChild: true });
        }
        if (is_child == "yes") {
            let invitedChildData = this.state.invitedChildData;
            let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
            if (invitedChildData && invitedChildData.length > 0 && invitedChildData[index] && this.isChildInvitationPending(invitedChildData[index], sub_id)) {
                console.log("pending_invitation", sub_id)
                this.setState({ child_status: 'pending', childObjectToBeUsed: invitedChildData[index] });
            }
            // if (element && element?.accepted_child === "pending") {
            //     this.setState({ child_status: 'pending' });
            // }
            this.setState({ is_child: true, child_index: index });
            this.toggleEditDelete()
        }
        else {
            let relations = this.state.relations
            let relation_status = relations[index].relation_status
            // if (relation_status != "pending_invitation") {
            this.setState({ relation_status: relation_status });
            // }
            this.setState({ is_child: false, relation_index: index });
            this.toggleEditDelete()
        }


    }

    handleClickEditInfo = (element, index, isChild, isDeleteChosen) => {
        if (isChild == "no") {
            let action = "User open edit relation popup on family page"
            let image = 'edit_relation_popup_family_page.png'
            functions.logUserActivity(action, image);
        } else {
            let action = "User copen edit child popup on family page"
            let image = 'edit_child_popup_family_page.png'
            functions.logUserActivity(action, image);
        }

        this.setState({ child_status: "", relation_status: "", isRelativeChild: false });
        if (element && element.merge_child_id && element.merge_status) {
            this.setState({ isRelativeChild: true });
        }

        if (isChild == "yes") {
            let invitedChildData = this.state.invitedChildData;
            let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
            if (invitedChildData && invitedChildData.length > 0 && invitedChildData[index].accepted_child && invitedChildData[index].accepted_child == 'pending' && sub_id == invitedChildData[index].parent_id) {
                console.log("pending_invitation", sub_id)
                this.setState({ child_status: 'pending', childObjectToBeUsed: invitedChildData[index] });
            }
            this.setState({ is_child: true, child_index: index });

            let action = "User clicked on edit child on family page"
            let image = 'edit_child_button_family_page.png'
            functions.logUserActivity(action, image);

            if (isDeleteChosen) {
                this.newHandleDeleteChild();
            } else {
                this.newHandleEditChild(index);
            }
        }
        else {
            let relations = this.state.relations
            let relation_status = relations[index].relation_status
            // if (relation_status != "pending_invitation") {
            this.setState({ relation_status: relation_status });
            // }
            this.setState({ is_child: false, relation_index: index });
            
            if (isDeleteChosen) {
                this.newHandleDeleteRelation();
            } else {
                this.newHandleEditRelation(index);
            }
        }

    }

    newHandleEditChild = (index) => {
        localStorage.setItem("edit_index", index)
        localStorage.setItem("form_type", 'edit_child')
        localStorage.removeItem("family_activeTab")
        functions.pushToRoute(this, "addchild", 'family');
    }

    newHandleDeleteChild = () => {
        let action = "User clicked on decline child button on family page"
        let image = 'decline_chid_button_family_page.png'
        functions.logUserActivity(action, image);
        this.setState({ action: "handleDeleteChild", modal_message: "Do you want to delete this child?" })
        this.toggleDeleteConfirm()
    }

    newHandleDeleteRelation = () => {
        let action = "User clicked on delete relation button on family page"
        let image = 'delete_relation_button_family_page.png'
        functions.logUserActivity(action, image);
        this.setState({ action: "handleDeleteRelation", modal_message: "Do you want to delete this relation?" })
        this.toggleDeleteConfirm()
    }

    newHandleEditRelation = (index) => {
        localStorage.setItem("edit_index", index)
        localStorage.setItem("form_type", 'edit_relation')
        localStorage.removeItem("family_activeTab")
        functions.pushToRoute(this, "addrelative", 'family');
    }

    handleEditChild = () => {
        localStorage.setItem("edit_index", this.state.child_index)
        localStorage.setItem("form_type", 'edit_child')
        localStorage.removeItem("family_activeTab")
        functions.pushToRoute(this, "addchild", 'family');
        this.toggleEditDelete()
    }

    handleAddChild = () => {
        let action = "User clicked on add child button on family page"
        let image = 'add_child_button_family_page.png'
        functions.logUserActivity(action, image);
        let self = this;
        localStorage.setItem("form_type", 'add_child')
        localStorage.removeItem("family_activeTab")

        localStorage.setItem("add_as_relative", "false")
        setTimeout(function () {
            functions.pushToRoute(self, "addchild", 'family');
        }, 1000)
    }

    handleDeleteChild = () => {
        let child_data = this.state.child_data
        let final_child_data = []
        let delete_child = child_data[this.state.child_index]
        let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        if (sub_id && sub_id != "") {
            let delete_final_json = {
                "action": "delete-child",
                sub_id: sub_id,
                child_id: delete_child.child_id
            }
            child_data.forEach((element, index) => {
                if (index !== this.state.child_index) {
                    final_child_data.push(element)
                }
            });
            this.setState({ child_data: final_child_data, open_edit: false })
            functions.SyncInMysql(this, delete_final_json);
            this.handleUpdateChild(final_child_data);
            if (final_child_data.length < 1) {
                localStorage.removeItem("child_data")
                localStorage.setItem("child_deleted", "yes")
            } else {
                localStorage.setItem("child_data", JSON.stringify(final_child_data))
            }
        }
    }

    handleUpdateChild = (child_data) => {
        let userdata = [];
        let send_child_data = []
        let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        child_data.map(childs => {
            if (childs.accept_status == "pending" && childs.creator_auto_id !== auto_id) {

            }
            else {
                send_child_data.push(childs)
            }
        })
        let dynamo_userdata = { 'child_data': send_child_data };
        functions.updateUserData(this, userdata, dynamo_userdata);
    }

    handleAddRelation = () => {
        let action = "User clicked on add relation/add on family page"
        let image = 'add_relation_button_family_page.png'
        functions.logUserActivity(action, image);
        let self = this;
        localStorage.setItem("form_type", "add_relation")
        setTimeout(function () {
            functions.pushToRoute(self, 'addrelative', 'family')
        }, 1000)
    }

    handleCircleGrowEffect = () => {
        var body = document.getElementById("family_listing");
        // var body = document.body;
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        var piTwo = Math.PI * 2;
        // Viewport
        if (body) {
            var vw = canvas.width = body.offsetWidth;
            var vh = canvas.height = body.offsetHeight;

            if (document.getElementById('allchild_grow')) {
                document.getElementById('allchild_grow').addEventListener("click", createRipple1);
            }

            if (document.getElementById('add_child_grow')) {
                document.getElementById('add_child_grow').addEventListener("click", createRipple1);
            }

            if (document.getElementById('add_relative_grow')) {
                document.getElementById('add_relative_grow').addEventListener("click", createRipple1);
            }

            if (document.getElementById('add_relative_grow2')) {
                document.getElementById('add_relative_grow2').addEventListener("click", createRipple1);
            }

            window.addEventListener("resize", onResize);

            //createRipple(this);
            function createRipple1(event) {
                // Exit if target is the canvas. That means it's still active.
                if (event.target === canvas) return;

                body.appendChild(canvas);
                // Event coords
                var x = event.clientX;
                var y = event.clientY;
                // Delta - difference between event and farthest corner
                var dx = x < vw / 2 ? vw - x : x;
                var dy = y < vh / 2 ? vh - y : y;
                var radius = Math.sqrt(dx * dx + dy * dy);

                var ripple = {
                    alpha: 0,
                    radius: 0,
                    x: vw / 2,
                    y: y
                };

                var tl = new TimelineMax({ onUpdate: drawRipple, onComplete: removeCanvas })
                    .to(ripple, 1.5, { alpha: 1, radius: radius })
                    .to(ripple, 0.1, { alpha: 0 }, 1.4);

                function drawRipple() {
                    ctx.clearRect(0, 0, vw, vh);
                    ctx.beginPath();
                    ctx.arc(ripple.x, ripple.y, ripple.radius, 0, piTwo, false);
                    ctx.fillStyle = "rgba(252,241,239," + ripple.alpha + ")";
                    ctx.fill();
                }
            }
            function removeCanvas() {
                if (body.contains(canvas)) {
                    body.removeChild(canvas);
                }
            }
            function onResize() {
                vw = canvas.width = window.innerWidth;
                vh = canvas.height = window.innerHeight;
            }
        }

    }

    handleYes = () => {
        let action1 = "User clicked on yes button on family page"
        let image = 'yes_button_family_page.png'
        functions.logUserActivity(action1, image);
        let action = this.state.action
        if (action == "handleDeclineRelation") {
            this.handleDeclineRelation()
        }
        else if (action == "handleDeleteChild") {
            this.handleDeleteChild()
        }
        else if (action == "handleDeleteRelation") {
            this.handleDeleteRelation()
        }
        else if (action == "handleDeclineChildRelation") {
            this.handleDeclineRelation(true)
        }
        this.toggleDeleteConfirm()
    }

    handleClick = (action, relation_status) => {
        console.log("action, relation_status is", action, relation_status);
        let { child_status } = this.state
        if (this.state.is_child == true) {
            let { child_data } = this.state
            if (child_status && child_status == "pending" && action == "edit") {
                const { childObjectToBeUsed } = this.state;
                let index = this.state.invitedChildData.length > 0 && this.state.invitedChildData.findIndex(item => item.child_id === childObjectToBeUsed.child_id);
                let action = "User clicked on accept child button on family page"
                let image = 'accept_button_family_page.png'
                functions.logUserActivity(action, image);
                this.AcceptChild(childObjectToBeUsed.creator_auto_id, '', index, childObjectToBeUsed.child_id)
            }
            else if (child_status && child_status == "pending" && action == "delete") {
                const { childObjectToBeUsed } = this.state;
                let index = this.state.invitedChildData.length > 0 && this.state.invitedChildData.findIndex(item => item.child_id === childObjectToBeUsed.child_id);
                let action = "User clicked on decline child relation button on family page"
                let image = 'decline_relation_button_family_page.png'
                functions.logUserActivity(action, image);
                this.setState({ action: "handleDeclineChildRelation", modal_message: "Do you want to decline this child?" })
                this.DeclineChild(childObjectToBeUsed.creator_auto_id, '', index, childObjectToBeUsed.child_id);
                // this.toggleEditDelete()
                // this.toggleDeleteConfirm()
            } else if (action == "edit") {
                let action = "User clicked on edit child on family page"
                let image = 'edit_child_button_family_page.png'
                functions.logUserActivity(action, image);
                this.handleEditChild()
            }
            else if (action == "delete") {
                let action = "User clicked on decline child button on family page"
                let image = 'decline_chid_button_family_page.png'
                functions.logUserActivity(action, image);
                this.setState({ action: "handleDeleteChild", modal_message: "Do you want to delete this child?" })
                this.toggleEditDelete()
                this.toggleDeleteConfirm()
            }
        }
        else {
            if (action == "edit") {
                if (relation_status == "pending_invitation") {
                    let action = "User clicked on accept button on family page"
                    let image = 'accept_button_family_page.png'
                    functions.logUserActivity(action, image);
                    this.handleAcceptRelation()
                    this.toggleEditDelete()
                }
                else {
                    let self = this
                    let relations = self.state.relations
                    let relation_email = relations[self.state.relation_index].email;
                    let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id");
                    functions.CheckRelationStatus(self, relation_email, sub_id, function (json_response) {
                        let data = JSON.parse(json_response).data;
                        if (data.relationAccepted) {
                            let action = "User clicked on edit relation button on family page"
                            let image = 'edit_relation_button_family_page.png'
                            functions.logUserActivity(action, image);
                            self.handleEditRelation()
                            self.toggleEditDelete()
                        } else {
                            self.setState({ modal_message: "Relative already accepted your invitation, edit is not available for this relative" })
                            self.toggleEditDelete();
                            self.toggleEditRelationConfirm();
                        }
                    })
                }
            }
            else if (action == "delete") {
                if (relation_status == "pending_invitation") {
                    let action = "User clicked on decline relation button on family page"
                    let image = 'decline_relation_button_family_page.png'
                    functions.logUserActivity(action, image);
                    this.setState({ action: "handleDeclineRelation", modal_message: "Do you want to decline this relation?" })
                    this.toggleEditDelete()
                    this.toggleDeleteConfirm()
                }
                else {
                    let action = "User clicked on delete relation button on family page"
                    let image = 'delete_relation_button_family_page.png'
                    functions.logUserActivity(action, image);
                    this.setState({ action: "handleDeleteRelation", modal_message: "Do you want to delete this relation?" })
                    this.toggleEditDelete()
                    this.toggleDeleteConfirm()
                }
            }

        }
    }

    checkDefaultName = (childName) => {
        let regex = new RegExp(/^([1-9])?(\d)$/);
        let childElement = childName.split(" ");
        if (childElement[0] === "Child" && Number.isInteger(parseInt(childElement[1])) && regex.test(childElement[1]))
            return true;
        return false;
    }

    handleMergeChild = (choice, item) => {
        let self = this;
        self.setState({ mergeChildPopUpLoader: true });
        let child_data = localStorage.getItem("child_data") ? JSON.parse(localStorage.getItem("child_data")) : []
        let ownChildData = [];
        let merging_child = {};

        if (choice === "Yes") {
            if (child_data && child_data.length) {
                ownChildData = [...child_data];
                let objIndex = ownChildData.findIndex((obj => obj.child_id === item.own_child.child_id));
                if (!this.checkDefaultName(item.merging_child.child_name)) {
                    ownChildData[objIndex].child_name = item.merging_child.child_name;
                }

                let myIndex = -1;

                if (!ownChildData[objIndex].parent_data && ownChildData[objIndex].merge_status && ownChildData[objIndex].parent_id) {
                    ownChildData[objIndex].parent_data = [];
                    ownChildData[objIndex].parent_data.push({
                        parent_id: ownChildData[objIndex].parent_id,
                        merge_status: ownChildData[objIndex].merge_status,
                        accept_status: ownChildData[objIndex].accept_status,
                        merge_child_id: ownChildData[objIndex].merge_child_id,
                    })
                }

                if (ownChildData[objIndex].parent_data && ownChildData[objIndex].parent_data.length > 0) {
                    myIndex = ownChildData[objIndex].parent_data.findIndex((element) => element.parent_id === item.merging_child.creator_auto_id);
                }

                if (myIndex === -1) {
                    console.log("stop the flow here or throw error");
                }

                ownChildData[objIndex].child_birthdate = item.merging_child.child_birthdate;
                ownChildData[objIndex].merge_status = "accepted";
                ownChildData[objIndex].parent_data[myIndex].merge_status = "accepted";

                merging_child = {
                    "child_id": item.own_child.child_id,
                    "parent_id": ownChildData[objIndex].parent_id,
                    "merging_child_id": item.merging_child.child_id
                }
            }

        } else if (choice === "No") {
            if (child_data && child_data.length) {
                ownChildData = [...child_data];
                let objIndex = ownChildData.findIndex((obj => obj.child_id === item.own_child.child_id));


                if (ownChildData[objIndex].parent_data && ownChildData[objIndex].parent_data.length > 0) {
                    let myIndex = ownChildData[objIndex].parent_data.findIndex((element) => element.parent_id === item.merging_child.creator_auto_id);

                    delete ownChildData[objIndex].parent_data[myIndex].merge_child_id
                    delete ownChildData[objIndex].parent_data[myIndex].merge_status

                    delete ownChildData[objIndex].parent_data[myIndex].parent_id
                    delete ownChildData[objIndex].parent_data[myIndex].accepted_child

                }

                if (ownChildData[objIndex].merge_status) {
                    delete ownChildData[objIndex].merge_status
                }
                if (ownChildData[objIndex].parent_id) {
                    delete ownChildData[objIndex].parent_id
                }
                // ownChildData[objIndex].merge_status = "declined";
            }
        }
        let userdata = [];
        let dynamo_userdata = { 'child_data': ownChildData };

        console.log("dynamo_userdata, merging_child is", dynamo_userdata, merging_child);
        functions.updateUserDataForMerge(this, userdata, dynamo_userdata, false, merging_child, function (err, response) {
            localStorage.setItem("child_data", JSON.stringify(ownChildData));
            if (response) {
                self.setState({ mergeChildPopUpLoader: false });
            }
            self.setState({ child_data: ownChildData });
        });
        this.skip_child();
    }

    AcceptChild = (relative_id, state_name, index, child_id) => {
        let self = this;
        let sub_id = cookie.load('auto_id') ? cookie.load('auto_id') : localStorage.getItem("auto_id");
        let user_email = cookie.load('username') ? cookie.load('username') : localStorage.getItem('email');
        functions.AcceptRelationChild(this, sub_id, relative_id, user_email, child_id, function (response_json) {
            console.log('response_json is', response_json);
            if (response_json) {
                self.toggleEditDelete();
                self.GetInfo();
            }
            // let notification_state = self.state[state_name];
            // // let notification_state1 = notification_state.splice(index, 1);
            // self.setState({ [state_name]: notification_state })
            // self.LoadNotification()
        });
    }

    DeclineChild = (relative_id, state_name, index, child_id = null) => {
        let success_action = "User clicked on Decline on notification page"
        let image = 'decline_button_notification_page.png'
        functions.logUserActivity(success_action, image);
        let self = this;
        let sub_id = cookie.load('auto_id') ? cookie.load('auto_id') : localStorage.getItem("auto_id");
        let user_email = cookie.load('username') ? cookie.load('username') : localStorage.getItem('email');
        functions.DeclineRelationChild(this, sub_id, relative_id, user_email, child_id, function (response_json) {
            if (response_json) {
                self.toggleEditDelete();
                self.GetInfo();
            }
            // let notification_state = self.state[state_name];
            // let notification_state1 = notification_state.splice(index, 1);
            // self.setState({ [state_name]: notification_state })
        });
    }

    formatMinMaxDate = (date) => {
        console.log("date max", date)
        let date_month = date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
        let date_year = date.getFullYear();
        let date_date = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let final_date = date_year + "-" + date_month + "-" + date_date;
        console.log("final_date", final_date)
        return final_date;
    }

    GetChildType = (birthdate_timestamp) => {
        let min = new Date()
        let max = new Date();
        let temp = max.getDate()
        max.setMonth(max.getMonth() + 9);
        let minimum_date = this.formatMinMaxDate(min);
        let maximum_date = this.formatMinMaxDate(max);
        let min_time = new Date(minimum_date).getTime()
        let max_time = new Date(maximum_date).getTime()
        console.log("min_time", min_time)
        console.log("max_time", max_time)
        console.log("birthdate_timestamp", birthdate_timestamp)
        if (birthdate_timestamp <= max_time && birthdate_timestamp >= min_time) {
            return "Expecting"
        }
        return "Existing"
    }

    async selectRelative(childObject) {
        localStorage.removeItem("parentinviteChild");

        console.log("childObject is", childObject);
        // this.setState({ selectedChild: childObject }, ()=>{
        //     console.log(this.state.selectedChild, "setSelectedChild")
        // })
        await this.setStateAsync({ selectedChild: childObject });
        functions.pushToRoute(this, 'existingRelativeList', 'family');
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
          this.setState(state, resolve)
        });
      }

    async selectChildren(relationObject) {
        console.log("relationObject is", relationObject);
        // this.setState({ selectedRelation: relationObject }, ()=>{
        //     console.log(this.state.selectedRelation, "setSelectedRelation")
        // })
        await this.setStateAsync({ selectedRelation: relationObject });
        functions.pushToRoute(this, 'select-children', 'family');
    }

    checkChildParentConnection = (relative_id, childObject) => {
        let index = -1;
        if (childObject && childObject.parent_data && childObject.parent_data.length > 0) {
            index = childObject.parent_data.findIndex(item => item.parent_id === relative_id);
        }

        return index === -1 ? false : true;
    }

    render() {
        const { dynamic_content } = this.state
        return (
            <div className="container" id="family_listing">
                {dynamic_content != "" &&
                    <div className="row">
                         <div className="common_mainAddOn">
                            <div className="common_main shrScrenTabsRel shrScrenTabsAddSpc">

                                <div className="shrScrenTabs shrScrenRmvBg">
                                    <div className="share_point">
                                        <div className="share_pointLeft"><p className="perks_title">Family</p></div>
                                        <div className="share_pointRght"><p>Share points</p></div>
                                        {/* <div className="share_pointRght" onClick={() => { this.togglePoints() }}><p>Share points</p></div> */}
                                    </div>

                                    {/*Tabs content */}
                                    <div className="extragain_tabs addrelative_tabs">
                                        <Nav tabs>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: this.state.activeTab === '1' })}
                                                    onClick={() => { this.toggleTab('1'); }}
                                                >
                                                    Children
                                                    <span className="nav_dot" ></span>
                                                </NavLink>
                                            </NavItem>

                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: this.state.activeTab === '2' })}
                                                    onClick={() => { this.toggleTab('2'); }}
                                                >
                                                    Relatives
                                                    <span className="nav_dot" ></span>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </div>
                                </div>

                                <TabContent activeTab={this.state.activeTab}> 
                                    {/* Children Tab Content */}
                                    <TabPane tabId="1">
                                        {/* All children design */}
                                        {this.state.loader &&
                                            <center className="LottieLoaderTabMid">
                                                <LottieLoader />
                                            </center>
                                        }
                                        {!this.state.loader && this.state.child_data && this.state.child_data.length == 0 &&
                                            <div className="your_odds_upr">
                                                <div className="star_group">
                                                    <img style={{ width: "10px" }} src={url.family_one_image_url + 'Group245.4.png'} alt="" title="" className="star_plusOne starComon" />
                                                    <img style={{ width: "10px" }} src={url.family_one_image_url + 'Group245.png'} alt="" title="" className="star_plusTwo starComon" />
                                                    <img style={{ width: "10px" }} src={url.family_one_image_url + 'Group245.3.png'} alt="" title="" className="star_plusThree starComon" />

                                                    <img style={{ width: "10px" }} src={url.family_one_image_url + 'Group245.4.png'} alt="" title="" className="star_plusFour starComon" />
                                                    <img style={{ width: "10px" }} src={url.family_one_image_url + 'Group245.png'} alt="" title="" className="star_plusFive starComon" />
                                                    <img style={{ width: "10px" }} src={url.family_one_image_url + 'Group245.3.png'} alt="" title="" className="star_plusseven starComon" />


                                                    <span className="two_person">
                                                        <img style={{ width: "36px" }} src={url.family_one_image_url + 'Groupt234.png'} alt="" title="" className="two_person_img" />
                                                        <img src={url.family_one_image_url + 'Groupt214.png'} alt="" title="" className="person_cup" />
                                                    </span>
                                                </div>
                                                <div className="your_odds">
                                                    <label></label>
                                                    <p> </p>
                                                    <Button id="add_child_grow" size="medium" color="primary" onClick={() => { this.handleAddChild() }} >
                                                        Add Child
                                                    </Button>
                                                </div>
                                            </div>
                                        }
                                        {!this.state.loader && this.state.child_data && this.state.child_data.length > 0 &&
                                            <div className="all_children_section 1212">
                                                {/* <div className="selectFltr">
                                                    <select>
                                                        <option>All</option>
                                                        <option>One</option>
                                                        <option>Two</option>
                                                    </select>
                                                </div> */}
                                                {this.state.invitedChildData.length === 0 && this.state.relativeParentChildData && this.state.relativeParentChildData.length > 0 && this.state.relativeParentChildData.filter(child => child.accepted_child === 'accept')
                                                    .map((child, index) => (
                                                        <div key={child.child_id} className="all_children">
                                                            <div className="all_children_left"><label>{initials(child.child_name.toUpperCase()).substring(0, 2)}</label></div>

                                                            <div className="all_children_rght">
                                                                <label>{child.child_name} {child.parent_name ? "(" + child.parent_name + ")" : ""}</label>
                                                                <p>{this.GetChildType(child.birthdate_timestamp)}, {this.dateFormat(child.child_birthdate)}</p>
                                                            </div>
                                                            <div className="all_relative_close">
                                                                <a
                                                                    onClick={() => { this.handleColonClick(child, index, 'yes') }}
                                                                // onClick={() => { this.handleChildIndex(index) }}
                                                                >:</a>
                                                            </div>
                                                        </div>
                                                    ))}


                                                {this.state.invitedChildData && this.state.invitedChildData.length > 0 && this.state.invitedChildData.map((child, index) => (
                                                    <div key={child.child_id} className="all_children">
                                                        <div className="all_children_left"><label>{initials(child.child_name.toUpperCase()).substring(0, 2)}</label></div>

                                                        <div className="all_children_rght childNwUpr">
                                                            <label>{child.child_name} {child.parent_name ? "(" + child.parent_name + ")" : ""} <span className="childNw">new</span></label>
                                                            <p>{this.GetChildType(child.birthdate_timestamp)}, {this.dateFormat(child.child_birthdate)}</p>
                                                        </div>
                                                        <div className="all_relative_close">
                                                            {/* {child.accepted_child === 'pending' &&
                                                                <a> <img src={url.family_one_image_url + 'Grouptm161.png'} alt="" title="" /></a>
                                                            } */}
                                                            <a
                                                                onClick={() => { this.handleColonClick(child, index, 'yes') }}
                                                            // onClick={() => { this.handleChildIndex(index) }}
                                                            >:</a>
                                                        </div>
                                                    </div>
                                                ))}

                                                {this.state.child_data && this.state.child_data.length > 0 && this.state.child_data.map((child, index) => (
                                                    <div key={child.child_id} className={`allChildrenUpr ${this.GetChildType(child.birthdate_timestamp) === 'Expecting' ? 'expectingSection' : 'existingSection'}`}>
                                                        <div className='children_tab_container'>
                                                            <div className="all_children_left"><label>{initials(child.child_name.toUpperCase()).substring(0, 2)}</label></div>

                                                            <div className="all_children_rght">
                                                                <label>{child.child_name} {child.parent_name ? "(" + child.parent_name + ")" : ""}</label>
                                                                <p>{this.GetChildType(child.birthdate_timestamp)}, {this.dateFormat(child.child_birthdate)}</p>
                                                            </div>
                                                            <div className="all_children_close editInfoUpr 13">
                                                                <a
                                                                    onClick={() => { this.toggleShowColonOptions(index) }}
                                                                    //onClick={() => { this.handleChildIndex(index) }}
                                                                ><span><img src={require('../../assets/images/EditChildIcon.svg')} alt="" title="" /></span>
                                                                    {(this.state.showColonOptions === index) && this.state.showOptions && <ul className="editInfo">
                                                                        <li onClick={() => { this.handleClickEditInfo(child, index, 'yes', false) }}>
                                                                            <a>Edit info</a>
                                                                        </li>
                                                                        <li onClick={() => { this.selectRelative(child) }}>
                                                                            <a>Add a Parent</a>
                                                                        </li>
                                                                        <li onClick={() => { this.handleClickEditInfo(child, index, 'yes', true) }}>
                                                                            <a>Delete</a>
                                                                        </li>
                                                                    </ul>}
                                                                </a>
                                                            </div>
                                                        </div>
                                                        

                                                        {this.state.relations && this.state.relations.length > 0 && this.state.relations.filter(item => (item.relative_id && this.checkChildParentConnection(item.relative_id, child)) && child.relation_with_child !== "parent").map((relation, index) => (
                                                            <div key={index} className="all_children all_childrenChild">
                                                                <div className="all_children_left">
                                                                    <label>{relation.given_name.charAt(0).toUpperCase() + relation.family_name.charAt(0).toUpperCase()}</label>
                                                                </div>
                                                                <div className="all_children_rght">
                                                                    <label>{relation.given_name + " " + relation.family_name}</label>
                                                                    {relation.optional_relation && relation.optional_relation != "" && relation.optional_relation == "In-law" &&
                                                                        <p>{relation.relation + " " + relation.optional_relation}</p>
                                                                    }
                                                                    {relation.optional_relation && relation.optional_relation != "" && relation.optional_relation != "In-law" &&
                                                                        <p>{relation.optional_relation + " " + relation.relation}</p>
                                                                    }
                                                                    {relation.optional_relation && relation.optional_relation == "" &&
                                                                        <p>{relation.relation}</p>
                                                                    }
                                                                    {!relation.optional_relation &&
                                                                        <p>{relation.relation}</p>
                                                                    }
                                                                </div>
                                                                {/* <div className="all_children_close">
                                                                    <a onClick={() => { this.toggleShowColonOptions(index) }}>:</a>
                                                                </div> */}
                                                            </div>
                                                        ))}

                                                    </div>
                                                ))}
                                            </div>
                                        }
                                        {/* End of All relative design */}
                                        {!this.state.loader && this.state.child_data && this.state.child_data.length > 0 &&
                                            <div className="allchild_submit">
                                                {/* <Button id="allchild_grow" size="medium" color="primary" onClick={() => { this.handleAddChild() }} >
                                                    Add Child
                                                </Button> */}
                                                <a className="cstmChild cstmChildNw" onClick={() => { this.handleAddChild() }}><div className="cstmChildPlus">+</div></a>
                                            </div>
                                        }
                                    </TabPane>
                                    {/* End of Children Tab Content */}
                                    {/* Relative Tab Content */}
                                    <TabPane tabId="2">
                                        {this.state.loader &&
                                           <center className="LottieLoaderTabMid">
                                              <LottieLoader />
                                          </center>
                                        }
                                        {!this.state.loader && this.state.relations && this.state.relations.length == 0 &&
                                            <div className="your_odds_upr">
                                                <div className="star_group">

                                                    {/* <img style={{ width: "10px" }} src={require('../../assets/images/UnionIcon.svg')} alt="" title="" className="star_plusOne starComon" />
                                                    <img style={{ width: "10px" }} src={require('../../assets/images/UnionIcon.svg')} alt="" title="" className="star_plusTwo starComon" />
                                                    <img style={{ width: "10px" }} src={require('../../assets/images/UnionIcon.svg')} alt="" title="" className="star_plusThree starComon" />

                                                    <img style={{ width: "10px" }} src={require('../../assets/images/UnionIcon.svg')} alt="" title="" className="star_plusFour starComon" />
                                                    <img style={{ width: "10px" }} src={require('../../assets/images/UnionIcon.svg')} alt="" title="" className="star_plusFive starComon" />
                                                    <img style={{ width: "10px" }} src={require('../../assets/images/UnionIcon.svg')} alt="" title="" className="star_plusseven starComon" /> */}


                                                    {/* <span className="two_person">
                                                        <img style={{ width: "36px" }} src={require('../../assets/images/relativeNw.svg')} alt="" title="" className="two_person_img" />
                                                        <img src={url.family_one_image_url + 'Groupt214.png'} alt="" title="" className="person_cup" /> 
                                                    </span> */}

                                                    <span>
                                                        <img src={require('../../assets/images/relativeNw.svg')} alt="" title="" className="two_person_img" />
                                                    </span>

                                                </div>
                                                <div className="your_odds">
                                                    <label>Increase Your Odds</label>
                                                    <p> Adding relatives helps to increase your odds of winning</p>
                                                    <Button id="add_relative_grow" size="medium" color="primary" onClick={() => { this.handleAddRelation() }} >
                                                        Add Relatives
                                                    </Button>
                                                </div>
                                            </div>
                                        }
                                        {!this.state.loader && this.state.relations && this.state.relations.length > 0 &&
                                            <div className="all_children_section ">
                                                {this.state.relations && this.state.relations.length > 0 && this.state.relations.map((relation, index) => (
                                                    <div className="allChildrenUpr 122">
                                                        <div key={index} className="all_children">
                                                            <div className="all_family_left">
                                                                <label>{relation.given_name.charAt(0).toUpperCase() + relation.family_name.charAt(0).toUpperCase()}</label>
                                                            </div>
                                                            <div className="all_relative_rght">
                                                                <label>{relation.given_name + " " + relation.family_name}</label>
                                                                {relation.optional_relation && relation.optional_relation != "" && relation.optional_relation == "In-law" &&
                                                                    <p>{relation.relation + " " + relation.optional_relation}</p>
                                                                }
                                                                {relation.optional_relation && relation.optional_relation != "" && relation.optional_relation != "In-law" &&
                                                                    <p>{relation.optional_relation + " " + relation.relation}</p>
                                                                }
                                                                {relation.optional_relation && relation.optional_relation == "" &&
                                                                    <p>{relation.relation}</p>
                                                                }
                                                                {!relation.optional_relation &&
                                                                    <p>{relation.relation}</p>
                                                                }
                                                            </div>
                                                            <div className="all_children_close editInfoUpr 12">
                                                                {/* For Wating icon */}
                                                                {relation.relation_status != 'approved_relations' &&
                                                                    <a> <img src={url.family_one_image_url + 'Grouptm161.png'} alt="" title="" className='pending-clock' /></a>
                                                                }
                                                                {/* For Wating icon */}

                                                                {relation.relation_status == "pending_invitation" &&
                                                                    <a
                                                                        onClick={() => { this.handleColonClick(relation, index, 'no') }}
                                                                    >:</a>
                                                                }
                                                                {relation.relation_status != "pending_invitation" &&
                                                                    <a
                                                                        onClick={() => { this.toggleShowColonOptions(index) }}
                                                                    // onClick={() => { this.handleColonClick(relation, index, 'no') }}
                                                                    ><span>:</span>
                                                                        {(this.state.showColonOptions === index) && this.state.showOptions && <ul className="editInfo">
                                                                            <li onClick={() => { this.handleClickEditInfo(relation, index, 'no', false) }}>
                                                                                <a>Edit info</a>
                                                                            </li>
                                                                            {relation?.relation_status !== "pending" &&
                                                                                <li onClick={() => { this.selectChildren(relation) }}>
                                                                                    <a>Add Children</a>
                                                                                </li>
                                                                            }
                                                                            <li onClick={() => { this.handleClickEditInfo(relation, index, 'no', true) }}>
                                                                                <a>Delete</a>
                                                                            </li>
                                                                        </ul>}
                                                                    </a>
                                                                }
                                                            </div>

                                                        </div>
                                                        {this.state.child_data && this.state.child_data.length > 0 && this.state.child_data.filter(item => (item.parent_id && this.checkChildParentConnection(relation.relative_id, item)) && (item.relation_with_child !== "parent")).map((child, index) => (
                                                            <div key={child.child_id} className="all_children all_childrenChild">
                                                                <div className="all_children_left"><label>{initials(child.child_name.toUpperCase()).substring(0, 2)}</label></div>

                                                                <div className="all_children_rght">
                                                                    <label>{child.child_name} {child.parent_name ? "(" + child.parent_name + ")" : ""}</label>
                                                                    <p>{this.GetChildType(child.birthdate_timestamp)}, {this.dateFormat(child.child_birthdate)}</p>
                                                                </div>

                                                                {/* <div className="all_children_close">
                                                                    <a onClick={() => { this.toggleShowColonOptions(index) }}>:</a>
                                                                </div> */}
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}
                                                {/* {this.state.mergingChildrenData && this.state.mergingChildrenData.length > 0 && this.state.mergingChildrenData.map((item, index) => (
                                                    <div key={item.merging_child.child_id} className="all_children">
                                                        <div className="all_children_left"><label>{initials(item.merging_child.child_name.toUpperCase()).substring(0, 2)}</label></div>

                                                        <div className="all_children_rght">
                                                            <label>{item.merging_child.child_name} {item.merging_child.parent_name ? "(" + item.merging_child.parent_name + ")" : ""}</label>
                                                            <p>{item.merging_child.type}, {this.dateFormat(item.merging_child.child_birthdate)}</p>
                                                        </div>
                                                        <div className="all_children_close">
                                                            <a
                                                                onClick={() => { this.handleColonClick(item.merging_child, index, 'yes') }}
                                                            >:</a>
                                                        </div>
                                                    </div>
                                                ))} */}
                                            </div>
                                        }
                                        {!this.state.loader && this.state.relations && this.state.relations.length > 0 &&
                                            <div className="your_odds">
                                                <Button id="add_relative_grow" size="medium" color="primary" onClick={() => { this.handleAddRelation() }} >
                                                    Add Relatives
                                                </Button>
                                            </div>
                                        }
                                    </TabPane>
                                    {/* End of Relative Tab Content */}

                                </TabContent>

                                {/* End of Tabs content */}

                                {/* Relation model */}
                                <div className="open_mail_model">
                                    <Modal isOpen={this.state.edit_delete_modal} toggle={this.toggleEditDelete} className={this.props.className + " custom_Addchildmodel"} >
                                        <ModalBody className="custom_editchildBody">
                                            <div className="editchild_content acceptDecline" style={{ postion: "relative" }}>
                                                {/* <img src={url.family_one_image_url + 'Group168.png'} onClick={() => { this.closeToggle(this, 'edit_delete_modal') }} alt="" title="" className="clearcontest_img popup_close_button" /> */}
                                                <img src={require('../../assets/images/closeChild.svg')} onClick={() => { this.closeToggle(this, 'edit_delete_modal') }} alt="" title="" className="clearcontest_img popup_close_button" />
                                                {this.state.relation_status != "approved_relations" && !this.state.isRelativeChild &&
                                                    <Button
                                                        size="medium"
                                                        style={{ marginTop: "30px" }}
                                                        color="primary"
                                                        onClick={() => { this.handleClick('edit', this.state.relation_status) }}
                                                    >
                                                        {this.state.relation_status == "pending_invitation" || this.state.child_status == "pending_invitation" || this.state.child_status == "pending" ? "Accept" : "Edit"}
                                                    </Button>
                                                }
                                                <Button
                                                    size="medium"
                                                    color="primary"
                                                    className="deleteButton closeModel childdel_btn"
                                                    onClick={() => { this.handleClick('delete', this.state.relation_status) }}
                                                >
                                                    {this.state.relation_status == "pending_invitation" || this.state.child_status == "pending_invitation" || this.state.child_status == "pending" ? "Decline" : "Delete"}
                                                </Button>
                                            </div>
                                        </ModalBody>
                                    </Modal>
                                </div>
                                {/* End of Relation model */}

                                <div className="open_mail_model">
                                    <Modal isOpen={this.state.delete_confirm_modal} toggle={this.toggleDeleteConfirm} className={this.props.className + " skilltestmodel"}>
                                        <ModalHeader style={{ borderBottom: "none" }}>
                                            <img src={url.family_one_image_url + 'Group168.png'} onClick={() => { this.closeToggle(this, 'delete_confirm_modal') }} alt="" title="" className="clearcontest_img confirm_close_button" />
                                        </ModalHeader>
                                        <ModalBody className="skilltestBody">
                                            <div className="support_model" style={{ postion: "relative" }}>
                                                <div className="skill_query">
                                                    <p style={{ fontSize: "20px" }}>{this.state.modal_message}</p>
                                                </div>
                                                <div className="skilltest_content">
                                                    <Button size="medium" color="primary" onClick={() => { this.handleYes() }}>
                                                        Yes
                                                    </Button>
                                                    <Button size="medium" color="primary" onClick={() => { this.closeToggle(this, 'delete_confirm_modal') }}>
                                                        No
                                                    </Button>
                                                </div>
                                            </div>
                                        </ModalBody>
                                    </Modal>
                                </div>

                                <div className="open_mail_model">
                                    <Modal onClosed={this.closeRelationCancel} isOpen={this.state.relation_edit_cancel} toggle={this.toggleEditRelationConfirm} className={this.props.className + " skilltestmodel"} >
                                        <ModalHeader style={{ borderBottom: "none" }}>
                                            <img src={url.family_one_image_url + 'Group168.png'} onClick={() => { this.closeToggle(this, 'relation_edit_cancel') }} alt="" title="" className="clearcontest_img confirm_close_button" />
                                        </ModalHeader>
                                        <ModalBody className="skilltestBody">
                                            <div className="support_model" style={{ postion: "relative" }}>
                                                <div className="skill_query">
                                                    <p style={{ fontSize: "20px" }}>{this.state.modal_message}</p>
                                                </div>
                                                <div className="cancel_edit">
                                                    <Button size="medium" color="primary" onClick={() => { this.closeToggle(this, 'relation_edit_cancel') }}>
                                                        Ok
                                                    </Button>

                                                </div>
                                            </div>
                                        </ModalBody>
                                    </Modal>
                                </div>

                                {/* Accepted pop up */}
                                <div>
                                    <Modal isOpen={false} toggle={this.toggleDeleteConfirm} className={this.props.className + " acceptmodel"}>
                                        <ModalHeader style={{ borderBottom: "none" }}>
                                            <img src={url.family_one_image_url + 'Group168.png'} onClick={() => { this.closeToggle(this, 'delete_confirm_modal') }} alt="" title="" className="clearaccept_img" />
                                        </ModalHeader>
                                        <ModalBody className="acceptBody">
                                            <div className="invitationAcept">
                                                <img src={require('../../assets/images/acceptYes.svg')} alt="" title="" />
                                                <label>Invitation Accepted by you</label>
                                            </div>
                                        </ModalBody>
                                    </Modal>
                                </div>
                                {/* End of Accepted pop up */}

                                {/* Declined pop up */}
                                <div>
                                    <Modal isOpen={this.state.invitation_status} toggle={this.toggleInvitation} className={this.props.className + " acceptmodel"}>
                                        <ModalHeader style={{ borderBottom: "none" }}>
                                            <img src={url.family_one_image_url + 'Group168.png'} onClick={() => { this.toggleInvitation() }} alt="" title="" className="clearaccept_img" />
                                        </ModalHeader>
                                        <ModalBody className="acceptBody">
                                            <div className="invitationAcept">
                                                {this.state.invitation_status == "accept" ? <img src={require('../../assets/images/acceptYes.svg')} alt="" title="" /> : <img src={require('../../assets/images/acceptNo.svg')} alt="" title="" />}
                                                <label>Invitation {this.state.invitation_status == "accept" ? "Accepted" : "Declined"} by you</label>
                                            </div>
                                        </ModalBody>
                                    </Modal>
                                </div>
                                {/* End of Declined pop up */}

                                {this.state.mergingChildrenData &&
                                    this.state.mergingChildrenData[this.state.selected_merge_child_index] &&
                                    this.state.mergingChildrenData[this.state.selected_merge_child_index].own_child.merge_status === "pending" &&
                                    <MergeChildPopup
                                        item={this.state.mergingChildrenData[this.state.selected_merge_child_index]}
                                        isMergeChildPopup={this.state.isMergeChildPopup}
                                        mergeChildPopUpLoader={this.state.mergeChildPopUpLoader}
                                        className={this.props.className}
                                        skip_child={this.skip_child}
                                        toggleMergeChildPopup={this.toggleMergeChildPopup}
                                        handleMergeChild={this.handleMergeChild}
                                    />
                                }
                                <Footer props={this.props} />
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}
export default family;

