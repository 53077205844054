import React, { useState, useEffect, useRef } from "react";
import VideoRecorder from "reactjs-video-recorder";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";

;

// const VideoRecordPage = (props) => {
//     console.log('VideoRecordPage props are', props)
//     useEffect(() => {
//         // Update the document title using the browser API
//         let recordButton = document.getElementsByClassName("button__Button-bmihlc-0 dexpoW");
//         console.log("recordButton", recordButton)
//         alert(recordButton)
//         // recordButton[0].style.visibilty = "hidden";


//     });
//     return (
//         <div className="App">
//             <h1>Video record</h1>

//             <div style={{ width: "100%", maxWidth: 480, height: 640 }}>
//                 <FromVideoRecorder push={props.history.push} />
//             </div>
//         </div>
//     );
// };

// const VideoPreviewPage = (props) => {

//     const handleRecordAgain = () => {
//         console.log("handleRecordAgain is");
//         props.history.push("/videoRecord1");

//     }

//     const handleSave = (videoData) => {
//         console.log("videoData is", videoData);
//     }


// };

export default class VideoPreviewPage extends React.Component {
    constructor(props) {
        super(props)
        this.videoRecorderRef = React.createRef();

    }
    componentDidMount() {
        setTimeout(() => {
            // let recordButton = document.getElementsByClassName("button__Button-bmihlc-0 dexpoW");
            // console.log("recordButton", recordButton)
            // recordButton[0].click()
            const playButton = document.querySelectorAll('.button__Button-bmihlc-0.dexpoW');
            playButton.forEach(btn => btn.click());
            console.log("this.videoRecorderRef", this.videoRecorderRef.current)
            // this.videoRecorderRef.current.turnOnCamera();
            // this.videoRecorderRef.current.hanxdleOpenVideoInput();
            // alert(recordButton)
        }, 1000);
        // setTimeout(() => {
        
    }

    handleTFunction = (hey) => {
        console.log('handleTFunction is', hey)
    }

    render() {
        return (
            <div className="bg-video">
                <div className="gerer" >
                    <div className="videoRecord ">

                        <VideoRecorder
                        ref={this.videoRecorderRef}
                            isFlipped
                            // isOnInitially
                            countdownTime={0}
                            timeLimit={10000}
                            mimeType="video/webm;codecs=vp8,opus"
                            constraints={{
                                audio: true,
                                video: {
                                    // width: { exact: 480, ideal: 480 },
                                    // height: { exact: 640, ideal: 640 },
                                    // aspectRatio: { exact: 0.7500000001, ideal: 0.7500000001 },
                                    resizeMode: "crop-and-scale"
                                }
                                // video: true
                            }}
                            onRecordingComplete={(videoBlob) => {
                                // Do something with the video...
                                console.log("videoBlob", videoBlob);
                                this.props.history.push("/videoPreview1", { videoBlob });
                            }}
                            
                            // renderActions={({
                            //     onStartRecording,
                            //     onStopRecording,
                            //     isCameraOn,
                            //     streamIsReady,
                            //     isVideoInputSupported,
                            //     isInlineRecordingSupported,
                            //     thereWasAnError,
                            //     isConnecting,
                            //     isRunningCountdown,
                            //     isReplayingVideo
                            //   }) => {
                            //     console.log({ isReplayingVideo });
                            //     if (
                            //       (!isInlineRecordingSupported && !isVideoInputSupported) ||
                            //       thereWasAnError ||
                            //       isConnecting ||
                            //       isRunningCountdown ||
                            //       isReplayingVideo
                            //     ) {
                            //       return null;
                            //     }
                        
                            //     return (
                            //       <div style={{ position: "absolute", bottom: "10%" }}>
                            //         <button className="recordButton"
                            //           onClick={() => {
                            //             if (isCameraOn && streamIsReady) {
                            //               onStartRecording();
                            //             }
                            //           }}
                            //         >
                            //           Record
                            //         </button>
                            //         <button onClick={onStopRecording}>Stop</button>
                            //       </div>
                            //     );
                            //   }}
                        
                        // showReplayControls
                        // t={handleTFunction}
                        // videoControlsList="nodownload"
                        />
                    </div>
                </div>
            </div>
        );

    }
}