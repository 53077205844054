import React, { Component } from 'react';
// import { Redirect, Route, BrowserRouter as Router, Switch, IndexRoute ,browserHistory} from 'react-router-dom';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
// import { Offline, Online } from "react-detect-offline";
import home from "../Screens/home";
import home_android from "../Screens/home/index_android";
import home_ftm from "../Screens/home/index_ftm";
import homemain from "../Screens/home/main";
import session_enter from "../Screens/home/session_enter";

import { browserName, isMobile, CustomView, isAndroid, isIOS } from 'react-device-detect';
import login from "../Screens/login";
import enterpassword from "../Screens/login/enterpassword";
import sent from "../Screens/login/sent";

//Singup routes

import create_password from "../Screens/signup/create_password";
import reset_password from "../Screens/signup/reset_password";
import address from "../Screens/signup/address";
import signup from "../Screens/signup";
import buildyourfamily from "../Screens/signup/buildyourfamily";
import addrelative from "../Screens/signup/addrelative";
import addchild from "../Screens/signup/addchild";
import buildfamily from "../Screens/signup/buildfamily";
import buildaddrelative from "../Screens/signup/buildaddrelative";
import gender from '../Screens/signup/gender';
import AddParentRelative from '../Screens/innerpages/addChildParentRelation'

import phone from "../Screens/phone";
import verification from "../Screens/phone/verification";
import phonesent from "../Screens/phone/phonesent";
import restorepassword from "../Screens/phone/restorepassword";
import restorepasswordsent from "../Screens/phone/restorepasswordsent";

import boarding from "../Screens/boarding";

import shipping_address from '../Screens/innerpages/shipping_address';
import ai_address from '../Screens/signup/ai_address';
import noconnection from "../Screens/innerpages/noconnection";
import main from "../Screens/innerpages/main";
import program_detail from "../Screens/innerpages/program_detail";
import offersdetail from "../Screens/innerpages/offersdetail";
import prize_detail from "../Screens/innerpages/prize_detail";
import notification from "../Screens/innerpages/notification";
import urgent from "../Screens/innerpages/urgent";
import story_detail from "../Screens/innerpages/story_detail";
import allwinners from "../Screens/innerpages/allwinners";
import familymembers from "../Screens/innerpages/familymembers";
import baby_registry from "../Screens/innerpages/baby_registry";


import reviewrequest from "../Screens/review_section/reviewrequest";
import uploadimg from '../Screens/review_section/uploadimg';
import upload_review from '../Screens/review_section/upload_review';
import uploaded from '../Screens/review_section/uploaded'
import winner_review from '../Screens/review_section/winner_review'

import family from "../Screens/family";

import help from "../Screens/extra/help";

import profile from '../Screens/profile/index'
import editprofile from '../Screens/profile/editprofile'
import VideoRecorderComponent from '../Screens/profile/videoRecord';
import NewVideoRecorder from '../Screens/profile/NewVideoRecord';
import NewVideoRecorder1 from '../Screens/profile/NewVideoRecord1';
import NewVideoRecorder2 from '../Screens/profile/NewVideoRecord2';
import VideoPreviewPage1 from '../Screens/profile/VideoPreviewPage';

import setting from '../Screens/profile/setting';
import change_password from '../Screens/profile/change_password';
import notification_setting from '../Screens/profile/notification_setting';
import closeMyAccount from '../Screens/profile/closeMyAccount';
import test from '../Screens/test';

import perks from '../Screens/perks/index';
import perksdetails from '../Screens/perks/perksdetails';
import perksonlinecode from '../Screens/perks/perksonlinecode';
import perksinstore from '../Screens/perks/perksinstore';

import unsubscribe from '../Screens/extra/unsubscribe';
import unsubscribe_email from '../Screens/extra/unsubscribe_email';
import terms_and_condition from '../Screens/extra/terms_and_condition';

import t_and_c from '../Screens/TermsCondition/index';
import VideoRecorderPage from '../Screens/VideoRecorderPage/index';
import VideoPreviewPage from '../Screens/VideoRecorderPage/VideoPreview';
import verify_account from '../Screens/signup/verify_account';

import feedback from "../Screens/extra/feedback";

import ErrorDialog from '../Screens/show_error';
import ChoiceBetweenRelative from '../Screens/innerpages/choicebetweenrelative';
import ExistingRelativeList from '../Screens/innerpages/existingRelativeList';

import linkSendOnEmail from '../Screens/linkSendOnEmail';
import SelectRelative from '../Screens/family/SelectRelative';
import SelectChildren from '../Screens/family/SelectChildren';

import AddChildPopup from '../Screens/innerpages/addChildPopup';
import SessionEnter from '../Screens/home/session_enter';
import PerksDetailAll from "../Screens/perks/perkdetailall"


export default class MyRouter extends Component {
   constructor(props) {
      super(props);
      this.state = {
         show_system_error: true,
         show_ftm_home: localStorage.getItem('ftm_home') && localStorage.getItem('ftm_home') == "true" ? true : false
      }
   }

   render() {
      return (
         <Router basename={'/'}>
            {/* <Online> */}
            <div className="pages_listing">
               <Switch>
                  <Route exact path="/t_and_c" component={t_and_c} />
                  <Route exact path="/" component={homemain} />
                  {this.state.show_ftm_home &&
                     < Route exact path="/home" component={home_ftm} />
                  }
                  {!this.state.show_ftm_home ?
                     (browserName == "Mobile Safari") && isIOS ?
                        <Route exact path="/home" component={home} /> : <Route exact path="/home" component={home_android} />
                     : ""
                  }
                  <Route exact path="/verify_account" component={verify_account} />
                  <Route exact path="/session_enter" component={session_enter} />
                  <Route exact path="/perksinstore" component={perksinstore} />
                  <Route exact path="/perksonlinecode" component={perksonlinecode} />
                  <Route exact path="/perks" component={perks} />
                  <Route exact path="/perksdetails" component={perksdetails} />
                  <Route exact path="/signup" component={signup} />
                  <Route exact path="/gender" component={gender} />
                  <Route exact path="/login" component={login} />
                  <Route exact path="/enterpassword" component={enterpassword} />
                  <Route exact path="/sent" component={sent} />
                  <Route exact path="/create_password" component={create_password} />
                  <Route exact path="/reset_password" component={reset_password} />
                  <Route exact path="/address" component={address} />
                  <Route exact path="/ai_address" component={ai_address} />
                  <Route exact path="/shipping_address" component={shipping_address} />
                  <Route exact path="/phone" component={phone} />
                  <Route exact path="/verification" component={verification} />
                  <Route exact path="/phonesent" component={phonesent} />
                  <Route exact path="/restorepassword" component={restorepassword} />
                  <Route exact path="/restorepasswordsent" component={restorepasswordsent} />
                  <Route exact path="/buildyourfamily" component={buildyourfamily} />
                  <Route exact path="/addrelative" component={addrelative} />
                  <Route exact path="/addChildParentelative" component={AddParentRelative} />
                  <Route exact path="/addchild" component={addchild} />
                  <Route exact path="/buildfamily" component={buildfamily} />
                  <Route exact path="/buildaddrelative" component={buildaddrelative} />
                  <Route exact path="/boarding" component={boarding} />
                  <Route exact path="/noconnection" component={noconnection} />
                  <Route exact path="/main" component={main} />   
                  <Route exact path="/program_detail" component={program_detail} />
                  <Route exact path="/session_enter" component={SessionEnter} />
                  <Route exact path="/offersdetail" component={offersdetail} />
                  <Route exact path="/perks_detail" component={PerksDetailAll}/>
                  <Route exact path="/prize_detail" component={prize_detail} />
                  <Route exact path="/story_detail" component={story_detail} />
                  <Route exact path="/familymembers" component={familymembers} />
                  <Route exact path="/notification" component={notification} />
                  <Route exact path="/winner_review" component={winner_review} />
                  <Route exact path="/urgent" component={urgent} />
                  <Route exact path="/upload_review" component={upload_review} />
                  <Route exact path="/reviewrequest" component={reviewrequest} />
                  <Route exact path="/allwinners" component={allwinners} />
                  <Route exact path="/baby_registry" component={baby_registry} />
                  <Route exact path="/family" component={family} />
                  <Route exact path="/profile" component={profile} />
                  <Route exact path="/test" component={test} />
                  <Route exact path="/help" component={help} />
                  <Route exact path="/editprofile" component={editprofile} />
                  <Route exact path="/videoRecord" component={VideoRecorderComponent} />
                  <Route exact path="/videoRecord2" component={NewVideoRecorder} />
                  <Route exact path="/videoRecord1" component={NewVideoRecorder1} />
                  <Route exact path="/videoPreview1" component={VideoPreviewPage1} />
                  <Route exact path="/videoRecord3" component={NewVideoRecorder2} />

                  <Route exact path="/VideoRecorderPage" component={VideoRecorderPage} />
                  <Route exact path="/VideoPreviewPage" component={VideoPreviewPage} />

                  <Route exact path="/setting" component={setting} />
                  <Route exact path="/uploadimg" component={uploadimg} />
                  <Route exact path="/uploaded" component={uploaded} />
                  <Route exact path="/change_password" component={change_password} />
                  <Route exact path="/notification_setting" component={notification_setting} />
                  <Route exact path="/unsubscribe" component={unsubscribe} />
                  <Route exact path="/unsubscribe_email" component={unsubscribe_email} />
                  <Route exact path="/terms_and_condition" component={terms_and_condition} />
                  <Route exact path="/closeaccount" component={closeMyAccount} />
                  <Route exact path="/feedback" component={feedback} />
                  <Route exact path="/choicebetweenrelative" component={ChoiceBetweenRelative} />
                  <Route exact path="/existingRelativeList" component={ExistingRelativeList} />
                  <Route exact path="/link-send-email" component={linkSendOnEmail} />

                  <Route exact path="/select-relative" component={SelectRelative} />
                  <Route exact path="/select-children" component={SelectChildren} />
                  <Route exact path="/addchildpopup" component={AddChildPopup} />
                  
                  <Route exact path="*" component={homemain} />
               </Switch>
            </div>

            {this.state.show_error == true &&
               <ErrorDialog />
            }
            {/* </Online>
            <Offline>
               <OfflineComponent prop={this} navigate={this.navigate} />
            </Offline> */}
         </Router>
      );
   }
};