import React from "react";
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import { Modal, ModalHeader, ModalBody, NavItem } from 'reactstrap';
import LottieLoader from '../LottieLoader';

const MergeChildPopup = (props) => (
    <div className="open_mail_model">
        {console.log("props are", props)}
        <Modal isOpen={props.isMergeChildPopup} toggle={() => props.toggleMergeChildPopup(false)} className={props.className + ' custom_Addchildmodel'} backdrop={'static'} keyboard={false}>
            <ModalBody className="custom_unknwnchildBody">
                {true && <div className="parentinvite_content unknwnchild_contentNw" style={{ postion: "relative" }}>
                    <>
                        {props.mergeChildPopUpLoader &&
                            <center>
                                <LottieLoader />
                            </center>
                        }
                        {!props.mergeChildPopUpLoader &&
                            <>
                                <div className="unknwnchild_head">
                                    {/* <img src={url.family_one_image_url + 'Group168.png'} onClick={() => { props.toggleMergeChildPopup(false) }} alt="" title="" className="clearcontest_img popup_close_button" /> */}
                                    <img src={require('../../assets/images/closeChild.svg')} onClick={() => { props.toggleMergeChildPopup(false) }} alt="" title="" className="clearcontest_img popup_close_button" />
                                    <h3 className="login_opt">It appears you have a duplicate child. Please confirm:</h3>

                                    <div className="addOneChild">
                                        {/* <p>Note: children born within 30 days of each other will be considered for de-duplication. Only show one comparison at a time, even if there are multiples. Show the closest in DOB first.</p> */}
                                        <h4>Your account</h4>
                                        <p>Child Name: {props.item.own_child.child_name}</p>
                                        <p>Child DOB: {props.item.own_child.child_birthdate}</p>
                                    </div>
                                    <div className="addOneChild">
                                        {/* <p>Note: children born within 30 days of each other will be considered for de-duplication. Only show one comparison at a time, even if there are multiples. Show the closest in DOB first.</p> */}
                                        <h4>Parent's account</h4>
                                        <p>Child Name: {props.item.merging_child.child_name}</p>
                                        <p>Child DOB: {props.item.merging_child.child_birthdate}</p>
                                    </div>
                                    <div className="addOneChild">
                                        <h4>Are these the same child?</h4>
                                    </div>
                                </div>
                                <div className="prent_submit">
                                    <Button size="medium" color="primary" onClick={() => { props.handleMergeChild("Yes", props.item) }}>
                                        Yes
                                    </Button>
                                    <Button className="prent_submitNo parent-skip" size="medium" color="primary" onClick={() => { props.handleMergeChild("No", props.item) }}>
                                        No
                                    </Button>
                                    <Button className="parent-skip" size="medium" color="primary" onClick={() => { props.skip_child() }}>
                                        Skip
                                    </Button>

                                </div>
                            </>
                        }
                    </>
                </div>}
            </ModalBody>
        </Modal>
    </div>
)

export default MergeChildPopup;