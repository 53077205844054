import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import functions from '../../assets/functions/common_functions';
import cookie from 'react-cookies';
import url from '../../assets/url';
import $ from 'jquery'
var initials = require("initials");
const Month = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec"
};
export default class buildfamily extends React.Component {
    constructor(props) {
        super(props);
        this.scrollToTopRef = React.createRef();

        let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        if (auto_id && auto_id != "") {
            if (this.props.history.action == "POP") {
                console.log("POP")
                this.props.history.goForward()
            }
        }
        else {
            this.props.history.push("/home")
        }
        this.state = {
            session_type: localStorage.getItem("session_type") ? localStorage.getItem("session_type") : "",
            child_data: localStorage.getItem("child_data") ? JSON.parse(localStorage.getItem("child_data")) : [],
            delete_confirm_modal: false,
            modal_message: "Do you want to delete this child?",
            dynamic_content: "",
            isUIReady: false
        };
        this.toggle = this.toggle.bind(this);
        this.dateFormat = this.dateFormat.bind(this);
    }
    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    componentDidMount = () => {
        let current_state = localStorage.getItem('current_state');
        if (current_state !== 'buildfamily') {
            functions.getLandingPage(this);
        }
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            console.log("dynamic_content", dynamic_content)
            this.setState({ dynamic_content: dynamic_content })
            setTimeout(() => {
                var myDiv = document.getElementById('many_child');
                console.log("myDiv", myDiv)
                if (myDiv) {
                    myDiv.scrollTop = 0;
                }
            }, 100);

        }

        let action = "User landed on buildfamily page"
        let image = 'buildfamily_page_landing.png'
        functions.logUserActivity(action, image);
        this.setState({ isUIReady: true });

    }

    scrollToTop = () => {
        if (this.scrollToTopRef.current) {
            this.scrollToTopRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.state.isUIReady && !prevState.isUIReady) || (this.state.dynamic_content !== "" && prevState.dynamic_content === "")) {
            // Perform scroll to top action
            this.scrollToTop();
        }
    }

    dateFormat = (value) => {
        let date_array = value.split("/");
        if (date_array.length > 1) {
            let year = date_array[0].substring(2, 4);
            let date = date_array[2];
            let month = date_array[1];
            let month_name = Month[month];
            let child_date = date + " " + month_name + " " + year;
            return child_date;
        }
        else {
            date_array = value.split("-");
            if (date_array.length > 1) {
                let year = date_array[0].substring(2, 4);
                let date = date_array[2];
                let month = date_array[1];
                let month_name = Month[month];
                let child_date = date + " " + month_name + " " + year;
                return child_date;
            }
        }
    }

    handleDelete = () => {
        console.log("child_index", this.state.child_index)
        this.toggleDeleteConfirm()
        let child_data = this.state.child_data
        let final_child_data = []
        let delete_child = child_data[this.state.child_index]
        let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        if (sub_id && sub_id != "") {
            let delete_final_json = {
                "action": "delete-child",
                sub_id: sub_id,
                child_id: delete_child.child_id
            }
            child_data.forEach((element, index) => {
                if (index !== this.state.child_index) {
                    final_child_data.push(element)
                }
            });
            this.setState({ child_data: final_child_data, open_edit: false })
            functions.SyncInMysql(this, delete_final_json);
            this.handleUpdate(final_child_data);
            if (final_child_data.length < 1) {
                localStorage.removeItem("child_data")
                functions.pushToRoute(this, 'buildyourfamily', 'buildfamily')
            } else {
                localStorage.setItem("child_data", JSON.stringify(final_child_data))
            }
        }
    }

    handleUpdate = (child_data) => {
        let userdata = [];
        let dynamo_userdata = { 'child_data': JSON.stringify(child_data) };
        functions.updateUserData(this, userdata, dynamo_userdata);
    }

    handleChildIndex = (index) => {
        let action = "User opened edit child popup on buildfamily page"
        let image = 'edit_child_popup_buildfamily_page.png'
        functions.logUserActivity(action, image);
        this.setState({ child_index: index });
        this.toggle()
    }

    handleEdit = () => {
        console.log("handleEdit");
        let action = "User clicked on Edit button on buildfamily page"
        let image = 'edit_button_buildfamily_page.png'
        functions.logUserActivity(action, image);
        localStorage.setItem("edit_index", this.state.child_index)
        localStorage.setItem("form_type", 'edit_child')
        functions.pushToRoute(this, "addchild", 'buildfamily');
        this.toggle()
    }

    handleAddChild = () => {
        let action = "User clicked on Add Child button on buildfamily page"
        let image = 'add_child_button_buildfamily_page.png'
        functions.logUserActivity(action, image);
        console.log("handleAddChild")
        localStorage.setItem("form_type", 'add_child')
        functions.pushToRoute(this, "addchild", 'buildfamily');
    }

    handleContinue = () => {
        let action = "User clicked on Continue button on buildfamily page"
        let image = 'continue_button_buildfamily_page.png'
        functions.logUserActivity(action, image);
        console.log("continue");

        let element = document.getElementById("signup_add_child")
        if (localStorage.getItem("session_type") && localStorage.getItem("session_type") == "signup") {
            console.log("element", element)
            element.click();
            let userdata = [];
            // add campaign id and signup status in user info
            let campaign_id = cookie.load("camp_id") ? cookie.load("camp_id") : ""
            let dynamo_userdata = { 'campaign_id': campaign_id, 'signup_status': "completed" };
            functions.updateUserData(this, userdata, dynamo_userdata);
        }

        let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
        console.log("previous_state", previous_state)
        let revert_page = localStorage.getItem("revert_page") ? localStorage.getItem("revert_page") : "";
        console.log("revert_page", revert_page)
        let perks_or_main = localStorage.getItem("perks_or_main") ? localStorage.getItem("perks_or_main") : "";
        if (previous_state == "program_detail" || revert_page == "program_detail") {
            localStorage.setItem("detail_page", 'true')
            functions.pushToRoute(this, "main", 'buildfamily')
        } else {
            if (perks_or_main == "perks") {
                localStorage.setItem("detail_page", 'false')
                functions.pushToRoute(this, "perks", 'buildfamily')
            }
            else {
                localStorage.setItem("detail_page", 'false')
                functions.pushToRoute(this, "main", 'buildfamily')
            }
        }
    }

    handleBack = () => {
        let action = "User clicked on back button on buildfamily page"
        let image = 'back_button_buildfamily_page.png'
        functions.logUserActivity(action, image);
        let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
        console.log("previous_state", previous_state)

        if (previous_state == "program_detail") {
            localStorage.setItem("detail_page", 'true')
            functions.pushToRoute(this, "main", 'buildfamily')
        }
        else {
            functions.pushToRoute(this, 'buildyourfamily', 'buildfamily')
        }
    }

    toggleDeleteConfirm = () => {
        if (this.state.delete_confirm_modal == false) {
            let action = "User opened delete confirmation popup on buildfamily page"
            let image = 'delete_confirmation_popup_buildfamily_page.png'
            functions.logUserActivity(action, image);
            this.toggle()
        }
        else {
            let action = "User closed delete confirmation popup on buildfamily page"
            let image = 'close_button_delete_confirmation_popup_buildfamily_page.png'
            functions.logUserActivity(action, image);
        }
        this.setState({
            delete_confirm_modal: !this.state.delete_confirm_modal
        });
    }

    handleYes = () => {
        this.handleDelete()
    }



    formatMinMaxDate = (date) => {
        console.log("date max", date)
        let date_month = date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
        let date_year = date.getFullYear();
        let date_date = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let final_date = date_year + "-" + date_month + "-" + date_date;
        console.log("final_date", final_date)
        return final_date;
    }

    GetChildType = (birthdate_timestamp) => {
        let min = new Date()
        let max = new Date();
        let temp = max.getDate()
        max.setMonth(max.getMonth() + 9);
        let minimum_date = this.formatMinMaxDate(min);
        let maximum_date = this.formatMinMaxDate(max);
        let min_time = new Date(minimum_date).getTime()
        let max_time = new Date(maximum_date).getTime()
        console.log("min_time", min_time)
        console.log("max_time", max_time)
        console.log("birthdate_timestamp", birthdate_timestamp)
        if (birthdate_timestamp <= max_time && birthdate_timestamp >= min_time) {
            return "Expecting"
        }
        return "Existing"
    }

    render() {
        const { dynamic_content } = this.state
        return (
            <div ref={this.scrollToTopRef} className="container">
                {dynamic_content != "" &&
                    <div className="row">
                        <div className="common_mainAddOn">
                            <div className='usrBuildSetBg'>
                                <div className="common_main resSection_main ">
                                    <div className="">
                                        <div className="sign_up">
                                            <h1>{this.state.session_type == "signup" ? "Sign Up" : ""}</h1>
                                        </div>
                                        <div className="point_benefits">
                                            <h2>{dynamic_content.static_text.buildfamily && dynamic_content.static_text.buildfamily.build_your_family ? dynamic_content.static_text.buildfamily.build_your_family.en : "Build Your Family"}</h2>
                                            <p>{dynamic_content.static_text.buildfamily && dynamic_content.static_text.buildfamily.points_and_benfit ? dynamic_content.static_text.buildfamily.points_and_benfit.en : "Text about points and benefits"} </p>
                                        </div>
                                    </div>


                                    <div className="child_info_space"></div>
                                    <div className="">
                                        <div id="many_child" className="many_childs">
                                            <div className='manyChildsInner'>
                                                {this.state.child_data && this.state.child_data.length > 0 && this.state.child_data.map((child, index) => (

                                                    <div key={child.child_id} className={`child_info 111 ${this.GetChildType(child.birthdate_timestamp) === 'Expecting' ? 'expectingSection' : 'existingSection'}`}>
                                                        <div className="child_left"><label>{initials(child.child_name.toUpperCase()).substring(0, 2)}</label></div>
                                                        <div className="child_rght">
                                                            <label>{child.child_name}</label>
                                                            <p>{this.GetChildType(child.birthdate_timestamp)}, {this.dateFormat(child.child_birthdate)}</p>
                                                            <a onClick={() => { this.handleChildIndex(index) }}>
                                                                :
                                                            </a>
                                                        </div>
                                                    </div>

                                                ))}
                                            </div>
                                            <a className="cstmChild">
                                                <div onClick={() => { this.handleAddChild() }} className="cstmChildPlus">+</div>
                                                {/* <div onClick={() => { this.handleAddChild() }} className="cstmChildTxt">Add Child</div> */}
                                            </a>

                                        </div>
                                    </div>

                                    <div className="child_count">
                                        <div className="addbuild_submit add_child_continue">
                                            <Button size="medium" color="primary" onClick={() => { this.handleContinue() }}>
                                                {dynamic_content.static_text.buildfamily && dynamic_content.static_text.buildfamily.continue ? dynamic_content.static_text.buildfamily.continue.en : "Continue"}
                                            </Button>
                                        </div>
                                        <div id="signup_add_child"></div>
                                    </div>

                                    <div className="open_mail_model">
                                        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className + " custom_Addchildmodel"}>
                                            {/* <ModalHeader close={closeRelation}></ModalHeader> */}
                                            <ModalBody className="custom_editchildBody">
                                                <div className="editchild_content" style={{ postion: "relative" }}>
                                                    <img src={require('../../assets/images/closeChild.svg')} alt="" title="" onClick={() => { this.toggle() }} className="clearcontest_img popup_close_button" />
                                                    {/* <div className="closepopup_rel"><ModalHeader close={closeAddchild}></ModalHeader></div> */}
                                                    <Button style={{ marginTop: "20px" }} size="medium" color="primary" onClick={() => { this.handleEdit() }}>
                                                        {dynamic_content.static_text.buildfamily && dynamic_content.static_text.buildfamily.edit ? dynamic_content.static_text.buildfamily.edit.en : "Edit"}
                                                    </Button>
                                                    <Button size="medium" color="primary" className="deleteButton closeModel childdel_btn 5656" onClick={() => { this.toggleDeleteConfirm() }} >
                                                        {dynamic_content.static_text.buildfamily && dynamic_content.static_text.buildfamily.delete ? dynamic_content.static_text.buildfamily.delete.en : "Delete"}
                                                    </Button>
                                                </div>
                                            </ModalBody>
                                        </Modal>
                                    </div>
                                    <div className="open_mail_model">
                                        <Modal isOpen={this.state.delete_confirm_modal} toggle={this.toggleDeleteConfirm} className={this.props.className + " skilltestmodel"}>
                                            <ModalHeader style={{ borderBottom: "none" }}>
                                                <img src={require('../../assets/images/closeChild.svg')} onClick={() => { this.toggleDeleteConfirm() }} alt="" title="" className="clearcontest_img confirm_close_button" />
                                            </ModalHeader>
                                            <ModalBody className="skilltestBody">
                                                <div className="support_model" style={{ postion: "relative" }}>
                                                    <div className="skill_query">
                                                        <p style={{ fontSize: "20px" }}>{this.state.modal_message}</p>
                                                    </div>
                                                    <div className="skilltest_content">
                                                        <Button size="medium" color="primary" onClick={() => { this.handleYes() }}>
                                                            {dynamic_content.static_text.buildfamily && dynamic_content.static_text.buildfamily.yes ? dynamic_content.static_text.buildfamily.yes.en : "Yes"}
                                                        </Button>
                                                        <Button size="medium" color="primary" onClick={() => { this.toggleDeleteConfirm() }}>
                                                            {dynamic_content.static_text.buildfamily && dynamic_content.static_text.buildfamily.no ? dynamic_content.static_text.buildfamily.no.en : "No"}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </ModalBody>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}