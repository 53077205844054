import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import cookie from 'react-cookies';
import url from '../../assets/url';
import TermsCondition from '../TermsCondition/index'
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from "react-html-parser";
import $ from 'jquery';
class babymoisturiser extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            current_program: {},
            show_t_and_c: false,
            dynamic_content: ""
        }
    }

    componentWillMount = () => {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
        }
        let self = this;
        let program_id = localStorage.getItem("program_id")

        // Set program related info
        let program = JSON.parse(localStorage.getItem("program"))
        let all_program = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : []
        let program_array = []
        if (program.short_description) {
            let terms = program.short_description
            let replace_terms = dynamic_content.static_text.uploaded ? dynamic_content.static_text.uploaded.terms_and_conditions.en : "Terms and Conditions"
            let new_terms = terms.replace("Terms and Conditions", "<span className='terms_all'>" + replace_terms + "</span>");
            program.short_description = new_terms
        }
        program_array.push(program)
        localStorage.setItem("review_logo_image", program.program_image);
        this.setState({ program_array: program_array, program_type: program.program_type })
    }

    handleTerms = (program) => {
        this.setState({ current_program: program, show_t_and_c: true });
    }

    handleCloseTerms = () => {
        this.setState({ show_t_and_c: false })
    }

    componentDidMount = () => {
        this.handleTermsClick()
    }

    handleTermsClick = () => {
        let self = this;
        $(document).on('click', '.terms_all', function () {
            let program = self.state.program_array[0]
            self.handleTerms(program)
        })
    }

    render() {
        const { dynamic_content } = this.state
        return (
            <div className="container">
                <div className="row">
                  <div className="common_mainAddOn">
                    {dynamic_content != "" &&
                        <div className="common_main detailspace_rmv">
                            {this.state.program_array && this.state.program_array.length > 0 && this.state.program_array.map((program, index) => (
                                <div key={index}>
                                    {/* start of One section */}
                                    <div className="mois_babyImg">
                                        <div className="moisback_section white_circle_div">
                                            <div className="moisback_secone"><img src={url.family_one_image_url + 'Group147.png'} alt="" title="" /></div>
                                            {/* <div className="moisback_sectwo"><img src={url.family_one_image_url + 'Group147.png')} alt="" title="" /></div>
                                <div className="moisback_secthree"><img src={url.family_one_image_url + 'Group147.png')} alt="" title="" /></div> */}
                                        </div>
                                        <div className="freebiesImges">
                                            <img src={url.image_path + program.program_image} alt="" title="" />
                                        </div>
                                        <div className="program_detail_logo">
                                            {program.logo && program.logo.map((logo1) => (
                                                <a><img src={url.image_path + logo1} alt="" title="" /></a>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="details_mois">
                                        <div className="mois_details">
                                            <a><img style={{ width: "16px" }} src={url.family_one_image_url + 'Untitled555.png'} alt="" title="" />
                                                <span>{dynamic_content.static_text.uploaded ? dynamic_content.static_text.uploaded.review.en : "Review"}</span></a>
                                        </div>
                                        <div className="mois_iner">
                                            <label>{ReactHtmlParser(program.program_title)}</label>
                                            <p>{ReactHtmlParser(program.program_subtitle)}</p>
                                        </div>
                                        <div className="descp_mois">
                                            <label>{dynamic_content.static_text.uploaded ? dynamic_content.static_text.uploaded.description.en : "Description"}</label>
                                            <p>{ReactHtmlParser(program.program_description)}</p>
                                        </div>
                                        <div className="mois_redem">
                                            <Button size="medium" color="primary">
                                                <img src={url.family_one_image_url + 'Vector2.1.png'} alt="" title="" />
                                                {dynamic_content.static_text.uploaded ? dynamic_content.static_text.uploaded.uploaded.en : "Uploaded"}
                                            </Button>
                                        </div>
                                        <div className="declaration_mois">
                                            <p>{ReactHtmlParser(program.short_description)}</p>
                                        </div>

                                    </div>
                                </div>
                            ))}
                            {this.state.show_t_and_c &&
                                <TermsCondition Back={this.handleCloseTerms.bind(this)} program={this.state.current_program} />
                            }
                            {/* End of One section */}

                        </div>
                    }
                   </div> 
                </div>
            </div>
        );
    }
}
export default babymoisturiser;

