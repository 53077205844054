import React, { useState } from "react";
import VideoRecorder from "reactjs-video-recorder";
import VideoPreview from "./VideoPreview";

const FromVideoRecorder = ({ setvideoRecoder, setBlobVideo }) => {
    console.log('FromVideoRecorder props are', { setvideoRecoder })
    const handleTFunction = (hey) => {
        console.log('handleTFunction is', hey)
    }
    return (
        <VideoRecorder
            isFlipped
            isOnInitially={true}
            countdownTime={0}
            timeLimit={10000}
            mimeType="video/webm;codecs=vp8,opus"
            constraints={{
                audio: true,
                // video: {
                //     width: { exact: 480, ideal: 480 },
                //     height: { exact: 640, ideal: 640 },
                //     aspectRatio: { exact: 0.7500000001, ideal: 0.7500000001 },
                //     resizeMode: "crop-and-scale"
                // }
                video: true
            }}
            useVideoInput={false}
            onRecordingComplete={(videoBlob) => {
                // Do something with the video...
                console.log("videoBlob", videoBlob);
                setBlobVideo(videoBlob)
                setvideoRecoder(false)

                // push("/VideoPreviewPage", { videoBlob });
            }}
            showReplayControls={false}
        // showReplayControls
        // t={handleTFunction}
        // videoControlsList="nodownload" 
        />

    );
};

// const VideoRecordPage = ({ history }) => {

//     return (

//     );
// };

export default function VideoRecorderComponent({ program, onSubmit, toggleVideoRecord }) {
    console.log("props", program)
    const [videoRecoder, setvideoRecoder] = useState(true);
    const [blobVideo, setBlobVideo] = useState(null)
    return (
        <div>
            <div className="videoRecord">
                {videoRecoder ?
                    <FromVideoRecorder setvideoRecoder={setvideoRecoder} setBlobVideo={setBlobVideo} />

                    :
                    <VideoPreview onSubmit={onSubmit} program={program} videoBlob={blobVideo} setvideoRecoder={setvideoRecoder} setBlobVideo={setBlobVideo} />

                }


            </div>

        </div>
    );
}