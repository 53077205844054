import React from 'react';
import '../../assets/css/style.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';

import relation_list from '../../assets/relation_list';
import functions from '../../assets/functions/common_functions';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import axios from 'axios';
import cookie from 'react-cookies';
import url from '../../assets/url';
import $ from 'jquery';
import LottieLoader from '../LottieLoader';
// import { Offline, Online } from "react-detect-offline";
// import OfflineComponent from '../boarding/index';
import { TimelineMax, Bounce, Linear, Power1, Elastic, Back, Sine } from "gsap/all";
import CSSPlugin from 'gsap/CSSPlugin';
const C = CSSPlugin;


export default class AddParentRelative extends React.Component {
    constructor(props) {
        super(props);
        let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        auto_id = auto_id ? auto_id : ""
        if (auto_id && auto_id != "") {
        }
        else {
            this.props.history.push("/")
        }
        this.state = {
            family_name: '',
            given_name: '',
            session_type: localStorage.getItem("session_type") ? localStorage.getItem("session_type") : "",
            email: '',
            edit_index: localStorage.getItem("edit_index") ? localStorage.getItem("edit_index") : 0,
            old_email: '',
            relations: [],
            optional_relation: "",
            optional: [],
            edit_email: false,
            relation: "",
            relative_id: '',
            name: 'country',
            relation_status: 'pending_relation',
            name_multi: [],
            user_email: '',
            dynamic_content: "",
            modal_message: "",
            loader:false,
            selectedChild:{}
        };
    }

    componentDidMount = () => {
        let current_state = localStorage.getItem('current_state');
        let selectedChild = this.props.location?.state?.stateData?.selectedChild;
        console.log("selectedChild",selectedChild)
        // if (current_state !== 'addrelative') {
        //     functions.getLandingPage(this);
        // }
        $('html, body').css('overflow', 'initial');
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            console.log("dynamic_content", dynamic_content)
            this.setState({ dynamic_content: dynamic_content });

        }
        let child_data = localStorage.getItem("child_data") ? JSON.parse(localStorage.getItem("child_data")) : []
        this.setState({ child_data })
        console.log("child_data", child_data)
        if (localStorage.getItem('parentinviteChild')) {

            let parentinviteChild = localStorage.getItem("parentinviteChild")
            let selectedChild = child_data.find(item => item.child_id === parentinviteChild)
            if (selectedChild) this.setState({ selectedChild })
        } 
        else{
            let selectedChild = this.props.location?.state?.stateData?.selectedChild
            console.log("selectedChild",selectedChild)
            let selectedChild_Data = child_data.find(item => selectedChild&& item.child_id === selectedChild.child_id)
            if (selectedChild_Data) this.setState({ selectedChild:selectedChild_Data })
        }

        let action = "User land on add relative page"
        let image = 'user_land_on_add_relative.png'
        // functions.logUserActivity(action, image)
        let relations = []
        let optional = []
        relation_list[0].forEach((element, index) => {
            relations.push({ "name": element })
        })
        relation_list[1].forEach((element, index) => {
            optional.push({ "name": element })
        })
        this.setState({ relations: relations, optional: optional })

        let T1 = new TimelineMax();
        T1.fromTo('.add_relative_page', 1, { opacity: 0 }, { opacity: 1 })
        this.handleCircleGrowEffect();

    };

    handleContinue = () => {
        let self = this
        let action = "User clicked on add of add chil pareent as relative page";
        let user_action = "User got error on signup/addrelative file handleContinue function";
        let image = 'user_got_error_signup-addrealtive.png'
        functions.logUserActivity(action, image)
        let user_email = localStorage.getItem("email");
        console.log("here")
        this.setState({ user_email: user_email, loader: true })
        if (this.state.given_name == '' || this.state.family_name == '' || this.state.email == '') {
            let error_body = url.dynamic_content.error_message.all_fields_required.en
            functions.logUserError(user_action, error_body);
            this.setState({ show_error_message: url.dynamic_content.error_message.all_fields_required.en, loader: false });
        }
        else if (this.state.given_name.length < 2) {
            let error_body = url.dynamic_content.error_message.first_name_length.en
            functions.logUserError(user_action, error_body);
            this.setState({ show_error_message: url.dynamic_content.error_message.first_name_length.en, loader: false });
        }
        else if (this.state.family_name.length < 2) {
            let error_body = url.dynamic_content.error_message.last_name_length.en
            functions.logUserError(user_action, error_body);
            this.setState({ show_error_message: url.dynamic_content.error_message.last_name_length.en, loader: false });
        }
        else if (this.state.email.length < 8) {
            let error_body = url.dynamic_content.error_message.email_length.en
            functions.logUserError(user_action, error_body);
            this.setState({ show_error_message: url.dynamic_content.error_message.email_length.en, loader: false });
        }
        else if (
            !this.state.email.match(/^([a-zA-Z0-9_.-]+@([\w-]+\.)+[\w-]{2,10})?$/)
        ) {
            let error_body = url.dynamic_content.error_message.invalid_email.en
            functions.logUserError(user_action, error_body);
            this.setState({ show_error_message: url.dynamic_content.error_message.invalid_email.en, loader: false });
        }
        else if (
            this.state.relation == ""
        ) {
            let error_body = url.dynamic_content.error_message.select_relation.en
            functions.logUserError(user_action, error_body);
            this.setState({ show_error_message: url.dynamic_content.error_message.select_relation.en, loader: false });
        }
        else if (this.state.email == user_email) {
            let error_body = url.dynamic_content.error_message.cant_add_yourself.en
            functions.logUserError(user_action, error_body);
            this.setState({ show_error_message: url.dynamic_content.error_message.cant_add_yourself.en, loader: false });
        }
        else {
            console.log("check relation")
            let add_relation = true;
            let relation_data = localStorage.getItem("relation_data") ? JSON.parse(localStorage.getItem("relation_data")) : [];
            relation_data.forEach((element, index) => {
                console.log("element.email", element.email);
                console.log("this.state.email", this.state.email);
                console.log("this.state.old_email", this.state.old_email);
                // if (element.email == this.state.email && element.email != this.state.old_email) {
                //     let error_body = url.dynamic_content.error_message.relative_already_exist.en
                //     functions.logUserError(user_action, error_body);
                //     this.setState({ show_error_message: url.dynamic_content.error_message.relative_already_exist.en, loader: false });
                //     add_relation = false;
                // }
                // if (add_relation == true && index == (relation_data.length - 1)) {
                //     this.handleAddRelation()
                // }
            });
            if (add_relation == true) {
                console.log("here add relation")
                let finaljson = {
                    email: this.state.email
                }
                let old_email = this.state.old_email;
                let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id");
                if (old_email !== "") {
                    functions.CheckRelationStatus(this, old_email, sub_id, function (json_response) {
                        let data = JSON.parse(json_response).data;
                        if (data.relationAccepted) {
                            functions.ValidateEmailForBounce(this, finaljson, function (response_json1) {
                                let response1 = JSON.parse(response_json1);
                                console.log("response1", response1)
                                if (response1.data.success == 0) {
                                    self.setState({ loader: false })
                                    let body = url.dynamic_content.error_message.invalid_email.en
                                    functions.logUserError(user_action, body);
                                    self.setState({ show_error_message: url.dynamic_content.error_message.invalid_email.en, loader: false })
                                } else {
                                    self.handleAddRelation()
                                }
                            })
                        } else {
                            self.setState({ modal_message: "Relative already accepted your invitation, edit is not available for this relative", loader: false })

                            self.toggleEditRelationConfirm();
                        }

                    })
                } else {
                    functions.ValidateEmailForBounce(this, finaljson, function (response_json1) {
                        let response1 = JSON.parse(response_json1);
                        console.log("response1", response1)
                        if (response1.data.success == 0) {
                            self.setState({ loader: false })
                            let body = url.dynamic_content.error_message.invalid_email.en
                            functions.logUserError(user_action, body);
                            self.setState({ show_error_message: url.dynamic_content.error_message.invalid_email.en, loader: false })
                        } else {
                            self.handleAddRelation()
                        }
                    })
                }

            }

        }
    }

    handleAddRelation = () => {
        let self = this;
        console.log("error childadd", self.state.selectedChild)
        let data = {
            sub_id: cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id"),
            first_name: this.state.given_name,
            sender_name: localStorage.getItem("given_name") ? localStorage.getItem("given_name") : '',
            last_name: this.state.family_name,
            relation: this.state.relation,
            optional_relation: this.state.optional_relation,
            email: this.state.email,
            user_email: cookie.load("username") ? cookie.load("username") : localStorage.getItem("email"),
            relative_id: this.state.relative_id,
            relation_status: this.state.relation_status,
            email_verified: localStorage.getItem("email_verified") ? localStorage.getItem("email_verified") : "false",
            old_email: this.state.email == this.state.old_email ? '' : this.state.old_email,
            is_relation_update: 0,
            child_id: self.state.selectedChild.child_id
        }

        let data_for_storage = {
            sub_id: cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id"),
            given_name: this.state.given_name,
            sender_name: localStorage.getItem("given_name") ? localStorage.getItem("given_name") : '',
            family_name: this.state.family_name,
            relation: this.state.relation,
            optional_relation: this.state.optional_relation,
            email: this.state.email,
            relation_status: this.state.relation_status,
            old_email: this.state.email == this.state.old_email ? '' : this.state.old_email,
            is_relation_update: 0
        }
        console.log("error childadd data", data);
        let relation_data = localStorage.getItem("relation_data") ? JSON.parse(localStorage.getItem("relation_data")) : [];
        if (this.state.old_email != "") {
            if (relation_data.length > 0) {
                relation_data.forEach((element, index) => {
                    if (element.email == this.state.old_email) {
                        element.email = this.state.email;
                        element.given_name = this.state.given_name;
                        element.sender_name = localStorage.getItem("given_name") ? localStorage.getItem("given_name") : '';
                        element.family_name = this.state.family_name;
                        element.relation = this.state.relation;
                        element.optional_relation = this.state.optional_relation;
                    }
                })
            }
        }
        else {
            relation_data.push(data_for_storage);
        }

        console.log("data", data);
        axios
            .request({
                method: "POST",
                url: url.add_user_relation,
                data: JSON.stringify(data),
                headers: {
                    "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : functions.getAuthKey()
                }
            })
            .then(function (response) {
                console.log("response", response)

                if (response.data.failed && response.data.failed == 1) {
                    self.setState({ loader: false, error_message: response.data.message })
                    functions.handleErrorPopup()
                }

                if (response.data.error == 1) {
                    console.log(response.data.message)
                    self.setState({ loader: false, error_message: response.data.message})
                } else {
                    let child_data = localStorage.getItem("child_data") ? JSON.parse(localStorage.getItem("child_data")) : [];
                    child_data && child_data.length > 0 && child_data.map(item => {
                        if (item.child_id === self.state.selectedChild.child_id) {
                            item['parent_id'] = response?.data?.relativeId;
                            item['accepted_child'] = 'pending'
                        }
                    })
                    localStorage.setItem("child_data", JSON.stringify(child_data));
                    // if (add_relation_state == true) {
                    let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
                    if (previous_state == "existingRelativeList") {
                        functions.pushToRoute(self, "main", 'addChildParentelative', true);
                    }
                    // if (previous_state == "choicebetweenrelative") {
                    //     functions.pushToRoute(self, "main", 'addChildParentelative', true);
                    // }
                    let add_relation_params = {
                        action: "migrate_relation_data",
                        sub_id: cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id"),
                        relative_email: self.state.email
                    }
                    self.setState({ loader: false })
                    console.log("add_relation_params", add_relation_params)
                    functions.SyncInMysql(self, add_relation_params);
                    // }
                }
            })
            .catch(function (error) {
                self.setState({ loader: false })
                console.log("error", error)
            });

        let enter_relative_program = localStorage.getItem("enter_relative_program") && localStorage.getItem("enter_relative_program") == "true" ? true : false

        if (enter_relative_program == true && relation_data.length == 2) {
            localStorage.setItem("show_relative", "false")
            localStorage.setItem("add_relative_required", "false")
        }
        localStorage.setItem("relation_data", JSON.stringify(relation_data))
        // let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";

        // if (previous_state == "familymembers") {
        //     setTimeout(function () {
        //         functions.pushToRoute(self, "familymembers", 'addChildParentelative');
        //     }, 1000)
        // }
        // else if (enter_relative_program == true) {
        //     functions.pushToRoute(self, "buildaddrelative", 'addChildParentelative');
        // }
        // else if (previous_state == "program_detail") {
        //     setTimeout(function () {
        //         functions.pushToRoute(self, "main", 'addChildParentelative');
        //     }, 1000)
        // }
        // else if (previous_state == "family") {
        //     localStorage.setItem("family_activeTab", '1')
        //     setTimeout(function () {
        //         functions.pushToRoute(self, "family", 'addChildParentelative');
        //     }, 1000)
        // }
        // else if (previous_state == "profile") {
        //     setTimeout(function () {
        //         functions.pushToRoute(self, "profile", 'addChildParentelative');
        //     }, 1000)
        // } else 
        // if (previous_state == "choicebetweenrelative") {
        //     setTimeout(function () {
        //         functions.pushToRoute(self, "main", 'addChildParentelative', true);
        //     }, 1000)
        // }
        // else {
        //     setTimeout(function () {
        //         functions.pushToRoute(self, "buildaddrelative", 'addChildParentelative');
        //     }, 1000)
        // }

    }

    handleFirstname = (event) => {
        if (!event.target.value.match(/^[a-zA-Z-'. àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
            console.log(event.target.value)
        }
        else {
            console.log(event.target.value)
            this.setState({
                given_name: event.target.value,
                show_error_message: ''
            });
        }
    }

    handleLastname = (event) => {
        if (!event.target.value.match(/^[a-zA-Z-'. àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
            console.log(event.target.value)
        }
        else {
            console.log(event.target.value)
            this.setState({
                family_name: event.target.value,
                show_error_message: ''
            });
        }
    }

    handleBack = () => {
        let action = "User clicked on back button of add relative page"
        let image = 'user_clicked_back_button_relative_page.png'
        functions.logUserActivity(action, image)
        let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
        console.log("previous_state", previous_state)
        if (previous_state == "existingRelativeList") {
            // this.props.history.push("/choicebetweenrelative")
            functions.pushToRoute(this, "existingRelativeList", 'main');
        }

        else {
            // functions.pushToRoute(this, "choicebetweenrelative", 'addChildParentelative');
            functions.pushToRoute(this, "main", '', true);
        }
    }

    handleCircleGrowEffect = () => {
        let self = this;
        var body = document.getElementById("add_relative_form");
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        var piTwo = Math.PI * 2;

        // Viewport
        var vw = canvas.width = body.offsetWidth;
        var vh = canvas.height = body.offsetHeight;
        console.log('View Port width height', vw + ' ' + vh);

        if (document.getElementById('add_relative_continue')) {
            document.getElementById('add_relative_continue').addEventListener("click", createRipple);
        }
        window.addEventListener("resize", onResize);

        function createRipple(event) {
            setTimeout(function () {
                if (!self.state.show_error_message) {
                    console.log('event data', event);
                    // Exit if target is the canvas. That means it's still active.
                    if (event.target === canvas) return;
                    body.appendChild(canvas);

                    // Event coords
                    var x = event.clientX;
                    var y = event.clientY;

                    var dx = x < vw / 2 ? vw - x : x;
                    var dy = y < vh / 2 ? vh - y : y;
                    var radius = Math.sqrt(dx * dx + dy * dy);
                    var ripple = {
                        alpha: 0,
                        radius: 0,
                        x: vw / 2,
                        y: y
                    };
                    var tl = new TimelineMax({ onUpdate: drawRipple, onComplete: removeCanvas })
                        .to(ripple, 1.5, { alpha: 1, radius: radius })
                        .to(ripple, 0.1, { alpha: 0 }, 1.4);

                    function drawRipple() {
                        ctx.clearRect(0, 0, vw, vh);
                        ctx.beginPath();
                        ctx.arc(ripple.x, ripple.y, ripple.radius, 0, piTwo, false);
                        ctx.fillStyle = "rgba(252,241,239," + ripple.alpha + ")";
                        ctx.fill();
                    }
                }
            }, 50)
        }

        function removeCanvas() {
            if (body.contains(canvas)) {
                body.removeChild(canvas);
            }
        }
        function onResize() {
            vw = canvas.width = window.innerWidth;
            vh = canvas.height = window.innerHeight;
        }
    }

    handleClickRelation = (name, value) => {
        let action = "User clicked on " + name + " on options of add relative page"
        let image = 'user_clicked_on_' + name + '_option_of_add_relative.png'
        functions.logUserActivity(action, image)
        let existing_value = this.state[name];
        if (existing_value == value) {
            this.setState({ [name]: "" })
        } else {
            this.setState({ [name]: value })
        }
    }

    closeRelationCancel = () => {
        let self = this;
        let enter_relative_program = localStorage.getItem("enter_relative_program") && localStorage.getItem("enter_relative_program") == "true" ? true : false
        let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
        let relation_data = localStorage.getItem("relation_data") ? JSON.parse(localStorage.getItem("relation_data")) : [];
        if (relation_data.length > 0) {
            relation_data.forEach((element, index) => {
                if (element.email == this.state.old_email) {
                    element.relation_status = "approved_relations";
                }
            })
        }
        localStorage.setItem("relation_data", JSON.stringify(relation_data))
        if (previous_state == "familymembers") {
            setTimeout(function () {
                functions.pushToRoute(self, "familymembers", 'addrelative');
            }, 1000)
        }
        else if (enter_relative_program == true) {
            functions.pushToRoute(self, "buildaddrelative", 'addrelative');
        }
        else if (previous_state == "program_detail") {
            setTimeout(function () {
                functions.pushToRoute(self, "main", 'addrelative');
            }, 1000)
        }
        else if (previous_state == "family") {
            localStorage.setItem("family_activeTab", '2')
            setTimeout(function () {
                functions.pushToRoute(self, "family", 'addrelative');
            }, 1000)
        }
        else if (previous_state == "profile") {
            setTimeout(function () {
                functions.pushToRoute(self, "profile", 'addrelative');
            }, 1000)
        }
        else {
            setTimeout(function () {
                functions.pushToRoute(self, "buildaddrelative", 'addrelative');
            }, 1000)
        }
    }

    closeRelationOption = () => {
        this.setState({ relationSelect: false })
    }

    openRelationOption = () => {
        this.setState({ relationSelect: true })
    }

    toggleEditRelationConfirm = () => {
        this.setState({
            relation_edit_cancel: !this.state.relation_edit_cancel
        });
    }

    closeToggle = (value, state) => {
        let action = "User closed edit popup on buildaddrelative page"
        let image = 'close_button_edit_popup_buildaddrelative_page.png'
        functions.logUserActivity(action, image);
        value.setState({
            [state]: !value.state[state]
        })
    }

    render() {
        const { dynamic_content, selectedChild } = this.state
        return (
            <div className="container" id="add_relative_form">
                {/* <Online> */}
                {this.state.loader &&
                    <center>
                        <LottieLoader />
                    </center>
                }
                {dynamic_content != "" && !this.state.loader &&
                    <div className="row">
                       <div className="common_mainAddOn"> 
                        <div className="common_main resSection_main add_relative_page">
                            <div className="sign_backlink sign_backlinkNw">
                                <img onClick={() => { this.handleBack() }} src={require('../../assets/images/bkArorIcon.svg')}  alt="" title="" />
                            </div>
                            {/* <div className="clearlink"><img src={url.family_one_image_url + 'Group5.png'} alt="" title="" /></div> */}

                            <div className="point_benefits">
                                <h2>{selectedChild.child_name} Parent is your (expectant) relative/friend?</h2>
                                <p>{dynamic_content.static_text.addrelative && dynamic_content.static_text.addrelative.points_and_benifits ? dynamic_content.static_text.addrelative.points_and_benifits.en : "Text about points and benefits"}</p>
                            </div>

                            <div className="addrelation_form addrelation_formCstm">
                                <FormControl>
                                    <div>
                                        {/* <TextField
                                            autoComplete='off'
                                            label="First name"
                                            type="text"
                                            value={this.state.given_name}
                                            disabled={this.state.edit_email ? true : false}
                                            autoFocus={true}
                                            margin="normal"
                                            onChange={event => {
                                                this.handleFirstname(event);
                                            }}
                                            inputRef={(input) => { this.given_name = input; }}
                                            onKeyPress={event => {
                                                if (event.key) {
                                                    if (event.key === "Enter") {
                                                        this.family_name.focus()
                                                    }
                                                }
                                            }}
                                        /> */}

                                        <input
                                            autoComplete='off'
                                            placeholder="First name"
                                            type="text"
                                            value={this.state.given_name}
                                            disabled={this.state.edit_email ? true : false}
                                            autoFocus={true}
                                            margin="normal"
                                            onChange={event => {
                                                this.handleFirstname(event);
                                            }}
                                            ref={(input) => { this.given_name = input; }}
                                            onKeyPress={event => {
                                                if (event.key) {
                                                    if (event.key === "Enter") {
                                                        this.family_name.focus()
                                                    }
                                                }
                                            }}
                                        />

                                        {/* {!this.state.edit_email && this.state.given_name && this.state.given_name.length > 0 &&
                                            <img
                                                onClick={() => { this.setState({ given_name: "" }) }}
                                                src={url.family_one_image_url + 'Group168.png'}
                                                className="clearimg"
                                                alt=""
                                                title=""
                                            />
                                        } */}

                                    </div>

                                    <div>
                                        {/* <TextField
                                            autoComplete='off'
                                            label="Last name"
                                            type="text"
                                            margin="normal"
                                            disabled={this.state.edit_email ? true : false}
                                            value={this.state.family_name}
                                            inputRef={(input) => { this.family_name = input; }}
                                            onKeyPress={event => {
                                                if (event.key) {
                                                    if (event.key === "Enter") {
                                                        this.email.focus()
                                                    }
                                                }
                                            }}
                                            onChange={event => {
                                                this.handleLastname(event);
                                            }}
                                        /> */}

                                        <input
                                           autoComplete='off'
                                           placeholder='Last name'
                                           type="text"
                                           margin="normal"
                                           disabled={this.state.edit_email ? true : false}
                                           value={this.state.family_name}
                                           ref={(input) => { this.family_name = input; }}
                                           onKeyPress={event => {
                                               if (event.key) {
                                                   if (event.key === "Enter") {
                                                       this.email.focus()
                                                   }
                                               }
                                           }}
                                           onChange={event => {
                                               this.handleLastname(event);
                                           }}
                                        />

                                        {/* {!this.state.edit_email && this.state.family_name && this.state.family_name.length > 0 &&
                                            <img
                                                onClick={() => { this.setState({ family_name: "" }) }}
                                                src={url.family_one_image_url + 'Group168.png'}
                                                className="clearimg"
                                                alt=""
                                                title=""
                                            />
                                        } */}

                                    </div>

                                    <div>
                                        {/* <TextField
                                            autoComplete='off'
                                            label="Email"
                                            type="email"
                                            margin="normal"
                                            disabled={this.state.edit_email ? true : false}
                                            inputRef={(input) => { this.email = input; }}
                                            value={this.state.email}
                                            onChange={(e) => { this.setState({ email: e.target.value.toLowerCase(), show_error_message: '' }) }}
                                        /> */}

                                        <input
                                           autoComplete='off'
                                           placeholder="Email"
                                           type="email"
                                           margin="normal"
                                           disabled={this.state.edit_email ? true : false}
                                           inputRef={(input) => { this.email = input; }}
                                           value={this.state.email}
                                           onChange={(e) => { this.setState({ email: e.target.value.toLowerCase(), show_error_message: '' }) }}
                                        />

                                        {/* {!this.state.edit_email && this.state.email && this.state.email.length > 0 &&
                                            <img
                                                onClick={() => { this.setState({ email: "" }) }}
                                                src={url.family_one_image_url + 'Group168.png'}
                                                className="clearimg"
                                                alt=""
                                                title=""
                                            />
                                        } */}

                                    </div>


                                </FormControl>

                                {/* new desing */}
                                {this.state.given_name && this.state.given_name.length > 0 && this.state.family_name && this.state.family_name.length > 0 &&
                                    <div className="myChildLblSec myChildLblSecCstm">
                                    <div className="myChildLbl">
                                        {this.state.relation == "" &&
                                            <label>{this.state.given_name + " is my"} </label>
                                        }
                                        {this.state.relation && this.state.relation != "" && this.state.optional_relation == "In-law" &&
                                            <label>{this.state.given_name + " is my " + this.state.relation + " " + this.state.optional_relation} </label>
                                        }
                                        {this.state.relation && this.state.relation != "" && this.state.optional_relation != "In-law" &&
                                            <label>{this.state.given_name + " is my " + this.state.optional_relation + " " + this.state.relation} </label>
                                        }
                                    </div>

                                    <div className="myChildInput">
                                        {this.state.relation == "" &&
                                            <div>
                                                <p onClick={this.openRelationOption}>Select</p>
                                                <img src={require('../../assets/images/relSelectArow.svg')} alt="" title="" onClick={this.openRelationOption} className="selectArow" />
                                            </div>
                                        }
                                        {this.state.relation && this.state.relation != "" && this.state.optional_relation == "In-law" &&
                                            <div>
                                                <p onClick={this.openRelationOption}>{this.state.relation + " " + this.state.optional_relation} </p>
                                                <img src={require('../../assets/images/relSelectArow.svg')} alt="" title="" onClick={this.openRelationOption} className="selectArow" />
                                            </div>
                                        }
                                        {this.state.relation && this.state.relation != "" && this.state.optional_relation != "In-law" &&
                                            <div>
                                                <p onClick={this.openRelationOption}>{this.state.optional_relation + " " + this.state.relation} </p>
                                                <img src={require('../../assets/images/relSelectArow.svg')} alt="" title="" onClick={this.openRelationOption} className="selectArow" />
                                            </div>
                                        }
                                    </div>
                                    {/* <div className="myOptionBtn">
                                        {this.state.relation == "" &&
                                            <label style={{ width: "200%" }}>{this.state.given_name + " is my..."} </label>
                                        }
                                        {this.state.relation && this.state.relation != "" && this.state.optional_relation == "In-law" &&
                                            <label style={{ width: "200%" }}>{this.state.given_name + " is my " + this.state.relation + " " + this.state.optional_relation} </label>
                                        }
                                        {this.state.relation && this.state.relation != "" && this.state.optional_relation != "In-law" &&
                                            <label style={{ width: "200%" }}>{this.state.given_name + " is my " + this.state.optional_relation + " " + this.state.relation} </label>
                                        }
                                        {this.state.relations && this.state.relations.length > 0 && this.state.relations.map((element, index) => (
                                            <Button className={this.state.relation == element.name ? "relation_active" : ""} key={index} onClick={() => { this.handleClickRelation("relation", element.name) }}>{element.name}</Button>
                                        ))}
                                    </div>
                                    <div className="myOptionBtn2">
                                    
                                        <div className="myOptionLine">
                                            <label>{dynamic_content.static_text.addrelative && dynamic_content.static_text.addrelative.add_optional ? dynamic_content.static_text.addrelative.add_optional.en : "Add optional:"}</label>
                                            {this.state.optional && this.state.optional.length > 0 && this.state.optional.map((element, index) => (
                                                <Button className={this.state.optional_relation == element.name ? "opt_relation_active" : ""} key={index} onClick={() => { this.handleClickRelation("optional_relation", element.name) }}>{element.name}</Button>
                                            ))}
                                        </div>
                                    </div> */}
                                    </div>
                                }
                                {/* End of new desing */}
                                {this.state.show_error_message && this.state.show_error_message != "" &&
                                    <p className="error_message">
                                        {this.state.show_error_message}
                                    </p>
                                }
                                {this.state.given_name && this.state.given_name.length > 0 && this.state.family_name && this.state.family_name.length > 0 &&
                                    <div className="signup_submit signup_submitCstm">
                                        <Button id="add_relative_continue" type="button" size="medium" color="primary" onClick={() => { this.handleContinue() }}>
                                            {"Add"}
                                        </Button>
                                    </div>
                                }
                            </div>
                            </div>
                            <div className="open_mail_model">
                            <Modal onClosed={this.closeRelationOption} isOpen={this.state.relationSelect}
                                toggle={this.closeRelationOption} className={this.props.className+" selectRelationModel selectRelationModelCstm"}
                                >
                                {/* <div className="selectRelation">
                                    <div><label>Select the relation</label></div>
                                    <div><img src={require('../../assets/images/Group5.png')}
                                        onClick={() => { this.closeRelationOption() }}
                                        alt="" title="" className="clearcontest_img confirm_close_button" />
                                    </div>
                                </div> */}

                                <ModalBody>
                                    <div className="support_model support_modelCstm" style={{ postion: "relative" }}>
                                        <div>
                                            <div className="selectRelationBtnCstm">
                                                
                                                <div className='fillTxtField'>
                                                    <div className='txtFieldLft'>
                                                        {this.state.relation == "" &&
                                                            <label>{this.state.given_name + " is my..."} </label>
                                                        }
                                                        {this.state.relation && this.state.relation != "" && this.state.optional_relation == "In-law" &&
                                                            <label>{this.state.given_name + " is my " + this.state.relation + " " + this.state.optional_relation} </label>
                                                        }
                                                        {this.state.relation && this.state.relation != "" && this.state.optional_relation != "In-law" &&
                                                            <label>{this.state.given_name + " is my " + this.state.optional_relation + " " + this.state.relation} </label>
                                                        }
                                                    </div>
                                                    <div className='txtFieldRght'><img src={require('../../assets/images/closeChild.svg')}
                                                        onClick={() => { this.closeRelationOption() }}
                                                        alt="" title="" />
                                                    </div>
                                                </div>

                                                <div className='selRelLbl'><label>Select the relation</label></div>
                                                
                                                <div className='relBtnGrp'>
                                                {this.state.relations && this.state.relations.length > 0 && this.state.relations.map((element, index) => (
                                                    <Button className={this.state.relation == element.name ? "relation_active" : ""} key={index} onClick={() => { this.handleClickRelation("relation", element.name) }}>{element.name}</Button>
                                                ))}
                                                </div>

                                            </div>

                                            <div className="addOptBtns">
                                                {/* <div className="myOptionLine"> */}
                                                <label>{dynamic_content.static_text.addrelative && dynamic_content.static_text.addrelative.add_optional ? dynamic_content.static_text.addrelative.add_optional.en : "Add optional:"}</label>
                                                <div className="addOptBtnsInr">
                                                  {this.state.optional && this.state.optional.length > 0 && this.state.optional.map((element, index) => (
                                                    <Button className={this.state.optional_relation == element.name ? "relation_active" : ""} key={index} onClick={() => { this.handleClickRelation("optional_relation", element.name) }}>{element.name}</Button>
                                                  ))}
                                                </div>
                                                {/* </div> */}
                                            </div>
                                            {/* <div className="selectOk">
                                                <Button size="medium" color="primary" onClick={() => { this.closeRelationOption() }}>
                                                    Ok
                                                </Button>
                                            </div> */}
                                        </div>
                                    </div>
                                </ModalBody>
                            </Modal>
                        </div>
                        </div>
                    </div>
                }
                {/*</Online>
                <Offline>
                  <OfflineComponent props={this} />
                </Offline> */}
            </div>
        );
    }
}