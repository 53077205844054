import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import functions from '../assets/functions/common_functions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Table } from 'reactstrap';
import url from '../assets/url'
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from "react-html-parser";
import axios from 'axios'
const Month = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec"
};

export default class Signup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            email: "",
            loader: false,
            enter_email: false,
            user_detail: [],
            program_data: [],
            entered_program_data: [],
            not_entered_program_data: [],
            pendingRelations: [],
            approvedRelations: [],
            pendingInvitation: [],
            winner_data: []
        }
        this.search = this.search.bind(this)
    }

    search = (program_id, myArray) => {
        console.log("program_id",program_id)
        console.log("myArray",myArray)
        for (var i = 0; i < myArray.length; i++) {
            console.log("myArray[i].program_id",myArray[i])
            console.log("program_id",program_id)
            if (myArray[i].program_id == program_id) {
                console.log("myArray[i]",myArray[i])
                return myArray[i];
            }
        }
    }



    handleContinue = () => {
        let self = this;
        console.log("here");
        if (this.state.email === '') {
            console.log("here");
            this.setState({ show_error_message: "Enter email." })
        }
        else if (this.state.email.length < 10) {
            console.log("here");
            this.setState({ show_error_message: "No. of character must be greater than 10" })
        }
        else if (
            !this.state.email.match(/^([a-zA-Z0-9_.-]+@([\w-]+\.)+[\w-]{2,10})?$/)
        ) {
            console.log("here");
            this.setState({ show_error_message: "Enter valid email address" })
        }
        else {
            console.log("here");
            console.log("get user profile")
            this.setState({ loader: true })
            this.setState({
                user_detail: [],
                program_data: [],
                entered_program_data: [],
                not_entered_program_data: [],
                pendingRelations: [],
                approvedRelations: [],
                pendingInvitation: [],
                winner_data: []
            })
            functions.getUserDetail(this, this.state.email, function (response_json) {
                let response = JSON.parse(response_json);
                console.log("response", response)
                if (response.catch_error) {
                    self.setState({ loader: false, show_error_message: response.message })
                }
                else if (!response.catch_error) {

                    if (response.data.error === 1) {
                        self.setState({ loader: false, show_error_message: response.data.message })
                    } else if (response.data.statusCode === 400) {
                        self.setState({ loader: false, show_error_message: response.data.message })
                    }

                    else if (response.status === 200) {
                        let user_detail = response.data.dynamo.Items[0];
                        let program_data = response.data.program_data
                        let entered_program_data = [];
                        let not_entered_program_data = [];
                        program_data.forEach(element => {
                            if (element.has_enter == 1) {
                                entered_program_data.push(element)
                            }
                            else {
                                not_entered_program_data.push(element)
                            }
                        });
                        let finaljson = {
                            "sub_id": response.data.dynamo.Items[0].auto_id
                        }
                        axios
                            .request({
                                url: url.getProgramOptinOrPendingData,
                                method: "POST",
                                data: JSON.stringify(finaljson),
                                headers:{
                                    "Authorization":localStorage.getItem("api_auth")?localStorage.getItem("api_auth"):functions.getAuthKey()
                                }
                            })
                            .then(function (response1) {
                                console.log("response", response1)
                                console.log("entered_program_data", entered_program_data)
                                if(response1.data && response1.data.length > 0){
                                    entered_program_data.forEach(element => {
                                        console.log("lement.program_id", element.program_id)
                                        console.log("response1.data", response1.data)
                                        let new_array = self.search(element.program_id, response1.data)
                                        console.log("new_array", new_array)
                                        element.entry_date = new_array.entry_date?new_array.entry_date:""
                                        element.entry_time = new_array.entry_time?new_array.entry_time:""
                                        element.pending_data = new_array.pending_data?new_array.pending_data:""
                                        element.successful_optin = new_array.successful_optin?new_array.successful_optin:""
                                    });
                                }

                                self.setState({ entered_program_data: entered_program_data })
                                let pendingRelations = response.data.relation_data.pendingRelations ? response.data.relation_data.pendingRelations : [];
                                let approvedRelations = response.data.relation_data.approvedRelations ? response.data.relation_data.approvedRelations : [];
                                let pendingInvitation = response.data.relation_data.pendingInvitation ? response.data.relation_data.pendingInvitation : [];
                                if (Object.keys(response.data.cognito).length > 0) {
                                    let length = Object.keys(response.data.cognito).length;
                                    console.log("length", length);
                                    let counter = 0;
                                    for (var key in response.data.cognito.UserAttributes) {
                                        counter = counter + 1;
                                        console.log("response.data.cognito[key].Name", response.data.cognito.UserAttributes[key].Name);
                                        if (response.data.cognito.UserAttributes[key].Name == "address") {
                                            console.log("response.data.cognito[key].Value", response.data.cognito.UserAttributes[key].Value);
                                            user_detail['address'] = JSON.parse(response.data.cognito.UserAttributes[key].Value)
                                            console.log("user_detail", user_detail);
                                            user_detail.email = response.data.cognito.Username
                                        }

                                        console.log("counter", counter);

                                        if (counter == length) {
                                            let final_user_detail = []
                                            final_user_detail.push(user_detail)
                                            console.log("final_user_detail", final_user_detail)
                                            console.log("enteredprogram", entered_program_data)
                                            self.setState({ loader: false, enter_email: true, user_detail: final_user_detail, entered_program_data: entered_program_data, not_entered_program_data: not_entered_program_data, pendingRelations: pendingRelations, approvedRelations: approvedRelations, pendingInvitation: pendingInvitation })
                                        }
                                    }
                                }
                            })
                            .catch(error => {
                                console.log("error", error)
                            });



                    }
                }
            })
        }

    }

    dateFormat = (value) => {
        let date_array = value.split("-");
        let year = date_array[0].substring(2, 4);
        let date = date_array[2];
        let month = date_array[1];
        let month_name = Month[month];
        let child_date = date + " " + month_name + " " + year;
        return child_date;
    }

    render() {
        return (
            <div className="container_test">
                {/* <div className="common_main"> */}
                <div className="sign_up">
                    <h1>Enter Email To Get User Details</h1>
                </div>
                {this.state.loader &&
                    <center>
                        <CircularProgress className="loader_class" color="secondary" />
                    </center>
                }
                {!this.state.loader &&
                    <div className="signup_form" style={{ display: "flex" }}>
                        <TextField
                            autoComplete='off'
                            autoFocus={true}
                            label="Enter your email"
                            type="email"
                            margin="normal"
                            inputRef={(input) => { this.email = input; }}
                            className="signup_first"
                            value={this.state.email ? this.state.email : ""}
                            onKeyPress={event => {
                                if (event.key === "Enter") {
                                    this.handleContinue()
                                }
                            }}
                            style={{ width: "50%" }}
                            onChange={(e) => { this.setState({ email: e.target.value, show_error_message: '' }) }}
                        />


                        <div className="signup_submit" style={{ width: "31%" }}>
                            <Button size="medium" color="primary" style={{ width: "50%" }} onClick={() => { this.handleContinue() }}>
                                Continue
                                </Button>
                        </div>

                    </div>
                }
                {this.state.show_error_message && this.state.show_error_message != "" &&
                    <p className="error_message">
                        {this.state.show_error_message}
                    </p>
                }
                <br />
                <div>
                    {this.state.user_detail && this.state.user_detail.length > 0 &&
                        <div>
                            <p>User Details</p>
                            <Table>
                                <tbody>
                                    <tr>
                                        <td>First Name </td>
                                        <td>{this.state.user_detail[0].given_name}</td>
                                    </tr>
                                    <tr>
                                        <td>Last Name </td>
                                        <td>{this.state.user_detail[0].family_name}</td>
                                    </tr>
                                    <tr>
                                        <td>Email </td>
                                        <td>{this.state.email}</td>
                                    </tr>
                                    <tr>
                                        <td>Email Verified </td>
                                        <td>{this.state.user_detail[0].email_verified ? "true" : "false"}</td>
                                    </tr>
                                    {this.state.user_detail[0].address &&
                                        <tr>
                                            <td>Address</td>
                                            <td>{this.state.user_detail[0].address.addressline1 + ", " + this.state.user_detail[0].address.city + ", " + this.state.user_detail[0].address.province + ", " + this.state.user_detail[0].address.postalcode + ", " + this.state.user_detail[0].address.country}</td>
                                        </tr>
                                    }
                                    <tr>
                                        <td>Manual Address </td>
                                        <td>{this.state.user_detail[0].manual_address ? "yes" : "no"}</td>
                                    </tr>
                                    <tr>
                                        <td>Gender </td>
                                        <td>{this.state.user_detail[0].gender}</td>
                                    </tr>
                                    <tr>
                                        <td>Date Of Birth </td>
                                        <td>{this.state.user_detail[0].birthdate}</td>
                                    </tr>
                                    <tr>
                                        <td>Phone Number </td>
                                        <td>{this.state.user_detail[0].phone_number}</td>
                                    </tr>
                                    <tr>
                                        <td>Created Date </td>
                                        <td>{this.state.user_detail[0].createdate + " " + this.state.user_detail[0].createtime}</td>
                                    </tr>
                                    <tr>
                                        <td>Updated Date </td>
                                        <td>{this.state.user_detail[0].updateddate + " " + this.state.user_detail[0].updatedtime}</td>
                                    </tr>

                                </tbody>
                            </Table>
                            <br />
                            {this.state.user_detail[0].child_data &&
                                <p>Child Details</p>
                            }
                            {this.state.user_detail[0].child_data &&
                                <Table>


                                    <tbody>
                                        <tr>
                                            <td>Child Name </td>
                                            {this.state.user_detail[0].child_data.map((element, index) => (
                                                <td key={index}>{element.child_name}</td>
                                            ))}
                                        </tr>
                                        <tr>
                                            <td>Child Gender </td>
                                            {this.state.user_detail[0].child_data.map((element, index) => (
                                                <td key={index}>{element.child_gender}</td>
                                            ))}
                                        </tr>
                                        <tr>
                                            <td>Child Birthdate </td>
                                            {this.state.user_detail[0].child_data.map((element, index) => (
                                                <td key={index}>{this.dateFormat(element.child_birthdate)}</td>
                                            ))}
                                        </tr>
                                        <tr>
                                            <td>Child Type </td>
                                            {this.state.user_detail[0].child_data.map((element, index) => (
                                                <td key={index}>{element.type}</td>
                                            ))}
                                        </tr>
                                    </tbody>
                                </Table>
                            }
                        </div>
                    }
                    <br />
                    {!this.state.loader && this.state.enter_email && this.state.approvedRelations && this.state.approvedRelations.length > 0 &&
                        <div>
                            <p>Approved Relations</p>
                            <Table>
                                <tbody>
                                    <tr>
                                        <td>Email</td>
                                        {!this.state.loader && this.state.enter_email && this.state.approvedRelations && this.state.approvedRelations.length > 0 && this.state.approvedRelations.map((element, index) => (
                                            <td key={index}>{element.email}</td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td>Relation </td>
                                        {!this.state.loader && this.state.enter_email && this.state.approvedRelations && this.state.approvedRelations.length > 0 && this.state.approvedRelations.map((element, index) => (
                                            <td key={index}>{element.relation}</td>
                                        ))}
                                    </tr>
                                </tbody>

                            </Table>
                        </div>
                    }
                    <br />
                    {!this.state.loader && this.state.enter_email && this.state.pendingRelations && this.state.pendingRelations.length > 0 &&
                        <div>
                            <p>Pending Relations</p>
                            <Table>
                                <tbody>
                                    <tr>
                                        <td>Email</td>
                                        {!this.state.loader && this.state.enter_email && this.state.pendingRelations && this.state.pendingRelations.length > 0 && this.state.pendingRelations.map((element, index) => (
                                            <td key={index}>{element.email}</td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td>Relation </td>
                                        {!this.state.loader && this.state.enter_email && this.state.pendingRelations && this.state.pendingRelations.length > 0 && this.state.pendingRelations.map((element, index) => (
                                            <td key={index}>{element.relation}</td>
                                        ))}
                                    </tr>
                                </tbody>

                            </Table>
                        </div>
                    }
                    <br />
                    {!this.state.loader && this.state.enter_email && this.state.pendingInvitation && this.state.pendingInvitation.length > 0 &&
                        <div>
                            <p>Pending Invitation</p>
                            <Table>
                                <tbody>
                                    <tr>
                                        <td>Email</td>
                                        {!this.state.loader && this.state.enter_email && this.state.pendingInvitation && this.state.pendingInvitation.length > 0 && this.state.pendingInvitation.map((element, index) => (
                                            <td key={index}>{element.email}</td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td>Relation </td>
                                        {!this.state.loader && this.state.enter_email && this.state.pendingInvitation && this.state.pendingInvitation.length > 0 && this.state.pendingInvitation.map((element, index) => (
                                            <td key={index}>{element.relation}</td>
                                        ))}
                                    </tr>
                                </tbody>

                            </Table>
                        </div>
                    }
                    <br />
                    {!this.state.loader && this.state.enter_email && this.state.entered_program_data && this.state.entered_program_data.length > 0 &&
                        <div>
                            <p>Entered Program</p>
                            <Table>

                                <tbody>
                                    <tr>
                                        <td>Program Title</td>
                                        {!this.state.loader && this.state.enter_email && this.state.entered_program_data && this.state.entered_program_data.length > 0 && this.state.entered_program_data.map((element, index) => (
                                            <td key={index}>{ReactHtmlParser(element.program_title)}</td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td>Total Points </td>
                                        {!this.state.loader && this.state.enter_email && this.state.entered_program_data && this.state.entered_program_data.length > 0 && this.state.entered_program_data.map((element, index) => (
                                            <td key={index}>{1 + element.family_activity.gain_family_points + element.social_activity.total_gain_social_share_points}</td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td>Family Share Points </td>
                                        {!this.state.loader && this.state.enter_email && this.state.entered_program_data && this.state.entered_program_data.length > 0 && this.state.entered_program_data.map((element, index) => (
                                            <td key={index}>{element.family_activity.gain_family_points == 0 ? element.family_activity.gain_family_points : element.family_activity.gain_family_points +"/"+ element.family_activity.total_family_points}</td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td>Social Share Points </td>
                                        {!this.state.loader && this.state.enter_email && this.state.entered_program_data && this.state.entered_program_data.length > 0 && this.state.entered_program_data.map((element, index) => (
                                            <td key={index}>{element.social_activity.total_gain_social_share_points}</td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td>Successful Optin </td>
                                        {!this.state.loader && this.state.enter_email && this.state.entered_program_data && this.state.entered_program_data.length > 0 && this.state.entered_program_data.map((element, index) => (
                                            <td key={index}>{element.successful_optin}</td>
                                        ))}
                                    </tr>

                                    <tr>
                                        <td>Pending Data </td>
                                        {!this.state.loader && this.state.enter_email && this.state.entered_program_data && this.state.entered_program_data.length > 0 && this.state.entered_program_data.map((element, index) => (
                                            <td key={index}>{element.pending_data?element.pending_data:""}</td>
                                        ))}
                                    </tr>

                                    <tr>
                                        <td>Entry Time </td>
                                        {!this.state.loader && this.state.enter_email && this.state.entered_program_data && this.state.entered_program_data.length > 0 && this.state.entered_program_data.map((element, index) => (
                                            <td key={index}>{element.entry_date + " "+ element.entry_time}</td>
                                        ))}
                                    </tr>
                                </tbody>

                            </Table>
                        </div>
                    }
                    <br />
                    {!this.state.loader && this.state.enter_email && this.state.not_entered_program_data && this.state.not_entered_program_data.length > 0 &&
                        <p>Eligible But Not Entered Program</p>
                    }
                    {!this.state.loader && this.state.enter_email && this.state.not_entered_program_data && this.state.not_entered_program_data.length > 0 &&
                        <div>

                            <Table>
                                <tbody>
                                    <tr>
                                        <td>Program Title</td>
                                        {!this.state.loader && this.state.enter_email && this.state.not_entered_program_data && this.state.not_entered_program_data.length > 0 && this.state.not_entered_program_data.map((element, index) => (
                                            <td key={index}>{ReactHtmlParser(element.program_title)}</td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td>Pending Total Points </td>
                                        {!this.state.loader && this.state.enter_email && this.state.not_entered_program_data && this.state.not_entered_program_data.length > 0 && this.state.not_entered_program_data.map((element, index) => (
                                            <td key={index}>{0 + element.family_activity.gain_family_points + element.social_activity.total_gain_social_share_points}</td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td>Pending Family Share Points </td>
                                        {!this.state.loader && this.state.enter_email && this.state.not_entered_program_data && this.state.not_entered_program_data.length > 0 && this.state.not_entered_program_data.map((element, index) => (
                                            <td key={index}>{element.family_activity.gain_family_points == 0 ? element.family_activity.gain_family_points : element.family_activity.gain_family_points +"/"+ element.family_activity.total_family_points}</td>
                                        ))}
                                    </tr>
                                </tbody>

                            </Table>
                        </div>
                    }
                    <br />
                </div>

            </div>
        );
    }
}


