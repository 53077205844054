import React from "react";

// import CircularProgress from "@material-ui/core/CircularProgress";
// import Countdown from "react-countdown-now";
// import Dialog from "material-ui/Dialog";
// import Drawer from "./drawer";
import url from "../../assets/url";
import cookie from "react-cookies";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import functions from "../../assets/functions/common_functions";
import LottieLoader from '../LottieLoader';
import { TimelineMax, Bounce, Linear, Power1, Elastic, Back, Sine } from "gsap/all";
import CSSPlugin from 'gsap/CSSPlugin';
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from "react-html-parser";
const C = CSSPlugin;
// {ReactHtmlParser(program.program_title)}
// import RaisedButton from "material-ui/RaisedButton";
// import session from "./function/session"
let nextid;
class terms_page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stepIndex: 0,
      finished: false,
      dynamic_content: "",
      currentStep: 0
    };
  }

  componentDidMount = () => {
    let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
    if (dynamic_content != "") {
      this.setState({ dynamic_content: dynamic_content })

    }
    let success_action = "User landed on simialc terms page"
    let image  = 'similac_terms_page_landing.png' 
    functions.logUserActivity(success_action, image);
    let TL22 = new TimelineMax();
    TL22.fromTo('.fade_it_up', 2, { opacity: 0, y: -10 }, { opacity: 1, y: 0 })
  }

  handleAnimation = () => {
    let TL = new TimelineMax();
    TL.fromTo('.phone_verification_block', 0.5, { opacity: 1 }, { opacity: 0, height: '200px' })
      .to('.program_entry_loader', 0.5, { opacity: 1 })
  }

  removeAnimationEffect = () => {
    let TL = new TimelineMax();
    TL.fromTo('.phone_verification_block', 0.5, { opacity: 0 }, { opacity: 1, height: 'auto' })
      .to('.program_entry_loader', 0.5, { opacity: 0 })
  }

  handleNext = () => {
    let success_action = "User clicked on yes button on similac terms page"
    let image = 'yes_button_similac_terms_page.png'
    functions.logUserActivity(success_action, image);
    let self = this;
    if (this.state.stepIndex == 2) {
      this.handleAnimation();
      this.setState({
        finished: true
      });
      self.props.onSubmit()
      window.open("https://www.amazon.ca/baby-reg/homepage/ref=as_li_ss_tl?ie=UTF8&linkCode=sl2&tag=family.oneinc-20&linkId=54849ede9be3837079a734eb1eec84c3&language=en_CA", "_blank")
    }

    const { stepIndex } = this.state;
    this.setState({
      stepIndex: stepIndex + 1
    });

    const { currentStep } = this.state;
    if (currentStep < 3) { // Assuming there are 3 steps (0-indexed)
      this.setState(prevState => ({
        progressPercent: (prevState.currentStep + 1) * 25,
        currentStep: prevState.currentStep + 1,
      }));
    }

  };

  handlePrev = () => {
    let success_action = "User clicked on back button on similac terms page"
    let image  = 'back_button_similac_terms_page.png' 
    functions.logUserActivity(success_action, image);
    const { stepIndex } = this.state;
    if (stepIndex > 0) {
      this.setState({ stepIndex: stepIndex - 1 });
    }
    else {
      this.props.BabyRegistryBack()
    }
  };

  render() {
    const { finished, stepIndex, dynamic_content } = this.state;
    const { progressPercent, currentStep } = this.state;
    const contentStyle = { margin: "0 16px" };
    return (
      <div >
        {dynamic_content != "" &&
          <div className="row rel_block fade_it_up">
            <div className="entrFreeBeiBg" style={{ position: "relative" }}>

              <div className="phone_verify_backlink termScreenArow">
                <img onClick={() => { this.handlePrev() }} src={require('../../assets/images/bkArorIcon.svg')} alt="" title="" />
              </div>

              <div className="contest_up">
                {/* <h1>{dynamic_content.static_text.t_and_c ? dynamic_content.static_text.t_and_c.enter_freebie.en : "Enter freebie"}</h1> */}
                <h1>Enter contest</h1>

              </div>
              <div className="contest_process1">
                {/* <p>{dynamic_content.static_text.t_and_c ? dynamic_content.static_text.t_and_c.requirement_subtitle.en : ""}</p> */}
                <p>How to complete your entry:</p>
                <div className="row">
                  <div className="nextnum_div 1313">

                    {/* <Stepper linear="false" activeStep={stepIndex}>
                      <Step className="steplabel_fstUpr">
                        <StepLabel className="steplabel_fst" />
                      </Step>
                      <Step className="steplabel_secondUpr">
                        <StepLabel
                          className={
                            this.state.stepIndex > 0 ? "steplabel_second" : ""
                          }
                        />
                      </Step>
                      <Step className="steplabel_thirdUpr">
                        <StepLabel
                          className={
                            this.state.stepIndex > 1 ? "steplabel_third" : ""
                          }
                        />
                      </Step>
                    </Stepper> */}

                    <div className='progressSurvey progressFreebie'>
                     <ul>
                      {Array.from({ length: 3 }, (_, i) => (
                        <li key={i}>
                          <img
                            src={
                              i < currentStep
                                ? require('../../assets/images/freeBieProgress.svg')
                                : require('../../assets/images/freeBieDisable.svg')
                            }
                            alt=""
                            title=""
                            style={{
                              opacity: i < currentStep ? (i + 1) / 3 : 1,
                              transition: 'opacity 0.5s ease', // Smooth transition for opacity change
                            }}
                          />
                        </li>
                      ))}
                    </ul>
                  </div> 
                  </div>
                  
                  <div className="artBoard_allparadiv">
                    {this.state.stepIndex === 0 && (
                      <div>
                        <p>Create an Amazon Baby Registry</p>
                        <p>Hint: We can only contribute funds towards a registry if you create it
                        {/* {dynamic_content.static_text.t_and_c ? dynamic_content.static_text.t_and_c.consent_description.en : ""} */}
                        </p>
                      </div>
                    )}
                    {this.state.stepIndex === 1 && (
                      <div>
                        <p>Add items to your Amazon Baby Registry</p>
                        <p>Hint: We’ll buy items on the winner’s registry up to $500. No gift cards</p>
                      </div>
                    )}
                    {this.state.stepIndex === 2 && (
                      <div>
                        <p>Invite us to your registry. Send our invitation to: amazon.baby@family.one</p>
                        <p>Hint: Use the same email address as your family.one account when sending an invite. That way we’ll know you’re a family.one Member</p>
                        <p>Once received, we’ll email back confirmation</p>
                      </div>
                    )}
                  </div>

                  {/* <div
                  className="artBoardbtn_div"
                  style={{ position: "relative", display: "flex" }}
                >
                  <div className="artBoard_btnbackdiv">
                    <Button
                      className="flatbtn"
                      onClick={() => {
                        this.handlePrev();
                      }}
                    >
                      <img
                        src={url.image_path + "prev_arrow.jpg"}
                        width="40"
                        className="btnback_arrow"
                      />
                      Back
                    </Button>
                  </div>
                  <button
                    className="artBoard_btnyes"
                    onClick={() => {
                      this.handleNext();
                    }}
                  >
                    Yes
                  </button>
                </div>  */}

                </div>
              </div>
            </div>
            <div className="program_entry_loader">
              <LottieLoader />
            </div>


            <div className="artBoard_allparadiv">
                    {this.state.stepIndex === 0 && (
                    <div className="editChildInfo_stper">
                      <Button size="medium" color="primary" onClick={() => { this.handleNext(); }} >
                        Next
                        {/* {dynamic_content.static_text.t_and_c ? dynamic_content.static_text.t_and_c.yes_button.en : "Yes"} */}
                      </Button>
                    </div>
                    )}
                    {this.state.stepIndex === 1 && (
                    <div className="editChildInfo_stper2">
                      <Button size="medium" color="primary" onClick={() => { this.handleNext(); }}>
                        Next
                      </Button>
                    </div>
                    )}
                    {this.state.stepIndex === 2 && (
                    <div className="editChildInfo_stper3">
                      <Button size="medium" color="primary" onClick={() => { this.handleNext(); }}>
                        Go To Amazon
                      </Button>
                    </div>
                    )}
              </div>

          </div>
        }
      </div>
    );
  }
}

export default terms_page;
