import React, { Fragment, useState } from 'react';

// import CircularProgress from "@material-ui/core/CircularProgress";
// import Countdown from "react-countdown-now";
// import Dialog from "material-ui/Dialog";
// import Drawer from "./drawer";
import url from "../../assets/url";
import cookie from "react-cookies";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import functions from "../../assets/functions/common_functions";
import LottieLoader from '../LottieLoader';
import { TimelineMax, Bounce, Linear, Power1, Elastic, Back, Sine } from "gsap/all";
import CSSPlugin from 'gsap/CSSPlugin';
import DateOfBirth from './components/dateofbirth'
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from "react-html-parser";
import $ from "jquery";
import uuid from 'react-uuid'
const C = CSSPlugin;
// {ReactHtmlParser(program.program_title)}
// import RaisedButton from "material-ui/RaisedButton";
// import session from "./function/session"
let nextid;

class terms_page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stepIndex: 0,
      finished: false,
      dynamic_content: "",
      minimum_date: "",
      disable_enter_button: true,
      policyme_link: "https://www.policyme.com/life/life-insurance-quotes-continued?email_ref_v0=1&birthdate=22081974&gender=F&smoker=False&uid=s8dh27g",
      birthdate: localStorage.getItem("birthdate") ? localStorage.getItem("birthdate") : "",
      maximum_date: "",
      dynamo_userdata: {},
      show_error_message: "",
      policy_unique_id: uuid(),
      parent_smoking_status: "",
      existing_health_policy: ""
    };
  }

  openTab = (url) => {
    // Create link in memory
    var a = window.document.createElement("a");
    a.target = '_blank';
    a.href = url;

    // Dispatch fake click
    var e = window.document.createEvent("MouseEvents");
    // e.initMouseEvent("click", true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    e.initMouseEvent("click", true, true, window, 0, 0, 0, 0, 0, true, false, false, false, 0, null);
    // a.dispatchEvent(e);
    a.dispatchEvent(e);
  };



  componentDidMount = () => {
    // let link = "https://www.policyme.com/life/life-insurance-quotes-continued?email_ref_v0=1&birthdate=22081974&gender=F&smoker=False&uid=s8dh27g"
    // window.open(link,'_blank');

    let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
    if (dynamic_content != "") {
      this.setState({ dynamic_content: dynamic_content })

    }
    let success_action = "User landed on simialc terms page"
    let image = 'similac_terms_page_landing.png'
    functions.logUserActivity(success_action, image);
    let TL22 = new TimelineMax();
    TL22.fromTo('.fade_it_up', 2, { opacity: 0, y: -10 }, { opacity: 1, y: 0 })
    // this.openTab(link);
  }

  componentWillMount = () => {
    console.log("this", this.props)

    let min = new Date()
    let max = new Date();
    min.setFullYear(min.getFullYear() - 101)
    max.setFullYear(max.getFullYear() - 18)
    let minimum_date = this.formatMinMaxDate(min);
    let maximum_date = this.formatMinMaxDate(max);
    let steps = []
    if (this.state.birthdate == "") {
      steps.push(
        {
          'question': "birthdate",
          'heading': "Enter Date of Birth:"
        }
      )
    }
    steps.push(
      {
        'question': "parent_smoking_status",
        'heading': "Do you smoke?"
      },
      {
        'question': "existing_health_policy",
        'heading': "Do you have an existing life or health policy?"
      }
    )
    this.setState({ steps: steps, minimum_date: minimum_date, maximum_date: maximum_date })
  }

  formatMinMaxDate = (date) => {
    let date_month = date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
    let date_year = date.getFullYear();
    let date_date = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let final_date = date_year + "-" + date_month + "-" + date_date;
    return final_date;
  }

  handleAnimation = () => {
    let TL = new TimelineMax();
    TL.fromTo('.phone_verification_block', 0.5, { opacity: 1 }, { opacity: 0, height: '200px' })
      .to('.program_entry_loader', 0.5, { opacity: 1 })
  }

  handlePrev = () => {
    let success_action = "User clicked on back button on enter policy terms page"
    functions.logUserActivity(success_action);
    // const { stepIndex } = this.state;
    // if (stepIndex > 0) {
    //   this.setState({ stepIndex: stepIndex - 1 });
    // }
    // else {
    this.props.Back()
    // }
  };

  handleSubmit = (value) => {
    let self = this
    const { stepIndex, dynamo_userdata } = this.state;
    console.log("handlesubmit", value)
    dynamo_userdata["birthdate"] = value
    this.setState({ birthdate: value, dynamo_userdata: dynamo_userdata })
    this.setState({ show_error_message: "" })
    $(".steplabel_fst").animate({ scrollTop: 200 });

    if (stepIndex == 0) {
      this.AddStep()
    } else {
      let smoking_value = ""
      let birthdate_value = value
      this.getPolicyLink(smoking_value, birthdate_value)
    }
    setTimeout(() => {
      $('.activeLine1').addClass('show');
    }, 300);
  }

  AddStep = () => {
    const { stepIndex } = this.state;
    this.setState({ stepIndex: stepIndex + 1 });
  }

  handleUpdate = (value, name, index) => {
    let success_action = "User clicked on " + value + " on " + name + " button on enter policy terms page"
    functions.logUserActivity(success_action);
    const { stepIndex, steps, dynamo_userdata } = this.state;
    console.log("stepIndex", stepIndex)
    console.log("steps", steps)
    console.log("index", index)
    dynamo_userdata[name] = value
    dynamo_userdata[name + "_entry_date"] = new Date().getTime()
    this.setState({ [name]: value, show_error_message: "", dynamo_userdata: dynamo_userdata })
    if (stepIndex == index - 1) {
      this.AddStep()
    }
    let smoking_value = ""
    let birthdate_value = ""
    if (name == "parent_smoking_status") {
      smoking_value = value
    }

    this.getPolicyLink(smoking_value, birthdate_value)
    if (index == steps.length) {
      this.setState({ disable_enter_button: false })
    }
    if (stepIndex < steps.length - 1) {
      setTimeout(() => {
        let class_name = ".activeLine" + index
        if (name == "parent_smoking_status") {
          class_name = ".activeLine_smoking"
        }
        $(class_name).addClass('show');
      }, 300);
    }
  }

  formatBirthdate = (date) => {
    let new_date = date.split("-")
    console.log("new_date", new_date)
    let [year, month, day] = new_date
    return day + "" + month + "" + year
  }

  getPolicyLink = (smoking_value, birthdate_value) => {
    const { birthdate, parent_smoking_status, policy_unique_id } = this.state;
    let gender = localStorage.getItem("gender") && localStorage.getItem("gender") == "male" ? "M" : "F"
    let given_name = localStorage.getItem("given_name") ? localStorage.getItem("given_name") : ""
    let family_name = localStorage.getItem("family_name") ? localStorage.getItem("family_name") : ""
    let email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email")
    email = email ? email : ""
    let smoking = parent_smoking_status == "yes" ? "True" : "False"
    if (smoking_value != "") {
      smoking = smoking_value == "yes" ? "True" : "False"
    }
    let final_birthdate_value = birthdate_value != "" ? birthdate_value : birthdate
    let url = "https://www.policyme.com/life/familyone-quotes?email_ref_v13=1&birthdate=" + this.formatBirthdate(final_birthdate_value) + "&gender=" + gender + "&smoker=" + smoking + "&first_name=" + given_name + "&last_name=" + family_name + "&email=" + email + "&utm_source=familyone"
    // let url = "https://www.policyme.com/life/life-insurance-quotes-continued?email_ref_v0=1&birthdate=" + this.formatBirthdate(final_birthdate_value) + "&gender=" + gender + "&smoker=" + smoking + "&uid=" + policy_unique_id
    console.log("url", url)
    this.setState({ policyme_link: url })
    return url
  }

  handleEnter = () => {
    let self = this
    let success_action = "User clicked on Enter button on enter policy terms page"
    functions.logUserActivity(success_action);
    console.log("handleEnter", this.state)
    const { stepIndex, steps, birthdate, dynamo_userdata, policy_unique_id } = this.state;
    if (stepIndex == steps.length) {
      let userdata = [{ Name: 'birthdate', Value: birthdate }];
      dynamo_userdata["policy_unique_id"] = policy_unique_id
      console.log("dynamo_userdata", dynamo_userdata)
      localStorage.setItem("birthdate", birthdate);
      this.handleAnimation();
      functions.updateUserDataPass(this, userdata, dynamo_userdata, function (err, passData) {
        if (err) {
        }
        else {
          console.log("passData", passData)
          self.props.onSubmit()
        }
      })

    } else {
      let action = "User got error on innerpages/enterpolicyterms file handleEnter function";
      let error_message = "All questions are required"
      functions.logUserError(action, error_message);
      this.setState({ show_error_message: error_message })
    }
  }

  render() {
    const { steps, stepIndex, dynamic_content } = this.state;
    return (
      <div >
        {dynamic_content != "" &&
          <div className="row rel_block fade_it_up">
            <div className="phone_verification_block" style={{ position: "relative" }}>
              <div className="phone_verify_backlink"><img onClick={() => { this.handlePrev() }} src={url.family_one_image_url + 'Group147.png'} alt="" title="" /></div>
              {/* <p>{""}</p> */}
              <div className="contest_up" style={{ paddingLeft: "16px" }}>
                <h1>{"Answer the following to enter and view your instant quote."}</h1>
              </div>
              <div>
                {/* <p>{"Answer " + steps.length + " questions to complete your entry"}</p> */}
                <div className="row">
                  <div className="policy_me_nextnum_div">
                    <Stepper className="policyme_nextnum_divIner" linear="false" activeStep={stepIndex} orientation="vertical">
                      {steps && steps.length && steps.map((steps, index) => (
                        <Step className={this.state.stepIndex > index ? steps.question == "parent_smoking_status" ? "steperActvLbl steplabel_secondUpr stp2_smoke activeLine_smoking" : "steperActvLbl steplabel_secondUpr stp2_smoke activeLine" + (index + 1) : "steplabel_secondUpr stp2_smoke activeLine" + (index + 1)}>
                          <StepLabel className="steplabel_fst" />
                          <div className="stp2_smokeRght">
                            <div><label>{steps.heading}</label></div>
                            {steps.question == "birthdate" ?
                              <div>
                                <DateOfBirth className="entrDobUsr" dateofbirth_label="Enter Date of Birth:" onDOBSubmit={this.handleSubmit} minimum_date={this.state.minimum_date} maximum_date={this.state.maximum_date} />
                              </div> :
                              <div>
                                <Button disabled={this.state.stepIndex > (index - 1) ? "" : "true"} className={this.state[steps.question] == "yes" ? "stperActvBtn" : ""} onClick={() => { this.handleUpdate("yes", steps.question, index + 1) }}>Yes</Button>
                                <Button disabled={this.state.stepIndex > (index - 1) ? "" : "true"} className={this.state[steps.question] == "no" ? "stperActvBtn" : ""} onClick={() => { this.handleUpdate("no", steps.question, index + 1) }}>No</Button>
                              </div>
                            }
                          </div>
                        </Step>
                      ))}
                    </Stepper>
                  </div>
                  {this.state.show_error_message && this.state.show_error_message != "" &&
                    <p className="error_message">
                      {this.state.show_error_message}
                    </p>
                  }
                  <a id="policy_new_tab" href="https://www.policyme.com/life/life-insurance-quotes-continued?email_ref_v0=1&birthdate=22081974&gender=F&smoker=False&uid=s8dh27g" target="_blank" />
                  <div className="contest_msg">
                    <div className="contest_opt">
                      <a onClick={() => { this.handleEnter() }} className={this.state.disable_enter_button ? "disable_link" : ""} href={this.state.policyme_link} target="_blank">
                        <Button size="medium" color="primary" >
                          {"Enter & View Quote"}
                        </Button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="program_entry_loader">
              <LottieLoader />
            </div>
          </div>
        }
      </div>
    );
  }
}

export default terms_page;
