import axios from "axios";
import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import url from '../../assets/url';
import LottieLoader from '../LottieLoader';
import functions from '../../assets/functions/common_functions';
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from "react-html-parser";
var base64 = require('base-64');

export default class PhoneSent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Partners: [],
            loader: false,
            Partner_Categories: [],
            Program_Categories: [],
            Notification_Types: [],
            blocked_notifications: {},
            already_unsubscribed: false,
            unsubscribe_category: "",
            username: "",
            sub_id: ""
        };
    }

    getUrlVars() {
        var vars = {};
        var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
            vars[key] = value;
        });
        return vars;
    }
    isValidEmail(email) {
        // Regular expression for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Test the email against the regex pattern
        return emailRegex.test(email);
      }

    componentWillMount = () => {
        let self = this
        let search = window.location.search
        console.log("search", search)
        let params = [];
        params = search.trim().split("&")
        let username = ""
        let sub_id = ""
        self.setState({ loader: true });
        let parameters = this.getUrlVars()
        if (params[1] && params[2]) {
            const emailParams = params[1].trim().split("=")[1];
            username = this.isValidEmail(emailParams) ? emailParams : base64.decode(emailParams);
            sub_id = params[2] ? params[2].trim().split("=")[1] : ""

        }
        let log_params = {
            sub_id: sub_id ? sub_id : "",
            email: username ? username : "",
            program_id: parameters.p_id ? parameters.p_id : "",
            email_category: parameters.ec ? parameters.ec : "",
            click_link: parameters.cl ? parameters.cl : ""
        }
        console.log("log_params", log_params)
        functions.callLambdaFunctionUser(self, log_params)
        if (params[0]) {
            let category = params[0].trim().split("=")
            if (category[0] == "?partner") {
                if (category[1] == "baby_registry") {
                    let notification_category = "Partners"
                    self.setState({ unsubscribe_category: "Amazon Baby Registry", notification_category: "Partners" })
                    // self.handleChange(category, username, sub_id)
                    self.getNotificationList(sub_id, username, notification_category)
                }
                else {
                    console.log("category[1]", category[1])
                    let notification_category = "Partners"
                    self.setState({ unsubscribe_category: category[1], notification_category: "Partners" })
                    // self.handleChange(category, username, sub_id)
                    self.getNotificationList(sub_id, username, notification_category)
                }
            } else if (category[0] == "?notification_type") {
                console.log("category", category)
                if (category[1] == "Family_Share") {
                    let notification_category = "Notification_Types"
                    self.setState({ unsubscribe_category: "Family_Share", notification_category: "Notification_Types" })
                    // self.handleChange(category, username, sub_id)
                    self.getNotificationList(sub_id, username, notification_category)
                }
                if (category[1] == "Family_Connect_Requests") {
                    let notification_category = "Notification_Types"
                    self.setState({ unsubscribe_category: "Family_Connect_Requests", notification_category: "Notification_Types" })
                    // self.handleChange(category, username, sub_id)
                    self.getNotificationList(sub_id, username, notification_category)
                }
                if (category[1] == "Newsletter") {
                    let notification_category = "Notification_Types"
                    self.setState({ unsubscribe_category: "Newsletter", notification_category: "Notification_Types" })
                    let userdata = [];
                    let dynamo_userdata = { 'unsubscribe': "true", 'unsubscribe_reason': "Newsletter", 'unsubscribe_user': username }
                    // localStorage.setItem("email_verified", true);
                    functions.invokeUserEmailVerify(sub_id)
                    let finalJson = {
                        "userAttributes": userdata,
                        "updateData": dynamo_userdata,
                        "username": username,
                        "sub_id": sub_id
                    }
                    axios
                        .request({
                            method: "POST",
                            url: url.update_user,
                            data: JSON.stringify(finalJson),
                            headers: {
                                "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : functions.getAuthKey()
                            }
                        })
                        .then(function (response) {

                        })

                    // self.handleChange(category, username, sub_id)
                    self.getNotificationList(sub_id, username, notification_category)
                }
            }
        }


    }

    getNotificationList = (auto_id, username, notification_category) => {
        let self = this
        functions.getNotificationsListing(this, auto_id, function (json_response) {
            let response = JSON.parse(json_response)
            console.log("response", response);
            if (response.data) {

                let Partner_temp_array = [];
                let Notification_Types_temp_array = [];
                let Partner_Categories_temp_array = [];
                let Program_Categories_temp_array = [];
                let Partners = [];
                let Notification_Types = [];
                let Partner_Categories = [];
                let Program_Categories = [];
                let blocked_notifications = {}
                let duplicate_key1 = [];
                let duplicate_key2 = [];
                let duplicate_key3 = [];
                let duplicate_key4 = [];

                response.data.Partners.forEach((element, index) => {
                    if (element.flag == "No") {
                        duplicate_key1.push(element.name)
                        let temp_object = element
                        Partner_temp_array.push(temp_object)
                        blocked_notifications.Partners = Partner_temp_array;
                        self.setState({
                            blocked_notifications: blocked_notifications
                        })
                    }
                })
                Partners = response.data.Partners
                if (!blocked_notifications.Partners) {
                    blocked_notifications.Partners = [];
                    self.setState({
                        blocked_notifications: blocked_notifications
                    })
                }
                let unique = Partners.filter((set => f => !set.has(f.name) && set.add(f.name))(new Set));
                if (duplicate_key1.length > 0) {
                    duplicate_key1.forEach((element, index) => {
                        var index = unique.findIndex(function (data) {
                            return data.name == element
                        })
                        unique[index].flag = "No";
                    })
                    Partners = unique;
                }
                self.setState({
                    Partners: Partners
                })

                response.data.Notification_Types.forEach((element, index) => {
                    if (element.flag == "No") {
                        duplicate_key2.push(element.name)
                        let temp_object = element
                        Notification_Types_temp_array.push(temp_object)
                        blocked_notifications.Notification_Types = Notification_Types_temp_array;
                        self.setState({
                            blocked_notifications: blocked_notifications
                        })
                    }
                })
                Notification_Types = response.data.Notification_Types
                if (!blocked_notifications.Notification_Types) {
                    blocked_notifications.Notification_Types = [];
                    self.setState({
                        blocked_notifications: blocked_notifications
                    })
                }
                let unique1 = Notification_Types.filter((set => f => !set.has(f.name) && set.add(f.name))(new Set));
                if (duplicate_key2.length > 0) {
                    duplicate_key2.forEach((element, index) => {
                        var index = unique1.findIndex(function (data) {
                            return data.name == element
                        })
                        unique1[index].flag = "No";
                    })
                    Notification_Types = unique1;
                }
                self.setState({
                    Notification_Types: Notification_Types
                })

                response.data.Partner_Categories.forEach((element, index) => {
                    if (element.flag == "No") {
                        duplicate_key3.push(element.name)
                        let temp_object = element
                        Partner_Categories_temp_array.push(temp_object)
                        blocked_notifications.Partner_Categories = Partner_Categories_temp_array;
                        self.setState({
                            blocked_notifications: blocked_notifications
                        })
                    }
                })
                Partner_Categories = response.data.Partner_Categories
                if (!blocked_notifications.Partner_Categories) {
                    blocked_notifications.Partner_Categories = [];
                    self.setState({
                        blocked_notifications: blocked_notifications
                    })
                }
                let unique2 = Partner_Categories.filter((set => f => !set.has(f.name) && set.add(f.name))(new Set));
                if (duplicate_key3.length > 0) {
                    duplicate_key3.forEach((element, index) => {
                        var index = unique2.findIndex(function (data) {
                            return data.name == element
                        })
                        console.log("index", index)
                        unique2[index].flag = "No";
                    })
                    Partner_Categories = unique2;
                }
                self.setState({
                    Partner_Categories: Partner_Categories
                })

                response.data.Program_Categories.forEach((element, index) => {
                    if (element.flag == "No") {
                        duplicate_key4.push(element.name)
                        let temp_object = element
                        Program_Categories_temp_array.push(temp_object)
                        blocked_notifications.Program_Categories = Program_Categories_temp_array;
                        self.setState({
                            blocked_notifications: blocked_notifications
                        })
                    }
                })
                Program_Categories = response.data.Program_Categories
                if (!blocked_notifications.Program_Categories) {
                    blocked_notifications.Program_Categories = [];
                    self.setState({
                        blocked_notifications: blocked_notifications
                    })
                }
                let unique3 = Program_Categories.filter((set => f => !set.has(f.name) && set.add(f.name))(new Set));
                if (duplicate_key4.length > 0) {
                    duplicate_key4.forEach((element, index) => {
                        var index = unique3.findIndex(function (data) {
                            return data.name == element
                        })
                        unique3[index].flag = "No";
                    })
                    Program_Categories = unique3;
                }
                self.setState({
                    Program_Categories: Program_Categories
                })
                setTimeout(() => {
                    self.handleChange(notification_category, username, auto_id)
                }, 500);
            }
        });
    }

    handleChange = (notification_category, username, sub_id) => {
        let self = this
        let blocked_notifications = this.state.blocked_notifications;

        console.log("notification_category", notification_category)
        console.log("this.state", this.state)
        let new_array = this.state[notification_category];
        console.log("new_array", new_array)
        let key_name = '';
        var index = new_array.findIndex(p => p.name == this.state.unsubscribe_category)
        // key_name = new_array[index].name
        // new_array[index].flag = 'No'
        if (new_array[index].flag == "Yes") {
            key_name = new_array[index].name
            new_array[index].flag = 'No'
            console.log("new_array", new_array)
            this.setState({ [notification_category]: new_array })

            if (Object.keys(blocked_notifications).length > 0) {
                let array_with_spaces = notification_category.replace(/_/g, ' ');
                let temp_array = blocked_notifications[notification_category] ? blocked_notifications[notification_category] : [];
                let temp_blocked_notifications = blocked_notifications[notification_category]
                console.log("temp_blocked_notifications", temp_blocked_notifications)
                if (temp_blocked_notifications && typeof temp_blocked_notifications != undefined && Object.keys(temp_blocked_notifications).length > 0) {
                    temp_blocked_notifications.forEach((element, index) => {
                        console.log("element", element)
                        console.log("element", element.name)
                        console.log("key_name", key_name)
                        if (element.name != key_name) {
                            let temp_object = {};
                            temp_object.name = key_name
                            temp_object.flag = "No";

                            if (array_with_spaces !== "Notification Types") {
                                temp_object.program_ids = new_array[index].program_ids
                            }
                            temp_array.push(temp_object);
                        }
                    });
                }
                else {
                    console.log("temp_blocked_notifications", temp_blocked_notifications)
                    temp_array = [];
                    let temp_object = {};
                    temp_object.name = key_name
                    temp_object.flag = "No";

                    if (array_with_spaces !== "Notification Types") {
                        temp_object.program_ids = new_array[index].program_ids
                    }
                    temp_array.push(temp_object);
                }
                blocked_notifications[notification_category] = temp_array
            }
            console.log("blocked_notifications", blocked_notifications)
            let userdata = [];
            let dynamo_userdata = { 'unsubscribe': "true", 'unsubscribe_reason': "Newsletter", 'unsubscribe_user': username, 'blocked_notifications': blocked_notifications };

            let finalJson = {
                "userAttributes": userdata,
                "updateData": dynamo_userdata,
                "username": username,
                "sub_id": sub_id
            }
            axios
                .request({
                    method: "POST",
                    url: url.update_user,
                    data: JSON.stringify(finalJson),
                    headers: {
                        "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : functions.getAuthKey()
                    }
                })
                .then(function (response) {
                    let final_json = {
                        'unsubscribe': "true",
                        'unsubscribe_reason': "unsubscribe_by_admin",
                        'action': 'unsubscribe_user_data',
                        'sub_id': sub_id
                    }
                    functions.SyncInMysql(self, final_json);
                    let success_action = "User got success on updateUserData function"
                    functions.logUserActivity(success_action, null);
                    self.setState({ loader: false })
                })
                .catch(function (error) {
                    let action = "User got error on updateUserData function";
                    let body = error.message
                    functions.logUserError(action, body);
                    //call function to log errors
                });
        }
        else {
            this.setState({ already_unsubscribed: true, loader: false })
        }
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    {this.state.loader &&
                        <center>
                            <LottieLoader />
                        </center>
                    }
                    {!this.state.loader &&
                        <div className="common_mainAddOn">
                            <div className="common_main">
                                {/* <div className="backlink"><img src={require('../../assets/images/Group147.png'} alt="" title="" /></div> */}
                                {/* <div className="clearlink"><img src={require('../../assets/images/Group5.png'} alt="" title="" /></div> */}
                                <div className="sign_up">
                                    {/* <h1>{dynamic_content.static_text.restorepasswordsent ? dynamic_content.static_text.phonesent.restore_email.en : "Restore Email"}</h1> */}
                                </div>

                                <div className="call_content">
                                    {/* <h1>{dynamic_content.static_text.restorepasswordsent ? dynamic_content.static_text.phonesent.sent.en : "Sent!"}</h1> */}
                                    {this.state.already_unsubscribed == true ? <p>You are already unsubscribed from <strong>{this.state.unsubscribe_category.replace(/_/g, ' ')}</strong> email list.</p> : <p>You have successfully unsubscribed from <strong>{this.state.unsubscribe_category}</strong> email list.</p>}
                                    <p>
                                        {/* {dynamic_content.static_text.restorepasswordsent ? ReactHtmlParser(dynamic_content.static_text.phonesent.message.en) : ""} */}
                                    </p>
                                </div>

                                <div className="call_link">
                                    <Button size="medium" color="primary" onClick={() => { functions.pushToRoute(this, "login", 'unsubscribe') }}>
                                        Go to Log In
                                    </Button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}