import React from "react";
import MyRouter from './router'
import cookie from 'react-cookies';
import session from './assets/functions/session';
import functions from './assets/functions/common_functions';
// import CircularProgress from '@material-ui/core/CircularProgress';
import { isMobile } from "react-device-detect";
import * as Sentry from "@sentry/react";
import { Provider } from 'react-redux';
import {configureStore} from './store';
import Button from '@material-ui/core/Button';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import axios from 'axios';
// import { Integrations } from "@sentry/react";
import url from "./assets/url";
import LottieLoader from '../src/Screens/LottieLoader';

require('dotenv').config()
var QRCode = require('qrcode.react');
var base64 = require('base-64');

// Sentry.init({
//   dsn: "https://38ff0aa96d7c42c68eef5982ade097de@o4505117900406784.ingest.sentry.io/4505124548902912",
//   integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   environment:url.staging,
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });
// To federated sign in from Facebook
class App extends React.Component {
  constructor(props) {
    console.log(process.env)
    let search = window.location.search;
    let encoded_email = localStorage.getItem("email") ? base64.encode(localStorage.getItem("email")) : "";
    console.log("encoded_email", encoded_email)
    super(props);
    this.state = {
      search_string: search ? decodeURIComponent(search) : "",
      qr_value: localStorage.getItem("email") ? "https://app2.family.one/#search_user?email=" + encoded_email : "https://app2.family.one",
      load: false
    }
  }

  

  handleLogout = () => {
    let all_local_storage = this.allLocalStorage();

    all_local_storage.forEach((element, index) => {
      for (var key in element) {
        localStorage.removeItem(key)
      }
    });
    let all_cookie = cookie.loadAll();
    let all_cookie_length = Object.keys(all_cookie).length;
    let index = 0;
    for (var key in all_cookie) {
      cookie.remove(key, { path: "/" })
      index = index + 1;
      let local_storage = this.allLocalStorage();
      if (local_storage && local_storage.length == 0) {
        if (index == all_cookie_length) {
          localStorage.setItem("logged_in", 'false')
          this.props.history.push("/")
        }
      }
      else {
        this.handleLogout()
      }
    }
  }

  getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value;
    });
    return vars;
  }
  
  toggle = () => {
    this.setState({
      open_dailog: !this.state.open_dailog
    });
  }

  componentDidMount = () => {
    let self = this
    let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
    let email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email")
    // if (email && email != "" && email != "null" && email != null && typeof email != undefined && auto_id && auto_id != "" && auto_id != "null" && auto_id != null && typeof auto_id != undefined) {

    // }
    const userAgent = navigator.userAgent;
    console.log("User Agent:", userAgent); // Log the full user agent
  
    const isFacebookInAppBrowser = /fb_iab|FBAN/i.test(userAgent); // Detect Facebook In-App Browser
    console.log("Is Facebook In-App Browser:", isFacebookInAppBrowser); // Check if detection is true
  
    if (isFacebookInAppBrowser) {
      console.log("Detected Facebook In-App Browser");
  
      // Display an overlay or message with the option to open the link
      const overlay = document.createElement('div');
      overlay.style.position = 'fixed';
      overlay.style.top = '0';
      overlay.style.left = '0';
      overlay.style.width = '100%';
      overlay.style.height = '100%';
      overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.7)';
      overlay.style.color = 'white';
      overlay.style.display = 'flex';
      overlay.style.alignItems = 'center';
      overlay.style.justifyContent = 'center';
      overlay.style.zIndex = '1000';
      overlay.innerHTML = `
        <div>
          <p>For a better experience, please open this link in your default browser.</p>
          <button id="open-in-browser">Open in Default Browser</button>
        </div>
      `;
      document.body.appendChild(overlay);
  
      document.getElementById('open-in-browser').onclick = () => {
        // Attempt to open the link in a new tab/window
        window.open(window.location.href, '_blank');
      };
    }
    let search = window.location.search;

    let parameters = this.getUrlVars()
    if(parameters && parameters.phoneTransferRequest === 'abort'){
      let transfer_data = {
        "action": parameters.phoneTransferRequest,
        "id" : parameters.id
      }
      console.log("transfer_data", transfer_data);
      let url = 'https://xy1uysx9qk.execute-api.us-east-1.amazonaws.com/prod/PhoneTransferHandler'
      console.log("transfer_data", transfer_data);
      axios.request({
                url: url,
                data: JSON.stringify(transfer_data),
                method: "post"
            }).then(function (response) {
              console.log("PhoneTransferHandler response", response);
              self.toggle();
              setTimeout(() => {
                self.setState({ open_dailog: false });
              }, 5000);
            });
    }
    if (parameters.sid) {
      cookie.save("session_id", parameters.sid, { path: "/" });
    }
    if (parameters.ref_nme) {
      cookie.save("http_referer", parameters.ref_nme, { path: "/" });
    } else {
      cookie.remove("http_referer", { path: "/" })
    }
    auto_id = auto_id ? auto_id : ""
    if (auto_id != "" && auto_id != "undefined") {
      cookie.save("auto_id", auto_id, { path: "/" });
      localStorage.setItem("auto_id", auto_id)
    }
    let session_auto_id = auto_id ? auto_id : null

    // let dynamic_content = localStorage.getItem("dynamic_content")?JSON.parse(localStorage.getItem("dynamic_content")):[]
    // if(dynamic_content && dynamic_content.length == 0){
    let location = window.location.pathname;
    let session_id = cookie.load("session_id") ? cookie.load("session_id") : ""
    if ((session_id == "" || localStorage.getItem("current_state") == "main_home") && location == "/") {
      session.checkSession(session_auto_id, '', 'true');
      console.log("https://app2.family.one/")
      self.setState({ load: true })
      functions.getDynamicContent(function (response_json) {
        functions.Loadscript()
      })
    }
    else {
      session.checkSession(session_auto_id, '', 'true');
      console.log("https://app2.family.one/")
      functions.getDynamicContent(function (response_json) {
        self.setState({ load: true })
        functions.Loadscript()
      })
      // let finaljson = {
      //   email: email,
      //   sub_id: auto_id
      // }
      // functions.checkUserExistence(self, finaljson, function (response_json) {
      //   let response = JSON.parse(response_json)
      //   console.log("res", response)
      //   if (response.data && response.data.dynamo && response.data.dynamo.Items && response.data.dynamo.Items.length == 0) {
      //     functions.pushToRoute(self, "", "app")
      //   }
      // });
    }

    window.addEventListener("storage", (function (event) {
      if (event.key == 'logout-event') {
        // ..
        localStorage.clear()
        cookie.remove('auto_id', { path: "/" })
        this.props.history.push("/")
      }
    }).bind(this));

    if (this.state.search_string !== "") {
      let id = this.state.search_string.substring(0, 8)
      if (id == "?fbclid=") {
        window.location.search = ''
      }
      else if (id == "?Contest") {
        let final_search = this.state.search_string.split("&");
        let social_shared_prog_id = final_search[0].trim().split("=").pop();
        let social_shared_refered_id = final_search[1].trim().split("=").pop();
        let social_medium = final_search[2].trim().split("=").pop();
        cookie.save("social_medium", social_medium, { path: "/" });
        cookie.save("social_shared_prog_id", social_shared_prog_id, { path: "/" })
        cookie.save("social_shared_refered_id", social_shared_refered_id, { path: "/" })
        localStorage.removeItem("given_name")
        localStorage.removeItem("family_name")
        localStorage.removeItem("email")
        localStorage.removeItem("picture")
      }
      else {
      }
    }
    const link_1 = document.createElement('link');
    link_1.href = "https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.4.1/css/swiper.css";
    link_1.rel = "stylesheet"
    document.body.appendChild(link_1);
    
  }

  render() {
    return (
      <Provider store={configureStore()}>
      <div>
        {/* <div className="onMobileShow"> */}
        {isMobile &&
          <div >
            {
              this.state.load == true ?

                <div>
                  <MyRouter prop={this} />
                </div> :
                <div className="main_site_load">
                  <center>
                    {/* <CircularProgress className="loader_class" color="secondary" /> */}
                    <LottieLoader />
                  </center>
                </div>
            }
          </div>
        }
        {!isMobile &&
          <div className="onwebShow" >
            <div className="AddsiteSidebar">
              <div className="">
                <div className="siteSidebarIner2">
                  <div >
                    {
                      this.state.load == true ?

                        <div>
                          <MyRouter prop={this} />
                        </div> :
                        <div className="main_site_load">
                          <center>
                            {/* <CircularProgress className="loader_class" color="secondary" /> */}
                            <LottieLoader />
                          </center>
                        </div>
                    }
                  </div>
                </div>
              </div>
              {
                this.state.load == true &&
                <div className="siteSidebar">
                  <h1><b>family.one</b> <br />
                    is designed for <br />
                    <strong>smartphones.</strong>
                  </h1>
                  <p>
                    You can <b>SCAN</b> this <b>QR Code</b> <br />
                    with your phone to continue <br />
                    your session there.
                  </p>
                  <div>
                    <QRCode style={{ height: "66px", width: "66px", position: "initial" }} value={this.state.qr_value} />
                  </div>
                </div>
              }
            </div>
          </div>}

      </div>
      <div className="open_mail_model">
        <Modal isOpen={this.state.open_dailog} toggle={this.toggle} className={this.props.className+" custom_Addchildmodel"} backdrop={'static'} keyboard={false}>
            <ModalBody className="ntcBody_phoneTransferRequest">
                <div className="ntvcontent_app padding_top">

                    <img src={require('./assets/images/closeChild.svg')} alt="" title="" onClick={() => { this.toggle() }} className="ntcimgClose" />
                    
                    <div className="ntc_head">
                        <h3>Request Terminated!</h3>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    </div>
    
    
    </Provider>
    );
  }
}
export default App;
