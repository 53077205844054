const INIT_STATE = {
    program_detail: localStorage.getItem("program_detail") ? JSON.parse(localStorage.getItem("program_detail")) : {},
    program_list: localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : [],
    all_program: localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : []
};
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case "ADD_PROGRAM_DETAIL":
            localStorage.setItem("program_detail", JSON.stringify(action.payload))
            return {
                ...state,
                program_detail: action.payload
            };
        case "ADD_PROGRAM_LIST":
            console.log("action.payload2", action.payload)

            return {
                ...state,
                program_list: action.payload
            };
        case "ADD_ALL_PROGRAM_LIST":
            localStorage.setItem("all_program", JSON.stringify(action.payload))
            return {
                ...state,
                all_program: action.payload
            };
        default:
            return state;
    }
};