import React from 'react';
import Button from '@material-ui/core/Button';
import '../../assets/css/style.css';
import cookie from 'react-cookies';
import functions from '../../assets/functions/common_functions';
import url from '../../assets/url';
import session from '../../assets/functions/session'

export default class VideoPreviewPage extends React.Component {
    constructor(props) {
        super(props)
        // functions.handleBackOne(this, 'setting')
        this.state = {

        }

    }

    handleRecordAgain = () => {
        console.log("handleRecordAgain is");
        this.props.history.push("/videoRecord1");

    }

    handleSave = (videoData) => {
        this.console.log("videoData is", videoData);
    }

    render() {
        return (
            <div className="App">
                <h1>Video preview</h1>

                {this.props.location.state && this.props.location.state.videoBlob && (
                    <div style={{ width: "100%", maxWidth: 480, height: 640 }}>
                        <video
                            src={window.URL.createObjectURL(this.props.location.state.videoBlob)}
                            width={480}
                            height={640}
                            loop
                            controls
                        />
                        <div className="child_info">
                            <button onClick={() => this.handleRecordAgain()}>Record Again</button>
                            <button onClick={() => this.handleSave(this.props.location.state.videoBlob)}>Save</button>
                        </div>
                    </div>
                )}
            </div>
        )
    }

}