import React, { useState, useEffect, useRef } from "react";
import VideoRecorder from "react-video-recorder";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";

const FromVideoRecorder = ({ push }) => {
    console.log('FromVideoRecorder props are', { push })
    const handleTFunction = (hey) => {
        console.log('handleTFunction is', hey)
    }
    return (
        <VideoRecorder
            isFlipped
            isOnInitially
            countdownTime={0}
            timeLimit={10000}
            mimeType="video/webm;codecs=vp8,opus"
            constraints={{
                audio: true,
                video: {
                    width: { exact: 480, ideal: 480 },
                    height: { exact: 640, ideal: 640 },
                    aspectRatio: { exact: 0.7500000001, ideal: 0.7500000001 },
                    resizeMode: "crop-and-scale"
                }
                // video: true
            }}
            onRecordingComplete={(videoBlob) => {
                // Do something with the video...
                console.log("videoBlob", videoBlob);
                push("/videoPreview", { videoBlob });
            }}
        // showReplayControls
        // t={handleTFunction}
        // videoControlsList="nodownload"
        />
    );
};

const VideoRecordPage = (props) => {
    console.log('VideoRecordPage props are', props)
    return (
        <div className="App">
            <h1>Video record</h1>

            <div style={{ width: "100%", maxWidth: 480, height: 640 }}>
                <FromVideoRecorder push={props.history.push} />
            </div>
        </div>
    );
};

const VideoPreviewPage = (props) => {

    const handleRecordAgain = () => {
        console.log("handleRecordAgain is");
        props.history.push("/videoRecord");

    }

    const handleSave = (videoData) => {
        console.log("videoData is", videoData);
    }

    return (
        <div className="App">
            <h1>Video preview</h1>

            {props.location.state && props.location.state.videoBlob && (
                <div style={{ width: "100%", maxWidth: 480, height: 640 }}>
                    <video
                        src={window.URL.createObjectURL(props.location.state.videoBlob)}
                        width={480}
                        height={640}
                        loop
                        controls
                    />
                    <div className="child_info">
                        <button onClick={() => handleRecordAgain()}>Record Again</button>
                        <button onClick={() => handleSave(props.location.state.videoBlob)}>Save</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default function VideoRecorderComponent() {
    return (
        <Router>
            <Switch>
                {/* <Redirect to="/videoRecord" exact path="/" /> */}
                <Route path="/videoRecord" component={VideoRecordPage} />
                <Route path="/videoPreview" component={VideoPreviewPage} />
            </Switch>
        </Router>
    );
}
