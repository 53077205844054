import React, { Fragment, useState } from 'react';
import '../../assets/css/style.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import functions from '../../assets/functions/common_functions';
import { isMobile } from "react-device-detect";
import DatePickerMobile from "react-mobile-datepicker";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import url from '../../assets/url';
import LottieLoader from '../LottieLoader';
import { TimelineMax, Bounce, Linear, Power1, Elastic, Back, Sine } from "gsap/all";
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from "react-html-parser";
const monthMap = {
    '1': 'Jan',
    '2': 'Feb',
    '3': 'Mar',
    '4': 'Apr',
    '5': 'May',
    '6': 'Jun',
    '7': 'Jul',
    '8': 'Aug',
    '9': 'Sep',
    '10': 'Oct',
    '11': 'Nov',
    '12': 'Dec',
};

const Month = {
    "01": "January",
    "02": "February",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    "10": "October",
    "11": "November",
    "12": "December"
};

const dateConfig = {
    'year': {
        format: 'YYYY',
        caption: 'Year',
        step: 1,
    },
    'month': {
        format: value => monthMap[value.getMonth() + 1],
        caption: 'Mon',
        step: 1,
    },
    'date': {
        format: 'DD',
        caption: 'Day',
        step: 1,
    },
};

export default class Entercontestdob extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            birthdate: new Date(),
            minimum_date: "",
            maximum_date: "",
            birthdate_selected: false,
            time: new Date(1988),
            show_date: false,
            isOpen: false,
            dynamic_content: ""
        }
    }

    formatMinMaxDate = (date) => {
        let date_month = date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
        let date_year = date.getFullYear();
        let date_date = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let final_date = date_year + "-" + date_month + "-" + date_date;
        return final_date;
    }

    componentWillMount = () => {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
        }

        let min = new Date()
        let max = new Date();
        min.setFullYear(min.getFullYear() - 100)
        let minimum_date = this.formatMinMaxDate(min);
        let maximum_date = this.formatMinMaxDate(max);
        this.setState({ minimum_date: minimum_date, maximum_date: maximum_date })
        // // let birthdate = isMobile ? this.formatedDate(min) : null;
        // let birthdate = this.formatedDate(min);
        let time = new Date()
        time.setFullYear(time.getFullYear() - 30)
        this.setState({ time: time })
        // this.setState({
        //     birthdate: birthdate
        //     // time: new Date(birthdate)
        // });
    }

    formatedDate = (date) => {
        let value = new Date(date)
        let month = value.getMonth() + 1
        let date_month = month < 10 ? "0" + month : month;
        let date_year = value.getFullYear();
        let date_date = value.getDate() < 10 ? "0" + value.getDate() : value.getDate();
        value = date_year + "-" + date_month + "-" + date_date;
        return value;
    }

    handleSubmit = () => {
        this.handleAnimation();
        let max_birth = new Date();
        max_birth.setFullYear(max_birth.getFullYear() - 19);

        let user_birth = new Date(this.state.birthdate)

        let user_timestamp = user_birth.getTime()
        let max_timestamp = max_birth.getTime()

        if (this.state.birthdate && this.state.birthdate != null && this.state.birthdate_selected == true && user_timestamp <= max_timestamp) {
            let userdata = [{ Name: 'birthdate', Value: this.state.birthdate }];
            let dynamo_userdata = { 'birthdate': this.state.birthdate };
            localStorage.setItem("birthdate", this.state.birthdate);
            functions.updateUserData(this, userdata, dynamo_userdata);
            localStorage.setItem("birthdate_required", "false");
            localStorage.setItem("show_dob", "false");
            this.props.onSubmit()
        }
        else {
            this.removeAnimationEffect();
            let action = "User got error on innerpages/entercontestdob file handleSubmit function";
            let body = url.dynamic_content.error_message.birthdate_missing.en
            functions.logUserError(action, body);
            this.setState({ show_error_message: url.dynamic_content.error_message.birthdate_missing.en })
        }
    }

    handleAnimation = () => {
        let TL = new TimelineMax();
        TL.fromTo('.phone_verification_block', 0.5, { opacity: 1 }, { opacity: 0, height: '200px' })
            .to('.program_entry_loader', 0.5, { opacity: 1 })
    }

    removeAnimationEffect = () => {
        let TL = new TimelineMax();
        TL.fromTo('.phone_verification_block', 0.5, { opacity: 0 }, { opacity: 1, height: 'auto' })
            .to('.program_entry_loader', 0.5, { opacity: 0 })
    }

    handleClickDate = () => {
        this._showDateTimePicker();
    }

    _showDateTimePicker = () => this.setState({ isOpen: true });

    _hideDateTimePicker = () => this.setState({ isOpen: false });

    handleSelect = date => {
        let success_action = "User select date in all entercontestdob page"
        let image = 'date_select_entercontestdob_page.png'
        functions.logUserActivity(success_action, image);
        this.setState({ isOpen: false });
        let month = date.getMonth() + 1
        let date_month = month < 10 ? "0" + month : month;
        let date_year = date.getFullYear();
        let date_date = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        date = date_year + "-" + date_month + "-" + date_date;
        this.setState({
            birthdate: date,
            time: new Date(date),
            show_date: true,
            show_error_message: '',
            birthdate_selected: true
        });
    };

    handleCancel = () => {
        this.setState({ isOpen: false });
    };

    dateFormat = (value) => {
        if (value) {

            let date_array = value.split("-");
            if (date_array.length > 1) {
                let year = date_array[0];
                let date = date_array[2];
                let month = date_array[1];
                let month_name = Month[month];
                let final_date = date + " " + month_name + " " + year;
                return final_date;
            }
            else {
                date_array = value.split("/");
                if (date_array.length > 1) {
                    let year = date_array[0];
                    let date = date_array[2];
                    let month = date_array[1];
                    let month_name = Month[month];
                    let final_date = date + " " + month_name + " " + year;
                    return final_date;
                }
            }
        } else {
            return value;
        }
    }

    handleFinalDate = (value) => {
        let success_action = "User select date in all entercontestdob page"
        let image = 'date_select_entercontestdob_page.png'
        functions.logUserActivity(success_action, image);
        let month = value.getMonth() + 1
        let date_month = month < 10 ? "0" + month : month;
        let date_year = value.getFullYear();
        let date_date = value.getDate() < 10 ? "0" + value.getDate() : value.getDate();
        let date = date_year + "-" + date_month + "-" + date_date;
        let timestamp = new Date(date).getTime()
        let minimum_date = new Date(this.state.minimum_date)
        let maximum_date = new Date(this.state.maximum_date)
        if (timestamp >= minimum_date.getTime() && timestamp <= maximum_date.getTime() || date == '') {
            this.setState({
                birthdate: date,
                time: new Date(date),
                show_date: true,
                show_error_message: '',
                birthdate_selected: true
            });
        }
    }

    render() {
        const { dynamic_content } = this.state
        return (
            <div className="">
                {dynamic_content != "" &&
                    <div className="row">

                        <div className="phone_verification_block" style={{ position: "relative" }}>
                            <div className="phone_verify_backlink"><img onClick={() => { this.props.DOBBack() }} src={url.family_one_image_url + 'Group147.png'} alt="" title="" /></div>
                            <div className="contest_up">
                                <h1>{dynamic_content.static_text.entercontestdob ? dynamic_content.static_text.entercontestdob.enter_contest.en : "Enter contest"}</h1>
                                <h2>{dynamic_content.static_text.entercontestdob ? dynamic_content.static_text.entercontestdob.your_date_of_birth.en : "Your Date of Birth"}</h2>
                            </div>
                            <div className="contest_process2 user_dob">
                                {isMobile &&
                                    <div>
                                        <TextField
                                            autoComplete='off'
                                            onClick={() => { this.handleClickDate() }}
                                            label={dynamic_content.static_text.entercontestdob ? dynamic_content.static_text.entercontestdob.user_date_of_birth.en : "User Date of Birth"}
                                            type="text"
                                            autoFocus={true}
                                            inputRef={(input) => { this.child_birthdate = input; }}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            value={this.state.show_date == true ? this.dateFormat(this.state.birthdate) : ""}
                                            margin="normal"
                                        />
                                        <label> </label>
                                        <div className="addChildDateIcon">
                                         <DatePickerMobile
                                            value={this.state.time}
                                            isOpen={this.state.isOpen}
                                            onSelect={this.handleSelect}
                                            onCancel={this.handleCancel}
                                            theme="ios"
                                            cancelText="Cancel"
                                            confirmText="Ok"
                                            showFormat="MM/DD/YYYY"
                                            min={new Date(this.state.minimum_date)}
                                            max={new Date(this.state.maximum_date)}
                                            dateConfig={dateConfig}
                                         />
                                        </div>
                                    </div>
                                }
                                {!isMobile && this.state.minimum_date && this.state.maximum_date &&
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Fragment>
                                           <div className="addChildDateIcon">
                                            <DatePicker
                                                openTo="year"
                                                format="dd/MM/yyyy"
                                                id="select_date"
                                                views={["year", "month", "date"]}
                                                label={dynamic_content.static_text.entercontestdob ? dynamic_content.static_text.entercontestdob.user_date_of_birth.en : "User Date of Birth"}
                                                placeholder="dd/mm/yyyy"
                                                minDate={new Date(this.state.minimum_date)}
                                                maxDate={new Date(this.state.maximum_date)}
                                                value={this.state.show_date == true ? this.state.birthdate : null}
                                                onChange={(e) => { this.handleFinalDate(e) }}
                                                margin="normal"
                                             />
                                            </div>
                                        </Fragment>
                                    </MuiPickersUtilsProvider>
                                }

                            </div>
                            {this.state.show_error_message && this.state.show_error_message != "" &&
                                <p className="error_message">
                                    {this.state.show_error_message}
                                </p>
                            }
                            <div className="contest_msg">
                                <div className="contest_opt">
                                    <Button size="medium" color="primary" onClick={() => { this.handleSubmit() }}>
                                        {dynamic_content.static_text.entercontestdob ? dynamic_content.static_text.entercontestdob.submit.en : "Submit"}
                                    </Button>
                                </div>
                                <div>{dynamic_content.static_text.entercontestdob ? ReactHtmlParser(dynamic_content.static_text.entercontestdob.contact_message.en) : "We collect your first name, last name and email address as the most basic account identifiers and default means for you to access your account and us to contact you"}</div>
                            </div>

                        </div>
                        <div className="program_entry_loader">
                            <LottieLoader />
                        </div>

                    </div>
                }
            </div>
        );
    }
}